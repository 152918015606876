<template>
<div>
    <!-- Headings -->
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Polish Comparison Report</h2>
        <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

        </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

        <div class="grid grid-cols-12 gap-x-2">
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div class="mt-2">
                    <label for="update-profile-form-6" class="form-label">Packet / Lot</label>
                    <input id="tabulator-html-filter-value" v-model="filter.packet_no" type="text" class="form-control w-full mt-2 sm:mt-0" placeholder="Search Packet / Kapan" />
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div class="mt-2">
                    <label for="update-profile-form-6" class="form-label">Select Kapan</label>
                    <TomSelect v-model="filter.kapan_id" multiple :options="{
                  placeholder: 'Select Kapan',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Process'
                    }
                  }
                }" class="w-full">
                        <!-- <option value="0">Select Kapan</option> -->
                        <option v-for="item in page_data.lot_items" :key="item.id" :value="item.id">{{item.lot_no}} ( {{ item.voucher_no ? item.voucher_no : '-' }} )</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div class="mt-2">
                    <label for="update-profile-form-6" class="form-label">Pending Type</label>
                    <TomSelect v-model="filter.pending_type" :options="{
                  placeholder: 'Select Type',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Process'
                    }
                  }
                }" class="w-full">
                        <option value="0">Pending Type</option>
                        <option value="1">Planning Pending</option>
                        <option value="2">Makeable Pending</option>
                        <option value="5">HPHT Pending</option>
                        <option value="3">Polish Pending</option>
                        <option value="4">LAB Pending</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div class="mt-2">
                    <label for="update-profile-form-6" class="form-label">Date Type</label>
                    <TomSelect v-model="filter.date_type" :options="{
                  placeholder: 'Date Type',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Process'
                    }
                  }
                }" class="w-full">
                        <option value="0">Date Type</option>
                        <option value="1">Planning</option>
                        <option value="2">Makeable</option>
                        <option value="3">Final Polish</option>
                        <option value="5">HPHT</option>
                        <option value="4">LAB</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
            <div class="mt-2">
              <div class="flex items-center"><label for="update-profile-form-6" class="form-label"
                >Select Start Date</label
              ><input
                class="show-code form-check-switch mr-0 ml-3 h-6"
                type="checkbox" v-model="filter.issue_pending"
              /></div>
              <input
                type= "date"
                id="update-profile-form-13"
                :disabled="filter.issue_pending"
                class="form-control"
                placeholder=""
                v-model="filter.s_date"
              />
            </div>

        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div class="mt-2">
            <label for="update-profile-form-6" class="form-label"
              >Select End Date</label
            >
            <input
              type= "date"
              id="update-profile-form-13"

              class="form-control"
              placeholder=""
              :disabled="filter.issue_pending"
              v-model="filter.e_date"
            />
          </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-6">
                <div class="mt-2">
                    <label for="update-profile-form-6" class="form-label">Comparisons</label>
                    <TomSelect v-model="filter.compare_items" :options="{
                  placeholder: 'Select Type',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Process'
                    }
                  }
                }" class="w-full" multiple>
                        <option value="1">Planning</option>
                        <option value="2">Makeable</option>
                        <option value="5">HPHT</option>
                        <option value="3">Final Polish</option>
                        <option value="4">LAB</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-2 md:col-span-2 xl:col-span-1">
                <div class="mt-7 flex pt-2">

                    <button id="tabulator-html-filter-reset" type="button" class="btn btn-secondary w-full mt-2 sm:mt-0 sm:ml-2" @click="onResetFilter">
                        Reset
                    </button>
                </div>
            </div>
            <div class="col-span-2 md:col-span-2 xl:col-span-1">
                <div class="mt-7 flex pt-2">

                    <button id="tabulator-html-filter-go" type="button" class="btn btn-primary w-full ml-2" tabindex="5" @click="getReportDetails()">
                        Generate
                    </button>
                </div>
            </div>
        </div>

        <div>
            <!-- BEgin: Process Report Start-->
            <div id="responsive-table" class="p-5" v-if="page_data.loading">
                <div class="preview">
                    <div class="overflow-x-auto">
                        <div class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center">
                            <LoadingIcon icon="bars" class="w-8 h-8" />
                            <div class="text-center text-xs mt-2">Getting Latest Price</div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-else-if="page_data.generate == 'gen'">
                <div class="scrollbar-hidden" v-if="page_data.items.length > 0">
                    <div class="intro-y col-span-12 overflow-auto tableFixHead mt-3 border-2">
                      <table class="w-full border-0">
                            <thead>
                                <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">#</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">KAPAN NO</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">PACKET NO</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">STOCK NO</th>
                                    <th class="p-2 text-xs lg:text-md border-r">STONE</th>
                                    <th class="p-2 text-xs lg:text-md border-r">PACKET WT(CT)</th>
                                    <th class="p-2 text-xs lg:text-md border-r">PARTY / MARKER NAME</th>
                                    <th class="p-2 text-xs lg:text-md border-r">PLAN DATE</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">PACKET NO</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">STOCK NO</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r" colspan="6" v-if="filter.compare_items.includes('1')">PLANNING</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r" colspan="12" v-if="filter.compare_items.includes('2')">MAKEABLE</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r" colspan="2" v-if="filter.compare_items.includes('5')">HPHT</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r" colspan="10" v-if="filter.compare_items.includes('3')">FINAL POLISH</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r" colspan="8" v-if="filter.compare_items.includes('4')">LAB</th>
                                  </tr>
                                  <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('1')">COLOR</th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('1')">CUT</th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('1')">CLARITY</th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('1')">SHAPE</th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('1')">PLAN WT(CT)</th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('1')">TOTAL PLAN WT(CT)</th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('2')">MAK. DATE</th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('2')">MANAGER NAME</th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('2')">WORKER NAME</th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('2')">MAK. Wt(Ct)</th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('2')">MAK. STATUS</th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('2')">COLOR</th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('2')">CUT</th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('2')">CLARITY</th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('2')">SHAPE</th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('2')">EXP WT(CT)</th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('2')">DIFF WT(CT)</th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('2')">TOTAL EXP WT(CT)</th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('5')">STATUS</th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('5')">COMMENT</th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('3')">REC. DATE</th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('3')">MANAGER NAME</th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('3')">WORKER NAME</th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('3')">COLOR</th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('3')">CUT</th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('3')">CLARITY</th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('3')">SHAPE</th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('3')">POL WT(CT)</th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('3')">DIFF WT(CT)</th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('3')">TOTAL POLISH(CT)</th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('4')">REC. DATE</th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('4')">COLOR</th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('4')">CUT</th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('4')">CLARITY</th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('4')">SHAPE</th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('4')">POL WT(CT)</th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('4')">DIFF WT(CT)</th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('4')">TOTAL POLISH(CT)</th>
                                  </tr>
                                  <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border">{{ page_data.total.total_packets ? page_data.total.total_packets : '0' }}</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border">{{ (page_data.total.total_issue_weight ? $h.myFloat(page_data.total.total_issue_weight) : '0') }}</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="filter.compare_items.includes('1')"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="filter.compare_items.includes('1')"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="filter.compare_items.includes('1')"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="filter.compare_items.includes('1')"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="filter.compare_items.includes('1')">{{ (page_data.total.total_planning_polish_weight ? $h.myFloat(page_data.total.total_planning_polish_weight) : '0') }}</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="filter.compare_items.includes('1')"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="filter.compare_items.includes('2')"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="filter.compare_items.includes('2')"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="filter.compare_items.includes('2')"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="filter.compare_items.includes('2')">{{ (page_data.total.total_makeable_weight ? $h.myFloat(page_data.total.total_makeable_weight) : '0') }}</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="filter.compare_items.includes('2')"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="filter.compare_items.includes('2')"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="filter.compare_items.includes('2')"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="filter.compare_items.includes('2')"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="filter.compare_items.includes('2')"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="filter.compare_items.includes('2')">{{ (page_data.total.total_makeable_polish_weight ? $h.myFloat(page_data.total.total_makeable_polish_weight) : '0') }}</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="filter.compare_items.includes('2')">{{ (page_data.total.total_diff_makeable_weight ? $h.myFloat(page_data.total.total_diff_makeable_weight) : '0') }}</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="filter.compare_items.includes('2')"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="filter.compare_items.includes('5')"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="filter.compare_items.includes('5')"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="filter.compare_items.includes('3')"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="filter.compare_items.includes('3')"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="filter.compare_items.includes('3')"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="filter.compare_items.includes('3')"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="filter.compare_items.includes('3')"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="filter.compare_items.includes('3')"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="filter.compare_items.includes('3')"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="filter.compare_items.includes('3')">{{ (page_data.total.total_final_weight ? $h.myFloat(page_data.total.total_final_weight) : '0') }}</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="filter.compare_items.includes('3')">{{ (page_data.total.total_diff_final_weight ? $h.myFloat(page_data.total.total_diff_final_weight) : '0') }}</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="filter.compare_items.includes('3')"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="filter.compare_items.includes('4')"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="filter.compare_items.includes('4')"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="filter.compare_items.includes('4')"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="filter.compare_items.includes('4')"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="filter.compare_items.includes('4')"></th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="filter.compare_items.includes('4')">{{ (page_data.total.total_lab_weight ? $h.myFloat(page_data.total.total_lab_weight) : '0') }}</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="filter.compare_items.includes('4')">{{ (page_data.total.total_diff_lab_weight ? $h.myFloat(page_data.total.total_diff_lab_weight) : '0') }}</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="filter.compare_items.includes('4')"></th>
                                  </tr>
                            </thead>
                                <tbody>

                                  <template v-for="(main_pack,index) in page_data.items" :key="index">
                                    <template v-for="(item,inx) in main_pack.packet_details" :key="inx">

                                      <tr class="border-b hover:bg-gray-200 p-0" :class="{'bg-gray-100': (inx % 2) == 0}">
                                          <template v-if="inx == 0">
                                            <td :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ index + 1 }}</td>
                                            <td :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs lg:text-md text-center border-r">{{ main_pack.lot_no }} <br> ({{ main_pack.voucher_no }})</td>
                                            <td :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ main_pack.number }}</td>
                                            <td :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ main_pack.vepari_no }}</td>
                                            <td :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ main_pack.stone_name }}</td>
                                            <td :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ main_pack.weight }}</td>
                                            <td :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.planning_employee_short_name }}</td>
                                            <td :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs lg:text-md text-center border-r">{{ item.pl_date }}</td>
                                          </template>
                                          <th class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.number ? item.number : '-') }}</th>
                                          <th class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.vepari_no ? item.vepari_no : '-') }}</th>
                                          <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="filter.compare_items.includes('1')">{{ (item.planning_color_name ? item.planning_color_name : '-') }}</td>
                                          <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="filter.compare_items.includes('1')">{{ (item.planning_cut_name ? item.planning_cut_name : '-') }}</td>
                                          <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="filter.compare_items.includes('1')">{{ (item.planning_purity_name ? item.planning_purity_name : '-') }}</td>
                                          <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="filter.compare_items.includes('1')">{{ (item.planning_shape_name ? item.planning_shape_name : '-') }}</td>
                                          <th class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="filter.compare_items.includes('1')">{{ (item.pl_ex_wt ? item.pl_ex_wt : '-') }}</th>
                                          <th v-if="inx == 0 && filter.compare_items.includes('1')" :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"><span v-if="item.pl_date != null"><span>{{ (main_pack.total_planning_polish_weight ? $h.myFloat(main_pack.total_planning_polish_weight) : '-') }}</span><br><hr class="my-3"><span>{{ (main_pack.total_planning_polish_percent ? main_pack.total_planning_polish_percent + ' %' : '-') }}</span></span></th>
                                          <th v-if="filter.compare_items.includes('2')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.mk_date ? item.mk_date : '-') }}</th>
                                          <th v-if="filter.compare_items.includes('2')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.makeable_manager_first_name ? item.makeable_manager_first_name : '-' }} {{ (item.makeable_manager_last_name ? item.makeable_manager_last_name : '') }}</th>
                                          <th v-if="filter.compare_items.includes('2')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.makeable_employee_short_name ? item.makeable_employee_short_name : '-' }}</th>
                                          <th v-if="filter.compare_items.includes('2')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.mk_wt ? item.mk_wt : '-') }}</th>
                                          <th v-if="filter.compare_items.includes('2')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.for_p == 1 ? '4P' : (item.semi_polish == 1 ? 'Semi Polish' : '')) }}</th>
                                          <td v-if="filter.compare_items.includes('2')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" :class="{'text-theme-6' : ((item.mk_color > item.pl_color) && item.pl_color > 0 && item.mk_color > 0),'text-theme-9' : ((item.pl_color > item.mk_color) && item.pl_color > 0 && item.mk_color > 0)}">{{ (item.makeable_color_name ? item.makeable_color_name : '-') }}</td>
                                          <td v-if="filter.compare_items.includes('2')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" :class="{'text-theme-6' : ((item.mk_cut > item.pl_cut) && item.pl_cut > 0 && item.mk_cut > 0),'text-theme-9' : ((item.pl_cut > item.mk_cut) && item.pl_cut > 0 && item.mk_cut > 0)}">{{ (item.makeable_cut_name ? item.makeable_cut_name : '-') }}</td>
                                          <td v-if="filter.compare_items.includes('2')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" :class="{'text-theme-6' : ((item.mk_purity > item.pl_purity) && item.pl_purity > 0 && item.mk_purity > 0),'text-theme-9' : ((item.pl_purity > item.mk_purity) && item.pl_purity > 0 && item.mk_purity > 0)}">{{ (item.makeable_purity_name ? item.makeable_purity_name : '-') }}</td>
                                          <td v-if="filter.compare_items.includes('2')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.makeable_shape_name ? item.makeable_shape_name : '-') }}</td>
                                          <th v-if="filter.compare_items.includes('2')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.mk_ex_wt ? item.mk_ex_wt : '-') }}</th>
                                          <th v-if="filter.compare_items.includes('2')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.mk_ex_wt ? $h.myFloat(item.mk_ex_wt - item.pl_ex_wt) : '-') }}</th>
                                          <th v-if="inx == 0 && filter.compare_items.includes('2')" :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"><span v-if="item.mk_date != null"><span class="m-5">{{ (main_pack.total_makeable_polish_weight ? $h.myFloat(main_pack.total_makeable_polish_weight) : '-') }}</span><br><hr class="my-3"><span>{{ (main_pack.total_makeable_polish_percent ? main_pack.total_makeable_polish_percent + ' %' : '-') }}</span><br><hr class="my-3"><span class="">{{ (main_pack.total_diff_makeable_percent ? main_pack.total_diff_makeable_percent + ' %' : '-') }}</span></span></th>
                                          <th v-if="filter.compare_items.includes('5')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.hp_date ? 'YES' : 'NO') }}</th>
                                          <th v-if="filter.compare_items.includes('5')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.issue_comment ? item.issue_comment : '-') }}</th>
                                          <th v-if="filter.compare_items.includes('3')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.po_date ? item.po_date : '-') }}</th>
                                          <th v-if="filter.compare_items.includes('3')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.final_manager_first_name ? item.final_manager_first_name : '-' }} {{ (item.final_manager_last_name ? item.final_manager_last_name : '') }}</th>
                                          <th v-if="filter.compare_items.includes('3')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.final_employee_short_name ? item.final_employee_short_name : '-' }}</th>
                                          <td v-if="filter.compare_items.includes('3')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" :class="{'text-theme-6' : ((item.po_color > item.mk_color) && item.po_color > 0 && item.mk_color > 0),'text-theme-9' : ((item.po_color < item.mk_color) && item.po_color > 0 && item.mk_color > 0)}">{{ (item.final_color_name ? item.final_color_name : '-') }}</td>
                                          <td v-if="filter.compare_items.includes('3')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" :class="{'text-theme-6' : ((item.po_cut > item.mk_cut) && item.po_cut > 0 && item.mk_cut > 0),'text-theme-9' : ((item.po_cut < item.mk_cut) && item.po_cut > 0 && item.mk_cut > 0)}">{{ (item.final_cut_name ? item.final_cut_name : '-') }}</td>
                                          <td v-if="filter.compare_items.includes('3')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" :class="{'text-theme-6' : ((item.po_purity > item.mk_purity) && item.po_purity > 0 && item.mk_purity > 0),'text-theme-9' : ((item.po_purity < item.mk_purity) && item.po_purity > 0 && item.mk_purity > 0)}">{{ (item.final_purity_name ? item.final_purity_name : '-') }}</td>
                                          <td v-if="filter.compare_items.includes('3')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.final_shape_name ? item.final_shape_name : '-') }}</td>
                                          <th v-if="filter.compare_items.includes('3')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.po_f_pw ? item.po_f_pw : '-') }}</th>
                                          <th v-if="filter.compare_items.includes('3')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.po_f_pw ? $h.myFloat(item.po_f_pw - item.mk_ex_wt) : '-') }}</th>
                                          <th v-if="inx == 0 && filter.compare_items.includes('3')" :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"><span v-if="item.po_date != null"><span class="m-5">{{ (main_pack.total_final_weight ? $h.myFloat(main_pack.total_final_weight) : '-') }}</span><br><hr class="my-3"><span>{{ (main_pack.total_final_percent ? main_pack.total_final_percent + ' %' : '-') }}</span><br><hr class="my-3"><span class="">{{ (main_pack.total_diff_final_percent ? main_pack.total_diff_final_percent + ' %' : '-') }}</span></span></th>
                                          <th v-if="filter.compare_items.includes('4')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.lab_date ? item.lab_date : '-') }}</th>
                                          <td v-if="filter.compare_items.includes('4')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" :class="{'text-theme-6' : ((item.lab_color > item.po_color) && item.po_color > 0 && item.lab_color > 0),'text-theme-9' : ((item.po_color > item.lab_color) && item.po_color > 0 && item.lab_color > 0)}">{{ (item.lab_color_name ? item.lab_color_name : '-') }}</td>
                                          <td v-if="filter.compare_items.includes('4')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" :class="{'text-theme-6' : ((item.lab_cut > item.po_cut) && item.po_cut > 0 && item.lab_cut > 0),'text-theme-9' : ((item.po_cut > item.lab_cut) && item.po_cut > 0 && item.lab_cut > 0)}">{{ (item.lab_cut_name ? item.lab_cut_name : '-') }}</td>
                                          <td v-if="filter.compare_items.includes('4')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" :class="{'text-theme-6' : ((item.lab_purity > item.po_purity) && item.po_purity > 0 && item.lab_purity > 0),'text-theme-9' : ((item.po_purity > item.lab_purity) && item.po_purity > 0 && item.lab_purity > 0)}">{{ (item.lab_purity_name ? item.lab_purity_name : '-') }}</td>
                                          <td v-if="filter.compare_items.includes('4')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.lab_shape_name ? item.lab_shape_name : '-') }}</td>
                                          <th v-if="filter.compare_items.includes('4')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.lab_wt ? item.lab_wt : '-') }}</th>
                                          <th v-if="filter.compare_items.includes('4')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.lab_wt ? $h.myFloat(item.lab_wt - item.po_f_pw) : '-') }}</th>
                                          <th v-if="inx == 0 && filter.compare_items.includes('4')" :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"><span v-if="item.lab_date != null"><span class="m-5">{{ (main_pack.total_lab_weight ? $h.myFloat(main_pack.total_lab_weight) : '-') }}</span><br><hr class="my-3"><span>{{ (main_pack.total_lab_percent ? main_pack.total_lab_percent + ' %' : '-') }}</span><br><hr class="my-3"><span class="">{{ (main_pack.total_diff_lab_percent ? main_pack.total_diff_lab_percent + ' %' : '-') }}</span></span></th>
                                      </tr>
                                    </template>
                                  </template>

                                  <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                    <th class="p-2 text-xs lg:text-md border"></th>
                                    <th class="p-2 text-xs lg:text-md border"></th>
                                    <th class="p-2 text-xs lg:text-md border">{{ (page_data.total.total_packets ? page_data.total.total_packets : '0') }}</th>
                                    <th class="p-2 text-xs lg:text-md border"></th>
                                    <th class="p-2 text-xs lg:text-md border"></th>
                                    <th class="p-2 text-xs lg:text-md border">{{ (page_data.total.total_issue_weight ? $h.myFloat(page_data.total.total_issue_weight) : '0') }}</th>
                                    <th class="p-2 text-xs lg:text-md border"></th>
                                    <th class="p-2 text-xs lg:text-md border"></th>
                                    <th class="p-2 text-xs lg:text-md border"></th>
                                    <th class="p-2 text-xs lg:text-md border"></th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('1')"></th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('1')"></th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('1')"></th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('1')"></th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('1')">{{ (page_data.total.total_planning_polish_weight ? $h.myFloat(page_data.total.total_planning_polish_weight) : '0') }}</th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('1')"></th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('2')"></th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('2')"></th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('2')"></th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('2')">{{ (page_data.total.total_makeable_weight ? $h.myFloat(page_data.total.total_makeable_weight) : '0') }}</th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('2')"></th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('2')"></th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('2')"></th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('2')"></th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('2')"></th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('2')">{{ (page_data.total.total_makeable_polish_weight ? $h.myFloat(page_data.total.total_makeable_polish_weight) : '0') }}</th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('2')">{{ (page_data.total.total_diff_makeable_weight ? $h.myFloat(page_data.total.total_diff_makeable_weight) : '0') }}</th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('2')"></th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('5')"></th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('5')"></th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('3')"></th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('3')"></th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('3')"></th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('3')"></th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('3')"></th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('3')"></th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('3')"></th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('3')">{{ (page_data.total.total_final_weight ? $h.myFloat(page_data.total.total_final_weight) : '0') }}</th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('3')">{{ (page_data.total.total_diff_final_weight ? $h.myFloat(page_data.total.total_diff_final_weight) : '0') }}</th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('3')"></th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('4')"></th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('4')"></th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('4')"></th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('4')"></th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('4')"></th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('4')">{{ (page_data.total.total_lab_weight ? $h.myFloat(page_data.total.total_lab_weight) : '0') }}</th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('4')">{{ (page_data.total.total_diff_lab_weight ? $h.myFloat(page_data.total.total_diff_lab_weight) : '0') }}</th>
                                    <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('4')"></th>
                                  </tr>

                              </tbody>

                        </table>
                    </div>
                </div>
                <div class="scrollbar-hidden text-center my-5" v-else> Records Not Found</div>
            </div>
        </div>
    </div>
    <!-- END: HTML Table Data -->

</div>
</template>

<script>
/* eslint-disable */
import {
    BASE_URL
} from '@/constants/config';
import axios from 'axios';
import moment from 'moment';
import {
    defineComponent,
    onMounted,
    reactive,
    toRef,
    watch,
    computed
} from 'vue';
import store from '@/store'
import { helper as $h } from '@/utils/helper'

export default defineComponent({
    setup() {
        const user_details = computed(() => store.state.user.currentUser)
        const page_data = reactive({
            total: [],
            items: [],
            manager_items: [],
            process_items: [],
            lot_items: [],
            employee_items: [],
            loading: false,
            generate: 'no'
        })

        const filter = reactive({
          packet_no: '',
          kapan_id: [],
          pending_type: '0',
          compare_items: ['1', '2', '3', '4', '5'],
          issue_pending : false,
          date_type: '1',
          s_date: moment().startOf('month').format('YYYY-MM-DD'),
          e_date:moment().format('YYYY-MM-DD'),
        })

        const api_filter = reactive({
            packet_no: '',
            kapan_id: [],
            compare_items: ['1', '2', '3', '4', '5'],
            pending_type: '0',
            date_type: '1',
            issue_pending : false,
            s_date: moment().startOf('month').format('YYYY-MM-DD'),
            e_date:moment().format('YYYY-MM-DD'),
        })

        const getReportDetails = async () => {

            api_filter.packet_no = filter.packet_no
            api_filter.kapan_id = filter.kapan_id
            api_filter.pending_type = filter.pending_type
            api_filter.compare_items = filter.compare_items
            api_filter.date_type = filter.date_type
            api_filter.issue_pending = filter.issue_pending
            api_filter.s_date = filter.s_date
            api_filter.e_date = filter.e_date

            const params = {
                packet_no: window.btoa(api_filter.packet_no),
                kapan_arr: api_filter.kapan_id,
                pending_type: api_filter.pending_type == '0' ? '' : window.btoa(api_filter.pending_type),
                date_type: api_filter.date_type == '0' ? '' : window.btoa(api_filter.date_type),
                issue_pending : api_filter.issue_pending,
                s_date : window.btoa(api_filter.s_date),
                e_date : window.btoa(api_filter.e_date),
            }
            page_data.loading = true

            let promise = axios({
                url: 'api/report/polish_comparison_report',
                method: "post",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),

                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    page_data.items = data.items
                    page_data.total = data.total
                    page_data.loading = false
                    page_data.generate = 'gen'
                  })
                  .catch(_error => {
                    page_data.items = []
                    page_data.total = []
                    page_data.generate = 'gen'
                    page_data.loading = false
                });
        }

        const getLotDetails = async () => {
            let params = {
            }
            let api_url = 'api/master/dp_lot_all_details'

            let promise = axios({
                url: api_url,
                method: "post",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),

                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    filter.kapan_id = []
                    page_data.lot_items = data.items
                })
                .catch(_error => {
                    filter.kapan_id = []
                    page_data.lot_items = []
                });
        }

        const getWorkerDetails = async () => {

            let params = {
                manager_id: user_details.value.user_role == 1 ? '' : user_details.value.account_id,
                employee_type: window.btoa('3'),
            }
            let api_url = ''

            if (filter.department_id == '0') {

                api_url = 'api/master/get_employees_by_manager_employee_type'
            } else {
                api_url = 'api/master/get_employees_by_manager_employee_type'
            }
            let promise = axios({
                url: api_url,
                method: "post",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),

                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    filter.pending_type = '0'
                    page_data.employee_items = data.items
                })
                .catch(_error => {
                    filter.pending_type = '0'
                    page_data.employee_items = []
                });
        }

        function onResetFilter() {
          filter.packet_no = ''
          filter.kapan_id = []
          filter.compare_items = ['1', '2', '3', '4', '5']
          filter.pending_type = '0'
          filter.date_type = '1'
          filter.issue_pending = false
          filter.s_date = moment().startOf('month').format('YYYY-MM-DD')
          filter.e_date = moment().format('YYYY-MM-DD')

          // getReportDetails()
        }
        onMounted(() => {
            getLotDetails()
            // getReportDetails()
        })
        return {
            page_data,
            filter,
            api_filter,
            user_details,
            getReportDetails,
            onResetFilter,
            getWorkerDetails,
        }
    }
})
</script>
<style scoped>
.tableFixHead {
        overflow-y: auto; /* make the table scrollable if height is more than 200 px  */
        max-height: 630px; /* gives an initial height of 200px to the table */
      }
      .tableFixHead thead {
        position: sticky; /* make the table heads sticky */
        top: 0px; /* table head will be placed from the top of the table and sticks to it */
      }
      </style>
