<template>
  <div>
      <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
          <h2 class="text-lg font-medium mr-auto">Rough Kapans</h2>
          <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
              <button @click="open_purchase_modal" class="btn btn-primary shadow-md mr-2" v-if="user_details.user_role == 1 || user_details.user_role == 3 || user_details.user_role == 8">Add Purchase</button>
          </div>
      </div>
      <!-- BEGIN: HTML Table Data -->
      <div class="intro-y box p-5 mt-5">
          <div class="flex flex-col">
              <div class="grid grid-cols-12 gap-x-2">
                  <div class="col-span-6 md:col-span-3 xl:col-span-2">
                      <div>
                          <label for="update-profile-form-6" class="form-label py-1">Kapan No</label>
                          <input id="tabulator-html-filter-value" v-model="filter_data.kapan_no" type="text" class="form-control 2xl:w-full mt-2 sm:mt-0" placeholder="Kapan No" />
                      </div>
                  </div>
                  <div class="col-span-6 md:col-span-3 xl:col-span-2" v-if="user_details.user_role != 3">
                      <div>
                          <label for="update-profile-form-6" class="form-label py-1">Select Party</label>
                          <TomSelect v-model="filter_data.party_id" :options="{
                    placeholder: 'Select Party',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Party'
                      }
                    }
                  }" class="w-full">
                              <option value="0">Select Party</option>
                              <option v-for="item in page_data.party_details" :key="item.id" :value="item.id">{{item.firm_name ? item.firm_name : item.first_name + ' ' +  item.last_name}}</option>
                          </TomSelect>
                      </div>
                  </div>
                  <div class="col-span-6 md:col-span-3 xl:col-span-2">
                      <div>
                          <label for="update-profile-form-6" class="form-label py-1">Purchase Type</label>
                          <TomSelect v-model="filter_data.type" :options="{
                    placeholder: 'Select Purchase Type',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Party'
                      }
                    }
                  }" class="w-full">
                              <option value="0">Select Type</option>
                              <option value="makeable">Makeable</option>
                              <option value="rough">Rough</option>
                          </TomSelect>
                      </div>
                  </div>
                  <div class="col-span-6 md:col-span-3 xl:col-span-2">
                      <div>
                          <label for="update-profile-form-6" class="form-label py-1">Select Stone</label>
                          <TomSelect v-model="filter_data.stone_id" :options="{
                    placeholder: 'Select Party',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Party'
                      }
                    }
                  }" class="w-full">
                              <option value="0">Select Stone</option>
                              <option value="1">N</option>
                              <option value="2">CVD</option>
                              <option value="3">HPHT</option>
                          </TomSelect>
                      </div>
                  </div>
                  <div class="col-span-6 md:col-span-3 xl:col-span-2">
                      <div>
                          <label for="update-profile-form-6" class="form-label py-1">Status</label>
                          <TomSelect v-model="filter_data.status" :options="{
                    placeholder: 'Select Status',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Series'
                      }
                    }
                  }" class="w-full">
                              <option value="0">Select Status</option>
                              <option value="stock">In Stock</option>
                              <option value="1">Active</option>
                              <option value="2">Processing</option>
                              <option value="3">Cancelled</option>
                              <option value="4">Delivered</option>
                          </TomSelect>
                      </div>
                  </div>
                  <div class="col-span-2 md:col-span-2 xl:col-span-1">
                      <div class="mt-7 flex pt-2">

                          <button id="tabulator-html-filter-reset" type="button" class="btn btn-secondary w-full mt-2 sm:mt-0 sm:ml-2" @click="onResetFilter">
                              Reset
                          </button>
                      </div>
                  </div>
                  <div class="col-span-2 md:col-span-2 xl:col-span-1">
                      <div class="mt-7 flex pt-2">

                          <button id="tabulator-html-filter-go" type="button" class="btn btn-primary w-full ml-2" tabindex="5" @click="initTabulator()">
                              Go
                          </button>
                      </div>
                  </div>
              </div>
              <!-- <div class="flex mt-5 sm:mt-0">
                  <button id="tabulator-print" class="btn btn-outline-secondary w-1/2 sm:w-auto mr-2" @click="onPrint">
                      <PrinterIcon class="w-4 h-4 mr-2" /> Print
                  </button>
                  <div class="dropdown w-1/2 sm:w-auto">
                      <button class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto" aria-expanded="false">
                          <FileTextIcon class="w-4 h-4 mr-2" /> Export
                          <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
                      </button>
                      <div class="dropdown-menu w-40">
                          <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                              <a id="tabulator-export-csv" href="javascript:;" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md" @click="onExportCsv">
                                  <FileTextIcon class="w-4 h-4 mr-2" /> Export CSV
                              </a>
                              <a id="tabulator-export-json" href="javascript:;" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md" @click="onExportJson">
                                  <FileTextIcon class="w-4 h-4 mr-2" /> Export JSON
                              </a>
                              <a id="tabulator-export-xlsx" href="javascript:;" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md" @click="onExportXlsx">
                                  <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX
                              </a>
                              <a id="tabulator-export-html" href="javascript:;" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md" @click="onExportHtml">
                                  <FileTextIcon class="w-4 h-4 mr-2" /> Export HTML
                              </a>
                          </div>
                      </div>
                  </div>
              </div> -->
          </div>
          <div class="grid grid-cols-12 gap-4">
              <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
                  <div class="report-box zoom-in">
                      <div class="box p-2">
                          <div class="flex items-center justify-between">
                              <!-- <ActivityIcon class="report-box__icon text-theme-10" /> -->
                              <div class="text-base text-gray-600">Total Kapan</div>
                              <div class="text-xl font-medium leading-8">{{ page_data.total.total_purchase }}</div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
                  <div class="report-box zoom-in">
                      <div class="box p-2">
                          <div class="flex items-center justify-between">
                              <!-- <ActivityIcon class="report-box__icon text-theme-10" /> -->
                              <div class="text-base text-gray-600">Total Weight</div>
                              <div class="text-xl font-medium leading-8">{{ page_data.total.total_weight ? $h.myFloat(page_data.total.total_weight) + ' ct' : '0.00 ct' }}</div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
                  <div class="report-box zoom-in">
                      <div class="box p-2">
                          <div class="flex items-center justify-between">
                              <!-- <CheckCircleIcon class="report-box__icon text-theme-11" /> -->
                              <div class="text-base text-gray-600">Cr. Weight</div>
                              <div class="text-xl font-medium leading-8">{{ page_data.total.total_cr_weight ? $h.myFloat(page_data.total.total_cr_weight) + ' ct' : '0.00 ct' }}</div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="overflow-x-auto scrollbar-hidden">
              <div id="tabulator" ref="tableRef" class="mt-5 table-report table-report--tabulator"></div>
          </div>
      </div>
      <!-- END: HTML Table Data -->
      <!-- END: Data List -->
      <div id="new-rough-purchase-modal" class="modal" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog modal-lg">
              <div class="modal-content">
                  <a data-dismiss="modal" href="javascript:;">
                      <XIcon class="w-8 h-8 text-gray-500" />
                  </a>
                  <div class="modal-body p-10">
                      <!-- <div id="vertical-form"> -->
                      <div class="flex flex-col justify-between mt-5">
                          <!-- BEGIN: Data List -->
                          <div class="intro-y overflow-auto">
                              <div class="flex items-center">
                                  <h2 class="intro-y text-lg font-medium mr-5" v-if="form_data.id == ''">New Purchase</h2>
                                  <h2 class="intro-y text-lg font-medium mr-5" v-else>Purchase No : <span class="ml-2 text-primary-1">{{ form_data.lot_no }}</span></h2>
                              </div>
                          </div>
                          <div class="grid grid-cols-12 gap-4">
                              <div class="intro-y col-span-12">
                                  <!-- BEGIN: Input -->
                                  <div class="">
                                      <div id="input" class="py-5">
                                          <div class="preview" v-if="(form_data.purchase_select == false && form_data.purchase_type == 2) || form_data.purchase_type == 1">
                                              <div v-if="[1,2,8].includes(user_details.user_role)">
                                                  <div v-if="!form_data.id">

                                                      <label class="form-label">Purchase Type</label>
                                                      <TomSelect v-model="form_data.purchase_type" :options="{
                            placeholder: 'Select Type'
                          }" class="w-full">
                                                          <option value="1">Rough Purchase</option>
                                                          <option value="2">Rejection Purchase</option>
                                                      </TomSelect>

                                                  </div>
                                                  <div v-if="form_data.id">

                                                      <label class="form-label">Purchase Type</label>
                                                      <TomSelect disabled v-model="form_data.purchase_type" :options="{
                            placeholder: 'Select Type'
                          }" class="w-full">
                                                          <option value="1">Rough Purchase</option>
                                                          <option value="2">Rejection Purchase</option>
                                                      </TomSelect>

                                                  </div>
                                              </div>
                                              <div class="mt-3">
                                                  <div class="sm:grid grid-cols-2 gap-2">
                                                      <div v-if="[1,2,8].includes(user_details.user_role)">
                                                        <label class="form-label">Select Party</label>
                                                        <TomSelect v-model="form_data.party" @change="getHomeParty" :options="{
                                                            placeholder: 'Select your favorite actors'
                                                          }" class="w-full">
                                                            <!-- <option value="0">Select Party</option> -->
                                                            <option v-for="(party,index) in page_data.party_details" :key="index" :value="party.id">{{ party.first_name }} {{ party.last_name }}</option>
                                                        </TomSelect>
                                                      </div>
                                                      <div class="mt-2 sm:mt-0">
                                                          <div id="form-label" class="">Purchase Type</div>
                                                          <select class="form-select mt-2" v-model="form_data.packet_type" aria-label="Default select example">
                                                              <option value="makeable">Makeable</option>
                                                              <option value="rough">Rough</option>
                                                          </select>
                                                          <!-- <template v-if="form_validate.stone_id.$error">
                                                              <div v-for="(error, index) in form_validate.stone_id.$errors" :key="index" class="text-theme-6 mt-2">
                                                                  {{ error.$message }}
                                                              </div>
                                                          </template> -->
                                                      </div>
                                                  </div>
                                              </div>
                                              <div class="mt-3">
                                                  <!-- <label class="form-label">Kapan No & Stone Type</label> -->
                                                  <div class="sm:grid grid-cols-2 gap-2">
                                                      <div>
                                                          <div class="">
                                                              <div id="input-group-4">Kapan <span class="text-theme-6"> * </span></div>

                                                              <input id="pos-form-1" v-model="form_data.kapan" name="kapan" type="text" class="form-control flex-1" :class="{ 'border-theme-6': form_validate.kapan.$error }" placeholder="Kapan No" />
                                                          </div>
                                                          <template v-if="form_validate.kapan.$error">
                                                              <div v-for="(error, index) in form_validate.kapan.$errors" :key="index" class="text-theme-6 mt-2">
                                                                  {{ error.$message }}
                                                              </div>
                                                          </template>
                                                      </div>
                                                      <div class="mt-2 sm:mt-0">
                                                          <div id="input-group-3" class="">Stone <span class="text-theme-6"> * </span></div>
                                                          <select class="form-select" v-model="form_data.stone_id" @change="loadValueByStone" aria-label="Default select example">
                                                              <option value="1">N</option>
                                                              <option value="2">CVD</option>
                                                              <option value="3">HPHT</option>
                                                          </select>
                                                          <!-- <template v-if="form_validate.stone_id.$error">
                                                              <div v-for="(error, index) in form_validate.stone_id.$errors" :key="index" class="text-theme-6 mt-2">
                                                                  {{ error.$message }}
                                                              </div>
                                                          </template> -->
                                                      </div>
                                                  </div>
                                              </div>
                                              <div class="mt-3" v-if="form_data.purchase_type == 1">
                                                  <!-- <label class="form-label">No. of Pieces & Total Weight</label> -->
                                                  <div class="sm:grid grid-cols-2 gap-2">
                                                      <div>
                                                          <div>

                                                              <div class="">
                                                                  <div id="input-group-3" :class="{ 'border-theme-6': form_validate.weight.$error }">Pieces <span class="text-theme-6"> * </span></div>
                                                                  <input type="text" class="form-control" name="piece" :class="{ 'border-theme-6': form_validate.piece.$error }" autocomplete="off" @keypress="$h.preventInvalidInput" v-model="form_data.piece" placeholder="No of Pieces" aria-describedby="input-group-3" />
                                                              </div>
                                                              <template v-if="form_validate.piece.$error">
                                                                  <div v-for="(error, index) in form_validate.piece.$errors" :key="index" class="text-theme-6 mt-2">
                                                                      {{ error.$message }}
                                                                  </div>
                                                              </template>

                                                          </div>
                                                      </div>
                                                      <div class="mt-2 sm:mt-0">
                                                          <div class="">
                                                              <div id="input-group-4" :class="{ 'border-theme-6': form_validate.weight.$error }">Weight (Ct) <span class="text-theme-6"> * </span></div>
                                                              <input type="text" class="form-control" name="weight" :class="{ 'border-theme-6': form_validate.weight.$error }" autocomplete="off" @keypress="$h.preventInvalidInput" v-model="form_data.weight" placeholder="Weight" aria-describedby="input-group-4" />
                                                          </div>
                                                          <template v-if="form_validate.weight.$error">
                                                              <div v-for="(error, index) in form_validate.weight.$errors" :key="index" class="text-theme-6 mt-2">
                                                                  {{ error.$message }}
                                                              </div>
                                                          </template>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div class="mt-3" v-if="page_data.home_party == 1">
                                                  <!-- <label class="form-label">Rate && Majuri Type</label> -->
                                                  <div class="sm:grid grid-cols-2 gap-2">
                                                      <div>

                                                          <div class="mt-2 sm:mt-0">
                                                              <div id="input-group-4">Rate</div>
                                                              <input @change="getWeight" type="text" class="form-control" v-model="form_data.per_ct_rate" placeholder="Per Carat Rate ($)" aria-describedby="input-group-4" />
                                                          </div>
                                                      </div>
                                                      <div>

                                                          <div class="mt-2 sm:mt-0">
                                                              <div id="input-group-3">Type</div>
                                                              <select class="form-select" v-model="form_data.majuri_type" @change="get_margin_costing" aria-label="Default select example">
                                                                  <option value="1">Percent(%)</option>
                                                                  <option value="2">Fixed</option>
                                                              </select>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div class="mt-3" v-if="page_data.home_party == 1">
                                                  <!-- <label class="form-label"> Majuri && Margin</label> -->
                                                  <div class="sm:grid grid-cols-2 gap-2">
                                                      <div>

                                                          <div class="mt-2 sm:mt-0">
                                                              <div id="input-group-4">Majuri</div>
                                                              <input type="text" class="form-control" @keypress="$h.preventInvalidInput" v-model="form_data.majuri_rate" placeholder="Majuri" aria-describedby="input-group-4" />
                                                          </div>
                                                      </div>
                                                      <div>

                                                          <div class="mt-2 sm:mt-0">
                                                              <div id="input-group-4">Margin</div>
                                                              <input type="text" class="form-control" @keypress="$h.preventInvalidInput" v-model="form_data.margin_percent" placeholder="Margin (%)" aria-describedby="input-group-4" />
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div class="mt-3">
                                                  <!-- <label class="form-label">Stone && Est Value</label> -->
                                                  <div class="sm:grid grid-cols-2 gap-2">
                                                      <div>

                                                          <div class="" v-if="page_data.home_party == 1">
                                                              <div id="input-group-3">Duration</div>
                                                              <input type="text" class="form-control" @keypress="$h.preventInvalidInput" v-model="form_data.due" placeholder="Duration" aria-describedby="input-group-3" />
                                                          </div>
                                                      </div>
                                                      <div>
                                                          <div class="mt-2 sm:mt-0">
                                                              <div class="flex justify-end items-center mt-5">
                                                                  <button type="button" :disabled="page_data.loading == true" class="btn btn-secondary w-24 m-auto mr-3" data-dismiss="modal" href="javascript:;">Cancel</button>
                                                                  <button v-if="form_data.purchase_type == 1" type="button" @click="add_purchase" :disabled="page_data.loading == true" class="btn btn-primary w-24 m-auto">{{ form_data.id ? 'Update' : 'Create' }}
                                                                      <LogInIcon v-if="page_data.loading == false" class="h-4 w-4 ml-2 -mt-1" />
                                                                      <LoadingIcon v-if="page_data.loading == true" icon="oval" color="white" class="w-4 h-4 ml-2" /></button>
                                                                  <button v-if="form_data.purchase_type == 2 && form_data.purchase_select == false" type="button" @click="form_data.purchase_select = true" class="btn btn-primary w-24 m-auto">Next</button>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                          <div class="preview" v-if="(form_data.purchase_select == true && form_data.purchase_type == 2)">
                                              <div>
                                                  <div>

                                                      <label class="form-label">Select Kapan</label>
                                                      <TomSelect v-model="form_data.purchase_list" multiple :options="{
                            placeholder: 'Select Kapan'
                          }" class="w-full">
                                                          <!-- <option value="0">Select Party</option> -->
                                                          <option v-for="(party,index) in page_data.rejection_items" :key="index" :value="party.kapan_id">{{ party.lot_no }} ({{ party.voucher_no }})</option>
                                                      </TomSelect>

                                                  </div>
                                              </div>
                                              <div class="mt-3">
                                                  <div>

                                                      <table class="w-full border">
                                                          <thead>
                                                              <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                                                  <!-- <th class="py-2 border-b text-xs lg:text-md text-center border-r"> # </th> -->
                                                                  <td class="py-2 border-b text-center border-r">NO</td>
                                                                  <td class="py-2 border-b text-center border-r">Kapan No</td>
                                                                  <td class="py-2 border-b text-center border-r">Avail. PCS</td>
                                                                  <td class="py-2 border-b text-center border-r">Avail. Wt (Ct)</td>
                                                                  <td class="py-2 border-b text-center border-r">New PCS</td>
                                                                  <td class="py-2 border-b text-center border-r">New Wt (Ct)</td>
                                                              </tr>
                                                          </thead>
                                                          <tbody v-if="form_data.rejection_details.length > 0">
                                                              <tr class="border-b bg-gray-100 dark:bg-dark-1">
                                                                  <th class="p-2 border-b text-xs lg:text-md text-center border-r text-white">.</th>
                                                                  <td class="p-2 border-b text-center border-r"></td>
                                                                  <td class="p-2 border-b text-center border-r">{{ form_data.total.cr_pcs }}</td>
                                                                  <td class="p-2 border-b text-center border-r">{{ form_data.total.cr_weight }}</td>
                                                                  <td class="p-2 border-b text-center border-r">{{ form_data.total.new_pcs }}</td>
                                                                  <td class="p-2 border-b text-center border-r">{{ form_data.total.new_weight }}</td>
                                                              </tr>
                                                              <tr v-for="(item,index) in form_data.rejection_details" :key="index">
                                                                  <th class="p-2 border-b text-xs lg:text-md text-center border-r">{{ index + 1 }}</th>
                                                                  <td class="p-2 border-b text-center border-r">{{ item.lot_no }}<br> ({{ item.voucher_no }})</td>
                                                                  <td class="p-2 border-b text-center border-r">{{ item.cr_rejection_pcs }}</td>
                                                                  <td class="p-2 border-b text-center border-r">{{ item.cr_rejection_wt }}</td>
                                                                  <td class="p-2 border-b text-center border-r"><input type="text" @keypress="$h.preventInvalidInput" @input="validateDetails(item)" class="form-control w-24 text-center" v-model="item.new_rejection_pcs"></td>
                                                                  <td class="p-2 border-b text-center border-r"><input type="text" @keypress="$h.preventInvalidInput" @input="validateDetails(item)" class="form-control w-24 text-center" v-model="item.new_rejection_wt"></td>
                                                              </tr>
                                                          </tbody>
                                                      </table>

                                                  </div>
                                              </div>
                                              <div class="mt-3">
                                                  <!-- <label class="form-label">Stone && Est Value</label> -->
                                                  <div class="sm:grid grid-cols-2 gap-2">
                                                      <div>

                                                          <div class="">
                                                              <!-- <div id="input-group-3">Duration</div> -->
                                                              <!-- <input type="text" class="form-control" @keypress="$h.preventInvalidInput" v-model="form_data.due" placeholder="Duration" aria-describedby="input-group-3" /> -->
                                                          </div>
                                                      </div>
                                                      <div>
                                                          <div class="mt-2 sm:mt-0">
                                                              <div class="flex justify-end items-center mt-5">
                                                                  <button v-if="form_data.purchase_type == 2 && form_data.purchase_select == true" type="button" @click="form_data.purchase_select = false" class="btn btn-secondary w-24 m-auto">Back</button>
                                                                  <button v-if="form_data.purchase_type == 2 && form_data.purchase_select == true" type="button" @click="add_purchase" :disabled="page_data.loading == true" class="btn btn-primary w-24 m-auto">{{ form_data.id ? 'Update' : 'Create' }}
                                                                      <LogInIcon v-if="page_data.loading == false" class="h-4 w-4 ml-2 -mt-1" />
                                                                      <LoadingIcon v-if="page_data.loading == true" icon="oval" color="white" class="w-4 h-4 ml-2" /></button>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <!-- END: Input -->
                              </div>
                          </div>
                          <!-- END: Data List -->
                      </div>
                      <!-- </div> -->
                  </div>
              </div>
          </div>
      </div>
      <!-- BEGIN: Single Process Assign Modal -->
      <!-- END: Data List -->
      <div id="rough-process-reject-modal" class="modal" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog modal-lg">
              <div class="modal-content">
                  <a data-dismiss="modal" href="javascript:;">
                      <XIcon class="w-8 h-8 text-gray-500" />
                  </a>
                  <div class="modal-body p-10">
                      <!-- <div id="vertical-form"> -->
                      <div class="flex flex-col justify-between mt-5">
                          <!-- BEGIN: Data List -->
                          <div class="intro-y overflow-auto">
                              <div class="flex items-center">
                                  <h2 class="intro-y text-lg font-medium mr-5">Add Rejection</h2>
                              </div>
                          </div>
                          <div class="grid grid-cols-12 gap-4">
                              <div class="intro-y col-span-12">
                                  <!-- BEGIN: Input -->
                                  <div class="">
                                      <div id="input" class="py-5">
                                          <div class="preview">
                                              <div>
                                                  <!-- <label class="form-label">Kapan No & Stone Type</label> -->
                                                  <div class="sm:grid grid-cols-2 gap-2">
                                                      <div>
                                                          <div class="">
                                                              <div id="input-group-4">Kapan</div>

                                                              <input id="pos-form-1" v-model="reject_form.kapan" name="kapan" type="text" disabled class="form-control flex-1" placeholder="Kapan No" />
                                                          </div>
                                                      </div>
                                                      <div class="mt-2 sm:mt-0">
                                                          <div id="input-group-3" class="">Stone</div>
                                                          <select class="form-select" v-model="reject_form.stone_id" disabled aria-label="Default select example">
                                                              <option value="1">N</option>
                                                              <option value="2">CVD</option>
                                                              <option value="3">HPHT</option>
                                                          </select>
                                                          <!-- <template v-if="form_validate.stone_id.$error">
                                                              <div v-for="(error, index) in form_validate.stone_id.$errors" :key="index" class="text-theme-6 mt-2">
                                                                  {{ error.$message }}
                                                              </div>
                                                          </template> -->
                                                      </div>
                                                  </div>
                                              </div>
                                              <div class="mt-3">
                                                  <!-- <label class="form-label">No. of Pieces & Total Weight</label> -->
                                                  <div class="sm:grid grid-cols-2 gap-2">
                                                      <div>
                                                          <div>

                                                              <div class="">
                                                                  <div id="input-group-3">Available Pieces</div>
                                                                  <input type="text" class="form-control" name="piece" disabled v-model="reject_form.avail_pcs" aria-describedby="input-group-3" />
                                                              </div>

                                                          </div>
                                                      </div>
                                                      <div class="mt-2 sm:mt-0">
                                                          <div class="">
                                                              <div id="input-group-4">Available Weight (Ct)</div>
                                                              <input type="text" class="form-control" name="weight" disabled v-model="reject_form.avail_wt" aria-describedby="input-group-4" />
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              <!-- <div class="mt-3" v-if="reject_form.purchase_type == 2">
                                                    <div id="input-group-3" class="">Stone</div>
                                                    <select class="form-select w-full" v-model="reject_form.rejection_type" aria-label="Default select example">
                                                        <option value="1">Direct Rejection</option>
                                                        <option value="2" v-if="reject_form.purchase_type == 2">Net Rejection</option>
                                                    </select>
                                              </div> -->
                                              <div class="mt-3">
                                                  <!-- <label class="form-label">No. of Pieces & Total Weight</label> -->
                                                  <div class="sm:grid grid-cols-2 gap-2">
                                                      <div>
                                                          <div>

                                                              <div class="">
                                                                  <div id="input-group-3">Pieces</div>
                                                                  <input type="text" class="form-control" name="piece" autocomplete="off" @keypress="$h.preventInvalidInput" v-model="reject_form.pieces" placeholder="No of Pieces" aria-describedby="input-group-3" />
                                                              </div>
                                                              <!-- <template v-if="reject_validate.pieces.$error">
                                                                  <div v-for="(error, index) in reject_validate.pieces.$errors" :key="index" class="text-theme-6 mt-2">
                                                                      {{ error.$message }}
                                                                  </div>
                                                              </template> -->

                                                          </div>
                                                      </div>
                                                      <div class="mt-2 sm:mt-0">
                                                          <div class="">
                                                              <div id="input-group-4" :class="{ 'border-theme-6': reject_validate.weight.$error }">Weight (Ct) <span class="text-theme-6"> * </span></div>
                                                              <input type="text" class="form-control" name="weight" :class="{ 'border-theme-6': reject_validate.weight.$error }" autocomplete="off" @keypress="$h.preventInvalidInput" v-model="reject_form.weight" placeholder="Weight" aria-describedby="input-group-4" />
                                                          </div>
                                                          <template v-if="reject_validate.weight.$error">
                                                              <div v-for="(error, index) in reject_validate.weight.$errors" :key="index" class="text-theme-6 mt-2">
                                                                  {{ error.$message }}
                                                              </div>
                                                          </template>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div class="mt-3">
                                                  <!-- <label class="form-label">Stone && Est Value</label> -->
                                                  <div class="sm:grid grid-cols-2 gap-2">
                                                      <div>

                                                          <div class="">

                                                          </div>
                                                      </div>
                                                      <div>
                                                          <div class="mt-2 sm:mt-0">
                                                              <div class="flex justify-end items-center mt-5">
                                                                  <button type="button" :disabled="page_data.r_loading == true" class="btn btn-secondary w-24 m-auto mr-3" data-dismiss="modal" href="javascript:;">Cancel</button>
                                                                  <button type="button" @click="process_reject" :disabled="page_data.r_loading == true" class="btn btn-primary w-24 m-auto"> Reject
                                                                      <LogInIcon v-if="page_data.r_loading == false" class="h-4 w-4 ml-2 -mt-1" />
                                                                      <LoadingIcon v-if="page_data.r_loading == true" icon="oval" color="white" class="w-4 h-4 ml-2" /></button>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <!-- END: Input -->
                              </div>
                          </div>
                          <!-- END: Data List -->
                      </div>
                      <!-- </div> -->
                  </div>
              </div>
          </div>
      </div>
      <!-- BEGIN: Single Process Assign Modal -->
      <!-- END: Data List -->
      <div id="rough-process-issue-modal" class="modal" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog modal-lg">
              <div class="modal-content">
                  <a data-dismiss="modal" href="javascript:;">
                      <XIcon class="w-8 h-8 text-gray-500" />
                  </a>
                  <div class="modal-body p-10">
                      <!-- <div id="vertical-form"> -->
                      <div class="flex flex-col justify-between mt-5">
                          <!-- BEGIN: Data List -->
                          <div class="intro-y overflow-auto">
                              <div class="flex items-center">
                                  <!-- <h2 class="intro-y text-lg font-medium mr-5">Process Assign</h2> -->
                                  <h2 class="intro-y text-lg font-medium mr-5">Purchase No : <span class="ml-2 text-primary-1">{{ issue_form.lot_no }}</span></h2>
                              </div>
                          </div>
                          <div class="grid grid-cols-12 gap-4">
                              <div class="intro-y col-span-12">
                                  <!-- BEGIN: Input -->
                                  <div class="">
                                      <div id="input" class="py-5">
                                          <div class="preview">
                                              <div>
                                                  <!-- <label class="form-label">Kapan No & Stone Type</label> -->
                                                  <div class="sm:grid grid-cols-2 gap-2">
                                                      <div>
                                                          <div class="">
                                                              <div id="input-group-4">Kapan</div>

                                                              <input id="pos-form-1" v-model="issue_form.kapan" name="kapan" type="text" disabled class="form-control flex-1" placeholder="Kapan No" />
                                                          </div>
                                                      </div>
                                                      <div class="mt-2 sm:mt-0">
                                                          <div id="input-group-3" class="">Stone</div>
                                                          <select class="form-select" v-model="issue_form.stone_id" disabled aria-label="Default select example">
                                                              <option value="1">N</option>
                                                              <option value="2">CVD</option>
                                                              <option value="3">HPHT</option>
                                                          </select>
                                                          <!-- <template v-if="form_validate.stone_id.$error">
                                                              <div v-for="(error, index) in form_validate.stone_id.$errors" :key="index" class="text-theme-6 mt-2">
                                                                  {{ error.$message }}
                                                              </div>
                                                          </template> -->
                                                      </div>
                                                  </div>
                                              </div>
                                              <div class="mt-3">
                                                  <div class="sm:grid grid-cols-2 gap-2">
                                                      <div>
                                                          <div>

                                                              <div class="">
                                                                  <label for="update-profile-form-6" class="form-label">Issue Type</label>
                                                                  <TomSelect v-model="issue_form.issue_type" :options="{
  placeholder: 'Select Process'
  }" class="w-full">
                                                                      <option value="1">Manager Assign</option>
                                                                      <option value="2">Process Assign</option>
                                                                  </TomSelect>
                                                              </div>

                                                          </div>
                                                      </div>
                                                      <div class="mt-2 sm:mt-0">
                                                          <div class="">
                                                              <div v-if="issue_form.issue_type == 2">

                                                                  <label for="update-profile-form-6" class="form-label">Select Process <span class="text-theme-6"> * </span></label>
                                                                  <TomSelect v-model="issue_form.process_id" @change="get_employee_details()" :options="{
  placeholder: 'Select Process'
  }" class="w-full">
                                                                      <option value="">Select Process</option>
                                                                      <option v-for="(process,ind) in page_data.process_items" :key="ind" :value="process.id">{{ process.process_name }}</option>
                                                                  </TomSelect>
                                                                  <template v-if="issue_validate.process_id.$error">
                                                                      <div v-for="(error, index) in issue_validate.process_id.$errors" :key="index" class="text-theme-6 mt-2">
                                                                          {{ error.$message }}
                                                                      </div>
                                                                  </template>
                                                              </div>
                                                              <div v-if="issue_form.issue_type == 1">

                                                                  <label for="update-profile-form-6" class="form-label">Select Manager <span class="text-theme-6"> * </span></label>
                                                                  <TomSelect v-model="issue_form.operator_id" :options="{
  placeholder: 'Select Process'
  }" class="w-full">
                                                                      <option value="">Select Manager</option>
                                                                      <option v-for="(process,ind) in page_data.manager_items" :key="ind" :value="process.id">{{ process.first_name }} {{ process.last_name }}</option>
                                                                  </TomSelect>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>

                                              </div>
                                              <div class="flex items-center mt-3" v-if="issue_form.issue_type == 2">
                                                  <div class="form-check">
                                                      <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-1 ml-3 cursor-pointer" value="0" type="radio" name="single_internal" id="single_internal" @change="issue_form.employee_id = ''" v-model="issue_form.external">
                                                      <label class="form-check-label inline-block text-black text-lg" for="single_internal">
                                                          Internal
                                                      </label>
                                                  </div>
                                                  <div class="form-check ml-6">
                                                      <input class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-1 ml-3 cursor-pointer" value="1" type="radio" name="single_external" id="single_external" @change="issue_form.employee_id = ''" v-model="issue_form.external">
                                                      <label class="form-check-label inline-block text-black text-lg" for="single_external">
                                                          External
                                                      </label>
                                                  </div>
                                              </div>
                                              <div class="mt-3" v-if="issue_form.external == '0' && issue_form.issue_type == 2">
                                                  <label for="update-profile-form-6" class="form-label">Select Employee <span class="text-theme-6"> * </span></label>
                                                  <TomSelect v-model="issue_form.employee_id" :options="{
                              placeholder: 'Select Employee'
                            }" class="w-full">
                                                      <option value="">Select Employee</option>
                                                      <option v-for="(emp,ind) in page_data.employee_items" :key="ind" :value="emp.id">{{ emp.first_name + ' ' + emp.last_name}}</option>
                                                  </TomSelect>
                                                  <template v-if="issue_validate.employee_id.$error">
                                                      <div v-for="(error, index) in issue_validate.employee_id.$errors" :key="index" class="text-theme-6 mt-2">
                                                          {{ error.$message }}
                                                      </div>
                                                  </template>
                                              </div>
                                              <div class="mt-3" v-else-if="issue_form.external == '1' && issue_form.issue_type == 2">
                                                  <label for="update-profile-form-6" class="form-label">Process Party <span class="text-theme-6"> * </span></label>
                                                  <TomSelect v-model="issue_form.employee_id" :options="{
                           placeholder: 'Select Party'
                         }" class="w-full">
                                                      <option value="">Select Party</option>
                                                      <option v-for="(party,ind) in page_data.process_party_items" :key="ind" :value="party.id">{{ party.first_name + ' ' + party.last_name }}</option>
                                                  </TomSelect>
                                                  <template v-if="issue_validate.employee_id.$error">
                                                      <div v-for="(error, index) in issue_validate.employee_id.$errors" :key="index" class="text-theme-6 mt-2">
                                                          {{ error.$message }}
                                                      </div>
                                                  </template>
                                              </div>
                                              <div class="mt-3">
                                                  <!-- <label class="form-label">No. of Pieces & Total Weight</label> -->
                                                  <div class="sm:grid grid-cols-2 gap-2">
                                                      <div>
                                                          <div>

                                                              <div class="">
                                                                  <div id="input-group-3">Available Pieces</div>
                                                                  <input type="text" class="form-control" name="piece" disabled v-model="issue_form.avail_pcs" aria-describedby="input-group-3" />
                                                              </div>

                                                          </div>
                                                      </div>
                                                      <div class="mt-2 sm:mt-0">
                                                          <div class="">
                                                              <div id="input-group-4">Available Weight (Ct)</div>
                                                              <input type="text" class="form-control" name="weight" disabled v-model="issue_form.avail_wt" aria-describedby="input-group-4" />
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div class="mt-3" v-if="issue_form.issue_type == 2">
                                                  <!-- <label class="form-label">No. of Pieces & Total Weight</label> -->
                                                  <div class="sm:grid grid-cols-2 gap-2">
                                                      <div>
                                                          <div>

                                                              <div class="">
                                                                  <div id="input-group-3" :class="{ 'border-theme-6': issue_validate.weight.$error }">Pieces <span class="text-theme-6"> * </span></div>
                                                                  <input type="text" class="form-control" name="piece" :class="{ 'border-theme-6': issue_validate.pieces.$error }" autocomplete="off" @keypress="$h.preventInvalidInput" v-model="issue_form.pieces" placeholder="No of Pieces" aria-describedby="input-group-3" />
                                                              </div>
                                                              <template v-if="issue_validate.pieces.$error">
                                                                  <div v-for="(error, index) in issue_validate.pieces.$errors" :key="index" class="text-theme-6 mt-2">
                                                                      {{ error.$message }}
                                                                  </div>
                                                              </template>

                                                          </div>
                                                      </div>
                                                      <div class="mt-2 sm:mt-0">
                                                          <div class="">
                                                              <div id="input-group-4" :class="{ 'border-theme-6': issue_validate.weight.$error }">Weight (Ct) <span class="text-theme-6"> * </span></div>
                                                              <input type="text" class="form-control" name="weight" :class="{ 'border-theme-6': issue_validate.weight.$error }" autocomplete="off" @keypress="$h.preventInvalidInput" v-model="issue_form.weight" placeholder="Weight" aria-describedby="input-group-4" />
                                                          </div>
                                                          <template v-if="issue_validate.weight.$error">
                                                              <div v-for="(error, index) in issue_validate.weight.$errors" :key="index" class="text-theme-6 mt-2">
                                                                  {{ error.$message }}
                                                              </div>
                                                          </template>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div class="mt-3">
                                                  <!-- <label class="form-label">Stone && Est Value</label> -->
                                                  <div class="sm:grid grid-cols-2 gap-2">
                                                      <div>

                                                          <div class="">

                                                          </div>
                                                      </div>
                                                      <div>
                                                          <div class="mt-2 sm:mt-0">
                                                              <div class="flex justify-end items-center mt-5">
                                                                  <button type="button" :disabled="page_data.i_loading == true" class="btn btn-secondary w-24 m-auto mr-3" data-dismiss="modal" href="javascript:;">Cancel</button>
                                                                  <button type="button" @click="process_issue" :disabled="page_data.i_loading == true" class="btn btn-primary w-24 m-auto"> Issue
                                                                      <LogInIcon v-if="page_data.i_loading == false" class="h-4 w-4 ml-2 -mt-1" />
                                                                      <LoadingIcon v-if="page_data.i_loading == true" icon="oval" color="white" class="w-4 h-4 ml-2" /></button>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <!-- END: Input -->
                              </div>
                          </div>
                          <!-- END: Data List -->
                      </div>
                      <!-- </div> -->
                  </div>
              </div>
          </div>
      </div>
      <!-- BEGIN: Single Process Assign Modal -->
  </div>
  </template>

  <script>
  /* eslint-disable */
  import {
      BASE_URL
  } from '@/constants/config'
  import {
      useVuelidate
  } from '@vuelidate/core'
  import axios from 'axios'
  import cash from 'cash-dom'
  import feather from 'feather-icons'
  import Tabulator from 'tabulator-tables'
  import {
      required,
      minLength,
      maxLength,
      email,
      url,
      integer
  } from '@vuelidate/validators'
  import {
      computed,
      defineComponent,
      inject,
      onMounted,
      reactive,
      ref,
      toRefs,
      watch
  } from 'vue'
  import {
      helper as $h
  } from '@/utils/helper'
  // import store from '@/store'
  // import ActionDropdown from '@/views/Purchase/components/rough_dropdown.vue'
  import router from '@/router'
  import Swal from 'sweetalert2'
  import {
      useStore
  } from '@/store'
  // @keypress="$h.preventInvalidInput"

  export default defineComponent({
      components: {
          // ActionDropdown,
      },
      setup() {
          const swal = inject('$swal')
          const store = useStore()
          const user_details = computed(() => store.state.user.currentUser)
          const tableRef = ref()
          const tabulator = ref()

          const page_data = reactive({
              party_details: [],
              loading: false,
              r_loading: false,
              i_loading: false,
              process_items: [],
              employee_items: [],
              rejection_items: [],
              manager_items: [],
              process_party_items: [],
              total: [],
              home_party: ""

          })

          const form_data = reactive({
            ip: localStorage.getItem('sk_key'),
              id: '',
              party: '0',
              stone_id: '2',
              piece: '',
              packet_type: 'rough',
              weight: '',
              due: 15,
              kapan: '',
              lot_no: '',
              per_ct_rate: '',
              majuri_type: '2',
              majuri_rate: '',
              margin_percent: '',
              purchase_type: '1',
              purchase_select: false,
              purchase_list: [],
              rejection_details: [],
              total: {
                  cr_weight: '',
                  new_weight: '',
                  cr_pcs: '',
                  new_pcs: '',

              },
          })

          const issue_form = reactive({
              issue_type: user_details.value.user_role == 1 ? '1' : '2',
              operator_id: '',
              pieces: '',
              weight: '',
              avail_pcs: '',
              avail_wt: '',
              purchase_id: '',
              process_id: '',
              employee_id: '',
              external: '0',
              lot_no: '',
              kapan: '',
              stone_id: '',
          })

          const reject_form = reactive({
              rejection_type: '1',
              purchase_type: '1',
              pieces: '',
              weight: '',
              purchase_id: '',
              kapan: '',
              lot_no: '',
              stone_id: '',
              avail_pcs: '',
              avail_wt: '',
          })

          const filter_data = reactive({
              status: '0',
              kapan_no: '',
              type: '0',
              party_id: '0',
              stone_id: '0',
          })

          const form_rules = {
              piece: {
                  required,
                  integer
              },
              weight: {
                  required,

              },
              kapan: {
                  required
              }
          }
          const issue_rules = {
              pieces: {
                  required,
                  integer
              },
              weight: {
                  required,
              },
              purchase_id: {
                  required,
              },
              process_id: {
                  required,
              },
              employee_id: {
                  required,
              }
          }
          const reject_rules = {
              // pieces: {
              //     required,
              //     integer
              // },
              weight: {
                  required,
              }
          }

          const issue_validate = useVuelidate(issue_rules, toRefs(issue_form))
          const form_validate = useVuelidate(form_rules, toRefs(form_data))
          const reject_validate = useVuelidate(reject_rules, toRefs(reject_form))

          const initTabulator = () => {

              const params = {
                  stone_id: (filter_data.stone_id == '0' ? '' : window.btoa(filter_data.stone_id)),
                  status: (filter_data.status == '0' ? '' : window.btoa(filter_data.status)),
                  kapan_no: (filter_data.kapan_no == '' ? '' : window.btoa(filter_data.kapan_no)),
                  employee_id: (filter_data.employee_id == '0' ? '' : window.btoa(filter_data.employee_id)),
                  party_id: (filter_data.party_id == '0' ? '' : window.btoa(filter_data.party_id)),
                  type: (filter_data.type == '0' ? '' : window.btoa(filter_data.type)),
                  // issue_date: (filter_data.issue_date == '' ? '' : window.btoa(filter_data.issue_date)),
                  // receive_date: (filter_data.receive_date == '' ? '' : window.btoa(filter_data.receive_date)),
              }
              if(user_details.value.user_role == 3) {


                            tabulator.value = new Tabulator(tableRef.value, {
                                ajaxURL: BASE_URL + 'api/kpn/grpd',
                                ajaxConfig: {
                                    method: "GET", //set request type to Position
                                    headers: {
                                        "Content-type": 'application/json; charset=utf-8', //set specific content type
                                        "Authorization": "Bearer " + localStorage.getItem("token")
                                    },
                                },
                                ajaxParams: params,
                                // ajaxFiltering: true,
                                // ajaxSorting: false,
                                // printAsHtml: true,
                                // printStyled: true,
                                // headerSort: false,
                                // pagination: 'remote',
                                // paginationSize: 20,
                                // paginationSizeSelector: [20, 30, 40],
                                // layout: 'fitColumns',
                                // responsiveLayout: 'collapse',
                                // dataTree: true,
                                // dataTreeStartExpanded: true,
                                // placeholder: 'No matching records found',
                                ajaxFiltering: true,
                                ajaxSorting: true,
                                printAsHtml: true,
                                printStyled: true,
                                headerSort: false,
                                pagination: 'remote',
                                paginationSize: 20,
                                paginationSizeSelector: [20, 30, 40],
                                layout: 'fitColumns',
                                responsiveLayout: 'collapse',
                                placeholder: 'No matching records found',
                                columns: [{
                                        formatter: 'responsiveCollapse',
                                        width: 40,
                                        minWidth: 30,
                                        hozAlign: 'center',
                                        resizable: false,
                                        headerSort: false
                                    },

                                    // For HTML table
                                    {
                                        title: 'Lot',
                                        field: 'lot_no',
                                        responsive: 0,
                                        vertAlign: 'middle',
                                        hozAlign: 'left',
                                        minWidth: 90,
                                        print: false,
                                        download: false,
                                        formatter: "textarea",
                                        cellClick: function (e, cell) {
                                            //e - the click event object
                                            //cell - cell component
                                            // console.log(cell.getData().id);
                                            // router.push("/purchase/rough/" + window.btoa(cell.getData().id));

                                        }
                                    },
                                    {
                                        title: 'KapanNo',
                                        field: 'voucher_no',
                                        minWidth: 100,
                                        vertAlign: 'middle',
                                        hozAlign: 'center',
                                        print: false,
                                        download: false,
                                        responsive: 0,
                                        formatter: "textarea"
                                    },
                                    {
                                        title: 'Type',
                                        field: 'type',
                                        minWidth: 100,
                                        vertAlign: 'middle',
                                        hozAlign: 'center',
                                        print: false,
                                        download: false,
                                        responsive: 0,
                                        formatter: "textarea"
                                    },
                                    {
                                      title: 'PIECES',
                                        field: 'issue_pieces',
                                        hozAlign: 'center',
                                        vertAlign: 'middle',
                                        minWidth: 120,
                                        print: false,
                                        download: true,
                                        formatter(cell) {
                                            return `<div>
                                            <div class="whitespace-nowrap text-center">${
                                              cell.getData().pr_pcs ? cell.getData().pr_pcs + ' Pcs' : 'N/A'
                                            }
                                            </div>

                                          </div>`

                                        }
                                    },
                                    {
                                      title: 'WEIGHT',
                                        field: 'issue_pieces',
                                        hozAlign: 'center',
                                        vertAlign: 'middle',
                                        minWidth: 120,
                                        print: false,
                                        download: true,
                                        formatter(cell) {
                                            return `<div>
                                            <div class="whitespace-nowrap text-center">${
                                              cell.getData().pr_wt ? cell.getData().pr_wt + ' Ct'  : 'N/A'
                                            }
                                            </div>

                                          </div>`

                                        }
                                    },
                                    {
                                        title: 'Status',
                                        minWidth: 90,
                                        field: 'active_status',
                                        hozAlign: 'left',
                                        vertAlign: 'middle',
                                        print: false,
                                        responsive: 0,
                                        download: false,
                                        formatter(cell) {

                                            if (cell.getData().status == 1 || cell.getData().status == 0) {

                                                return `<div class="flex items-center lg:justify-center text-theme-9">
                                      <i data-feather="check-square" class="w-4 h-4 mr-2"></i> Active</div>`
                                            } else if (cell.getData().status == 2) {

                                                return `<div class="flex items-center lg:justify-center text-theme-9">
                                      <i data-feather="check-square" class="w-4 h-4 mr-2"></i> Processing</div>`
                                            } else if (cell.getData().status == 3) {

                                                return `<div class="flex items-center lg:justify-center text-theme-6">
                                      <i data-feather="check-square" class="w-4 h-4 mr-2"></i> Cancelled</div>`
                                            } else if (cell.getData().status == 4) {

                                                return `<div class="flex items-center lg:justify-center text-theme-9">
                                      <i data-feather="check-square" class="w-4 h-4 mr-2"></i> Delivered</div>`
                                            }
                                        }
                                    },
                                    {
                                        title: 'Date',
                                        field: 'created_at',
                                        hozAlign: 'left',
                                        vertAlign: 'middle',
                                        minWidth: 100,
                                        print: false,
                                        download: true,
                                        formatter: 'textarea'
                                    },
                                    {
                                        title: 'Create IP',
                                        responsive: 0,
                                        minWidth: 100,
                                        hozAlign: 'center',
                                        vertAlign: 'middle',
                                        field: 'issue_trans_ip',
                                        print: false,
                                        download: false,
                                        formatter(cell) {
                                            return cell.getData().issue_trans_ip
                                        }
                                    },
                                ],
                                renderComplete() {
                                    feather.replace({
                                        'stroke-width': 1.5
                                    })
                                }
                            })
              } else {
                            tabulator.value = new Tabulator(tableRef.value, {
                                ajaxURL: BASE_URL + 'api/kpn/grpd',
                                ajaxConfig: {
                                    method: "GET", //set request type to Position
                                    headers: {
                                        "Content-type": 'application/json; charset=utf-8', //set specific content type
                                        "Authorization": "Bearer " + localStorage.getItem("token")
                                    },
                                },
                                ajaxParams: params,
                                // ajaxFiltering: true,
                                // ajaxSorting: false,
                                // printAsHtml: true,
                                // printStyled: true,
                                // headerSort: false,
                                // pagination: 'remote',
                                // paginationSize: 20,
                                // paginationSizeSelector: [20, 30, 40],
                                // layout: 'fitColumns',
                                // responsiveLayout: 'collapse',
                                // dataTree: true,
                                // dataTreeStartExpanded: true,
                                // placeholder: 'No matching records found',
                                ajaxFiltering: true,
                                ajaxSorting: true,
                                printAsHtml: true,
                                printStyled: true,
                                headerSort: false,
                                pagination: 'remote',
                                paginationSize: 20,
                                paginationSizeSelector: [20, 30, 40],
                                layout: 'fitColumns',
                                responsiveLayout: 'collapse',
                                placeholder: 'No matching records found',
                                columns: [{
                                        formatter: 'responsiveCollapse',
                                        width: 40,
                                        minWidth: 30,
                                        hozAlign: 'center',
                                        resizable: false,
                                        headerSort: false
                                    },

                                    // For HTML table
                                    {
                                        title: 'Lot',
                                        field: 'lot_no',
                                        responsive: 0,
                                        vertAlign: 'middle',
                                        hozAlign: 'left',
                                        minWidth: 90,
                                        print: false,
                                        download: false,
                                        formatter: "textarea",
                                        cellClick: function (e, cell) {
                                            //e - the click event object
                                            //cell - cell component
                                            console.log(cell.getData().id);
                                            router.push("/purchase/rough/" + window.btoa(cell.getData().id));

                                        }
                                    },
                                    {
                                        title: 'KapanNo',
                                        field: 'voucher_no',
                                        minWidth: 100,
                                        vertAlign: 'middle',
                                        hozAlign: 'center',
                                        print: false,
                                        download: false,
                                        responsive: 0,
                                        formatter: "textarea"
                                    },
                                    {
                                        title: 'Type',
                                        field: 'type',
                                        minWidth: 100,
                                        vertAlign: 'middle',
                                        hozAlign: 'center',
                                        print: false,
                                        download: false,
                                        responsive: 0,
                                        formatter: "textarea"
                                    },
                                    {
                                        title: 'Party',
                                        field: 'party',
                                        minWidth: 100,
                                        hozAlign: 'center',
                                        vertAlign: 'middle',
                                        print: false,
                                        responsive: 0,
                                        download: false,
                                        formatter(cell) {
                                            return `<div>
                                <div class="whitespace-nowrap">${
                                  cell.getData().party_firm_name ? cell.getData().party_firm_name : cell.getData().party_first_name + '<br>' + cell.getData().party_last_name
                                }</div>
                              </div>`
                                        }
                                    },
                                    {
                                      title: 'O. PCS <br> O. WT',
                                        field: 'issue_pieces',
                                        hozAlign: 'center',
                                        vertAlign: 'middle',
                                        minWidth: 120,
                                        print: false,
                                        download: true,
                                        formatter(cell) {
                                            return `<div>
                                            <div class="whitespace-nowrap text-center">${
                                              cell.getData().pr_pcs ? cell.getData().pr_pcs + ' Pcs' : 'N/A'
                                            }<br>${
                                              cell.getData().pr_wt ? cell.getData().pr_wt + ' Ct'  : 'N/A'
                                            }
                                            </div>

                                          </div>`

                                        }
                                    },
                                    {
                                        title: 'Cr. PCS <br> Cr. WT',
                                        field: 'issue_pieces',
                                        hozAlign: 'center',
                                        vertAlign: 'middle',
                                        minWidth: 120,
                                        print: false,
                                        download: true,
                                        formatter(cell) {
                                            return `<div>
                                            <div class="whitespace-nowrap text-center">${
                                              cell.getData().cr_pcs ? cell.getData().cr_pcs + ' Pcs' : 'N/A'
                                            }<br>${
                                              cell.getData().cr_wt ? cell.getData().cr_wt + ' Ct'  : 'N/A'
                                            }
                                            </div>

                                          </div>`

                                        }
                                    },
                                    {
                                        title: 'Proc. PCS <br> Proc. WT',
                                        field: 'issue_pieces',
                                        hozAlign: 'center',
                                        vertAlign: 'middle',
                                        minWidth: 120,
                                        print: false,
                                        download: true,
                                        formatter(cell) {

                                            return `<div>
                                            <div class="whitespace-nowrap text-center">${
                                              cell.getData().issue_pieces ? cell.getData().inpr_pcs + ' Pcs' : 'N/A'
                                            }<br>${
                                              cell.getData().issue_weight ? cell.getData().inpr_wt + ' Ct'  : 'N/A'
                                            }
                                            </div>

                                          </div>`

                                        }
                                    },
                                    {
                                        title: 'Rej. PCS <br> Rej. WT',
                                        field: 'issue_pieces',
                                        hozAlign: 'left',
                                        vertAlign: 'middle',
                                        minWidth: 120,
                                        print: false,
                                        download: true,
                                        formatter(cell) {

                                            const fail_pcs = (cell.getData().fail_pcs ? cell.getData().fail_pcs : 0);
                                            const reject_pcs = (cell.getData().rejection_pcs ? cell.getData().rejection_pcs : 0)
                                            const fail_wr = (cell.getData().fail_wt ? parseFloat(cell.getData().fail_wt) : 0)
                                            const reject_wr = (cell.getData().rejection_wt ? parseFloat(cell.getData().rejection_wt) : 0)
                                            return `<div>
                                            <div class="whitespace-nowrap text-center">${
                                              (fail_pcs + reject_pcs) + ' Pcs'
                                            }<br>${
                                              $h.myFloat(fail_wr + reject_wr) + ' Ct'
                                            }
                                            </div>

                                          </div>`

                                        }
                                    },
                                    {
                                        title: 'Lose Wt',
                                        field: 'lose_weight',
                                        hozAlign: 'left',
                                        vertAlign: 'middle',
                                        minWidth: 100,
                                        print: false,
                                        download: true,
                                        formatter(cell) {
                                            return `<div>
                                            <div class="whitespace-nowrap text-center">${
                                              cell.getData().ghat_wt ? cell.getData().ghat_wt + ' Ct' : 'N/A'
                                            }
                                            </div>

                                          </div>`

                                        }
                                    },
                                    {
                                        title: 'Status',
                                        minWidth: 90,
                                        field: 'active_status',
                                        hozAlign: 'left',
                                        vertAlign: 'middle',
                                        print: false,
                                        responsive: 0,
                                        download: false,
                                        formatter(cell) {

                                            if (cell.getData().status == 1 || cell.getData().status == 0) {

                                                return `<div class="flex items-center lg:justify-center text-theme-9">
                                      <i data-feather="check-square" class="w-4 h-4 mr-2"></i> Active</div>`
                                            } else if (cell.getData().status == 2) {

                                                return `<div class="flex items-center lg:justify-center text-theme-9">
                                      <i data-feather="check-square" class="w-4 h-4 mr-2"></i> Processing</div>`
                                            } else if (cell.getData().status == 3) {

                                                return `<div class="flex items-center lg:justify-center text-theme-6">
                                      <i data-feather="check-square" class="w-4 h-4 mr-2"></i> Cancelled</div>`
                                            } else if (cell.getData().status == 4) {

                                                return `<div class="flex items-center lg:justify-center text-theme-9">
                                      <i data-feather="check-square" class="w-4 h-4 mr-2"></i> Delivered</div>`
                                            }
                                        }
                                    },
                                    {
                                        title: 'Date',
                                        field: 'created_at',
                                        hozAlign: 'left',
                                        vertAlign: 'middle',
                                        minWidth: 100,
                                        print: false,
                                        download: true,
                                        formatter: 'textarea'
                                    },
                                    {
                                        title: 'Create IP',
                                        responsive: 0,
                                        minWidth: 100,
                                        hozAlign: 'center',
                                        vertAlign: 'middle',
                                        field: 'issue_trans_ip',
                                        print: false,
                                        download: false,
                                        formatter(cell) {
                                            return cell.getData().issue_trans_ip
                                        }
                                    },
                                    {
                                        title: 'Action',
                                        hozAlign: 'center',
                                        field: 'status',
                                        minWidth: 100,
                                        vertAlign: 'middle',
                                        print: false,
                                        responsive: 0,
                                        download: false,
                                        formatter(cell) {

                                            const dropdown = document.createElement("div");
                                            dropdown.classList.add("dropdown");

                                            const button = document.createElement("button");
                                            button.classList.add("btn", "btn-secondary", "btn-sm", "dropdown-toggle");
                                            button.setAttribute("id", "dropdownMenuButton");
                                            button.setAttribute("type", "button");
                                            button.setAttribute("data-toggle", "dropdown");
                                            button.setAttribute("aria-haspopup", "true");
                                            button.setAttribute("aria-expanded", "false");
                                            button.innerText = "Action";

                                            const menu_icon = document.createElement("i");
                                            menu_icon.classList.add("w-4", "h-4", "ml-2");
                                            menu_icon.setAttribute("data-feather", "chevron-down");
                                            button.appendChild(menu_icon);

                                            const menu = document.createElement("div");
                                            menu.setAttribute("aria-labelledby", "dropdownMenuButton");
                                            menu.classList.add("dropdown-menu")

                                            const sub_menue = document.createElement("div")
                                            sub_menue.classList.add("dropdown-menu__content", "box", "bg-theme-26", "dark:bg-dark-6", "text-white");

                                            const main_view_details = document.createElement("div");
                                            main_view_details.classList.add("p-1", "border-t", "border-theme-27", "dark:border-dark-3");
                                            const view_details = document.createElement("div");
                                            view_details.classList.add("flex", "items-center", "block", "p-2", "transition", "duration-300", "ease-in-out", "rounded-md", "cursor-pointer", "hover:bg-theme-1", "dark:hover:bg-dark-3");
                                            view_details.setAttribute("data-dismiss", "dropdown");
                                            view_details.innerText = "View Details";

                                            view_details.addEventListener('click', function handleClick(event) {
                                                router.push("/purchase/rough/" + window.btoa(cell.getData().id));
                                            });
                                            main_view_details.appendChild(view_details);
                                            sub_menue.appendChild(main_view_details);

                                            if ((cell.getData().status == 0 || cell.getData().status == 1) && cell.getData().man_status == 1 && cell.getData().hold_transaction_id == null && cell.getData().manager_id == user_details.value.account_id && cell.getData().manager_type == user_details.value.user_role) {

                                                const main_edit = document.createElement("div");
                                                main_edit.classList.add("p-1", "border-t", "border-theme-27", "dark:border-dark-3");
                                                const edit = document.createElement("div");
                                                edit.setAttribute("data-dismiss", "dropdown");
                                                edit.classList.add("flex", "items-center", "block", "p-2", "transition", "duration-300", "ease-in-out", "rounded-md", "cursor-pointer", "hover:bg-theme-1", "dark:hover:bg-dark-3");
                                                edit.innerText = "Edit Details";

                                                edit.addEventListener('click', function handleClick(event) {
                                                    get_purchase_data(cell.getData().id)
                                                });
                                                main_edit.appendChild(edit);
                                                sub_menue.appendChild(main_edit);

                                                const main_reject_purchase = document.createElement("div");
                                                main_reject_purchase.classList.add("p-1", "border-t", "border-theme-27", "dark:border-dark-3");
                                                const reject_purchase = document.createElement("div");
                                                reject_purchase.setAttribute("data-dismiss", "dropdown");
                                                reject_purchase.classList.add("flex", "items-center", "block", "p-2", "transition", "duration-300", "ease-in-out", "rounded-md", "cursor-pointer", "hover:bg-theme-1", "dark:hover:bg-dark-3");
                                                reject_purchase.innerText = "Cancel Purchase";

                                                reject_purchase.addEventListener('click', function handleClick(event) {
                                                    cancel_process(cell.getData().id)
                                                });
                                                main_reject_purchase.appendChild(reject_purchase);
                                                sub_menue.appendChild(main_reject_purchase);

                                                const main_reject_weight = document.createElement("div");
                                                main_reject_weight.classList.add("p-1", "border-t", "border-theme-27", "dark:border-dark-3");
                                                const reject_weight = document.createElement("div");
                                                reject_weight.setAttribute("data-dismiss", "dropdown");
                                                reject_weight.classList.add("flex", "items-center", "block", "p-2", "transition", "duration-300", "ease-in-out", "rounded-md", "cursor-pointer", "hover:bg-theme-1", "dark:hover:bg-dark-3");
                                                reject_weight.innerText = "Reject Weight";

                                                reject_weight.addEventListener('click', function handleClick(event) {
                                                    reject_form.purchase_id = cell.getData().id
                                                    reject_form.kapan = cell.getData().voucher_no
                                                    reject_form.lot_no = cell.getData().lot_no
                                                    reject_form.avail_pcs = cell.getData().cr_pcs
                                                    reject_form.purchase_type = cell.getData().purchase_type
                                                    reject_form.rejection_type = cell.getData().purchase_type == 1 ? '1' : '2'
                                                    reject_form.avail_wt = cell.getData().cr_wt
                                                    reject_form.stone_id = String(cell.getData().stone_type)
                                                    open_reject_pcs_modal()
                                                });
                                                main_reject_weight.appendChild(reject_weight);
                                                sub_menue.appendChild(main_reject_weight);

                                                const main_issue_process = document.createElement("div");
                                                main_issue_process.classList.add("p-1", "border-t", "border-theme-27", "dark:border-dark-3");
                                                const issue_process = document.createElement("div");
                                                issue_process.setAttribute("data-dismiss", "dropdown");
                                                issue_process.classList.add("flex", "items-center", "block", "p-2", "transition", "duration-300", "ease-in-out", "rounded-md", "cursor-pointer", "hover:bg-theme-1", "dark:hover:bg-dark-3");
                                                issue_process.innerText = "Purchase Assign";

                                                issue_process.addEventListener('click', function handleClick(event) {
                                                    issue_form.purchase_id = cell.getData().id
                                                    issue_form.kapan = cell.getData().voucher_no
                                                    issue_form.lot_no = cell.getData().lot_no
                                                    issue_form.avail_pcs = cell.getData().cr_pcs
                                                    issue_form.avail_wt = cell.getData().cr_wt
                                                    issue_form.stone_id = String(cell.getData().stone_type)
                                                    open_issue_modal()
                                                });
                                                main_issue_process.appendChild(issue_process);
                                                sub_menue.appendChild(main_issue_process);

                                              }
                                                if ((cell.getData().status == 0 || cell.getData().status == 1) && cell.getData().man_status == 1 && cell.getData().hold_transaction_id == null && cell.getData().pr_status == null && cell.getData().manager_id == user_details.value.account_id && cell.getData().manager_type == user_details.value.user_role) {

                                                const main_update_p = document.createElement("div");
                                                main_update_p.classList.add("p-1", "border-t", "border-theme-27", "dark:border-dark-3");
                                                const update_p = document.createElement("div");
                                                update_p.setAttribute("data-dismiss", "dropdown");
                                                update_p.classList.add("flex", "items-center", "block", "p-2", "transition", "duration-300", "ease-in-out", "rounded-md", "cursor-pointer", "hover:bg-theme-1", "dark:hover:bg-dark-3");
                                                update_p.innerText = "Add Pieces";

                                                update_p.addEventListener('click', function handleClick(event) {
                                                    let issue_weight = cell.getData().inpr_wt ? cell.getData().inpr_wt : 0
                                                    let issue_pieces = cell.getData().inpr_pcs ? cell.getData().inpr_pcs : 0

                                                    if (issue_weight > 0 || issue_pieces > 0) {
                                                        swal('Warning', 'Kapan weight is in process, can not stock !')
                                                    } else {
                                                        router.push({
                                                            path: '/purchase/update/' + window.btoa(cell.getData().id)
                                                        })
                                                    }
                                                });
                                                main_update_p.appendChild(update_p);
                                                sub_menue.appendChild(main_update_p);
                                            }

                                            menu.appendChild(sub_menue);
                                            dropdown.appendChild(button);
                                            dropdown.appendChild(menu);

                                            // const item2 = document.createElement("a");
                                            // item2.classList.add("dropdown-item");
                                            // item2.innerText = "Item 2";
                                            // menu.appendChild(item2);

                                            const b = cash(dropdown);
                                            return b[0]

                                            const a = cash(`<div style='display: flex'>
                                            <div class='dropdown'>
                                             <button class='btn btn-secondary dropdown-toggle' type='button' id='dropdownMenuButton' data-bs-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>Action</button>
                                             <div class='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                                              <div class="dropdown-menu__content box bg-theme-26 dark:bg-dark-6 text-white">` +
                                                (cell.getData().status == 0 && cell.getData().pr_status == null ? `<div class='p-2 border-t border-theme-27 dark:border-dark-3'><button class="flex items-center p-2"><i data-feather="edit" class="w-4 h-4 mr-3"></i> Edit </button></div>` : ``) +
                                                (cell.getData().status == 0 ? `<div class='p-2 border-t border-theme-27 dark:border-dark-3'><button class="flex items-center p-1"><i data-feather="trash-2" class="w-4 h-4 mr-3"></i> Reject Purchase</button></div>` : '') +
                                                (cell.getData().status == 0 ? `<div class='p-2 border-t border-theme-27 dark:border-dark-3'><button class="flex items-center p-1"><i data-feather="pie-chart" class="w-4 h-4 mr-3"></i> Reject Pieces</button></div>` : '') +
                                                (cell.getData().status == 0 ? `<div class='p-2 border-t border-theme-27 dark:border-dark-3'><button class="flex items-center p-1"><i data-feather="chevrons-right" class="w-4 h-4 mr-3"></i> Issue</button></div>` : '') +
                                                (cell.getData().status == 0 ? `<div class='p-2 border-t border-theme-27 dark:border-dark-3'><button class="flex items-center p-1"><i data-feather="plus" class="w-4 h-4 mr-3"></i> New Pieces</button></div>` : '') +
                                                (`<div class='p-2 border-t border-theme-27 dark:border-dark-3'><button class="flex items-center p-1"> View Details</button></div>`) +
                                                `</div>
                                              </div></div></div>`)
                                            cash(a).on('click', function () {
                                                // get_purchase_data(cell.getData().id)
                                            })
                                            return a[0]

                                        }
                                    }
                                ],
                                renderComplete() {
                                    feather.replace({
                                        'stroke-width': 1.5
                                    })
                                }
                            })

              }

              axios({
                  url: 'api/kpn/grpt',
                  method: 'post',
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: 'Bearer ' + localStorage.getItem('token')
                  },
                  data: params,
                  config: {
                      headers: {
                          'Accept': 'application/json'
                      }
                  }
              }).then((res) => {
                  page_data.total = res.data.items
              })
          }

          // Process Assign
          const process_assign = async () => {

              issue_validate.value.$touch()
              if (issue_validate.value.$invalid) {
                  swal('Error', 'Fields are empty.', 'error');
                  return
              }

              const params = {
                  pieces: (issue_form.pieces != '' ? window.btoa(issue_form.pieces) : ''),
                  weight: (issue_form.weight != '' ? window.btoa(issue_form.weight) : ''),
                  purchase_id: (issue_form.purchase_id != '' ? window.btoa(issue_form.purchase_id) : ''),
                  process_id: (issue_form.process_id != '' ? window.btoa(issue_form.process_id) : ''),
                  employee_id: (issue_form.employee_id != '' ? window.btoa(issue_form.employee_id) : ''),
                  external: (issue_form.external != '' ? window.btoa(issue_form.external) : ''),
                  ip: localStorage.getItem('sk_key')
              }

              let promise_url = ''

              let promise = axios({
                  url: promise_url,
                  method: "POST",
                  data: params,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token"),

                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {

                      if (data.status == 'error') {
                          swal('Warning', data.message, 'warning');
                      } else {
                          swal('Success', data.message, 'success');

                          issue_form.pieces = ''
                          issue_form.weight = ''
                          issue_form.purchase_id = ''
                          issue_form.process_id = ''
                          issue_form.employee_id = ''
                          issue_form.external = '0'

                          cash('#rough-process-assign-modal').modal('hide')
                          validate.value.$reset()
                          initTabulator()
                      }
                  })
                  .catch(_error => {
                      swal('Error', 'Something went wrong.', 'error');
                  });

          }

          // Process Cancel
          const process_issue = async () => {

              if (issue_form.issue_type == 1) {

                  const params = {
                      operator_id: (issue_form.operator_id != '' ? window.btoa(issue_form.operator_id) : ''),
                      issue_type: (issue_form.issue_type != '' ? window.btoa(issue_form.issue_type) : ''),
                      purchase_id: (issue_form.purchase_id != '' ? window.btoa(issue_form.purchase_id) : ''),
                      ip: localStorage.getItem('sk_key')
                  }

                  let promise_url = 'api/kpn/prrp'

                  page_data.i_loading = true

                  let promise = axios({
                      url: promise_url,
                      method: "POST",
                      data: params,
                      baseURL: BASE_URL,
                      headers: {
                          Authorization: "Bearer " + localStorage.getItem("token"),

                      },
                      config: {
                          headers: {
                              Accept: "application/json"
                          }
                      }
                  });
                  return promise
                      .then(result => result.data)
                      .then(data => {

                          if (data.status == 'error') {
                              swal('Warning', data.message, 'warning');
                              page_data.i_loading = false
                          } else {
                              swal('Success', data.message, 'success');

                              issue_form.pieces = ''
                              issue_form.weight = ''
                              issue_form.purchase_id = ''
                              issue_form.process_id = ''
                              issue_form.employee_id = ''
                              issue_form.external = '0'
                              page_data.i_loading = false

                              cash('#rough-process-issue-modal').modal('hide')
                              issue_validate.value.$reset()
                              // router.push('/purchase/rough/issue')
                              initTabulator()
                          }
                      })
                      .catch(_error => {
                          page_data.i_loading = false
                          swal('Error', 'Something went wrong.', 'error');
                      });
              } else {

                  issue_validate.value.$touch()
                  if (issue_validate.value.$invalid) {
                      // swal('Error', 'Fields are empty.', 'error');
                      return
                  }

                  const params = {
                      pieces: (issue_form.pieces != '' ? window.btoa(issue_form.pieces) : ''),
                      weight: (issue_form.weight != '' ? window.btoa(issue_form.weight) : ''),
                      purchase_id: (issue_form.purchase_id != '' ? window.btoa(issue_form.purchase_id) : ''),
                      process_id: (issue_form.process_id != '' ? window.btoa(issue_form.process_id) : ''),
                      employee_id: (issue_form.employee_id != '' ? window.btoa(issue_form.employee_id) : ''),
                      external: (issue_form.external != '0' ? window.btoa(issue_form.external) : ''),
                      issue_type: (issue_form.issue_type != '' ? window.btoa(issue_form.issue_type) : ''),
                      ip: localStorage.getItem('sk_key')
                  }

                  let promise_url = 'api/kpn/prrp'

                  page_data.i_loading = true

                  let promise = axios({
                      url: promise_url,
                      method: "POST",
                      data: params,
                      baseURL: BASE_URL,
                      headers: {
                          Authorization: "Bearer " + localStorage.getItem("token"),

                      },
                      config: {
                          headers: {
                              Accept: "application/json"
                          }
                      }
                  });
                  return promise
                      .then(result => result.data)
                      .then(data => {

                          if (data.status == 'error') {
                              swal('Warning', data.message, 'warning');
                              page_data.i_loading = false
                          } else {
                              swal('Success', data.message, 'success');

                              issue_form.pieces = ''
                              issue_form.weight = ''
                              issue_form.purchase_id = ''
                              issue_form.process_id = ''
                              issue_form.employee_id = ''
                              issue_form.external = '0'
                              page_data.i_loading = false

                              cash('#rough-process-issue-modal').modal('hide')
                              issue_validate.value.$reset()
                              // router.push('/purchase/rough/issue')
                              initTabulator()
                          }
                      })
                      .catch(_error => {
                          page_data.i_loading = false
                          swal('Error', 'Something went wrong.', 'error');
                      });
              }

          }

          // Process Cancel
          const process_reject = async () => {

              reject_validate.value.$touch()
              if (reject_validate.value.$invalid) {
                  swal('Error', 'Fields are empty.', 'error');
                  return
              }

              const params = {
                  rejection_type: (reject_form.rejection_type != '' ? window.btoa(reject_form.rejection_type) : ''),
                  pieces: (reject_form.pieces != '' ? window.btoa(reject_form.pieces) : ''),
                  weight: (reject_form.weight != '' ? window.btoa(reject_form.weight) : ''),
                  id: (reject_form.purchase_id != '' ? window.btoa(reject_form.purchase_id) : ''),
                  ip: localStorage.getItem('sk_key')
              }

              let promise_url = 'api/kpn/rrp'
              page_data.r_loading = true

              let promise = axios({
                  url: promise_url,
                  method: "POST",
                  data: params,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token"),

                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {

                      if (data.status == 'error') {
                          swal('Warning', data.message, 'warning');
                          page_data.r_loading = false
                      } else {
                          swal('Success', data.message, 'success');

                          reject_form.rejection_type = '1'
                          reject_form.pieces = ''
                          reject_form.weight = ''
                          reject_form.purchase_id = ''
                          reject_form.kapan = ''
                          reject_form.lot_no = ''
                          reject_form.stone_id = ''
                          reject_form.avail_pcs = ''
                          reject_form.avail_wt = ''
                          page_data.r_loading = false

                          cash('#rough-process-reject-modal').modal('hide')
                          reject_validate.value.$reset()
                          initTabulator()
                      }
                  })
                  .catch(_error => {
                      page_data.r_loading = false
                      swal('Error', 'Something went wrong.', 'error');
                  });

          }

          //New Purchase
          const add_purchase = async () => {

              if (form_data.purchase_type == 1) {

                  form_validate.value.$touch()
                  if (form_validate.value.$invalid) {
                      // swal('Error', 'Fields are empty.', 'error');
                      return
                  }
              } else if (form_data.purchase_type == 2) {

                  if (form_data.purchase_list.length > 0) {

                      for (let i = 0; i < form_data.rejection_details.length; i++) {
                          if (form_data.rejection_details[i].new_rejection_wt <= 0 || form_data.rejection_details[i].new_rejection_wt == '' || form_data.rejection_details[i].new_rejection_wt == null) {

                              swal('Warning', 'Selected purchase weight must be added.', 'warning')
                              return;
                          }
                      }

                      // form_data.rejection_details.forEach(element => {
                      //   if(element.new_rejection_wt <= 0 || element.new_rejection_wt == '' || element.new_rejection_wt == null) {

                      //     swal('Warning','Selected purchase weight must be added.','warning')
                      //     return;
                      //   }
                      // });
                  } else {

                      swal('Warning', 'Please, select purchase first.', 'warning');
                      return
                  }
              }

              const params = form_data

              let promise_url = ''

              if (form_data.id) {
                  promise_url = 'api/kpn/up'
              } else {
                  promise_url = 'api/kpn/anp'
              }

              page_data.loading = true

              let promise = axios({
                  url: promise_url,
                  method: "POST",
                  data: params,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token"),

                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {

                      if (data.status == 'error') {
                        page_data.loading = false
                        if(data.type == "warning") {
                          update_process_confirm(data.message)
                        } else {
                          swal('Warning', data.message, 'warning');
                        }
                      } else {
                          swal('Success', data.message, 'success');

                          form_data.id = ''
                          // form_data.party = '0'
                          form_data.stone_id = 2
                          form_data.piece = ''
                          form_data.weight = ''
                          form_data.due = 15
                          form_data.kapan = ''
                          form_data.lot_no = ''
                          form_data.per_ct_rate = ''
                          form_data.purchase_type = '1'
                          form_data.purchase_select = false
                          form_data.purchase_list = []
                          form_data.rejection_details = []
                          form_data.majuri_type = '2'
                          // form_data.majuri_rate = ''
                          // form_data.margin_percent = ''
                          get_margin_costing()
                          page_data.loading = false

                          cash('#new-rough-purchase-modal').modal('hide')
                          form_validate.value.$reset()
                          initTabulator()
                      }
                  })
                  .catch(_error => {
                      page_data.loading = false
                      swal('Error', 'Something went wrong.', 'error');
                  });

          }

          // Reject Purchase
          const cancel_process = async (id) => {

              swal({
                  title: "Are you sure?",
                  text: "You are about to Dactive purchase!",
                  type: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Reject",
                  showLoaderOnConfirm: true,
                  cancelButtonText: "Cancel",

                  allowOutsideClick: () => !$Swal.isLoading()
              }).then(result => {
                  if (result.isConfirmed) {

                      const params = {
                          id: (id != '' ? window.btoa(id) : ''),
                          ip: localStorage.getItem('sk_key')
                      }

                      let promise_url = 'api/kpn/rp'
                      let promise = axios({
                          url: promise_url,
                          method: "POST",
                          data: params,
                          baseURL: BASE_URL,
                          headers: {
                              Authorization: "Bearer " + localStorage.getItem("token"),

                          },
                          config: {
                              headers: {
                                  Accept: "application/json"
                              }
                          }
                      });
                      return promise
                          .then(result => result.data)
                          .then(data => {

                              if (data.status == 'error') {
                                if(data.type && data.type == "warning") {
                                  cancel_process_confirm(params.id, data.message)
                                } else {
                                  swal('Warning', data.message, 'warning');
                                }
                              } else {
                                  swal('Success', data.message, 'success');

                                  initTabulator()
                              }
                          })
                          .catch(_error => {
                              swal('Error', 'Something went wrong.', 'error');
                          });

                  }
              });
          }
          const cancel_process_confirm = async (id, mfg) => {

              swal({
                  title: "Are you sure?",
                  text: mfg,
                  type: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Confirm",
                  showLoaderOnConfirm: true,
                  cancelButtonText: "Cancel",

                  allowOutsideClick: () => !$Swal.isLoading()
              }).then(result => {
                  if (result.isConfirmed) {

                      const params = {
                          id: (id != '' ? window.btoa(id) : ''),
                          ip: localStorage.getItem('sk_key')
                      }

                      let promise_url = 'api/kpn/rpc'
                      let promise = axios({
                          url: promise_url,
                          method: "POST",
                          data: params,
                          baseURL: BASE_URL,
                          headers: {
                              Authorization: "Bearer " + localStorage.getItem("token"),

                          },
                          config: {
                              headers: {
                                  Accept: "application/json"
                              }
                          }
                      });
                      return promise
                          .then(result => result.data)
                          .then(data => {

                              if (data.status == 'error') {
                                  swal('Warning', data.message, 'warning');
                              } else {
                                  swal('Success', data.message, 'success');

                                  initTabulator()
                              }
                          })
                          .catch(_error => {
                              swal('Error', 'Something went wrong.', 'error');
                          });

                  }
              });
          }
          const update_process_confirm = async (mfg) => {

              swal({
                  title: "Are you sure?",
                  text: mfg,
                  type: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Confirm",
                  showLoaderOnConfirm: true,
                  cancelButtonText: "Cancel",

                  allowOutsideClick: () => !$Swal.isLoading()
              }).then(result => {
                  if (result.isConfirmed) {

                    if (form_data.purchase_type == 1) {

                      form_validate.value.$touch()
                      if (form_validate.value.$invalid) {
                          // swal('Error', 'Fields are empty.', 'error');
                          return
                      }
                    } else if (form_data.purchase_type == 2) {

                      if (form_data.purchase_list.length > 0) {

                          for (let i = 0; i < form_data.rejection_details.length; i++) {
                              if (form_data.rejection_details[i].new_rejection_wt <= 0 || form_data.rejection_details[i].new_rejection_wt == '' || form_data.rejection_details[i].new_rejection_wt == null) {

                                  swal('Warning', 'Selected purchase weight must be added.', 'warning')
                                  return;
                              }
                          }

                          // form_data.rejection_details.forEach(element => {
                          //   if(element.new_rejection_wt <= 0 || element.new_rejection_wt == '' || element.new_rejection_wt == null) {

                          //     swal('Warning','Selected purchase weight must be added.','warning')
                          //     return;
                          //   }
                          // });
                      } else {

                          swal('Warning', 'Please, select purchase first.', 'warning');
                          return
                      }
                    }

                    const params = form_data

                    let promise_url = ''

                    if (form_data.id) {
                      promise_url = 'api/kpn/upc'
                    } else {
                      promise_url = 'api/kpn/anp'
                    }

                    page_data.loading = true

                    let promise = axios({
                    url: promise_url,
                    method: "POST",
                    data: params,
                    baseURL: BASE_URL,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),

                    },
                    config: {
                        headers: {
                            Accept: "application/json"
                        }
                    }
                  });
                  return promise
                  .then(result => result.data)
                  .then(data => {

                      if (data.status == 'error') {
                        page_data.loading = false
                        if(data.type == "warning") {
                          update_process_confirm()
                        } else {
                          swal('Warning', data.message, 'warning');
                        }
                      } else {
                          swal('Success', data.message, 'success');

                          form_data.id = ''
                          // form_data.party = '0'
                          form_data.stone_id = 2
                          form_data.piece = ''
                          form_data.weight = ''
                          form_data.due = 15
                          form_data.kapan = ''
                          form_data.lot_no = ''
                          form_data.per_ct_rate = ''
                          form_data.purchase_type = '1'
                          form_data.purchase_select = false
                          form_data.purchase_list = []
                          form_data.rejection_details = []
                          form_data.majuri_type = '2'
                          // form_data.majuri_rate = ''
                          // form_data.margin_percent = ''
                          get_margin_costing()
                          page_data.loading = false

                          cash('#new-rough-purchase-modal').modal('hide')
                          form_validate.value.$reset()
                          initTabulator()
                      }
                  })
                  .catch(_error => {
                      page_data.loading = false
                      swal('Error', 'Something went wrong.', 'error');
                  });

                }
              });
          }

          const get_party_details = () => {

              axios({
                  url: 'api/master/dp_parties',
                  method: 'post',
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: 'Bearer ' + localStorage.getItem('token')
                  },
                  config: {
                      headers: {
                          'Accept': 'application/json'
                      }
                  }
              }).then((res) => {
                  page_data.party_details = res.data.items

                  if (user_details.value.user_role == 3) {
                      form_data.party = user_details.value.account_id
                  } else {

                    form_data.party = String(page_data.party_details[0].id)
                  }

                  getHomeParty()
              })
          }

          const get_margin_costing = async () => {
              const params = {
                  type: window.btoa(form_data.majuri_type)
              }
              axios({
                  url: 'api/majuri_costing/get_party_majuri_costing',
                  method: 'post',
                  baseURL: BASE_URL,
                  data: params,
                  headers: {
                      Authorization: 'Bearer ' + localStorage.getItem('token')
                  },
                  config: {
                      headers: {
                          'Accept': 'application/json'
                      }
                  }
              }).then((res) => {
                  form_data.majuri_rate = res.data.majuri_value.value
                  form_data.margin_percent = res.data.margin_value.value
              })
          }

          const get_purchase_data = async (id) => {
              const params = {
                  id: window.btoa(id),
                  ip: localStorage.getItem('sk_key')
              }
              axios({
                  url: 'api/kpn/ep',
                  method: 'post',
                  baseURL: BASE_URL,
                  data: params,
                  headers: {
                      Authorization: 'Bearer ' + localStorage.getItem('token')
                  },
                  config: {
                      headers: {
                          'Accept': 'application/json'
                      }
                  }
              }).then((res) => {
                  form_data.id = window.btoa(res.data.item.id)
                  form_data.piece = res.data.item.pr_pcs
                  form_data.party = String(res.data.item.party_id)
                  form_data.packet_type = res.data.item.type
                  form_data.stone_id = res.data.item.stone_type
                  form_data.weight = res.data.item.pr_wt
                  form_data.due = res.data.item.due
                  form_data.kapan = res.data.item.voucher_no
                  form_data.lot_no = res.data.item.lot_no
                  form_data.per_ct_rate = res.data.item.per_ct_rate
                  form_data.majuri_rate = res.data.item.majuri_rate
                  form_data.margin_percent = res.data.item.margin_percent
                  form_data.purchase_type = String(res.data.item.purchase_type)
                  form_data.purchase_list = (res.data.item.rejection_id ? res.data.item.rejection_id : [])
                  form_data.rejection_details = res.data.item.rejection_list

                  if (form_data.purchase_type == 2) {

                      calculate_total()
                  }

                  cash('#new-rough-purchase-modal').modal('show')
              })
          }

          // Export
          const onExportCsv = () => {
              tabulator.value.download('csv', 'data.csv')
          }

          const onExportJson = () => {
              tabulator.value.download('json', 'data.json')
          }

          const onExportXlsx = () => {
              const win = window
              win.XLSX = xlsx
              tabulator.value.download('xlsx', 'data.xlsx', {
                  sheetName: 'Products'
              })
          }

          const onExportHtml = () => {
              tabulator.value.download('html', 'data.html', {
                  style: true
              })
          }

          const onResetFilter = () => {

              filter_data.status = '0'
              filter_data.kapan_no = ''
              filter_data.party_id = '0'
              filter_data.type = '0'
              filter_data.stone_id = '0'
          }

          // Print
          const onPrint = () => {
              tabulator.value.print()
          }

          const open_purchase_modal = () => {
              get_rejection_details()
              cash('#new-rough-purchase-modal').modal('show')
          }

          const open_issue_modal = () => {
              cash('#rough-process-issue-modal').modal('show')
          }

          const open_reject_pcs_modal = () => {
              cash('#rough-process-reject-modal').modal('show')
          }

          const get_process_details = (id) => {
              if (id != 0) {
                  const params = {
                      user_id: id,
                      process_type: window.btoa('bunch')
                  }
                  if (id != '0') {
                      axios({
                          url: 'api/master/manager_processes',
                          method: 'post',
                          baseURL: BASE_URL,
                          data: params,
                          headers: {
                              Authorization: 'Bearer ' + localStorage.getItem('token')
                          },
                          config: {
                              headers: {
                                  'Accept': 'application/json'
                              }
                          }
                      }).then((res) => {
                          page_data.process_items = res.data.items
                          issue_form.process_id = ''
                      })
                  } else {
                      page_data.process_items = []
                      process.process_id = ''
                  }
              }
          }
          const get_rejection_details = (id) => {
              if (id != 0) {
                  const params = {
                      user_id: id,
                      process_type: window.btoa('bunch')
                  }
                  if (id != '0') {
                      axios({
                          url: 'api/kpn/get_rejection_list',
                          method: 'post',
                          baseURL: BASE_URL,
                          data: params,
                          headers: {
                              Authorization: 'Bearer ' + localStorage.getItem('token')
                          },
                          config: {
                              headers: {
                                  'Accept': 'application/json'
                              }
                          }
                      }).then((res) => {
                          page_data.rejection_items = res.data.items
                      })
                  } else {
                      page_data.rejection_items = []
                  }
              }
          }
          const get_manager_details = (id) => {
              if (id != 0) {
                  const params = {
                      user_id: id,
                      // process_type: window.btoa('bunch')
                  }
                  if (id != '0') {
                      axios({
                          url: 'api/master/dp_managers',
                          method: 'post',
                          baseURL: BASE_URL,
                          data: params,
                          headers: {
                              Authorization: 'Bearer ' + localStorage.getItem('token')
                          },
                          config: {
                              headers: {
                                  'Accept': 'application/json'
                              }
                          }
                      }).then((res) => {
                          page_data.manager_items = res.data.items

                          var i = page_data.manager_items.length;
                          while (i--) {
                              if (page_data.manager_items[i]['id'] == user_details.value.account_id) {
                                  page_data.manager_items.splice(i, 1);
                              }
                          }
                          issue_form.operator_id = String(page_data.manager_items[0].id)
                      })
                  } else {
                      page_data.manager_items = []
                      process.operator_id = ''
                  }
              }
          }

          const get_employee_details = () => {
              const params = {
                  manager_id: window.btoa(user_details.value.account_id),
                  process_id: window.btoa(issue_form.process_id),
                  process_type: window.btoa('bunch'),
              }
              if (issue_form.process_id != '') {

                  axios({
                      url: 'api/master/get_employees_by_manager_process',
                      method: 'post',
                      baseURL: BASE_URL,
                      data: params,
                      headers: {
                          Authorization: 'Bearer ' + localStorage.getItem('token')
                      },
                      config: {
                          headers: {
                              'Accept': 'application/json'
                          }
                      }
                  }).then((res) => {
                      page_data.employee_items = res.data.items
                      issue_form.employee_id = ''
                  })
                  axios({
                      url: 'api/master/get_jober_details_by_process',
                      method: 'post',
                      baseURL: BASE_URL,
                      data: params,
                      headers: {
                          Authorization: 'Bearer ' + localStorage.getItem('token')
                      },
                      config: {
                          headers: {
                              'Accept': 'application/json'
                          }
                      }
                  }).then((res) => {
                      page_data.process_party_items = res.data.items
                      issue_form.party_id = ''
                  })
              } else {
                  page_data.employee_items = []
                  issue_form.employee_id = ''
                  page_data.process_party_items = []
                  issue_form.party_id = ''
              }
          }

          watch(() => form_data.purchase_list, (CurrentVal, OldVal) => {
            if(form_data.purchase_list.length >  0) {

              if (CurrentVal.length > OldVal.length) {
                  let purchase_id = form_data.purchase_list[CurrentVal.length - 1]

                  page_data.rejection_items.forEach(item => {
                      if (item.kapan_id == purchase_id) {
                          form_data.rejection_details.push({
                              id: item.kapan_id,
                              rejection_id: item.id,
                              lot_no: item.lot_no,
                              voucher_no: item.voucher_no,
                              cr_rejection_wt: item.cr_rejection_wt,
                              cr_rejection_pcs: item.cr_rejection_pcs,
                              new_rejection_wt: '',
                              new_rejection_pcs: '',
                          })
                          calculate_total()
                      }
                  });
                  // rejection_details
              } else {
                  let purchase_id = OldVal.filter(x => CurrentVal.indexOf(x) === -1);
                  let length = form_data.rejection_details.length

                  while (length--) {
                      if (form_data.rejection_details[length]['id'] == purchase_id[0]) {
                          form_data.rejection_details.splice(length, 1);
                      }
                  }
              }
            }
          })

          const getHomeParty = () => {
            page_data.home_party = ""

            page_data.party_details.forEach(element => {

              console.log(element.id)
              if(element.id == form_data.party) {
                page_data.home_party = element.home_party
              }
            });
          }

          const validateDetails = (item) => {

              if (item.cr_rejection_wt < (item.new_rejection_wt ? item.new_rejection_wt : 0)) {
                  item.new_rejection_wt = ''
                  swal('Warning', 'Weight mush be less then ' + item.cr_rejection_wt + ' Ct')
                  return
              }

              calculate_total()

          }
          const calculate_total = () => {
              let cr_weight = 0
              let new_weight = 0
              let cr_pcs = 0
              let new_pcs = 0
              form_data.rejection_details.forEach(element => {

                  cr_weight += parseFloat(element.cr_rejection_wt)
                  new_weight += (element.new_rejection_wt ? parseFloat(element.new_rejection_wt) : 0)
                  cr_pcs += parseFloat(element.cr_rejection_pcs)
                  new_pcs += (element.new_rejection_pcs ? parseFloat(element.new_rejection_pcs) : 0)
                  console.log(new_weight)
              });
              form_data.total.cr_weight = $h.myFloat(cr_weight)
              form_data.total.new_weight = $h.myFloat(new_weight)
              form_data.total.cr_pcs = (cr_pcs)
              form_data.total.new_pcs = (new_pcs)
          }

          onMounted(() => {
              if (user_details.value.user_role == 3) {
                  form_data.party = user_details.value.account_id
              } else {}
              get_margin_costing()
              get_party_details()
              get_manager_details()
              calculate_total()
              get_process_details(user_details.value.account_id)
              initTabulator()
          })
          return {
              page_data,
              form_data,
              user_details,
              filter_data,
              reject_form,
              issue_form,
              get_margin_costing,
              initTabulator,
              issue_validate,
              form_validate,
              reject_validate,
              process_issue,
              process_reject,
              add_purchase,
              cancel_process,
              onExportCsv,
              onExportJson,
              onExportXlsx,
              onExportHtml,
              onPrint,
              tableRef,
              onResetFilter,
              process_assign,
              open_purchase_modal,
              get_employee_details,
              validateDetails,
              getHomeParty

          }
      }
  })
  </script>
