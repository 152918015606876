<template>
  <div>
    <div class="intro-y flex items-center mt-2">
      <h2 class="text-lg font-medium mr-auto">Kapan Report</h2>
    </div>
    <div class="intro-y box p-5 mt-3" style="z-index: 50;">

      <div class="grid grid-cols-12 gap-x-2">
        <div class="col-span-8 sm:col-span-6 md:col-span-6 xl:col-span-6">
          <div>
            <TomSelect v-model="page_data.kapan_id" :options="{
              placeholder: 'Select Kapan',
              loadingClass: 'loading',
              plugins: {
                dropdown_header: {
                  title: 'Kapan'
                }
              }
            }" class="w-full" multiple>
              <option v-for="item in page_data.lot_items" :key="item.id" :value="item.id">{{ item.lot_no }} (
                {{ item.voucher_no ? item.voucher_no : '-' }} )</option>
            </TomSelect>
          </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1">

          <button id="tabulator-html-filter-go" type="button" class="btn btn-primary w-full ml-2" tabindex="5"
            :disabled="page_data.r_loading == true" @click="getReportDetails()">
            Generate
          </button>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1">
                <div class="flex">

                    <button id="tabulator-html-filter-go" type="button" class="btn btn-primary w-full ml-2" tabindex="5" @click="exportReportDetails">
                        Export
                    </button>
                </div>
            </div>
      </div>

      <div class="mt-5">

        <div id="responsive-table" class="p-5" v-if="page_data.r_loading">
          <div class="preview">
            <div class="overflow-x-auto">
              <div class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center">
                <LoadingIcon icon="bars" class="w-8 h-8" />
                <div class="text-center text-xs mt-2">Getting Latest Price</div>
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          <div v-if="page_data.generate == 'gen'">


            <div class="intro-y box">
              <div class="p-5 border-b border-gray-200 dark:border-dark-5">
                <div class="grid grid-cols-12 gap-x-5">
                  <div class="col-span-12 lg:col-span-5">

                    <div class="mt-2">
                      <label for="update-profile-form-6" class="form-label font-bold">Kapan No. :&nbsp;</label><label
                        for="update-profile-form-6" class="form-label">
                        {{page_data.kapan_details.voucher_no}} ({{page_data.kapan_details.lot_no}})
                        <!-- <span v-for="(item, key) in page_data.kapan_details" :key="key">

                          <span v-if="key != 0">, </span>{{ item.voucher_no }}
                        </span> -->
                      </label>
                    </div>
                    <div class="mt-2">
                      <label for="update-profile-form-6" class="form-label font-bold">Purchase Pieces. :&nbsp;</label><label
                        for="update-profile-form-6" class="form-label">
                        {{page_data.kapan_details.total_pieces}}
                        <!-- <span v-for="(item, key) in page_data.kapan_details" :key="key">

                          <span v-if="key != 0">, </span>{{ item.lot_no }}
                        </span> -->
                      </label>
                    </div>
                    <div class="mt-2">
                      <label for="update-profile-form-6" class="form-label font-bold">Purchase Weight (Ct). :&nbsp;</label><label
                        for="update-profile-form-6" class="form-label">
                        {{page_data.kapan_details.total_weight}} Ct
                        <!-- <span v-for="(item, key) in page_data.kapan_details" :key="key">

                          <span v-if="key != 0">, </span>{{ item.lot_no }}
                        </span> -->
                      </label>
                    </div>
                    <div class="mt-2">
                      <label for="update-profile-form-6" class="form-label font-bold">Purchase Per / Ct. :&nbsp;</label><label
                        for="update-profile-form-6" class="form-label">
                        {{page_data.kapan_details.per_ct_rate ? page_data.kapan_details.per_ct_rate : 0}} $
                        <!-- <span v-for="(item, key) in page_data.kapan_details" :key="key">

                          <span v-if="key != 0">, </span>{{ item.lot_no }}
                        </span> -->
                      </label>
                    </div>
                    <div class="mt-2">
                      <label for="update-profile-form-6" class="form-label font-bold">Entry Date. :&nbsp;</label><label
                        for="update-profile-form-6" class="form-label">
                        {{page_data.kapan_details.cetry_date ? page_data.kapan_details.cetry_date : ""}}
                        <!-- <span v-for="(item, key) in page_data.kapan_details" :key="key">

                          <span v-if="key != 0">, </span>{{ item.lot_no }}
                        </span> -->
                      </label>
                    </div>
                    <div class="mt-2">
                      <label for="update-profile-form-6" class="form-label font-bold">Average (Rs).
                        :&nbsp;</label><label for="update-profile-form-6" class="form-label"> <span
                          class="flex items-center"><input id="pos-form-1" v-model="page_data.conversation_rate"
                            name="conversation_rate" type="text" class="form-control flex-1 p-1 m-0 mx-2"
                            placeholder="Rate ($)" @input="updateInrRates" /></span>
                      </label>
                    </div>

                  </div>
                  <div class="col-span-12 lg:col-span-5">

                    <div class="mt-2">
                      <label for="update-profile-form-6" class="form-label font-bold">Rough Cost. :&nbsp;</label><label
                        for="update-profile-form-6" class="form-label">
                        {{$h.myFloat((parseFloat(page_data.kapan_details.total_weight * (page_data.conversation_rate ? page_data.conversation_rate : 0) * (page_data.kapan_details.per_ct_rate ? page_data.kapan_details.per_ct_rate : 0))))}}
                        <!-- <span v-for="(item, key) in page_data.kapan_details" :key="key">

                          <span v-if="key != 0">, </span>{{ item.voucher_no }}
                        </span> -->
                      </label>
                    </div>
                    <div class="mt-2">
                      <label for="update-profile-form-6" class="form-label font-bold">Total Labour. :&nbsp;</label><label
                        for="update-profile-form-6" class="form-label">
                        {{page_data.kapan_weight_summary.total_majuri}}
                        <!-- <span v-for="(item, key) in page_data.kapan_details" :key="key">

                          <span v-if="key != 0">, </span>{{ item.voucher_no }}
                        </span> -->
                      </label>
                    </div>
                    <div class="mt-2">
                      <label for="update-profile-form-6" class="form-label font-bold">Total Cost. :&nbsp;</label><label
                        for="update-profile-form-6" class="form-label">
                        {{parseFloat(page_data.kapan_weight_summary.total_majuri + $h.myFloat((parseFloat(page_data.kapan_details.total_weight * (page_data.conversation_rate ? page_data.conversation_rate : 0) * (page_data.kapan_details.per_ct_rate ? page_data.kapan_details.per_ct_rate : 0))))).toFixed(2)}}
                        <!-- <span v-for="(item, key) in page_data.kapan_details" :key="key">

                          <span v-if="key != 0">, </span>{{ item.voucher_no }}
                        </span> -->
                      </label>
                    </div>
                    <div class="mt-2">
                      <label for="update-profile-form-6" class="form-label font-bold">Avg Ct Cost. :&nbsp;</label><label
                        for="update-profile-form-6" class="form-label">
                        {{parseFloat((page_data.kapan_weight_summary.total_majuri + $h.myFloat((parseFloat(page_data.kapan_details.total_weight * (page_data.conversation_rate ? page_data.conversation_rate : 0) * (page_data.kapan_details.per_ct_rate ? page_data.kapan_details.per_ct_rate : 0))))) / page_data.kapan_details.total_weight).toFixed(2)}}
                        <!-- <span v-for="(item, key) in page_data.kapan_details" :key="key">

                          <span v-if="key != 0">, </span>{{ item.voucher_no }}
                        </span> -->
                      </label>
                    </div>

                  </div>

                </div>
              </div>
              <div class="p-5 border-b border-gray-200 dark:border-dark-5">
                <div class=" grid grid-cols-12 gap-x-2">
                  <div class="col-span-12 md:col-span-6">
                    <div class="border mt-5">
                      <div class="overflow-x-auto">
                        <table class="w-full text-center text-xs">
                          <thead>
                            <tr  class="bg-gray-300">
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5" colspan="9">PLANNING  SUMMARY</th>
                            </tr>
                            <tr  class="bg-gray-300">
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5"></th>
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">PIECES</th>
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">EXP. MK</th>
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">EXP. PL</th>
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">EXP. PER CT</th>
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">PER CT(INR)</th>
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">EXP. AMT</th>
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">AMT(INR)</th>
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">%</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="hover:bg-gray-200 border-2 border-b-0" :class="{'bg-gray-200': (page_data.plan_items.length - 1) == index}" v-for="(item, index) in page_data.plan_items" :key="index">
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ item.plan_shape }}</td>
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ item.total_pieces }}</td>
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ item.total_weight }}</td>
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ item.total_polish_weight }}</td>
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ item.per_ct }}</td>
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ parseFloat(item.per_ct * (page_data.conversation_rate ? page_data.conversation_rate : 0)).toFixed() }}</td>
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ item.total_value }}</td>
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ parseFloat(item.total_value * (page_data.conversation_rate ? page_data.conversation_rate : 0)).toFixed() }}</td>
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ item.percentage }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="border mt-5">
                      <div class="overflow-x-auto">
                        <table class="w-full text-center text-xs">
                          <thead>
                            <tr  class="bg-gray-300">
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5" colspan="9">MAKEABLE  SUMMARY</th>
                            </tr>
                            <tr  class="bg-gray-300">
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5"></th>
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">PIECES</th>
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">EXP. MK</th>
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">EXP. PL</th>
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">EXP. PER CT</th>
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">PER CT(INR)</th>
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">EXP. AMT</th>
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">AMT(INR)</th>
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">%</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="hover:bg-gray-200 border-2 border-b-0" :class="{'bg-gray-200': (page_data.plan_items.length - 1) == index}" v-for="(item, index) in page_data.makeable_items" :key="index">
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ item.plan_shape }}</td>
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ item.total_pieces }}</td>
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ item.total_weight }}</td>
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ item.total_polish_weight }}</td>
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ item.per_ct }}</td>
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ parseFloat(item.per_ct * (page_data.conversation_rate ? page_data.conversation_rate : 0)).toFixed() }}</td>
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ item.total_value }}</td>
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ parseFloat(item.total_value * (page_data.conversation_rate ? page_data.conversation_rate : 0)).toFixed() }}</td>
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ item.percentage }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="border mt-5">
                      <div class="overflow-x-auto">
                        <table class="w-full text-center text-xs">
                          <thead>
                            <tr  class="bg-gray-300">
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5" colspan="9">FINAL  SUMMARY</th>
                            </tr>
                            <tr  class="bg-gray-300">
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5"></th>
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">PIECES</th>
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">EXP. MK</th>
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">EXP. PL</th>
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">EXP. PER CT</th>
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">PER CT(INR)</th>
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">EXP. AMT</th>
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">AMT(INR)</th>
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">%</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="hover:bg-gray-200 border-2 border-b-0" :class="{'bg-gray-200': (page_data.plan_items.length - 1) == index}" v-for="(item, index) in page_data.final_items" :key="index">
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ item.plan_shape }}</td>
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ item.total_pieces }}</td>
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ item.total_weight }}</td>
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ item.total_polish_weight }}</td>
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ item.per_ct }}</td>
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ parseFloat(item.per_ct * (page_data.conversation_rate ? page_data.conversation_rate : 0)).toFixed() }}</td>
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ item.total_value }}</td>
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ parseFloat(item.total_value * (page_data.conversation_rate ? page_data.conversation_rate : 0)).toFixed() }}</td>
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ item.percentage }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="border mt-5">
                      <div class="overflow-x-auto">
                        <table class="w-full text-center text-xs">
                          <thead>
                            <tr  class="bg-gray-300">
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5" colspan="10">SIZE POLISH SUMMARY</th>
                            </tr>
                            <tr  class="bg-gray-300">
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5"></th>
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Size</th>
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">PIECES</th>
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">EXP. MK</th>
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">EXP. PL</th>
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">EXP. PER CT</th>
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">PER CT(INR)</th>
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">EXP. AMT</th>
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">AMT(INR)</th>
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">%</th>
                            </tr>
                          </thead>
                          <tbody v-for="(itm, inx) in page_data.size_wise_stock" :key="inx">
                            <tr class="border-2 border-b-0 bg-dark-3 text-white">
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ itm.plan_shape }}</td>
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ itm.plan_shape }}</td>
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ itm.total_pieces }}</td>
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ itm.total_weight }}</td>
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ itm.total_polish_weight }}</td>
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ itm.per_ct }}</td>
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ parseFloat(itm.per_ct * (page_data.conversation_rate ? page_data.conversation_rate : 0)).toFixed() }}</td>
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ itm.total_value }}</td>
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ parseFloat(itm.total_value * (page_data.conversation_rate ? page_data.conversation_rate : 0)).toFixed() }}</td>
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ itm.percentage }}</td>
                            </tr>
                            <tr class="hover:bg-gray-200 border-2 border-b-0" :class="{'bg-gray-200': (page_data.plan_items.length - 1) == index}" v-for="(item, index) in itm.list" :key="index">
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ item.plan_shape }}</td>
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ item.charni_name }}</td>
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ item.total_pieces }}</td>
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ item.total_weight }}</td>
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ item.total_polish_weight }}</td>
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ item.per_ct }}</td>
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ parseFloat(item.per_ct * (page_data.conversation_rate ? page_data.conversation_rate : 0)).toFixed() }}</td>
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ item.total_value }}</td>
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ parseFloat(item.total_value * (page_data.conversation_rate ? page_data.conversation_rate : 0)).toFixed() }}</td>
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ item.percentage }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="col-span-12 md:col-span-6">
                    <div class="border mt-5">
                      <div class="overflow-x-auto">
                        <table class="w-full text-center text-xs">
                            <tr  class="bg-gray-300">
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5" colspan="3">CARAT SUMMARY</th>
                            </tr>
                            <tr  class="bg-gray-300">
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5"></th>
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Weight</th>
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">%</th>
                            </tr>
                            <tr  class="bg-gray-100">
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Kachu Weight</th>
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">{{page_data.kapan_weight_summary.kachu_weight}}</th>
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">{{page_data.kapan_weight_summary.kachu_percent}} %</th>
                            </tr>
                            <tr  class="bg-gray-100">
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Loss Weight</th>
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">{{page_data.kapan_weight_summary.loss_weight}}</th>
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">{{page_data.kapan_weight_summary.loss_percent}} %</th>
                            </tr>
                            <tr  class="bg-gray-100">
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Mix Packet Loss</th>
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">{{page_data.kapan_weight_summary.mix_packet_loss}}</th>
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">{{page_data.kapan_weight_summary.mix_packet_loss_percent}} %</th>
                            </tr>
                            <tr class="bg-gray-100">
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Rejection Weight</th>
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">{{page_data.kapan_weight_summary.assortment_weight}}</th>
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">{{page_data.kapan_weight_summary.assortment_percent}} %</th>
                            </tr>
                            <tr class="bg-gray-100">
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Final Weight</th>
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">{{page_data.kapan_weight_summary.final_weight}}</th>
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">{{page_data.kapan_weight_summary.final_percent}} %</th>
                            </tr>
                            <tr class="bg-gray-200">
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Total Weight</th>
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">{{page_data.kapan_weight_summary.total_weight}}</th>
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5"></th>
                            </tr>
                        </table>
                      </div>
                    </div>
                    <div class="border mt-5">
                      <div class="overflow-x-auto">
                        <table class="mt-2 w-full border-2">
                          <thead>
                            <tr class="bg-dark-3 border text-white">
                              <th class="py-2 text-xs whitespace-nowrap" colspan="8">Kapan Stock</th>
                            </tr>
                            <tr class="bg-dark-3 border text-white">
                              <th class="py-2 border text-xs whitespace-nowrap">Manager</th>
                              <th class="py-2 border text-xs whitespace-nowrap">Total Packets</th>
                              <th class="py-2 border text-xs whitespace-nowrap">Total Pieces</th>
                              <th class="py-2 border text-xs whitespace-nowrap">Total Weight(Ct)</th>
                            </tr>
                          </thead>
                          <tbody v-if="page_data.current_stocks.length == 0">
                            <tr class="bg-gray-100">
                              <td class="py-2 border-b border-r text-xs text-center" colspan="4">Records Not Found</td>
                            </tr>
                          </tbody>
                          <tbody v-else v-for="(packet, index) in page_data.current_stocks" :key="index" class="cursor-pointer">
                            <tr class="bg-gray-200">
                              <td class="py-2 border-b border-r text-xs text-center" @click="hidePacketDetails(index)">{{ (packet.first_name ? packet.first_name : '-') + ' ' + (packet.last_name ? packet.last_name : '') }} ({{ packet.short_name ? packet.short_name : '' }})</td>
                              <td class="py-2 border-b border-r text-xs text-center" @click="get_packet_list(packet.manager_id, packet.employee_id, packet.employee_role, packet.process_id)">{{ packet.total_packets ? packet.total_packets : '-' }}</td>
                              <td class="py-2 border-b border-r text-xs text-center" @click="get_packet_list(packet.manager_id, packet.employee_id, packet.employee_role, packet.process_id)">{{ packet.total_pieces ? packet.total_pieces : '-' }}</td>
                              <td class="py-2 border-b border-r text-xs text-center">{{ packet.issue_weight ? $h.myFloat(packet.issue_weight) : '-' }}</td>
                            </tr>
                            <tr v-if="page_data.allshow[index] == true && packet.children.length > 0">
                              <!-- <td class="py-2 border-b border-r text-xs text-center"></td> -->
                              <td class="py-2 border-b border-r text-xs text-center" colspan="3">
                                <table class="w-full border-2">
                                  <thead>
                                    <tr class="bg-dark-3 border text-white">
                                      <th class="py-2 border text-xs whitespace-nowrap border-r">Employees</th>
                                      <th class="py-2 border text-xs whitespace-nowrap">Total Packets</th>
                                      <th class="py-2 border text-xs whitespace-nowrap">Total Pieces</th>
                                      <th class="py-2 border text-xs whitespace-nowrap">Total Weight(Ct)</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="(item, ind) in packet.children" :key="ind">
                                      <td class="py-2 text-xs whitespace-nowrap text-center border">
                                        <span v-if="item.employee_role == 7">{{ (item.emp_first_name ? item.emp_first_name : '-') + ' ' + (item.emp_last_name ? item.emp_last_name : '') }} ({{ item.emp_short_name ? item.emp_short_name : '' }})</span>
                                        <span v-else-if="item.employee_role == 4">{{ (item.jober_first_name ? item.jober_first_name : '-') + ' ' + (item.jober_last_name ? item.jober_last_name : '') }} ({{ item.jober_short_name ? item.jober_short_name : '' }})</span>
                                        <span v-else-if="item.employee_role == 5 || item.employee_role == 6">{{ (item.planner_first_name ? item.planner_first_name : '-') + ' ' + (item.planner_last_name ? item.planner_last_name : '') }} ({{ item.planner_short_name ? item.planner_short_name : '' }})</span>
                                        <span v-else>-</span>
                                      </td>
                                      <td class="py-2 text-xs whitespace-nowrap text-center border" @click="get_packet_list(item.manager_id, item.employee_id, item.employee_role, item.process_id)">{{ item.total_packets ? item.total_packets : '-' }}</td>
                                      <td class="py-2 text-xs whitespace-nowrap text-center border" @click="get_packet_list(item.manager_id, item.employee_id, item.employee_role, item.process_id)">{{ item.total_pieces ? item.total_pieces : '-' }}</td>
                                      <td class="py-2 text-xs whitespace-nowrap text-center border">{{ item.issue_weight ? $h.myFloat(item.issue_weight) : '-' }}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="border mt-5">
                      <div class="overflow-x-auto">
                        <table class="w-full text-center text-xs">
                          <thead>
                            <tr  class="bg-gray-300">
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5" colspan="6">LABOUR  SUMMARY</th>
                            </tr>
                            <tr  class="bg-gray-300">
                              <!-- <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Employee</th> -->
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Process</th>
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Issue Pieces</th>
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Issue Weight</th>
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Receive Weight</th>
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Weight Loss</th>
                              <th class="border-b whitespace-nowrap border-r py-2 px-2 dark:border-dark-5">Majuri</th>
                            </tr>
                          </thead>
                          <tbody v-if="page_data.labour_details.length == 0">
                            <tr class="bg-gray-100">
                              <td class="py-2 border-b border-r text-xs text-center" colspan="4">Records Not Found</td>
                            </tr>
                          </tbody>
                          <tbody v-else v-for="(item, index) in page_data.labour_details" :key="index" @click="hideKapanLabour(index)" class="cursor-pointer">
                            <tr class="bg-gray-200">
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ item.process_name }}</td>
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ item.issue_count }}</td>
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ item.issue_weight }}</td>
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ item.receive_weight }}</td>
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ item.weight_loss }}</td>
                              <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ item.emp_majuri }}</td>
                            </tr>
                            <tr v-if="page_data.allshowLabour[index] == true && item.children.length > 0">
                              <!-- <td class="py-2 border-b border-r text-xs text-center"></td> -->
                              <td class="py-2 border-b border-r text-xs text-center" colspan="6">
                                <table class="w-full border-2">
                                  <thead>
                                    <tr class="bg-dark-3 border text-white">
                                      <th class="py-2 border text-xs whitespace-nowrap border-r">Employee/Party</th>
                                      <th class="py-2 border text-xs whitespace-nowrap">Issue Pieces</th>
                                      <th class="py-2 border text-xs whitespace-nowrap">Issue Weight</th>
                                      <th class="py-2 border text-xs whitespace-nowrap">Receive Weight</th>
                                      <th class="py-2 border text-xs whitespace-nowrap">Weight Loss</th>
                                      <th class="py-2 border text-xs whitespace-nowrap">Majuri</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="(employee, ind) in item.children" :key="ind">

                                      <td class="py-2 text-xs whitespace-nowrap text-center border">{{ employee.employee_short_name }}</td>
                                      <td class="py-2 text-xs whitespace-nowrap text-center border">{{ employee.issue_count }}</td>
                                      <td class="py-2 text-xs whitespace-nowrap text-center border">{{ employee.issue_weight }}</td>
                                      <td class="py-2 text-xs whitespace-nowrap text-center border">{{ employee.receive_weight }}</td>
                                      <td class="py-2 text-xs whitespace-nowrap text-center border">{{ employee.weight_loss }}</td>
                                      <td class="py-2 text-xs whitespace-nowrap text-center border">{{ employee.emp_majuri }}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                          <tr class="bg-gray-300 border-2 border-b-0">
                            <!-- <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ item.employee_short_name }}</td> -->
                            <td class="border-b border-r py-2 px-2 whitespace-nowrap">Total</td>
                            <td class="border-b border-r py-2 px-2 whitespace-nowrap"></td>
                            <td class="border-b border-r py-2 px-2 whitespace-nowrap"></td>
                            <td class="border-b border-r py-2 px-2 whitespace-nowrap"></td>
                            <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ page_data.kapan_weight_summary.loss_weight }}</td>
                            <td class="border-b border-r py-2 px-2 whitespace-nowrap">{{ page_data.kapan_weight_summary.total_majuri }}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>

                </div>
              </div>



            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- END: Data List -->
    <div id="current-stock-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-xl">
            <div class="modal-content">
              <div class="modal-header p-5">
                <div class="flex justify-between items-center w-full">
                  <h2 class="text-lg font-medium mr-auto">Stock List</h2>
                  <button
                    id="tabulator-html-filter-go"
                    type="button"
                    class="btn btn-primary ml-2"
                    tabindex="5"
                    @click="exportFilterData"
                  >
                  Export
                  </button>
                  <a data-dismiss="modal" href="javascript:;">
                      <XIcon class="w-8 h-8 text-gray-500" />
                  </a>
                </div>

              </div>
                <div class="modal-body">

                  <div class="border-b border-gray-200 dark:border-dark-5">
                    <div class="">
                      <div class="border">
                        <div class="overflow-x-auto">
                          <ag-grid-vue
                          class="ag-theme-alpine"
                          style="height: 500px"
                          :columnDefs="(columnDefs.value)"
                          :rowData="rowData.value"
                          :defaultColDef="defaultColDef"
                          rowSelection="multiple"
                          animateRows="true"
                          @grid-ready="onGridReady"
                          :overlayNoRowsTemplate="overlayNoRowsTemplate"
                          >
                        </ag-grid-vue>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
    <!-- BEGIN: DATA List -->

  </div>
</template>

<script>
import { computed, defineComponent, inject, onMounted, reactive, ref, toRefs } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { BASE_URL } from '@/constants/config.js'
import axios from 'axios'
import { helper as $h } from '@/utils/helper'
import store from '@/store';
import FileDownload from "js-file-download";
import { AgGridVue } from "ag-grid-vue3";
import {
  onGridReady,
  ModuleRegistry
} from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { CsvExportModule } from "@ag-grid-community/csv-export";
import { ClipboardModule } from "@ag-grid-enterprise/clipboard";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { MultiFilterModule } from "@ag-grid-enterprise/multi-filter";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
// import "styag-grid-community/les/ag-theme-alpine.css"; // Optional theme CSS

export default defineComponent({
  components: {
   AgGridVue
  },
  setup() {

    // const user_details = computed(() => store.state.user.currentUser)
    // const route = useRoute()
    const swal = inject('$swal')

    const page_data = reactive({
      "r_loading": false,
      "m_loading": false,
      "generate": "no",
      "sr_no": 1,
      "kapan_id": [],
      "costing_per_ct": "",
      "allshow": [],
      "allshowLabour": [],
      "kapan_details": [],
      "size_wise_stock": [],
      "plan_items": [],
      "makeable_items": [],
      "final_items": [],
      "current_stocks": [],
      "labour_details": [],
      "kapan_weight_summary": [],
      "total_majuri": 0,
      "total_loss": 0,
      "conversation_rate": 1,
      "rough_total_pieces": 0,
      "rough_total_weight": 0,
      "rough_avg_weight": 0,
      "pending_total_pieces": 0,
      "pending_total_weight": 0,
      "pending_avg_weight": 0,
      "plan_polish_weight": 0,
      "plan_rough_weight": 0,
      "plan_total_pieces": 0,
      "plan_polish_per": 0,
      "plan_18_up_polish_per": 0,
      "shape_group_arr": [],
      "shape_plans": [],
      "manual_charni_arr": [],
      "manual_charni": [],
      "purity_list": [],
      "plan_list": [],
      "pending_packets_arr": [],
      "size_id": null,
      "size_name": null,
      "size_arr": [],
      "purity_arr": [],
      "shape_group": null,
      "final_polish_arr": [],
      "final_polish_type": "0"

    })

    const gridApi = ref();

    const rowData = reactive({
      value:[]
    });

    const onGridReady = (params) => {
    gridApi.value = params.api;
    };

    const defaultColDef = {
      sortable: true,
      filter: true,
      // floatingFilter: true,
      // menuTabs: ["filterMenuTab"],
      // flex: 1
      flex: 1,
      minWidth: 200,
      floatingFilter: true,
      menuTabs: ["filterMenuTab"],
    };

    ModuleRegistry.registerModules([
      ClientSideRowModelModule,
      CsvExportModule,
      ClipboardModule,
      MenuModule,
      MultiFilterModule,
      SetFilterModule,
    ]);

    const overlayNoRowsTemplate = 'Stocks Not Found!'

    const columnDefs = reactive({
     value: [
     { headerName: "#",field: 'sr_no',maxWidth: 60 },
     { headerName: "PACKET NO",field: 'packet_no',minWidth: 150},
     { headerName: "STOCK NO",field: 'stock_no',minWidth: 150},
     { headerName: "PROCESS",field: 'process_name',minWidth: 100},
     { headerName: "MANAGER",field: 'manager_name',minWidth: 100},
     { headerName: "EMPLOYEE",field: 'employee_name',minWidth: 100},
      { headerName: "PIECES",field: 'pieces',minWidth: 100},
      { headerName: "WEIGHT(WT)",field: 'weight',minWidth: 100},
      { headerName: "POLISH(WT)",field: 'polish_weight',minWidth: 100},
      { headerName: "SHAPE",field: 'shape_name',minWidth: 100},
      { headerName: "COLOR",field: 'color_name',minWidth: 100},
      { headerName: "PURITY",field: 'purity_name',minWidth: 100},
      { headerName: "CUT",field: 'cut_name',minWidth: 100},
      { headerName: "POLISH",field: 'polish_name',minWidth: 100},
      { headerName: "SYMMETRY",field: 'symmetry_name',minWidth: 100},
      { headerName: "STONE",field: 'stone_name',minWidth: 100},
     ],
   });

   const get_packet_list = (manager_id, employee_id, employee_role, process_id) => {
      const params = {
        manager_id: manager_id,
        employee_id: employee_id,
        employee_type: employee_role,
        process_id: process_id,
        kapan_id: page_data.kapan_id,

      };

      rowData.value = []
      page_data.sr_no = 1;

      let promise = axios({
        url: "api/report/get_final_kapan_stock_report",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),

        },
        config: {
          headers: {
            Accept: "application/json"
          }
        }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          cash('#current-stock-modal').modal('show')

          let stock_items = data.items;
          stock_items.map(item => {
            rowData.value.push({
              'sr_no': page_data.sr_no,
              'packet_no': item.number,
              'stock_no': item.vepari_no,
              'process_name': item.process_name,
              'manager_name': item.manager_name,
              'employee_name': item.employee_name,
              'pieces': (item.pieces ? item.pieces : 1),
              'weight': item.issue_weight,
              'polish_weight': item.polish_weight,
              'shape_name': item.shape_name,
              'color_name': item.color_name,
              'purity_name': item.purity_name,
              'cut_name': item.cut_name,
              'polish_name': item.polish_name,
              'stone_name': item.stone_name,
              'symmetry_name': item.symmetry_name
              });
              page_data.sr_no += 1;
          });

          gridApi.value.setRowData(rowData.value);

        })
        .catch(_error => {
        });
    }

    const getReportDetails = () => {

      if(page_data.kapan_id.length == 0) {

      } else {

        const params = {
          kapan_id: page_data.kapan_id
        };

        page_data.r_loading = true

        let promise = axios({
          url: "api/report/kapan-report",
          method: "POST",
          data: params,
          baseURL: BASE_URL,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),

          },
          config: {
            headers: {
              Accept: "application/json"
            }
          }
        });
        return promise
          .then(result => result.data)
          .then(data => {

            page_data.r_loading = false

            if (data.status == "success") {

              page_data.kapan_details = data.kapan_details
              page_data.size_wise_stock = data.size_wise_stock
              page_data.plan_items = data.plan_items
              page_data.makeable_items = data.makeable_items
              page_data.final_items = data.final_items
              page_data.current_stocks = data.current_stocks
              page_data.labour_details = data.labour_details
              page_data.kapan_weight_summary = data.kapan_weight_summary

              page_data.generate = 'gen';
            } else {
              swal("Warning !", data.message, "warning")
            }


          })
          .catch(_error => {
            page_data.r_loading = false
            swal("Error !", "Something went wrong", "error")
          });
      }


    }

    const getLotDetails = async () => {
      let params = {
      }
      let api_url = 'api/master/dp_lot_all_details'

      let promise = axios({
        url: api_url,
        method: "post",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),

        },
        config: {
          headers: {
            Accept: "application/json"
          }
        }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          page_data.kapan_id = []
          page_data.lot_items = data.items
        })
        .catch(_error => {
          page_data.kapan_id = []
          page_data.lot_items = []
        });
    }

    const updateInrRates = () => {

      if(page_data.conversation_rate > 0) {

        page_data.conversation_rate = parseFloat(page_data.conversation_rate)
      } else {
        page_data.conversation_rate = 0
      }

      page_data.planning_polish_pending.forEach(sub_element => {
        sub_element.inr_avg_repo = parseFloat(sub_element.avg_repo * page_data.conversation_rate).toFixed(0)
        sub_element.inr_avg_value = parseFloat(sub_element.avg_value * page_data.conversation_rate).toFixed(0)
        sub_element.inr_total_value = parseFloat(sub_element.total_value * page_data.conversation_rate).toFixed(0)
      });
      page_data.final_polish_pending.forEach(sub_element => {
        sub_element.inr_avg_repo = parseFloat(sub_element.avg_repo * page_data.conversation_rate).toFixed(0)
        sub_element.inr_avg_value = parseFloat(sub_element.avg_value * page_data.conversation_rate).toFixed(0)
        sub_element.inr_total_value = parseFloat(sub_element.total_value * page_data.conversation_rate).toFixed(0)
      });
      page_data.final_polish_done.forEach(sub_element => {
        sub_element.inr_avg_repo = parseFloat(sub_element.avg_repo * page_data.conversation_rate).toFixed(0)
        sub_element.inr_avg_value = parseFloat(sub_element.avg_value * page_data.conversation_rate).toFixed(0)
        sub_element.inr_total_value = parseFloat(sub_element.total_value * page_data.conversation_rate).toFixed(0)
      });
      page_data.planning_polish_pending_total.inr_avg_repo = parseFloat(page_data.planning_polish_pending_total.avg_repo * page_data.conversation_rate).toFixed(0)
      page_data.planning_polish_pending_total.inr_avg_value = parseFloat(page_data.planning_polish_pending_total.avg_value * page_data.conversation_rate).toFixed(0)
      page_data.planning_polish_pending_total.inr_total_value = parseFloat(page_data.planning_polish_pending_total.total_value * page_data.conversation_rate).toFixed(0)
      page_data.final_polish_pending_total.inr_avg_repo = parseFloat(page_data.final_polish_pending_total.avg_repo * page_data.conversation_rate).toFixed(0)
      page_data.final_polish_pending_total.inr_avg_value = parseFloat(page_data.final_polish_pending_total.avg_value * page_data.conversation_rate).toFixed(0)
      page_data.final_polish_pending_total.inr_total_value = parseFloat(page_data.final_polish_pending_total.total_value * page_data.conversation_rate).toFixed(0)
      page_data.final_polish_done_total.inr_avg_repo = parseFloat(page_data.final_polish_done_total.avg_repo * page_data.conversation_rate).toFixed(0)
      page_data.final_polish_done_total.inr_avg_value = parseFloat(page_data.final_polish_done_total.avg_value * page_data.conversation_rate).toFixed(0)
      page_data.final_polish_done_total.inr_total_value = parseFloat(page_data.final_polish_done_total.total_value * page_data.conversation_rate).toFixed(0)

      // Update Rates
    }
    function hidePacketDetails(index) {
      page_data.allshow[index] = !page_data.allshow[index]
    }
    function hideKapanLabour(index) {
      page_data.allshowLabour[index] = !page_data.allshowLabour[index]
    }

    const exportReportDetails = () =>{

      const today = new Date();
      const date = today.getDate() + '' + (today.getMonth() + 1) + '' + today.getFullYear();
      // const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const dateTime = date;
      let filename = "KODLLIN_" +dateTime + ".xlsx";

      let promise = axios({
          url: "/api/report/final_polish_kapan_export",
          method: "post",
          data: {
            kapan_id: page_data.kapan_id,
            conversation_rate: window.btoa(page_data.conversation_rate),
          },
          baseURL: BASE_URL,
          headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),

          },
          config: {
              headers: {
                  Accept: "application/json"
              }
          },
          responseType: "blob",
      });

      return promise
          .then((result) => result.data)
          .then((data) => {
              FileDownload(data, filename);
          })
          .catch((_error) => {
              return [];
          });
      }

      const exportFilterData = () => {
      gridApi.value.exportDataAsCsv();
    }

    onMounted(() => {
      getLotDetails()
    })
    return {
      page_data,
      getReportDetails,
      updateInrRates,
      exportReportDetails,
      hidePacketDetails,
      hideKapanLabour,

      get_packet_list,
      exportFilterData, columnDefs, rowData, overlayNoRowsTemplate, onGridReady, defaultColDef, ModuleRegistry,
      deselectRows: () =>{
        gridApi.value.deselectAll()
      }
    }
  }

})
</script>
