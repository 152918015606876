<template>
  <div>
    <div class="xl:grid block xl:grid-cols-2 gap-6" v-if="[1,2,8].includes(user_details.user_role)">
      <div>
        <h2 class="intro-y text-lg font-medium mt-5 ml-2">Scan Packet</h2>
        <div class="flex items-center mt-2 ml-2">
          <div class="mr-2 flex items-center">
            <input
              id="remember-me"
              type="checkbox" v-model="pagedata.check"
              class="form-check-input border mr-2 bg-white"
            />
            <label for="remember-me" class="text-md font-medium">Menual</label>
          </div>
        </div>
        <div class="w-full flex sm:w-auto mt-3 ml-2">
          <div class="w-56 relative text-gray-700 dark:text-gray-300 mr-4">
            <input
              type="text"
              v-model="pagedata.search"
              autofocus
              ref="focusMe"
              @change="getPacketDetailsScan"
              class="form-control w-56 box pr-10 placeholder-theme-13"
              placeholder="Search Packet"
            />
            <SearchIcon
              class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
            />
          </div>
          <button class="btn btn-primary shadow-md mr-2" @click="AddtoList">Add</button>
          <button class="btn btn-primary shadow-md mr-2" @click="AssignProcess" v-if="pagedata.addlist.length > 0">Assign Packets</button>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-5 mt-5">
      <div
        class="col-span-6 xl:col-span-2 md:col-span-3 sm:col-span-4 box p-5 cursor-pointer zoom-in" v-for="(add,index) in pagedata.addlist" :key="index"
      >
        <div class="flex justify-between">
          <div class="font-medium text-base">{{ add }}</div>
          <button class="btn btn-outline-danger p-1 pl-2 hover:text-white" @click="removeItem(index)"><Trash2Icon class="w-4 h-4 mr-1" /></button>
        </div>
      </div>
    </div>
    <!-- BEGIN: Data List -->
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex items-center">
        <h2 class="text-lg font-bold">Discuss Packets</h2>
        <div class="w-48 relative text-gray-700 dark:text-gray-300 ml-4" v-if="user_details.user_role != 5 && user_details.user_role != 6">
            <select
                class="form-select"
                v-model="pagedata.type"
                @change="initTabulator"
                aria-label="Default select example"
              >
                <option value="">Select Type</option>
                <option value="makeable">Makeable</option>
                <option value="rough">Rough</option>
                <option value="video">Video</option>
                <!-- <option value="lab">Lab</option> -->
              </select>
          </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5 table-report table-report--tabulator"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
  <!-- BEGIN: new Process Modal -->
  <div
    id="new-process-modal"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <a data-dismiss="modal" href="javascript:;">
          <XIcon class="w-8 h-8 text-gray-500" />
        </a>
        <div class="modal-header">
          <h2 class="font-medium text-base mr-auto">Transfer Packet</h2>
        </div>
        <div class="modal-body p-3 text-center">
          <h2 class="font-medium text-base mr-auto">Packet Code : {{ process.packet_code }}</h2>
          <div class="intro-y box">
            <!-- <div id="vertical-form"> -->
            <div class="flex flex-col justify-between">
              <!-- BEGIN: Data List -->
              <div class="intro-y overflow-auto">
                <div class="flex">
                </div>
              </div>
              <div class="m-auto mt-3">
                <div class="mt-3" v-if="user_details.user_role != 3">
                    <label for="update-profile-form-6" class="form-label"
                      >Discuss Type</label
                    >
                    <TomSelect
                      v-model="process.eternals"
                        :options="{
                          placeholder: 'Select Transaction Type'
                        }"
                        class="w-60 m-auto"
                    >
                    <option value="0">Manager Transfer</option>
                    <option value="1">Party Transfer</option>
                    <option v-if="user_details.account_id == process.cr_manager" value="2">Employee Transfer</option>
                    </TomSelect>
                  </div>
                  <div class="mt-3" v-if="process.eternals == '0' && user_details.user_role != 3">
                    <label for="update-profile-form-6" class="form-label"
                      >Select Manager</label
                    >
                    <TomSelect
                      v-model="process.manager_id"
                        :options="{
                          placeholder: 'Select Manager'
                        }"
                        class="w-60 m-auto"
                    >
                      <option value="0">Select Manager</option>
                      <option v-for="(manager,ind) in pagedata.managers" :key="ind" :value="manager.user_id">{{ manager.first_name + ' ' + manager.last_name }}</option>
                    </TomSelect>
                  </div>
                  <div class="flex justify-center" v-if="process.eternals != '2'">
                    <div class="mb-3 xl:w-96">
                      <label for="exampleFormControlTextarea1" class="form-label inline-block mb-2 text-gray-700"
                        >Remark</label
                      >
                      <textarea
                        class="
                          form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                        "
                        id="exampleFormControlTextarea1"
                        rows="3"
                        v-model="process.remark"
                        placeholder="Enter Message"
                      ></textarea>
                    </div>
                  </div>
                </div>
              <button type="button" @click="transferPacket" :disabled="pagedata.submit" class="btn btn-primary w-24 m-auto mt-5">Submit
                <LoadingIcon v-if="pagedata.submit" icon="oval" color="white" class="w-4 h-4 ml-2" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: new Process Modal -->
</template>
<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config'
import axios from 'axios'
import cash from 'cash-dom'
import { computed, defineComponent, inject, onMounted, reactive, ref, toRef, watch } from 'vue'
import print from 'vue3-print-nb'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import Toastify from 'toastify-js'
import store from '@/store'
import router from '@/router'
export default defineComponent({
  directives: {
    print
  },
  setup() {
    const user_details = computed(() => store.state.user.currentUser)
    const pagedata = reactive({
      search: '',
      check: false,
      type: '',
      addlist: [],
      submit: false,
      managers: []
    })
    const focusMe = ref()
    const swal = inject('$swal')
    const process = reactive({
      eternals: '0',
      manager_id: '0',
      remark: '',
      packet_id: '',
      packet_code: '',
      cr_manager: '',
    })
    const tableRef = ref()
    const tabulator = ref()
    onMounted(() => {
      initTabulator()
      getAllManager()

      if([1,2,8].includes(user_details.value.user_role)) {

        focusMe.value.focus()
      }
    })
    const initTabulator = () => {
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: BASE_URL + 'api/discuss/get_discuss_packets',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxParams: {
          type: window.btoa(pagedata.type)
        },
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        headerSort: false,
        pagination: 'remote',
        paginationSize: 20,
        dataTree:true,
        dataTreeStartExpanded:false,
        paginationSizeSelector: [20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: 'collapse',
        placeholder: 'No matching records found',
        columns: [
          {
            formatter: 'responsiveCollapse',
            width: 40,
            minWidth: 30,
            hozAlign: 'center',
            resizable: false,
            headerSort: false
          },

          // For HTML table
          {
            title: 'Packet No',
            responsive: 0,
            hozAlign: 'center',
            field: 'packet_code',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().packet_code ? cell.getData().urgent == 1 ? '<span class="text-theme-6">'+ cell.getData().packet_code +'</span>' : cell.getData().packet_code : ''
                }</div>
              </div>`
            }
          },
          {
            title: 'Type',
            responsive: 0,
            field: 'packet_type',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().packet_type ? cell.getData().packet_type : ''
                }</div>
              </div>`
            }
          },
          {
            title: 'From <br> To',
            responsive: 0,
            field: 'from',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  (cell.getData().sender_first_name ? (cell.getData().sender_first_name + ' ' + cell.getData().sender_last_name) : '') + `<br>` +
                   (cell.getData().receiver_first_name ? (cell.getData().receiver_first_name + ' ' + cell.getData().receiver_last_name) : '')
                }</div>
              </div>`
            }
          },
          {
            title: 'Accept At <br> Return At',
            responsive: 0,
            field: 'to',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  (cell.getData().created_at ? (cell.getData().created_at) : '') + `<br>` +
                   (cell.getData().return_date ? (cell.getData().return_date) : '')
                }</div>
              </div>`
            }
          },
          {
            title: 'Remark',
            responsive: 0,
            hozAlign: 'center',
            vertAlign: 'middle',
            minWidth:300,
            field: 'remark',
            print: false,
            download: false
          },
          {
            title: 'Action',
            field: '',
            hozAlign: 'center',
            vertAlign: 'middle',
            headerSort:false,
            print: false,
            download: false,
            formatter(cell) {

              if(user_details.value.id == cell.getData().user_id) {

                if(cell.getData().status == 1) {

                  const a = cash(`<div class="flex lg:justify-center items-center">
                    <button class="btn btn-primary btn-sm flex items-center">
                      Transfer
                      </button>
                      </div>`)
                      cash(a).on('click', function() {

                        process.packet_code = cell.getData().packet_code
                        process.packet_id = cell.getData().packet_id
                        process.cr_manager = cell.getData().manager_id
                        cash('#new-process-modal').modal('show')

                      })
                  return a[0]

                } else if(cell.getData().status == 2) {
                  return  `<div class="">
                    <p class="text-theme-9 font-12 font-bold">`+'Transfered'+`</p>
                  </div>`;
                } else if(cell.getData().status == 3) {
                  return  `<div class="">
                    <p class="text-theme-9 font-12 font-bold">`+'Cancelled'+`</p>
                  </div>`;
                }



              } else {

                if( cell.getData().status == 3) {

                  return  `<div class="">
                    <p class="text-theme-6 font-12 font-bold">`+'Cancelled'+`</p>
                  </div>`;

                } else if(cell.getData().status == 2) {

                  return  `<div class="">
                    <p class="text-theme-9 font-12 font-bold">`+'Accepted'+`</p>
                  </div>`;
              } else if(cell.getData().status == 1) {

                return  `<div class="">
                  <p class="text-theme-9 font-12 font-bold">`+'Accepted'+`</p>
                </div>`;
                }
              }
            }
          },
          {
            title: '',
            field: '',
            hozAlign: 'center',
            vertAlign: 'middle',
            headerSort:false,
            print: false,
            download: false,
            formatter(cell) {

              if(user_details.value.id == cell.getData().issue_by && cell.getData().status == 0) {
                const a = cash(`<div class="flex lg:justify-center items-center">
                  <button class="btn btn-danger btn-sm flex items-center">
                    Withdraw
                    </button>
                    </div>`)
                    cash(a).on('click', function() {

                      swal({
                        title: "Are you sure?",
                        text: "You are You Want To Cancel Transaction!",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Delete",
                        showLoaderOnConfirm: true,
                        cancelButtonText: "Cancel",

                        allowOutsideClick: () => !swal.isLoading()
                      }).then(result => {
                          if(result.isConfirmed){
                            acceptTransaction(cell.getData().packet_id,"Cancel")

                          }
                      });


                    })
                return a[0]

              } else if(user_details.value.id == cell.getData().user_id && cell.getData().status == 0) {
                const a = cash(`<div class="flex lg:justify-center items-center">
                  <button class="btn btn-primary btn-sm flex items-center">
                    Accept
                    </button>
                    </div>`)
                    cash(a).on('click', function() {

                      swal({
                        title: "Are you sure?",
                        text: "You are You Want To Accept Transaction!",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Accept",
                        showLoaderOnConfirm: true,
                        cancelButtonText: "Cancel",

                        allowOutsideClick: () => !swal.isLoading()
                      }).then(result => {
                          if(result.isConfirmed){
                            acceptTransaction(cell.getData().packet_id,"Accept")
                          }
                      });

                    })
                return a[0]

              }
            }
          }
        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }

    function acceptTransaction(id,type) {

      const params = {
        packet_id: window.btoa(id),
      }

      let api_url = ""

      if(type == "Accept") {
        api_url = "api/discuss/accept_discuss_packet"
      } else {
        api_url = "api/discuss/cancel_discuss_packet"
      }

        let promise = axios({
        url: api_url,
        method: "post",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token"),
 },
        config: { headers: { Accept: "application/json" } },
      });
      return promise
        .then((result) => result.data)
        .then((data) => {

          if(data.status == "error") {
            swal("Warning!", data.data, "warning");
          } else if(data.status == "success") {
            swal("Success!", data.data, "success");
          }
          initTabulator()
        })

    }
    function transferPacket() {

      if(process.manager_id == '0' && process.eternals == '0' && user_details.value.user_role != 3) {
        swal("Warning!", "Please Scan Packets !", "warning");
        return;
      }

      swal({
        title: "Are you sure?",
        text: "You are You Want To Add Discuss!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Submit",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        allowOutsideClick: () => !swal.isLoading()
      }).then(result => {
          if(result.isConfirmed){

            const params = {
              packet_id: process.packet_id,
              manager_id: process.manager_id,
              party_id: process.eternals,
              remark: process.remark,
            }

            let promise = axios({
            url: 'api/discuss/transfer_discuss_packets',
            method: "post",
            data: params,
            baseURL: BASE_URL,
            headers: { Authorization: "Bearer " + localStorage.getItem("token"),
 },
            config: { headers: { Accept: "application/json" } },
          });
          return promise
            .then((result) => result.data)
            .then((data) => {

              if(data.status == "error") {
                swal("Warning!", data.data, "warning");
              } else if(data.status == "success") {
                swal("Success!", "Item Returned to Manager.", "success");
              }
              pagedata.submit = false
              initTabulator()
              cash('#new-process-modal').modal('hide')
              process.packet_code = ''
              process.cr_manager = ''
              process.packet_id = ''
              process.remark = ''
            })
          }
      });

    }
    function AddtoList() {
      if(pagedata.search != '') {
        for (let i = 0; i < pagedata.addlist.length; i++) {
          if(pagedata.addlist[i] == pagedata.search) {
            swal("Warning!", "Already in list, Scan other packet!", "warning");
            return
          }
        }
        const params = {
          barcode : window.btoa(pagedata.search),
        }
        axios({
          url: 'api/discuss/scan_packet_to_discuss_transfer',
          method: 'post',
          baseURL: BASE_URL,
          data: params,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          const index = ref(pagedata.addlist.length)
          pagedata.addlist[index.value] = res.data.paket_items.number
          pagedata.search = ''
          focusMe.value.focus()

        }).catch((err) => {

          pagedata.search = ''
          focusMe.value.focus()
          swal("Warning!", err.response.data.error, "warning");

        })
      }
    }
    function removeItem(id) {
      pagedata.addlist.splice(id, 1);
    }
    function AssignProcess() {

      if(pagedata.addlist.length == 0) {
        swal("Warning!", "Please Scan Packets !", "warning");
        return;
      }

      swal({
        title: "Are you sure?",
        text: "You are You Want To Add Discuss!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Submit",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        allowOutsideClick: () => !swal.isLoading()
      }).then(result => {
          if(result.isConfirmed){

            const params = {
              packet_numbers: pagedata.addlist,
            }

            let promise = axios({
            url: 'api/discuss/add_packets_discuss',
            method: "post",
            data: params,
            baseURL: BASE_URL,
            headers: { Authorization: "Bearer " + localStorage.getItem("token"),
 },
            config: { headers: { Accept: "application/json" } },
          });
          return promise
            .then((result) => result.data)
            .then((data) => {

              if(data.status == "error") {
                swal("Warning!", data.data, "warning");
              } else if(data.status == "success") {
                swal("Success!", "Item Returned to Manager.", "success");
              }
              pagedata.submit = false
              initTabulator()
              cash('#new-process-modal').modal('hide')
              pagedata.addlist = []
              process.manager_id = '0'
              process.eternals = '0'
            })
          }
      });
    }
    function getAllManager() {
      axios({
        url: 'api/master/dp_managers',
        method: 'post',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.managers = res.data.items
        process.manager_id = '0'
      })
     }
    function getPacketDetailsScan() {
        if(!pagedata.check && pagedata.search) {
          AddtoList();
        }
      }
    return {
      pagedata,
      focusMe,
      user_details,
      process,
      tableRef,
      getPacketDetailsScan,
      AddtoList,
      removeItem,
      getAllManager,
      AssignProcess,
      initTabulator,
      transferPacket
    }
  }
})
</script>

