<template>
  <div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <form id="tabulator-html-filter-form" class="xl:flex sm:mr-auto">
          <div class="grid grid-cols-12 gap-x-2">
            <!-- <div class="col-span-6 md:col-span-4 xl:col-span-3 my-1">
              <div class="flex items-center sm:mr-2">

                <label class="mr-2"
                  >Field</label
                >
                <select
                  id="tabulator-html-filter-field"
                  v-model="filter.field"
                  class="form-select"
                >
                <option value="first_name">First Name</option>
                <option value="last_name">Last Name</option>
                <option value="short_name">Short Name</option>
                <option value="email">Email</option>
                </select>
              </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2 my-1">
              <div class="flex items-center sm:mr-2">

                <label class="mr-2"
                >Type</label
              >
              <select
                id="tabulator-html-filter-type"
                v-model="filter.type"
                class="form-select w-fullo"
              >
                <option value="like" selected>like</option>
                <option value="=">=</option>
                <option value="<">&lt;</option>
                <option value="<=">&lt;=</option>
                <option value=">">></option>
                <option value=">=">>=</option>
                <option value="!=">!=</option>
              </select>
              </div>
            </div>
            <div class="col-span-6 md:col-span-4 xl:col-span-3 my-1">
              <div class="flex items-center sm:mr-2">

                <label class="mr-2">Value</label>
                <input
                  id="tabulator-html-filter-value"
                  v-model="filter.value"
                  type="text"
                  class="form-control sm:w-40"
                  placeholder="Search..."
                />
              </div>
            </div>
            <div class="col-span-3 md:col-span-2 xl:col-span-1 my-1">
              <button
                  id="tabulator-html-filter-go"
                  type="button"
                  class="btn btn-primary w-full"
                  @click="onFilter"
                >
                  Go
                </button>
            </div>
            <div class="col-span-3 md:col-span-2 xl:col-span-1 my-1">
              <button
                  id="tabulator-html-filter-reset"
                  type="button"
                  class="btn btn-secondary w-full"
                  @click="onResetFilter"
                >
                  Reset
                </button>
            </div> -->
          </div>
        </form>
        <div class="flex mt-5 sm:mt-0">
          <router-link
          :to="{ name: 'create-employee-details' }"
          tag="button"
           class="btn btn-primary btn-sm shadow-md p-2 mr-2">

         New Employee
          </router-link>
          <div class="dropdown w-1/2 sm:w-auto">
            <a class="btn btn-secondary p-1 rounded-sm text-right" @click="exportFilterData"
              ><FileTextIcon class="w-4 h-4 mr-2" /> Export</a
            >
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-5">
        <ag-grid-vue
            class="ag-theme-alpine"
            style="height: 600px"
            :columnDefs="columnDefs.value"
            :rowData="rowData.value"
            :defaultColDef="defaultColDef"
            rowSelection="multiple"
            animateRows="true"
            @grid-ready="onGridReady"
            :overlayNoRowsTemplate="overlayNoRowsTemplate"
          >
          </ag-grid-vue>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>

</template>

<script setup>
/* eslint-disable */
import { defineComponent, ref,onMounted, reactive, inject } from 'vue'
import xlsx from 'xlsx'
// import feather from 'feather-icons'
// import Tabulator from 'tabulator-tables'

import { AgGridVue } from 'ag-grid-vue3'
import ShapeRenderer from '@/custom-renderer/ShapeRenderer.js'
import {
  GridApi,
  ModuleRegistry,
  createGrid
} from '@ag-grid-community/core'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { CsvExportModule } from '@ag-grid-community/csv-export'
import { ClipboardModule } from '@ag-grid-enterprise/clipboard'
import { MenuModule } from '@ag-grid-enterprise/menu'
import { MultiFilterModule } from '@ag-grid-enterprise/multi-filter'
import { SetFilterModule } from '@ag-grid-enterprise/set-filter'

import { useRouter, useRoute } from 'vue-router'
import { BASE_URL } from '@/constants/config.js'

  const router = useRouter()
  const route = useRoute()
  const swal = inject('$swal')
  const filter = reactive({
    field: 'first_name',
    type: 'like',
    value: ''
  })

  const page_data = reactive({
    current_page: 1,
    total_records: 0,
    sr_no: 1,
    last_page: '',
    per_page: 500
  })

  const gridApi = ref()

  const rowData = reactive({
    value: []
  })

  const onGridReady = params => {
    gridApi.value = params.api
  }

  const defaultColDef = {
    sortable: true,
    filter: true,
    flex: 1,
    minWidth: 100,
    floatingFilter: true,
    menuTabs: ['filterMenuTab']
  }

  ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    CsvExportModule,
    ClipboardModule,
    MenuModule,
    MultiFilterModule,
    SetFilterModule
  ])

  const overlayNoRowsTemplate = 'No Records Found!'

  const columnDefs = reactive({
value: [
  { headerName: '#', field: 'sr_no', minWidth: 100 },
  { headerName: 'Action', field: 'action', minWidth: 150,
  cellRenderer: (params) => {
    const action_div = document.createElement("div");
    action_div.classList.add(
              "flex",
              "justify-center",
              "items-center"
            );
          const button = document.createElement("button");
          button.type = "button";
          button.classList.add(
            "btn",
            "btn-primary",
            "icon-edit",
            "btn-sm",
            "p-1",
            "mr-2"
          );
          button.innerHTML = 'Edit</i>';
          button.addEventListener("click", () => {
            // gridApi.value.getModel().rowsToDisplay
            console.log(params.data.id)
            router.push("/master/employee/update/" + window.btoa(params.data.id));
          });

          action_div.appendChild(button);

          // console.log()
          if(rowData.value[params.rowIndex].active_status == "Active") {

            const delete_button = document.createElement("button");
            delete_button.type = "button";
            delete_button.classList.add(
              "btn",
              "btn-danger",
              "icon-edit",
              "btn-sm",
              "p-1",
              "mr-2",
            );
            delete_button.innerHTML = 'Inactive</i>';
            delete_button.addEventListener("click", () => {
              swal.fire({
                title: "Are you sure?",
                text: "You are about to Inactivate Record!",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Confirm",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",
                preConfirm: () => {
                  return true;
                },
                allowOutsideClick: () => !Swal.isLoading(),
              }).then((result) => {
                if (result.isConfirmed) {
                  updateStatus(window.btoa(params.data.id))
                }
              });
            });
            action_div.appendChild(delete_button);
          } else {

            const delete_button = document.createElement("button");
            delete_button.type = "button";
            delete_button.classList.add(
              "btn",
              "btn-primary",
              "icon-edit",
              "btn-sm",
              "p-1"
            );
            delete_button.innerHTML = 'Activate</i>';
            delete_button.addEventListener("click", () => {
              swal.fire({
                title: "Are you sure?",
                text: "You are about to Activate Record!",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Confirm",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",
                preConfirm: () => {
                  return true;
                },
                allowOutsideClick: () => !Swal.isLoading(),
              }).then((result) => {
                if (result.isConfirmed) {
                  updateStatus(window.btoa(params.data.id))
                }
              });
            });
            action_div.appendChild(delete_button);
          }

          const print_button = document.createElement("button");
            print_button.type = "button";
            print_button.classList.add(
              "btn",
              "btn-primary",
              "icon-edit",
              "btn-sm",
              "p-1",
              "mr-2",
            );
            print_button.innerHTML = 'Print</i>';
            print_button.addEventListener("click", () => {
              let url = BASE_URL + 'employee-print/' + btoa(params.data.id)
                window.open(url, '_blank');
            });
            action_div.appendChild(print_button);
          return action_div;
        }
   },
  { headerName: 'Status', field: 'active_status', minWidth: 150 },
  { headerName: 'First Name', field: 'first_name', minWidth: 150 },
  { headerName: 'Last Name', field: 'last_name', minWidth: 150 },
  { headerName: 'Short Name', field: 'short_name', minWidth: 150 },
  { headerName: 'Manager Name', field: 'manager_name', minWidth: 150 },
  { headerName: 'Process', field: 'processes', minWidth: 150 },
  { headerName: 'Email', field: 'email', minWidth: 150 },
  { headerName: 'Mobile', field: 'mobile_no', minWidth: 150 },
  { headerName: 'Birth Date', field: 'dob', minWidth: 150 },
  { headerName: 'Gender', field: 'gender', minWidth: 150 },
  { headerName: 'Age', field: 'age', minWidth: 150 },
  { headerName: 'Blood Group', field: 'blood_group', minWidth: 150 },
  { headerName: 'Cast', field: 'cast', minWidth: 150 },
  { headerName: 'Merries Status', field: 'merital_status', minWidth: 150 },
  { headerName: 'Aadhar No', field: 'aadhar_no', minWidth: 150 },
  { headerName: 'Pan No', field: 'pan_no', minWidth: 150 },
  { headerName: 'Bank Name', field: 'bank_name', minWidth: 150 },
  { headerName: 'IFSC Code', field: 'ifsc_code', minWidth: 150 },
  { headerName: 'Account No', field: 'account_no', minWidth: 150 },
  { headerName: 'Current Address', field: 'current_addr', minWidth: 150 },
  { headerName: 'Permenant Address', field: 'permenamt_addr', minWidth: 150 },
  { headerName: 'ZipCode', field: 'pincode', minWidth: 150 },
  { headerName: 'Taluka', field: 'taluka', minWidth: 150 },
  { headerName: 'District', field: 'discrict', minWidth: 150 },
  { headerName: 'State', field: 'state', minWidth: 150 },
  { headerName: 'Stagdard', field: 'standard', minWidth: 150 },
  { headerName: 'Per(%)', field: 'percent', minWidth: 150 },
  { headerName: 'College', field: 'college', minWidth: 150 },
  { headerName: 'Language', field: 'language', minWidth: 150 },
  { headerName: 'Shape Know', field: 'shape_know', minWidth: 150 },
  { headerName: 'Emp. Salary', field: 'salary', minWidth: 150 },
  { headerName: 'Domain Know', field: 'domain_know', minWidth: 150 },
  { headerName: 'Diamond Know', field: 'diamond_knowledge', minWidth: 150 },
  { headerName: 'Sarin Knowledge', field: 'sarin_knowledge', minWidth: 150 },
  { headerName: 'Laser Knowledge', field: 'laser_knowledge', minWidth: 150 },
  { headerName: 'Computer Knowledge', field: 'computer_knowledge', minWidth: 150 },
  { headerName: 'Ref Name', field: 'ref_name', minWidth: 150 },
  { headerName: 'Ref Code', field: 'ref_code', minWidth: 150 },
  { headerName: 'Designation', field: 'ref_designation', minWidth: 150 },
  { headerName: 'Mobile No', field: 'ref_mobile', minWidth: 150 },
  { headerName: 'Address', field: 'ref_address', minWidth: 150 },
  { headerName: 'About Company', field: 'about_company', minWidth: 150 },
]
})

  const initTabulator = () => {

    if(page_data.current_page == 1) {

      rowData.value = []
      gridApi.value.setRowData(rowData.value)
    }

    let promise = axios({
        url: 'api/master/get_employee_details',
        method: "get",
        baseURL: BASE_URL,
        params:  {
          size: page_data.per_page,
          page: page_data.current_page,
        },
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
        },
        config: {
            headers: {
                Accept: "application/json"
            }
        }
    });
        return promise
            .then(result => result.data)
            .then(data => {

              page_data.loading = false

              let items = data.data
              items.map(item => {
                rowData.value.push({
                  sr_no: page_data.sr_no,
                  id: item.id,
                  action: "",
                  active_status: item.active_status,
                  first_name: item.first_name,
                  last_name: item.last_name,
                  short_name: item.short_name,
                  manager_name: (item.manager_item.short_name),
                  processes: item.processes,
                  email: item.email,
                  mobile_no: item.mobile_no,
                  dob: item.user_details.dob,
                  gender: item.user_details.gender,
                  age: item.user_details.age,
                  blood_group: item.user_details.blood_group,
                  cast: item.user_details.cast,
                  merital_status: (item.user_details.merital_status == 1  ?  "Merried" : "UnMerried"),
                  aadhar_no: item.user_details.aadhar_no,
                  pan_no: item.user_details.pan_no,
                  bank_name: item.user_details.bank_name,
                  ifsc_code: item.user_details.ifsc_code,
                  account_no: item.user_details.account_no,
                  current_addr: item.user_details.current_addr,
                  permenamt_addr: item.user_details.permenamt_addr,
                  pincode: item.user_details.pincode,
                  taluka: item.user_details.taluka,
                  discrict: item.user_details.discrict,
                  state: item.user_details.state,
                  standard: item.user_details.standard,
                  percent: item.user_details.percent,
                  college: item.user_details.college,
                  language: item.user_details.language,
                  shape_know: item.user_details.shape_know,
                  salary: item.user_details.salary,
                  domain_know: item.user_details.domain_know,
                  diamond_knowledge: (item.user_details.diamond_knowledge  == 0 ? "YES" : "NO"),
                  sarin_knowledge: (item.user_details.sarin_knowledge  == 0 ? "YES" : "NO"),
                  laser_knowledge: (item.user_details.laser_knowledge  == 0 ? "YES" : "NO"),
                  computer_knowledge: (item.user_details.computer_knowledge  == 0 ? "YES" : "NO"),
                  ref_name: item.user_details.ref_name,
                  ref_code: item.user_details.ref_code,
                  ref_designation: item.user_details.ref_designation,
                  ref_mobile: item.user_details.ref_mobile,
                  ref_address: item.user_details.ref_address,
                  about_company: item.user_details.about_company
                })
                page_data.sr_no += 1
              })

              gridApi.value.setRowData(rowData.value)
              if (page_data.current_page == 1) {
                page_data.last_page = data.meta.last_page
                page_data.total_records = data.meta.total
              }

              if (page_data.current_page == data.meta.last_page) {
                // page_data.in_progress = false
              } else {
                page_data.current_page += 1
                initTabulator()
              }
            })
            .catch(_error => {
              swal("Error!", "Something went wrong.", "error");
                return [];
            });
  }

  const exportFilterData = () => {
    gridApi.value.exportDataAsCsv()
  }

  const updateStatus = (id) => {
      console.log(id);
      const params = {
      "id":  id,
      };

      let promise = axios({
        url: "api/master/update_employee_status",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token"),
 },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

        if(data.status == "error") {
          swal("Warning", data.message, "warning");
        } else {
          page_data.current_page = 1;
          initTabulator();
        }

        })
        .catch(_error => {

        });
    }


  onMounted(() => {
    setTimeout(() => {
      initTabulator()
    }, 200);
  })
</script>
