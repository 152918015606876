<template>
  <div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <form id="tabulator-html-filter-form" class="xl:flex sm:mr-auto">
          <div class="flex w-full">
            <div class="input-group mt-2 sm:mt-0 mr-3">
              <div id="input-group-3" class="input-group-text w-48 text-center">Worker Type</div>
              <select
                class="form-select"
                v-model="formData.employee_type"
                aria-label="Default select example"
                @change="initTabulator"
              >
                <option value="">All</option>
                <option value="4">Employee</option>
                <!-- <option value="2">Manager</option> -->
                <option value="7">Job Party</option>
                <!-- <option value="4">Purchase Party</option> -->
              </select>
            </div>
            <div class="input-group mt-2 sm:mt-0">
              <TomSelect
                v-model="formData.process_id"
                    :options="{
                      placeholder: 'Select Process'
                    }"
                    class="w-60"
                    @change="initTabulator"
                  >
                  <option value="0">Select Process</option>
                  <option v-for="process in formData.processes" :key="process.id" :value="process.id">{{ process.process_name }}</option>
                </TomSelect>
            </div>
            <div class="input-group mt-2 sm:mt-0">
              <TomSelect
                v-model="formData.manager_id"
                    :options="{
                      placeholder: 'Select Manager'
                    }"
                    class="w-60"
                    @change="initTabulator"
                  >
                  <option value="0">Select Manager</option>
                  <option v-for="manager in formData.managers" :key="manager.id" :value="manager.id">{{ manager.first_name }} {{ manager.last_name }}</option>
                </TomSelect>
            </div>
          </div>

        </form>
        <div class="flex mt-5 sm:mt-0">
          <router-link
          :to="{ name: 'manual-rate-create' }"
          tag="button"
           class="btn btn-primary shadow-md mr-2">

          New Sync
          </router-link>
          <div class="dropdown w-1/2 sm:w-auto">
            <button
              class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto"
              aria-expanded="false"
            >
              <FileTextIcon class="w-4 h-4 mr-2" /> Export
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-menu w-40">
              <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                <a
                  id="tabulator-export-csv"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportCsv"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export CSV
                </a>
                <a
                  id="tabulator-export-json"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportJson"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export JSON
                </a>
                <a
                  id="tabulator-export-xlsx"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportXlsx"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX
                </a>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>

</template>

<script>
/* eslint-disable */
import { defineComponent, ref,onMounted, reactive } from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import { useRouter, useRoute } from 'vue-router'
import { BASE_URL } from '@/constants/config.js'

export default defineComponent({
  setup() {
    const router = useRouter()
    const route = useRoute()
    const tableRef = ref()
    const tabulator = ref()

    const formData = reactive({
      process_id : '',
      rate_type : '',
      manager_id : '',
      employee_type : '',
      processes: [],
      managers: [],
    })
    const initTabulator = () => {
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: BASE_URL + 'api/master/rate_update_list',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxParams: {
          process_id : formData.process_id == '' ? '' : formData.process_id,
          rate_type : formData.rate_type == '' ? '' : formData.rate_type,
          manager_id : formData.manager_id == '' ? '' : formData.manager_id,
          employee_type : formData.employee_type == '' ? '' : formData.employee_type
        },
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 20,
        paginationSizeSelector: [10, 20, 30, 40],
        layout: 'fitColumns',
        headerSort: false,
        responsiveLayout: 'collapse',
        placeholder: 'No matching records found',
        columns: [
          // For HTML table
          {
            title: 'Id',
            responsive: 0,
            minWidth: 100,
            field: 'id',
            vertAlign: 'middle',
            print: false,
            download: false,

          },
          {
            title: 'Process Name',
            responsive: 0,
            minWidth: 150,
            field: 'process_name',
            vertAlign: 'middle',
            print: false,
            download: false,

          },
          {
            title: 'Manager Name',
            responsive: 0,
            minWidth: 150,
            field: 'manager_name',
            vertAlign: 'middle',
            print: false,
            download: false,

          },
          {
            title: 'Worker List',
            responsive: 0,
            width: 150,
            field: 'employee_name',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter: "textarea"

          },
          {
            title: 'Start Date',
            responsive: 0,
            field: 'start_date',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter: "textarea"

          },
          {
            title: 'End Date',
            responsive: 0,
            field: 'end_date',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter: "textarea"

          },
          {
            title: 'Created At',
            responsive: 0,
            field: 'created_at',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter: "textarea"

          },
          {
            title: 'Status',
            minWidth: 100,
            field: 'active_status',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div class="flex lg:justify-center ${
                cell.getData().statuscolor
              }">
                <i data-feather="check-square" class="w-4 h-4 mr-2"></i> ${
                  cell.getData().statusText
                }
              </div>`
            }
          }

        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }

    function getProcesses() {
      let url = 'api/master/dp_process'
      const params = {
      }
      axios({
        url: url,
        method: 'post',
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token"),
 },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        formData.processes = res.data.items
      })
    }
    function getManagers() {
      let url = 'api/master/dp_managers'
      const params = {
      }
      axios({
        url: url,
        method: 'post',
        baseURL: BASE_URL,
        data: params,
        headers: { Authorization: "Bearer " + localStorage.getItem("token"),
 },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        formData.managers = res.data.items
      })
    }

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }


    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'data.csv')
    }

    const onExportJson = () => {
      tabulator.value.download('json', 'data.json')
    }

    const onExportXlsx = () => {
      const win = window
      win.XLSX = xlsx
      tabulator.value.download('xlsx', 'data.xlsx', {
        sheetName: 'Products'
      })
    }

    const onExportHtml = () => {
      tabulator.value.download('html', 'data.html', {
        style: true
      })
    }

    // Print
    const onPrint = () => {
      tabulator.value.print()
    }

    onMounted(() => {
      initTabulator()
      reInitOnResizeWindow()
      getProcesses()
    })


    return {
      tableRef,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      formData,
      getProcesses,
      initTabulator
    }
  }
})
</script>
