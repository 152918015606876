<template>
<div>
    <!-- Headings -->
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Kapan Done Report</h2>
        <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

        </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

        <div class="grid grid-cols-12 gap-x-2">
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div class="mt-2">
                  <label for="update-profile-form-6" class="form-label">Report Type</label>
                    <TomSelect v-model="filter.report_type" :options="{
                  placeholder: 'Report Type',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Report'
                    }
                  }
                }" class="w-full">
                        <option value="1">Polish Summary</option>
                        <option value="2">Polish Expand</option>
                        <option value="3">Polish Detail</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div class="mt-2">
                    <label for="update-profile-form-6" class="form-label">Select Kapan</label>
                    <TomSelect v-model="filter.kapan_id" multiple :options="{
                  placeholder: 'Select Kapan',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Process'
                    }
                  }
                }" class="w-full">
                        <option value="0">Select Kapan</option>
                        <option v-for="item in page_data.lot_items" :key="item.id" :value="item.id">{{item.lot_no}} ( {{ item.voucher_no ? item.voucher_no : '-' }} )</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div class="mt-2">
                    <label for="update-profile-form-6" class="form-label">Pre Process</label>
                    <TomSelect v-model="filter.process_list" multiple :options="{
                  placeholder: 'Select Process',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Process'
                    }
                  }
                }" class="w-full">
                        <option v-for="item in page_data.process_items" :key="item.id" :value="item.id">{{item.process_name}}</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div class="mt-2">
                    <div class="flex items-center"><label for="update-profile-form-6" class="form-label">Select Start Date</label><input class="show-code form-check-switch mr-0 ml-3 h-6" type="checkbox" v-model="filter.issue_pending" /></div>
                    <input type="date" id="update-profile-form-13" :disabled="filter.issue_pending" class="form-control" placeholder="" v-model="filter.issue_date" />
                </div>

            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div class="mt-2">
                    <label for="update-profile-form-6" class="form-label">Select End Date</label>
                    <input type="date" id="update-profile-form-13" class="form-control" placeholder="" :disabled="filter.issue_pending" v-model="filter.receive_date" />
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div class="mt-2">
                    <label for="update-profile-form-6" class="form-label">Bunch Details</label>
                    <div>
                        <input class="show-code form-check-switch mr-0 ml-3 h-6" type="checkbox" v-model="filter.bunch_details" />
                    </div>
                </div>
            </div>
            <div class="col-span-2 md:col-span-2 xl:col-span-1">
                <div class="mt-7 xl:mt-2 flex pt-2">

                    <button id="tabulator-html-filter-reset" type="button" class="btn btn-secondary w-full  sm:ml-2" @click="onResetFilter">
                        Reset
                    </button>
                </div>
            </div>
            <div class="col-span-2 md:col-span-2 xl:col-span-1">
                <div class="mt-7 xl:mt-2 flex pt-2">

                    <button id="tabulator-html-filter-go" type="button" class="btn btn-primary w-full ml-2" tabindex="5" @click="getReportDetails()">
                        Generate
                    </button>
                </div>
            </div>
        </div>

        <div>
            <!-- BEgin: Process Report Start-->
            <div id="responsive-table" class="p-5" v-if="page_data.loading">
                <div class="preview">
                    <div class="overflow-x-auto">
                        <div class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center">
                            <LoadingIcon icon="bars" class="w-8 h-8" />
                            <div class="text-center text-xs mt-2">Getting Latest Price</div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-else>
                <div class="scrollbar-hidden">
                    <div class="intro-y col-span-12 overflow-auto tableFixHead mt-3 border-2">
                        <table class="w-full border-0" v-if="api_filter.report_type == 1">
                            <thead>
                                <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap border-b">#</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Kapan No</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">O PCS</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">O WT(Ct)</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap"></th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap"></th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" colspan="8" v-if="api_filter.bunch_details == true">BUNCH PROCESS</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" colspan="6">PRE PROCESS</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" colspan="3">FINAL ISSUE</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" colspan="4">FINAL RECEIVE</th>
                                </tr>
                                <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r border-b"></th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r">D.REJ. PCS</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r">D.REJ. WT</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r">D.REJ. (%)</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="api_filter.bunch_details == true">P.REJ. PCS</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="api_filter.bunch_details == true">P.REJ. WT</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="api_filter.bunch_details == true">P.REJ. (%)</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="api_filter.bunch_details == true">N.REJ. PCS</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="api_filter.bunch_details == true">N.REJ. WT</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="api_filter.bunch_details == true">N.REJ. ($)</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="api_filter.bunch_details == true">LOSE WT</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="api_filter.bunch_details == true">LOSE (%)</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r">P.REJ WT</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r">P.REJ (%)</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r">N.REJ WT</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r">N.REJ (%)</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r">LOSE WT</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r">LOSE (%)</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r">ISSUE PCS</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r">ISSUE WT</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r">ISSUE (%)</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r">POLISH <br> JAMA PCS</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r">POLISH <br> JAMA WT</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r">POLISH (%) <br> F ISS.CT</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r">POLISH (%) <br> F O.CT</th>
                                </tr>
                            </thead>
                            <tbody v-for="(item,index) in page_data.items" :key="index">

                                <tr class="border-b hover:bg-gray-200 p-0" :class="{'bg-gray-100': (index % 2) == 0}">
                                    <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ index + 1 }}</td>
                                    <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r  whitespace-nowrap">{{ item.lot_no }} ({{ item.voucher_no }})</td>
                                    <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ item.pr_pcs }}</th>
                                    <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ item.pr_wt ? $h.myFloat(item.pr_wt) : '-' }}</th>
                                    <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.bunch_direct_rejection_pcs ? item.bunch_direct_rejection_pcs : '-') }}</th>
                                    <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r text-red">{{ (item.bunch_direct_rejection_wt > 0 ? $h.myFloat(item.bunch_direct_rejection_wt) : '') }}</th>
                                    <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r text-red">{{ (item.bunch_direct_rejection_percent > 0 ? $h.myFloat(item.bunch_direct_rejection_percent) + ' %' : '') }}</th>
                                    <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r" v-if="api_filter.bunch_details == true">{{ item.bunch_rejection_pcs }}</td>
                                    <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r" v-if="api_filter.bunch_details == true">{{ (item.bunch_rejection_wt ? $h.myFloat(item.bunch_rejection_wt) : 0) }}</td>
                                    <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r" v-if="api_filter.bunch_details == true">{{ (item.bunch_rejection_percent ? $h.myFloat(item.bunch_rejection_percent) + ' %' : 0) }}</td>
                                    <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r" v-if="api_filter.bunch_details == true">{{ item.bunch_net_rejection_pcs }}</td>
                                    <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r" v-if="api_filter.bunch_details == true">{{ (item.bunch_net_rejection_wt ? $h.myFloat(item.bunch_net_rejection_wt) : 0) }}</td>
                                    <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r" v-if="api_filter.bunch_details == true">{{ (item.bunch_net_rejection_percent ? $h.myFloat(item.bunch_net_rejection_percent) + ' %' : 0) }}</td>
                                    <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r" v-if="api_filter.bunch_details == true">{{ (item.bunch_ghat_wt ? $h.myFloat(item.bunch_ghat_wt) : 0) }}</td>
                                    <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r" v-if="api_filter.bunch_details == true">{{ (item.bunch_ghat_percent ? $h.myFloat(item.bunch_ghat_percent) + ' %' : 0) }}</td>
                                    <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.lot_rejection_wt ? $h.myFloat(item.lot_rejection_wt) : 0) }}</td>
                                    <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.lot_rejection_percent ? $h.myFloat(item.lot_rejection_percent) + ' %' : 0) }}</td>
                                    <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.lot_net_rejection_wt ? $h.myFloat(item.lot_net_rejection_wt) : 0) }}</td>
                                    <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.lot_net_rejection_percent ? $h.myFloat(item.lot_net_rejection_percent) + ' %' : 0) }}</td>
                                    <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.lot_ghat_wt ? $h.myFloat(item.lot_ghat_wt) : 0) }}</td>
                                    <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.lot_ghat_percent ? $h.myFloat(item.lot_ghat_percent) + ' %' : 0) }}</td>
                                    <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.issue_pieces > 0 ? item.issue_pieces : '') }}</th>
                                    <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.issue_weight > 0 ? $h.myFloat(item.issue_weight) : '') }}</th>
                                    <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r text-primary-8">{{ (item.issue_percent > 0 ? $h.myFloat(item.issue_percent) + ' %' : '') }}</th>
                                    <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.final_pieces > 0 ? item.final_pieces : '') }}</th>
                                    <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.final_weight > 0 ? $h.myFloat(item.final_weight) : '') }}</th>
                                    <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r text-green">{{ (item.final_percent > 0 ? $h.myFloat(item.final_percent) + ' %' : '') }}</th>
                                    <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r text-primary-4">{{ (item.rough_percent > 0 ? $h.myFloat(item.rough_percent) + ' %' : '') }}</th>
                                </tr>
                            </tbody>

                        </table>
                        <table class="w-full border-0" v-else-if="api_filter.report_type == 2">
                            <thead>
                                <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap border-b">#</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Kapan No</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">O PCS</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">O WT(Ct)</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap"></th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap"></th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" colspan="8" v-if="api_filter.bunch_details == true">BUNCH PROCESS</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" :colspan="7 + (api_filter.process_list.length * 2) + 1">PRE PROCESS</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" colspan="3">FINAL ISSUE</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" colspan="4">FINAL RECEIVE</th>
                                </tr>
                                <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r border-b"></th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r">D.REJ. PCS</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r">D.REJ. WT</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r">D.REJ. (%)</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="api_filter.bunch_details == true">P.REJ. PCS</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="api_filter.bunch_details == true">P.REJ. WT</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="api_filter.bunch_details == true">P.REJ. (%)</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="api_filter.bunch_details == true">N.REJ. PCS</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="api_filter.bunch_details == true">N.REJ. WT</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="api_filter.bunch_details == true">N.REJ. ($)</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="api_filter.bunch_details == true">LOSE WT</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="api_filter.bunch_details == true">LOSE (%)</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r">Packet No</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r">Stock No</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r">O.Wt</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r">P.REJ(WT)</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r">P.REJ (%)</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r">N.REJ(WT)</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r">N.REJ (%)</th>
                                    <template v-for="(itm, ind) in page_data.process_header" :key="ind">
                                      <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r" :colspan="2">{{ itm }}</th>
                                    </template>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r">LOSE WT</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r">LOSE(%)</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r">ISSUE(WT)</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r">ISSUE(%)</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r">POLISH <br>JAMA(PCS)</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r">POLISH <br>JAMA(WT)</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r">POLISH (%) <br> F.ISS.CT</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r">POLISH (%) <br> F.O.CT</th>
                                </tr>
                            </thead>
                            <tbody v-for="(item,index) in page_data.items" :key="index">

                                <tr v-for="(sub_item,inx) in item.packet_details" :key="inx" class="border-b hover:bg-gray-200 p-0" :class="{'bg-gray-100': (inx % 2) == 0}">
                                  <td :rowspan="item.packet_details.length" v-if="inx == 0" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ index + 1 }}</td>
                                  <td :rowspan="item.packet_details.length" v-if="inx == 0" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.lot_no }} ({{ item.voucher_no }})</td>
                                  <th :rowspan="item.packet_details.length" v-if="inx == 0" class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ item.pr_pcs }}</th>
                                  <th :rowspan="item.packet_details.length" v-if="inx == 0" class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ item.pr_wt ? $h.myFloat(item.pr_wt) : '-' }}</th>
                                  <th :rowspan="item.packet_details.length" v-if="inx == 0" class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.bunch_direct_rejection_pcs ? item.bunch_direct_rejection_pcs : '-') }}</th>
                                  <th :rowspan="item.packet_details.length" v-if="inx == 0" class="py-2 border-b border-t text-xs lg:text-md text-center border-r text-red">{{ (item.bunch_direct_rejection_wt > 0 ? $h.myFloat(item.bunch_direct_rejection_wt) : '') }}</th>
                                  <th :rowspan="item.packet_details.length" v-if="inx == 0" class="py-2 border-b border-t text-xs lg:text-md text-center border-r text-red">{{ (item.bunch_direct_rejection_percent > 0 ? $h.myFloat(item.bunch_direct_rejection_percent) + ' %' : '') }}</th>
                                  <td :rowspan="item.packet_details.length" v-if="inx == 0 && api_filter.bunch_details == true" class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ item.bunch_rejection_pcs }}</td>
                                  <td :rowspan="item.packet_details.length" v-if="inx == 0 && api_filter.bunch_details == true" class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.bunch_rejection_wt ? $h.myFloat(item.bunch_rejection_wt) : 0) }}</td>
                                  <td :rowspan="item.packet_details.length" v-if="inx == 0 && api_filter.bunch_details == true" class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.bunch_rejection_percent ? $h.myFloat(item.bunch_rejection_percent) + ' %' : 0) }}</td>
                                  <td :rowspan="item.packet_details.length" v-if="inx == 0 && api_filter.bunch_details == true" class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ item.bunch_net_rejection_pcs }}</td>
                                  <td :rowspan="item.packet_details.length" v-if="inx == 0 && api_filter.bunch_details == true" class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.bunch_net_rejection_wt ? $h.myFloat(item.bunch_net_rejection_wt) : 0) }}</td>
                                  <td :rowspan="item.packet_details.length" v-if="inx == 0 && api_filter.bunch_details == true" class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.bunch_net_rejection_percent ? $h.myFloat(item.bunch_net_rejection_percent) + ' %' : 0) }}</td>
                                  <td :rowspan="item.packet_details.length" v-if="inx == 0 && api_filter.bunch_details == true" class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.bunch_ghat_wt ? $h.myFloat(item.bunch_ghat_wt) : 0) }}</td>
                                  <td :rowspan="item.packet_details.length" v-if="inx == 0 && api_filter.bunch_details == true" class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.bunch_ghat_percent ? $h.myFloat(item.bunch_ghat_percent) + ' %' : 0) }}</td>
                                  <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (sub_item.number ? sub_item.number : '') }}</th>
                                  <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (sub_item.vepari_no ? sub_item.vepari_no : '') }}</th>
                                  <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (sub_item.weight ? sub_item.weight : '') }}</th>
                                  <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (sub_item.lot_rejection_wt ? $h.myFloat(sub_item.lot_rejection_wt) : 0) }}</td>
                                  <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (sub_item.lot_rejection_percent ? $h.myFloat(sub_item.lot_rejection_percent) + ' %' : 0) }}</td>
                                  <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (sub_item.lot_net_rejection_wt ? $h.myFloat(sub_item.lot_net_rejection_wt) : 0) }}</td>
                                  <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (sub_item.lot_net_rejection_percent ? $h.myFloat(sub_item.lot_net_rejection_percent) + ' %' : 0) }}</td>
                                  <template v-for="(itm, ind) in sub_item.packet_process_arr" :key="ind">
                                      <td class="p-2 border-b border-t text-xs lg:text-md text-center border-r whitespace-nowrap">{{ itm }}</td>
                                  </template>
                                  <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (sub_item.lot_ghat_wt ? $h.myFloat(sub_item.lot_ghat_wt) : 0) }}</td>
                                  <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (sub_item.lot_ghat_percent ? $h.myFloat(sub_item.lot_ghat_percent) + ' %' : 0) }}</td>
                                  <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (sub_item.issue_weight > 0 ? $h.myFloat(sub_item.issue_weight) : '') }}</th>
                                  <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r text-primary-8">{{ (sub_item.issue_percent > 0 ? $h.myFloat(sub_item.issue_percent) + ' %' : '') }}</th>
                                  <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (sub_item.final_pieces > 0 ? sub_item.final_pieces : '') }}</th>
                                  <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (sub_item.final_weight > 0 ? $h.myFloat(sub_item.final_weight) : '') }}</th>
                                  <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r text-primary-4">{{ (sub_item.rough_percent > 0 ? $h.myFloat(sub_item.rough_percent) + ' %' : '') }}</th>
                                  <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r text-green">{{ (sub_item.final_percent > 0 ? $h.myFloat(sub_item.final_percent) + ' %' : '') }}</th>
                                </tr>
                                <tr class="border-b bg-gray-200 p-0">
                                  <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                  <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                  <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r"></th>
                                  <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r"></th>
                                  <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r"></th>
                                  <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r text-red"></th>
                                  <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r text-red"></th>
                                  <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r" v-if="api_filter.bunch_details == true"></td>
                                  <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r" v-if="api_filter.bunch_details == true"></td>
                                  <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r" v-if="api_filter.bunch_details == true"></td>
                                  <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r" v-if="api_filter.bunch_details == true"></td>
                                  <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r" v-if="api_filter.bunch_details == true"></td>
                                  <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r" v-if="api_filter.bunch_details == true"></td>
                                  <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r" v-if="api_filter.bunch_details == true"></td>
                                  <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r" v-if="api_filter.bunch_details == true"></td>
                                  <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r"></td>
                                  <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r"></td>
                                  <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.total_lot_weight ? $h.myFloat(item.total_lot_weight) : 0) }}</td>
                                  <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.lot_rejection_wt ? $h.myFloat(item.lot_rejection_wt) : 0) }}</td>
                                  <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.lot_rejection_percent ? $h.myFloat(item.lot_rejection_percent) + ' %' : 0) }}</td>
                                  <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.lot_net_rejection_wt ? $h.myFloat(item.lot_net_rejection_wt) : 0) }}</td>
                                  <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.lot_net_rejection_percent ? $h.myFloat(item.lot_net_rejection_percent) + ' %' : 0) }}</td>
                                  <template v-for="(itm, ind) in item.packet_process_arr" :key="ind">
                                      <th class="p-2 border-b border-t text-xs lg:text-md text-center border-r whitespace-nowrap">{{ itm }}</th>
                                    </template>
                                  <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.lot_ghat_wt ? $h.myFloat(item.lot_ghat_wt) : 0) }}</td>
                                  <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.lot_ghat_percent ? $h.myFloat(item.lot_ghat_percent) + ' %' : 0) }}</td>
                                  <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.issue_weight > 0 ? $h.myFloat(item.issue_weight) : '') }}</th>
                                  <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r text-primary-8">{{ (item.issue_percent > 0 ? $h.myFloat(item.issue_percent) + ' %' : '') }}</th>
                                  <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.final_pieces > 0 ? item.final_pieces : '') }}</th>
                                  <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.final_weight > 0 ? $h.myFloat(item.final_weight) : '') }}</th>
                                  <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r text-green">{{ (item.final_percent > 0 ? $h.myFloat(item.final_percent) + ' %' : '') }}</th>
                                  <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r text-primary-4">{{ (item.rough_percent > 0 ? $h.myFloat(item.rough_percent) + ' %' : '') }}</th>
                                </tr>
                            </tbody>

                        </table>
                        <table class="w-full border-0" v-else-if="api_filter.report_type == 3">
                            <thead>
                                <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap border-b">#</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">Kapan No</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">O PCS</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r">O WT(Ct)</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap"></th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap"></th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" colspan="8" v-if="api_filter.bunch_details == true">BUNCH PROCESS</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" :colspan="7 + (api_filter.process_list.length * 2) + 1">PRE PROCESS</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" colspan="3">FINAL ISSUE</th>
                                    <th class="py-2 text-xs lg:text-md whitespace-nowrap border-r" colspan="6">FINAL RECEIVE</th>
                                </tr>
                                <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r border-b"></th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r"></th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r">D.REJ. PCS</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r">D.REJ. WT</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r">D.REJ. (%)</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="api_filter.bunch_details == true">P.REJ. PCS</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="api_filter.bunch_details == true">P.REJ. WT</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="api_filter.bunch_details == true">P.REJ. (%)</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="api_filter.bunch_details == true">N.REJ. PCS</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="api_filter.bunch_details == true">N.REJ. WT</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="api_filter.bunch_details == true">N.REJ. ($)</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="api_filter.bunch_details == true">LOSE WT</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r" v-if="api_filter.bunch_details == true">LOSE (%)</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r">Packet No</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r">Stock No</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r">O. WT</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r">P.REJ WT</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r">P.REJ (%)</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r">N.REJ WT</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r">N.REJ (%)</th>
                                    <template v-for="(itm, ind) in page_data.process_header" :key="ind">
                                      <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r" :colspan="2">{{ itm }}</th>
                                    </template>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r">LOSE WT</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r">LOSE (%)</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r">ISSUE WT</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r">ISSUE (%)</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r w-24">SHAPE</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r w-24">POLISH <br> JAMA WT</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r w-24">POLISH (%) <br> F ISS.CT</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r w-24">POLISH (%) <br> F O.CT</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r w-24">TOTAL (%) <br> F ISS.CT</th>
                                    <th class="px-2 text-xs lg:text-md whitespace-nowrap border-r w-24">TOTAL (%) <br> F O.CT</th>
                                </tr>
                            </thead>
                            <tbody v-for="(item,index) in page_data.items" :key="index">

                                <tr v-for="(sub_item,inx) in item.packet_details" :key="inx" class="border-b hover:bg-gray-200 p-0" :class="{'bg-gray-100': (inx % 2) == 0}">
                                  <td :rowspan="item.packet_details.length" v-if="inx == 0" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ index + 1 }}</td>
                                  <td :rowspan="item.packet_details.length" v-if="inx == 0" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.lot_no }} ({{ item.voucher_no }})</td>
                                  <th :rowspan="item.packet_details.length" v-if="inx == 0" class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ item.pr_pcs }}</th>
                                  <th :rowspan="item.packet_details.length" v-if="inx == 0" class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ item.pr_wt ? $h.myFloat(item.pr_wt) : '-' }}</th>
                                  <th :rowspan="item.packet_details.length" v-if="inx == 0" class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.bunch_direct_rejection_pcs ? item.bunch_direct_rejection_pcs : '-') }}</th>
                                  <th :rowspan="item.packet_details.length" v-if="inx == 0" class="py-2 border-b border-t text-xs lg:text-md text-center border-r text-red">{{ (item.bunch_direct_rejection_wt > 0 ? $h.myFloat(item.bunch_direct_rejection_wt) : '') }}</th>
                                  <th :rowspan="item.packet_details.length" v-if="inx == 0" class="py-2 border-b border-t text-xs lg:text-md text-center border-r text-red">{{ (item.bunch_direct_rejection_percent > 0 ? $h.myFloat(item.bunch_direct_rejection_percent) + ' %' : '') }}</th>
                                  <td :rowspan="item.packet_details.length" v-if="inx == 0 && api_filter.bunch_details == true" class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ item.bunch_rejection_pcs }}</td>
                                  <td :rowspan="item.packet_details.length" v-if="inx == 0 && api_filter.bunch_details == true" class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.bunch_rejection_wt ? $h.myFloat(item.bunch_rejection_wt) : 0) }}</td>
                                  <td :rowspan="item.packet_details.length" v-if="inx == 0 && api_filter.bunch_details == true" class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.bunch_rejection_percent ? $h.myFloat(item.bunch_rejection_percent) + ' %' : 0) }}</td>
                                  <td :rowspan="item.packet_details.length" v-if="inx == 0 && api_filter.bunch_details == true" class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ item.bunch_net_rejection_pcs }}</td>
                                  <td :rowspan="item.packet_details.length" v-if="inx == 0 && api_filter.bunch_details == true" class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.bunch_net_rejection_wt ? $h.myFloat(item.bunch_net_rejection_wt) : 0) }}</td>
                                  <td :rowspan="item.packet_details.length" v-if="inx == 0 && api_filter.bunch_details == true" class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.bunch_net_rejection_percent ? $h.myFloat(item.bunch_net_rejection_percent) + ' %' : 0) }}</td>
                                  <td :rowspan="item.packet_details.length" v-if="inx == 0 && api_filter.bunch_details == true" class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.bunch_ghat_wt ? $h.myFloat(item.bunch_ghat_wt) : 0) }}</td>
                                  <td :rowspan="item.packet_details.length" v-if="inx == 0 && api_filter.bunch_details == true" class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.bunch_ghat_percent ? $h.myFloat(item.bunch_ghat_percent) + ' %' : 0) }}</td>
                                  <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (sub_item.number ? sub_item.number : '') }}</th>
                                  <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (sub_item.vepari_no ? sub_item.vepari_no : '') }}</th>
                                  <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (sub_item.weight ? sub_item.weight : '') }}</th>
                                  <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (sub_item.lot_rejection_wt ? $h.myFloat(sub_item.lot_rejection_wt) : 0) }}</td>
                                  <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (sub_item.lot_rejection_percent ? $h.myFloat(sub_item.lot_rejection_percent) + ' %' : 0) }}</td>
                                  <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (sub_item.lot_net_rejection_wt ? $h.myFloat(sub_item.lot_net_rejection_wt) : 0) }}</td>
                                  <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (sub_item.lot_net_rejection_percent ? $h.myFloat(sub_item.lot_net_rejection_percent) + ' %' : 0) }}</td>
                                  <template v-for="(itm, ind) in sub_item.packet_process_arr" :key="ind">
                                      <td class="p-2 border-b border-t text-xs lg:text-md text-center border-r whitespace-nowrap">{{ itm }}</td>
                                  </template>
                                  <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (sub_item.lot_ghat_wt ? $h.myFloat(sub_item.lot_ghat_wt) : 0) }}</td>
                                  <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (sub_item.lot_ghat_percent ? $h.myFloat(sub_item.lot_ghat_percent) + ' %' : 0) }}</td>
                                  <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (sub_item.issue_weight > 0 ? $h.myFloat(sub_item.issue_weight) : '') }}</th>
                                  <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r text-primary-8">{{ (sub_item.issue_percent > 0 ? $h.myFloat(sub_item.issue_percent) + ' %' : '') }}</th>
                                  <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r m-0" colspan="6">
                                    <table v-if="sub_item.sub_packet_details.length > 0">
                                      <tr v-for="(itm, inx) in sub_item.sub_packet_details" :key="inx">
                                        <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r w-24">{{ (itm.final_shape_name) }}</th>
                                        <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r w-24">{{ (itm.final_weight > 0 ? $h.myFloat(itm.final_weight) : '') }}</th>
                                        <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r text-green w-24">{{ (itm.final_percent > 0 ? $h.myFloat(itm.final_percent) + ' %' : '') }}</th>
                                        <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r w-24 text-primary-4">{{ (itm.rough_percent > 0 ? $h.myFloat(itm.rough_percent) + ' %' : '') }}</th>
                                        <th v-if="inx == 0" :rowspan="sub_item.sub_packet_details.length" class="py-2 border-t text-xs lg:text-md text-center border-r w-24 text-primary-4">{{ (sub_item.final_percent > 0 ? $h.myFloat(sub_item.final_percent) + ' %' : '') }}</th>
                                        <th v-if="inx == 0" :rowspan="sub_item.sub_packet_details.length" class="py-2 border-t text-xs lg:text-md text-center border-r w-24 text-green">{{ (sub_item.rough_percent > 0 ? $h.myFloat(sub_item.rough_percent) + ' %' : '') }}</th>
                                      </tr>
                                    </table>
                                  </th>
                                  <!-- <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (sub_item.final_pieces > 0 ? sub_item.final_pieces : '') }}</th>
                                  <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (sub_item.final_weight > 0 ? $h.myFloat(sub_item.final_weight) : '') }}</th>
                                  <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r text-primary-4">{{ (sub_item.rough_percent > 0 ? $h.myFloat(sub_item.rough_percent) + ' %' : '') }}</th>
                                  <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r text-green">{{ (sub_item.final_percent > 0 ? $h.myFloat(sub_item.final_percent) + ' %' : '') }}</th> -->
                                </tr>
                                <tr class="border-b bg-gray-200 p-0">
                                  <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                  <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"></td>
                                  <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r"></th>
                                  <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r"></th>
                                  <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r"></th>
                                  <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r text-red"></th>
                                  <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r text-red"></th>
                                  <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r" v-if="api_filter.bunch_details == true"></td>
                                  <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r" v-if="api_filter.bunch_details == true"></td>
                                  <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r" v-if="api_filter.bunch_details == true"></td>
                                  <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r" v-if="api_filter.bunch_details == true"></td>
                                  <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r" v-if="api_filter.bunch_details == true"></td>
                                  <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r" v-if="api_filter.bunch_details == true"></td>
                                  <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r" v-if="api_filter.bunch_details == true"></td>
                                  <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r" v-if="api_filter.bunch_details == true"></td>
                                  <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r"></td>
                                  <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r"></td>
                                  <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.total_lot_weight ? $h.myFloat(item.total_lot_weight) : 0) }}</td>
                                  <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.lot_rejection_wt ? $h.myFloat(item.lot_rejection_wt) : 0) }}</td>
                                  <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.lot_rejection_percent ? $h.myFloat(item.lot_rejection_percent) + ' %' : 0) }}</td>
                                  <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.lot_net_rejection_wt ? $h.myFloat(item.lot_net_rejection_wt) : 0) }}</td>
                                  <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.lot_net_rejection_percent ? $h.myFloat(item.lot_net_rejection_percent) + ' %' : 0) }}</td>
                                  <template v-for="(itm, ind) in item.packet_process_arr" :key="ind">
                                      <th class="p-2 border-b border-t text-xs lg:text-md text-center border-r whitespace-nowrap">{{ itm }}</th>
                                    </template>
                                  <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.lot_ghat_wt ? $h.myFloat(item.lot_ghat_wt) : 0) }}</td>
                                  <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.lot_ghat_percent ? $h.myFloat(item.lot_ghat_percent) + ' %' : 0) }}</td>
                                  <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.issue_weight > 0 ? $h.myFloat(item.issue_weight) : '') }}</th>
                                  <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r text-primary-8">{{ (item.issue_percent > 0 ? $h.myFloat(item.issue_percent) + ' %' : '') }}</th>
                                  <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.final_pieces > 0 ? item.final_pieces : '') }}</th>
                                  <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.final_weight > 0 ? $h.myFloat(item.final_weight) : '') }}</th>
                                  <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r"></th>
                                  <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r"></th>
                                  <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r text-green">{{ (item.final_percent > 0 ? $h.myFloat(item.final_percent) + ' %' : '') }}</th>
                                  <th class="py-2 border-b border-t text-xs lg:text-md text-center border-r text-primary-4">{{ (item.rough_percent > 0 ? $h.myFloat(item.rough_percent) + ' %' : '') }}</th>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END: HTML Table Data -->

</div>
</template>

<script>
/* eslint-disable */
import {
    BASE_URL
} from '@/constants/config';
import axios from 'axios';
import moment from 'moment';
import {
    defineComponent,
    onMounted,
    reactive,
    toRef,
    watch,
    computed
} from 'vue';
import store from '@/store'
import {
    helper as $h
} from '@/utils/helper'

export default defineComponent({
    setup() {
        const user_details = computed(() => store.state.user.currentUser)
        const page_data = reactive({
            total: [],
            items: [],
            process_header: [],
            manager_items: [],
            process_items: [],
            lot_items: [],
            loading: false,
        })

        const filter = reactive({
            report_type: '1',
            bunch_details: false,
            kapan_id: [],
            process_list: [],
            issue_pending: false,
            issue_date: moment().startOf('month').format('YYYY-MM-DD'),
            receive_date: moment().format('YYYY-MM-DD'),
        })

        const api_filter = reactive({
            report_type: '1',
            bunch_details: false,
            kapan_id: [],
            process_list: [],
            worker_id: '0',
            issue_pending: false,
            issue_date: moment().startOf('month').format('YYYY-MM-DD'),
            receive_date: moment().format('YYYY-MM-DD'),
        })

        const getReportDetails = async () => {

            api_filter.report_type = filter.report_type
            api_filter.kapan_id = filter.kapan_id
            api_filter.process_list = filter.process_list
            api_filter.issue_pending = filter.issue_pending
            api_filter.issue_date = filter.issue_date
            api_filter.receive_date = filter.receive_date

            const params = {
                report_type: api_filter.report_type == '0' ? '' : window.btoa(api_filter.report_type),
                kapan_id: api_filter.kapan_id.length > 0 ? api_filter.kapan_id : [],
                process_list: api_filter.process_list.length > 0 ? api_filter.process_list : [],
                issue_pending: api_filter.issue_pending,
                start_date: window.btoa(api_filter.issue_date),
                end_date: window.btoa(api_filter.receive_date),
            }
            page_data.loading = true

            let promise = axios({
                url: 'api/report/spr_gkdr',
                method: "post",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),

                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    page_data.items = data.items
                    page_data.process_header = data.process_header
                    page_data.total = data.total
                    page_data.loading = false

                    console.log(page_data.process_header)
                  })
                  .catch(_error => {
                    page_data.items = []
                    page_data.total = []
                    page_data.process_header = []
                    page_data.loading = false
                });
        }

        const getLotDetails = async () => {
            let params = {}
            let api_url = 'api/report/spr_gkdi'

            let promise = axios({
                url: api_url,
                method: "post",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),

                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    filter.kapan_id = []
                    page_data.lot_items = data.items
                })
                .catch(_error => {
                    filter.kapan_id = []
                    page_data.lot_items = []
                });
        }

        const getProcessDetails = async () => {
            let params = {}
            let api_url = 'api/report/spr_gpii'

            let promise = axios({
                url: api_url,
                method: "post",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),

                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    filter.process_list = []
                    page_data.process_items = data.items
                })
                .catch(_error => {
                    filter.process_list = []
                    page_data.process_items = []
                });
        }

        function onResetFilter() {
            filter.report_type = '1'
            filter.kapan_id = []
            filter.process_list = []
            filter.issue_pending = false
            filter.issue_date = moment().startOf('month').format('YYYY-MM-DD')
            filter.receive_date = moment().format('YYYY-MM-DD')
        }
        onMounted(() => {
            getLotDetails()
            getProcessDetails()
            getReportDetails()
        })
        return {
            page_data,
            filter,
            api_filter,
            user_details,
            getReportDetails,
            onResetFilter,
        }
    }
})
</script>

<style scoped>
.tableFixHead {
    overflow-y: auto;
    /* make the table scrollable if height is more than 200 px  */
    max-height: 650px;
    /* gives an initial height of 200px to the table */
}

.tableFixHead thead {
    position: sticky;
    /* make the table heads sticky */
    top: 0px;
    /* table head will be placed from the top of the table and sticks to it */
}
</style>
