<template>
  <div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="grid grid-cols-12 gap-x-2">
              <div class="col-span-6 md:col-span-3 xl:col-span-2">
                  <div>
                    <label for="update-profile-form-6" class="form-label py-0">Report Type</label>
                    <TomSelect
                        v-model="filter.report_type"
                        :options="{
                          placeholder: 'Report Type',
                          loadingClass: 'loading',
                          plugins: {
                            dropdown_header: {
                              title: 'Report Type'
                            }
                          }
                        }"
                        class="w-full"
                      >
                      <option value="1">Jangad Lot-Wise</option>
                      <option value="2">Packet Summary</option>
                      <option value="3">Employee Summary</option>
                      <option value="4">Jangad Summary</option>
                      </TomSelect>
                  </div>
              </div>
              <div class="col-span-6 md:col-span-3 xl:col-span-2">
                  <div>
                      <label for="update-profile-form-6" class="form-label py-0">Packet / Lot</label>
                      <input id="tabulator-html-filter-value" v-model="filter.packet_no" type="text" class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0" placeholder="Search Packet / Kapan" />
                  </div>
              </div>
              <div class="col-span-6 md:col-span-3 xl:col-span-2">
                  <div>
                      <label for="update-profile-form-6" class="form-label py-0">Select Kapan</label>
                      <TomSelect v-model="filter.kapan_no" :options="{
                    placeholder: 'Select Kapan',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Process'
                      }
                    }
                  }" class="w-full" multiple>
                          <option v-for="item in page_data.lot_items" :key="item.id" :value="item.id">{{item.lot_no}} ( {{ item.voucher_no ? item.voucher_no : '-' }} )</option>
                      </TomSelect>
                  </div>
              </div>
              <div class="col-span-6 md:col-span-3 xl:col-span-2">
                  <div>
                      <label for="update-profile-form-6" class="form-label py-0">Select Process</label>
                      <TomSelect v-model="filter.process_id" @change="getEmployeeDetails" :options="{
                    placeholder: 'Select Process',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Process'
                      }
                    }
                  }" class="w-full">
                          <option value="0">Select Process</option>
                          <option v-for="item in page_data.process_items" :key="item.id" :value="item.id">{{item.process_name}} ( {{ item.process_short_code ? item.process_short_code : '-' }} )</option>
                      </TomSelect>
                  </div>
              </div>
              <div class="col-span-6 md:col-span-3 xl:col-span-2">
                  <div>
                      <label for="update-profile-form-6" class="form-label py-0">Select Manager</label>
                      <TomSelect v-model="filter.manager_id" @change="getEmployeeDetails" :options="{
                    placeholder: 'Select Manager',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Manager'
                      }
                    }
                  }" class="w-full">
                          <option value="0">Select Manager</option>
                          <option v-for="item in page_data.manager_items" :key="item.id" :value="item.id">{{item.first_name}} {{item.last_name}} ( {{ item.short_code ? item.short_code : '-' }} )</option>
                      </TomSelect>
                  </div>
              </div>
              <div class="col-span-6 md:col-span-3 xl:col-span-2">
                  <div>
                      <label for="update-profile-form-6" class="form-label py-0">Karigar Type</label>
                      <TomSelect v-model="filter.employee_type" @change="getEmployeeDetails" :options="{
                    placeholder: 'Select Karigar Type',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Karigar Type'
                      }
                    }
                  }" class="w-full">
                          <option value="0">Karigar Type</option>
                          <option value="1">Employee</option>
                          <option value="3">Job Party</option>
                      </TomSelect>
                  </div>
              </div>
              <div class="col-span-6 md:col-span-3 xl:col-span-2" v-if="filter.employee_type != '0'">
                  <div>
                      <label for="update-profile-form-6" class="form-label py-0">Select Employee</label>
                      <TomSelect v-model="filter.employee_id" :options="{
                    placeholder: 'Select Employee',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Employee'
                      }
                    }
                  }" class="w-full" multiple>
                          <option v-for="item in page_data.employee_items" :key="item.id" :value="item.id">{{item.first_name}} {{item.last_name}} ( {{ item.process_short_code ? item.short_code : '-' }} )</option>
                      </TomSelect>
                  </div>
              </div>
              <div class="col-span-6 md:col-span-3 xl:col-span-2">
              <div>
                <div class="flex items-center"><label for="update-profile-form-6" class="form-label py-0"
                  >Select Start Date</label
                ><input
                  class="show-code form-check-switch mr-0 ml-3 h-5"
                  type="checkbox" v-model="filter.issue_pending"
                /></div>
                <input
                  type= "date"
                  id="update-profile-form-13"
                  :disabled="filter.issue_pending"
                  class="form-control"
                  placeholder=""
                  v-model="filter.start_date"
                />
              </div>

          </div>
          <div class="col-span-6 md:col-span-3 xl:col-span-2">
            <div>
              <label for="update-profile-form-6" class="form-label py-0"
                >Select End Date</label
              >
              <input
                type= "date"
                id="update-profile-form-13"

                class="form-control"
                placeholder=""
                :disabled="filter.issue_pending"
                v-model="filter.end_date"
              />
            </div>
          </div>
          <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div class="flex itens-center mt-7">

            <button
              id="tabulator-html-filter-reset"
              type="button"
              class="btn btn-secondary"
              @click="onResetFilter"
            >
              Reset
            </button>
            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary ml-2"
              tabindex="5"
              @click="getReportDetail(1)"
              :disabled="page_data.in_progress == true || page_data.loading == true"
            >
            Generate
            </button>
            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary ml-2"
              tabindex="5"
              @click="exportFilterData"
              :disabled="page_data.in_progress == true || page_data.loading == true"
            >
            Export
            </button>
          </div>
        </div>
        </div>

      <div>
        <!-- BEgin: Process Report Start-->
        <div id="responsive-table" class="p-5" v-if="page_data.loading">
          <div class="preview">
            <div class="overflow-x-auto">
              <div
                class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
              >
                <LoadingIcon icon="bars" class="w-8 h-8" />
                <div class="text-center text-xs mt-2">Getting Latest Price</div>
            </div>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-12 w-full gap-5 mt-2">
                <div class="col-span-12">
                    <div class="flex flex-wrap">

                        <div class="text-sm font-bold mr-6">Total Pieces: {{ page_data.issue_pieces ? page_data.issue_pieces : 0 }}</div>
                        <div class="text-sm font-bold mr-6">Issue Weight: {{ page_data.issue_weight ? page_data.issue_weight : 0 }} Ct</div>
                        <div class="text-sm font-bold mr-6">Repeat Weight: {{ page_data.repeat_weight ? page_data.repeat_weight : 0 }} Ct</div>
                        <div class="text-sm font-bold mr-6">LS Weight: {{ page_data.ls_weight ? page_data.ls_weight : 0 }} Ct</div>
                        <div class="text-sm font-bold mr-6">Receive Weight: {{ page_data.receive_weight ? page_data.receive_weight : 0 }} Ct</div>
                        <div class="text-sm font-bold mr-6">Loss Weight: {{ page_data.weight_loss ? page_data.weight_loss : 0 }} Ct</div>
                        <div class="text-sm font-bold mr-6">Top Weight: {{ page_data.top_weight ? page_data.top_weight : 0 }} Ct</div>
                        <div class="text-sm font-bold mr-6">Kap Weight: {{ page_data.kap_weight ? page_data.kap_weight : 0 }} Ct</div>
                        <!-- <div class="text-sm font-bold mr-6">Polish Weight: {{ page_data.pie_weight ? page_data.pie_weight : 0 }} Ct</div>
                        <div class="text-sm font-bold mr-6">Polish Weight: {{ page_data.showing_weight ? page_data.showing_weight : 0 }} Ct</div> -->
                        <div class="text-sm font-bold mr-6">Pie Labour: {{ page_data.pie_labour ? page_data.pie_labour : 0 }} </div>
                        <div class="text-sm font-bold mr-6">Show Labour: {{ page_data.showing_labour ? page_data.showing_labour : 0 }} </div>
                        <div class="text-sm font-bold mr-6">Total Labour: {{ page_data.emp_majuri ? page_data.emp_majuri : 0 }} </div>
                    </div>
                </div>
            </div>

        <div class="mt-5">
          <div class="scrollbar-hidden">
            <div class="intro-y col-span-12 overflow-auto" v-if="api_filter.report_type == 1">
              <ag-grid-vue
                class="ag-theme-alpine"
                style="height: 600px"
                :columnDefs="(columnDefs.value)"
                :rowData="rowData.value"
                :defaultColDef="defaultColDef"
                rowSelection="multiple"
                animateRows="true"
                @grid-ready="onGridReady"
                @filterChanged="calculate_total"
                :overlayNoRowsTemplate="overlayNoRowsTemplate"
                >
              </ag-grid-vue>
            </div>
            <div class="intro-y col-span-12 overflow-auto" v-if="api_filter.report_type == 2">
              <ag-grid-vue
              class="ag-theme-alpine"
              style="height: 600px"
              :columnDefs="(lotColumnDefs.value)"
              :rowData="rowData.value"
              :defaultColDef="defaultColDef"
              rowSelection="multiple"
              animateRows="true"
              @filterChanged="calculate_total"
                @grid-ready="onGridReady"
                :overlayNoRowsTemplate="overlayNoRowsTemplate"
              >
              </ag-grid-vue>
            </div>
            <div class="intro-y col-span-12 overflow-auto" v-if="api_filter.report_type == 3">
              <ag-grid-vue
              class="ag-theme-alpine"
              style="height: 600px"
              :columnDefs="(empColumnDefs.value)"
              :rowData="rowData.value"
              :defaultColDef="defaultColDef"
              rowSelection="multiple"
              animateRows="true"
              @filterChanged="calculate_total"
                @grid-ready="onGridReady"
                :overlayNoRowsTemplate="overlayNoRowsTemplate"
              >
              </ag-grid-vue>
            </div>
            <div class="intro-y col-span-12 overflow-auto" v-if="api_filter.report_type == 4">
              <ag-grid-vue
              class="ag-theme-alpine"
              style="height: 600px"
              :columnDefs="(jangadColumnDefs.value)"
              :rowData="rowData.value"
              :defaultColDef="defaultColDef"
              rowSelection="multiple"
              animateRows="true"
              @filterChanged="calculate_total"
                @grid-ready="onGridReady"
                :overlayNoRowsTemplate="overlayNoRowsTemplate"
              >
              </ag-grid-vue>
            </div>
          </div>
        </div>
      </div>
      <!-- END: HTML Table Data -->
    </div>

  </div>
</template>
<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config';
import axios from 'axios';
import moment from 'moment';
import { defineComponent, onMounted, reactive, ref, toRef, watch, computed } from 'vue';
import store from '@/store'
import { AgGridVue } from "ag-grid-vue3";
import ShapeRenderer from '@/custom-renderer/ShapeRenderer.js'
import {
  onGridReady,
  ModuleRegistry
} from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { CsvExportModule } from "@ag-grid-community/csv-export";
import { ClipboardModule } from "@ag-grid-enterprise/clipboard";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { MultiFilterModule } from "@ag-grid-enterprise/multi-filter";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { helper as $h} from '@/utils/helper'

import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
// import "styag-grid-community/les/ag-theme-alpine.css"; // Optional theme CSS


export default defineComponent({
  components: {
   AgGridVue,
  //  ShapeRenderer
 },
  setup() {
    const page_data = reactive({
      parties: [],
      employee_items: [],
      process_items: [],
      manager_items: [],
      current_page: 1,
      sr_no: 1,
      last_page: "",
      per_page: 200,
      total_records: 0,
      total_jangad: 0,
      issue_pieces: 0,
      issue_weight: 0,
      pending_pieces: 0,
      pending_weight: 0,
      receive_pieces: 0,
      receive_issue_weight: 0,
      receive_weight: 0,
      weight_loss: 0,
      ls_weight: 0,
      repeat_weight: 0,
      top_weight: 0,
      kap_weight: 0,
      pie_weight: 0,
      showing_weight: 0,
      emp_majuri: 0,
      pie_labour: 0,
      showing_labour: 0,
      loading: false,
      generated: false,
      in_progress: false,
    })

    const filter = reactive({
      start_date : moment().startOf('month').format('YYYY-MM-DD'),
      end_date : moment().endOf('month').format('YYYY-MM-DD'),
      party_id : [],
      report_type : '1',
      packet_no : '',
      kapan_no : '',
      manager_id : '0',
      process_id : '0',
      employee_type : '0',
      employee_id : [],
      issue_pending : false,
    })
    const api_filter = reactive({
      start_date : moment().startOf('month').format('YYYY-MM-DD'),
      end_date : moment().endOf('month').format('YYYY-MM-DD'),
      party_id : [],
      report_type : '1',
      packet_no : '',
      kapan_no : '',
      manager_id : '0',
      process_id : '0',
      employee_type : '0',
      employee_id : [],
      issue_pending : false,
    })

    const gridApi = ref();

    const rowData = reactive({
      value:[]
    });

    const onGridReady = (params) => {
    gridApi.value = params.api;
    };


    const defaultColDef = {
      sortable: true,
      filter: true,
      // floatingFilter: true,
      // menuTabs: ["filterMenuTab"],
      // flex: 1
      flex: 1,
      minWidth: 200,
      floatingFilter: true,
      menuTabs: ["filterMenuTab"],
    };

    ModuleRegistry.registerModules([
      ClientSideRowModelModule,
      CsvExportModule,
      ClipboardModule,
      MenuModule,
      MultiFilterModule,
      SetFilterModule,
    ]);

    const overlayNoRowsTemplate = 'Scan packets for polish receive!'

   const columnDefs = reactive({
     value: [
     { headerName: "#",field: 'sr_no',maxWidth: 60 },
      { headerName: "Packet No",field: 'number',minWidth: 150},
      { headerName: "Stock No",field: 'vepari_no',minWidth: 150},
      { headerName: "Jangad No",field: 'transaction_code',minWidth: 150},
      { headerName: "Process",field: 'process_name',minWidth: 150},
      { headerName: "Issue Ct",field: 'issue_weight',minWidth: 120},
      { headerName: "Repeat Ct",field: 'repeat_weight',minWidth: 120},
      { headerName: "LS Ct",field: 'ls_weight',minWidth: 120},
      { headerName: "Receive Ct",field: 'receive_weight',minWidth: 120},
      { headerName: "Loss Ct",field: 'weight_loss',minWidth: 120},
      { headerName: "Top Ct",field: 'top_weight',minWidth: 120},
      { headerName: "Kap Ct",field: 'kap_weight',minWidth: 120},
      { headerName: "Manager",field: 'manager_short_name',minWidth: 120},
      { headerName: "Employee",field: 'employee_short_name',minWidth: 120},
      { headerName: "Pie Labour",field: 'pie_labour',minWidth: 120},
      { headerName: "Show Labour",field: 'show_labour',minWidth: 130},
      { headerName: "Issue Date",field: 'issue_date',minWidth: 130},
      { headerName: "Receive Date",field: 'receive_date',minWidth: 130}
     ],
   });
    const lotColumnDefs = reactive({
     value: [
     { headerName: "#",field: 'sr_no',maxWidth: 60 },
      { headerName: "Packet No",field: 'number',minWidth: 150},
      { headerName: "Stock No",field: 'vepari_no',minWidth: 150},
      { headerName: "Issue Pieces",field: 'issue_pieces',minWidth: 120},
      { headerName: "Issue Ct",field: 'issue_weight',minWidth: 120},
      { headerName: "Repeat Ct",field: 'repeat_weight',minWidth: 120},
      { headerName: "LS Ct",field: 'ls_weight',minWidth: 120},
      { headerName: "Receive Ct",field: 'receive_weight',minWidth: 120},
      { headerName: "Loss Ct",field: 'weight_loss',minWidth: 120},
      { headerName: "Top Ct",field: 'top_weight',minWidth: 120},
      { headerName: "Kap Ct",field: 'kap_weight',minWidth: 120},
      { headerName: "Manager",field: 'manager_short_name',minWidth: 120},
      { headerName: "Employee",field: 'employee_short_name',minWidth: 120},
      { headerName: "Pie Labour",field: 'pie_labour',minWidth: 120},
      { headerName: "Show Labour",field: 'show_labour',minWidth: 120},
      { headerName: "Total Labour",field: 'emp_majuri',minWidth: 120}
     ],
   });
    const empColumnDefs = reactive({
     value: [
       { headerName: "#",field: 'sr_no',maxWidth: 60 },
       { headerName: "Employee",field: 'employee_short_name',minWidth: 120},
      { headerName: "Manager",field: 'manager_short_name',minWidth: 120},
      { headerName: "Receive Pieces",field: 'issue_pieces',minWidth: 120},
      { headerName: "Issue Ct",field: 'issue_weight',minWidth: 120},
      { headerName: "Repeat Ct",field: 'repeat_weight',minWidth: 120},
      { headerName: "LS Ct",field: 'ls_weight',minWidth: 120},
      { headerName: "Receive Ct",field: 'receive_weight',minWidth: 120},
      { headerName: "Loss Ct",field: 'weight_loss',minWidth: 120},
      { headerName: "Top Ct",field: 'top_weight',minWidth: 120},
      { headerName: "Kap Ct",field: 'kap_weight',minWidth: 120},
      { headerName: "Pie Labour",field: 'pie_labour',minWidth: 120},
      { headerName: "Show Labour",field: 'show_labour',minWidth: 130},
      { headerName: "Total Labour",field: 'emp_majuri',minWidth: 130}
     ],
   });
    const jangadColumnDefs = reactive({
     value: [
       { headerName: "#",field: 'sr_no',maxWidth: 60 },
       { headerName: "Jangad No",field: 'transaction_code',minWidth: 120},
       { headerName: "Receive Pieces",field: 'issue_pieces',minWidth: 120},
       { headerName: "Issue Ct",field: 'issue_weight',minWidth: 120},
       { headerName: "Repeat Ct",field: 'repeat_weight',minWidth: 120},
       { headerName: "LS Ct",field: 'ls_weight',minWidth: 120},
      // { headerName: "Receive Pieces",field: 'receive_pieces',minWidth: 120},
      { headerName: "Receive Ct",field: 'receive_weight',minWidth: 120},
      // { headerName: "Pending Pieces",field: 'pending_pieces',minWidth: 120},
      // { headerName: "Pending Weight",field: 'pending_weight',minWidth: 120},
      { headerName: "Loss Ct",field: 'weight_loss',minWidth: 120},
      { headerName: "Top Ct",field: 'top_weight',minWidth: 120},
      { headerName: "Kap Ct",field: 'kap_weight',minWidth: 120},
      { headerName: "Employee",field: 'employee_short_name',minWidth: 120},
     { headerName: "Manager",field: 'manager_short_name',minWidth: 120},
      { headerName: "Pie Labour",field: 'pie_labour',minWidth: 120},
      { headerName: "Show Labour",field: 'show_labour',minWidth: 130},
      { headerName: "Total Labour",field: 'emp_majuri',minWidth: 130}
     ],
   });

    const getReportDetail = (index) => {



      page_data.current_page = index
      if(page_data.current_page == 1) {

        api_filter.start_date = filter.start_date
        api_filter.end_date = filter.end_date
        api_filter.party_id = filter.party_id
        api_filter.report_type = filter.report_type
        api_filter.packet_no = filter.packet_no
        api_filter.kapan_no = filter.kapan_no
        api_filter.manager_id = filter.manager_id
        api_filter.process_id = filter.process_id
        api_filter.employee_type = filter.employee_type
        api_filter.employee_id = filter.employee_id
        api_filter.issue_pending = filter.issue_pending
        rowData.value = []
        gridApi.value.setRowData(rowData.value);
        page_data.loading = true
        page_data.sr_no = 1;
      }
      page_data.in_progress = true


        let params = {
          start_date : api_filter.start_date,
          end_date : api_filter.end_date,
          party_id : api_filter.party_id,
          report_type : api_filter.report_type,
          packet_no : api_filter.packet_no,
          kapan_no : api_filter.kapan_no,
          manager_id : api_filter.manager_id,
          process_id : api_filter.process_id,
          employee_type : api_filter.employee_type,
          employee_id : api_filter.employee_id,
          issue_pending : api_filter.issue_pending,
          page : page_data.current_page,
          size : page_data.per_page,
        }

        let promise = axios({
          url: 'api/report/ls-report',
          method: "post",
          data: params,
          baseURL: BASE_URL,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token")
          },
          config: { headers: { Accept: "application/json" } }
        });
        return promise
        .then(result => result.data)
        .then(data => {

          page_data.loading = false

          let items = data.data


            if(api_filter.report_type == 1) {

              items.map(item => {
                rowData.value.push({
                  'id': item.id,
                  'sr_no': page_data.sr_no,
                  'number': item.number,
                  'transaction_code': item.transaction_code,
                  'vepari_no': item.vepari_no,
                  'issue_weight': item.issue_weight,
                  'receive_weight': item.receive_weight,
                  'weight_loss': item.weight_loss,
                  'ls_weight': item.ls_weight,
                  'repeat_weight': item.repeat_weight,
                  'top_weight': item.top_weight,
                  'kap_weight': item.kap_weight,
                  'pie_weight': item.pie_weight,
                  'show_weight': item.show_weight,
                  'pie_labour': item.pie_labour,
                  'show_labour': item.show_labour,
                  'emp_majuri': Number(item.emp_majuri),
                  'manager_short_name': item.manager_short_name,
                  'process_name': item.process_name,
                  'issue_date': item.issue_date,
                  'receive_date': item.receive_date,
                  'employee_short_name': item.employee_short_name
                });
                page_data.sr_no += 1;
              });

              gridApi.value.setRowData(rowData.value);

            } else if(api_filter.report_type == 2) {

              items.map(item => {
                rowData.value.push({
                  'id': item.id,
                  'sr_no': page_data.sr_no,
                  'number': item.number,
                  'vepari_no': item.vepari_no,
                  'issue_pieces': item.issue_pieces,
                  'issue_weight': item.issue_weight,
                  'pending_pieces': item.pending_pieces,
                  'pending_weight': item.pending_weight,
                  'receive_pieces': item.receive_pieces,
                  'receive_issue_weight': item.receive_issue_weight,
                  'receive_weight': item.receive_weight,
                  'weight_loss': item.weight_loss,
                  'ls_weight': item.ls_weight,
                  'repeat_weight': item.repeat_weight,
                  'top_weight': item.top_weight,
                  'kap_weight': item.kap_weight,
                  'pie_weight': item.pie_weight,
                  'show_weight': item.showing_weight,
                  'emp_majuri': Number(item.emp_majuri),
                  'pie_labour': item.pie_labour,
                  'show_labour': item.showing_labour,
                  'manager_short_name': item.manager_short_name,
                  'employee_short_name': item.employee_short_name
                });
                page_data.sr_no += 1;
              });

              gridApi.value.setRowData(rowData.value);
            } else if(api_filter.report_type == 3) {

              items.map(item => {
                rowData.value.push({
                  'sr_no': page_data.sr_no,
                  'issue_pieces': item.issue_pieces,
                  'issue_weight': item.issue_weight,
                  'pending_pieces': item.pending_pieces,
                  'pending_weight': item.pending_weight,
                  'receive_pieces': item.receive_pieces,
                  'receive_issue_weight': item.receive_issue_weight,
                  'receive_weight': item.receive_weight,
                  'weight_loss': item.weight_loss,
                  'ls_weight': item.ls_weight,
                  'repeat_weight': item.repeat_weight,
                  'top_weight': item.top_weight,
                  'kap_weight': item.kap_weight,
                  'pie_weight': item.pie_weight,
                  'show_weight': item.showing_weight,
                  'emp_majuri': Number(item.emp_majuri),
                  'pie_labour': item.pie_labour,
                  'show_labour': item.showing_labour,
                  'manager_short_name': item.manager_short_name,
                  'employee_short_name': item.employee_short_name
                });
                page_data.sr_no += 1;
              });

              gridApi.value.setRowData(rowData.value);
            } else if(api_filter.report_type == 4) {

              items.map(item => {
                rowData.value.push({
                  'sr_no': page_data.sr_no,
                  'transaction_code': item.transaction_code,
                  'issue_pieces': item.issue_pieces,
                  'issue_weight': item.issue_weight,
                  'pending_pieces': item.pending_pieces,
                  'pending_weight': item.pending_weight,
                  'receive_pieces': item.receive_pieces,
                  'receive_issue_weight': item.receive_issue_weight,
                  'receive_weight': item.receive_weight,
                  'weight_loss': item.weight_loss,
                  'ls_weight': item.ls_weight,
                  'repeat_weight': item.repeat_weight,
                  'top_weight': item.top_weight,
                  'kap_weight': item.kap_weight,
                  'pie_weight': item.pie_weight,
                  'show_weight': item.showing_weight,
                  'emp_majuri': Number(item.emp_majuri),
                  'pie_labour': item.pie_labour,
                  'show_labour': item.showing_labour,
                  'manager_short_name': item.manager_short_name,
                  'employee_short_name': item.employee_short_name
                });
                page_data.sr_no += 1;
              });

              gridApi.value.setRowData(rowData.value);
            }

            if(page_data.current_page == 1) {
              page_data.last_page = data.last_page
            }

            if(page_data.current_page == data.last_page) {
              page_data.in_progress = false;
            } else {
              page_data.current_page += 1;
              getReportDetail(page_data.current_page);
            }
            calculate_total()

        })
        .catch(_error => {
          // page_data.items = []
          page_data.loading = false
        });
    }
    const exportFilterData = () => {
      gridApi.value.exportDataAsCsv();
    }

    const calculate_total = () => {

      let total_jangad = 0;
      let issue_pieces = 0;
      let issue_weight = 0;
      let receive_weight = 0;
      let weight_loss = 0;
      let ls_weight = 0;
      let repeat_weight = 0;
      let top_weight = 0;
      let kap_weight = 0;
      let pie_weight = 0;
      let showing_weight = 0;
      let emp_majuri = 0;
      let pie_labour = 0;
      let showing_labour = 0;
      let items = gridApi.value.getModel().rowsToDisplay

      items.forEach(element => {

        if(api_filter.report_type == 1) {

          issue_pieces += 1;
        } else if(api_filter.report_type == 2) {

          issue_pieces += element.data.issue_pieces;
        } else if(api_filter.report_type == 3) {

          issue_pieces += element.data.issue_pieces;
        } else if(api_filter.report_type == 4) {

          issue_pieces += element.data.issue_pieces;
          total_jangad += 1;
        }

        issue_weight = parseFloat(issue_weight) + parseFloat(element.data.issue_weight ? parseFloat(element.data.issue_weight) : 0);
        receive_weight = parseFloat(receive_weight) + parseFloat(element.data.receive_weight ? parseFloat(element.data.receive_weight) : 0);
        weight_loss = parseFloat(weight_loss) + parseFloat(element.data.weight_loss ? parseFloat(element.data.weight_loss) : 0);
        ls_weight = parseFloat(ls_weight) + parseFloat(element.data.ls_weight ? parseFloat(element.data.ls_weight) : 0);
        repeat_weight = parseFloat(repeat_weight) + parseFloat(element.data.repeat_weight ? parseFloat(element.data.repeat_weight) : 0);
        top_weight = parseFloat(top_weight) + parseFloat(element.data.top_weight ? parseFloat(element.data.top_weight) : 0);
        kap_weight = parseFloat(kap_weight) + parseFloat(element.data.kap_weight ? parseFloat(element.data.kap_weight) : 0);
        pie_weight = parseFloat(pie_weight) + parseFloat(element.data.pie_weight ? parseFloat(element.data.pie_weight) : 0);
        showing_weight = parseFloat(showing_weight) + parseFloat(element.data.show_weight ? parseFloat(element.data.show_weight) : 0);
        emp_majuri = parseFloat(emp_majuri) + parseFloat(element.data.emp_majuri ? parseFloat(element.data.emp_majuri) : 0);
        pie_labour = parseFloat(pie_labour) + parseFloat(element.data.pie_labour ? parseFloat(element.data.pie_labour) : 0);
        showing_labour = parseFloat(showing_labour) + parseFloat(element.data.show_labour ? parseFloat(element.data.show_labour) : 0);

      });

        page_data.total_jangad = total_jangad
        page_data.issue_pieces = issue_pieces
        page_data.issue_weight = $h.myFloat(issue_weight)
        page_data.receive_weight = $h.myFloat(receive_weight)
        page_data.weight_loss = $h.myFloat(weight_loss)
        page_data.ls_weight = $h.myFloat(ls_weight)
        page_data.repeat_weight = $h.myFloat(repeat_weight)
        page_data.top_weight = $h.myFloat(top_weight)
        page_data.kap_weight = $h.myFloat(kap_weight)
        page_data.pie_weight = $h.myFloat(pie_weight)
        page_data.showing_weight = $h.myFloat(showing_weight)
        page_data.emp_majuri = $h.myFloat(emp_majuri)
        page_data.pie_labour = $h.myFloat(pie_labour)
        page_data.showing_labour = $h.myFloat(showing_labour)


    }

    window.getValue = function getValue(inputSelector) {
      var text = document.querySelector(inputSelector).value;
      switch (text) {
        case "none":
          return;
        case "tab":
          return "\t";
        default:
          return text;
      }
    };

    window.getParams = function getParams() {
        return {
          columnSeparator: getValue("#columnSeparator"),
        };
      };

    const getManagerDetails = async () => {
              let params = {
                  process_id: filter.process_id == '0' ? '' : window.btoa(filter.process_id)
              }
              let api_url = ''

              if (filter.process_id == '0') {

                  api_url = 'api/master/dp_managers'
              } else {
                  api_url = 'api/master/get_manager_by_department'
              }
              let promise = axios({
                  url: api_url,
                  method: "post",
                  data: params,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token")
                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {
                      filter.manager_id = '0'
                      page_data.manager_items = data.items
                  })
                  .catch(_error => {
                      filter.manager_id = '0'
                      page_data.manager_items = []
                  });
    }

    const getProcessDetails = async () => {
        let params = {
            transaction_type: 2
        }
        let api_url = ''

        if (filter.manager_id == '0') {

            api_url = 'api/master/dp_process'
        } else {
            api_url = 'api/master/dp_process'
        }
        let promise = axios({
            url: api_url,
            method: "post",
            data: params,
            baseURL: BASE_URL,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: {
                headers: {
                    Accept: "application/json"
                }
            }
        });
        return promise
            .then(result => result.data)
            .then(data => {
                filter.process_id = '0'
                page_data.process_items = data.items
            })
            .catch(_error => {
                filter.process_id = '0'
                page_data.process_items = []
            });
    }

    const getLotDetails = async () => {
        let params = {
            id: window.btoa(filter.manager_id)
        }
        let api_url = 'api/master/dp_lot_all_details'

        let promise = axios({
            url: api_url,
            method: "post",
            data: params,
            baseURL: BASE_URL,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
            },
            config: {
                headers: {
                    Accept: "application/json"
                }
            }
        });
        return promise
            .then(result => result.data)
            .then(data => {
                filter.kapan_id = '0'
                page_data.lot_items = data.items
            })
            .catch(_error => {
                filter.kapan_id = '0'
                page_data.lot_items = []
            });
    }

    const getEmployeeDetails = async () => {

      let params = {
          manager_id: filter.manager_id == '0' ? '' : filter.manager_id,
          process_id: filter.process_id == '0' ? '' : window.btoa(filter.process_id),
          employee_type: filter.employee_type == '0' ? '' : window.btoa(filter.employee_type),
      }
      let api_url = ''

      if (filter.department_id == '0') {

          api_url = 'api/master/get_employees_by_manager_employee_type'
      } else {
          api_url = 'api/master/get_employees_by_manager_employee_type'
      }
      let promise = axios({
          url: api_url,
          method: "post",
          data: params,
          baseURL: BASE_URL,
          headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
          },
          config: {
              headers: {
                  Accept: "application/json"
              }
          }
      });
      return promise
          .then(result => result.data)
          .then(data => {
              filter.employee_id = '0'
              page_data.employee_items = data.items
          })
          .catch(_error => {
              filter.employee_id = '0'
              page_data.employee_items = []
          });
    }
    function onResetFilter() {
      filter.start_date = moment().startOf('month').format('YYYY-MM-DD')
      filter.end_date = moment().endOf('month').format('YYYY-MM-DD')
      filter.party_id = []
      filter.detail_type = '1'
      filter.report_type = '1'
      filter.issue_pending = false
    }
    onMounted(() => {
      getManagerDetails()
      getProcessDetails()
      getLotDetails()
      setTimeout(() => {

        getReportDetail(1)
      }, 200);

    })
    return {
      getReportDetail, exportFilterData, page_data, filter, onResetFilter, columnDefs, rowData, overlayNoRowsTemplate, onGridReady, defaultColDef, ModuleRegistry, lotColumnDefs, api_filter,
      empColumnDefs,
jangadColumnDefs,
getEmployeeDetails,
      calculate_total, deselectRows: () =>{
        gridApi.value.deselectAll()
      }
    }
  }
})
</script>
