<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-3">
      <h2 class="text-lg font-medium mr-auto">Multiple Receive</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-4">

                <div>
                  <label for="update-profile-form-6" class="form-label"
                    >Scan Lot No</label
                  >
                  <div class="input-group mt-2">

                  <input
                    id="scan_lot_no"
                    type="text"
                    tabindex="1"
                    placeholder="Scan Barcode"
                    v-model="screenData.scan_lot_no"
                    :class="{'form-control':true,'loading':screenData.scanload}"
                    autocomplete="off"
                    @change="scanAndAddPack"
                  />

                  <button type="button" class="btn btn-primary mr-auto" @click="scanAndAddPack" style="border-radius: 0!important">
                    <SearchIcon class="w-5 h-5 mr-2" /> Search
                  </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Input -->
      </div>
    </div>
    <!-- END: HTML Table Data -->

     <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Scaned Packet History</h2>
            <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
          <button
           class="btn btn-primary shadow-md mr-2" @click="takePolishReturn" :disabled="screenData.return_progress">
           Take Return
           <LoadingIcon icon="oval" color="white" class="w-4 h-4 ml-2" :hidden="!screenData.return_progress"/>
          </button>
      </div>
          </div>

          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-4 xl:col-span-4">

                <div>
                  <label for="update-profile-form-6" class="form-label font-bold">
                    Total Scanned Pcs. :&nbsp;</label>
                    <label for="update-profile-form-6" class="form-label">{{screenData.total_scan_pack}} </label>
                </div>

              </div>
            <div class="col-span-4 xl:col-span-4">
               <div>
                  <label for="update-profile-form-6" class="form-label font-bold">
                    Total Weight (Ex PW). :&nbsp;</label>
                    <label for="update-profile-form-6" class="form-label">{{$h.myFloat(screenData.total_scan_pack_wt)}}  </label>
                </div>
            </div>

              <div class="col-span-12 ">
              <ag-grid-vue
              class="ag-theme-alpine"
              style="height: 500px"
              :columnDefs="columnDefs.value"
              :rowData="rowData.value"
              :defaultColDef="defaultColDef"
              rowSelection="multiple"
              animateRows="true"
              @grid-ready="onGridReady"
              :overlayNoRowsTemplate="overlayNoRowsTemplate"
            >
            </ag-grid-vue>
              </div>

            </div>
        </div>
        </div>
        <!-- END: Input -->
      </div>
    </div>
    <div id="receive-items-modal" ref="receive-items-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">Receive Details</h2>
          </div>
          <div class="modal-body">


            <table class="w-full border">
              <thead>
                <tr>
                  <th class="text-center">No</th>
                  <th class="text-center">Packet No</th>
                  <th class="text-center">Stock No</th>
                  <th class="text-center">Process</th>
                  <th class="text-center">Issue Weight</th>
                  <th class="text-center">Receive Weight</th>
                  <th class="text-center">Rejection Weight</th>
                  <th class="text-center">Net Rejection Weight</th>
                  <th class="text-center">Color</th>
                  <th class="text-center">Cut</th>
                  <th class="text-center">Purity</th>
                  <th class="text-center">Polish</th>
                  <th class="text-center">Shape</th>
                  <th class="text-center">Symmetry</th>
                  <th class="text-center">Status</th>
                  <th class="text-center">Message</th>
                </tr>
              </thead>
              <tbody>
                <tr class="border" v-for="(plan,index) in screenData.receive_data" :key="index">
                  <td class="text-center">
                    <p>{{ index + 1 }}</p>
                  </td>
                  <td class="text-center">{{ (plan.process ? plan.process : '-') }}</td>
                  <td class="text-center">{{ (plan.issue_weight ? plan.issue_weight : '-') }}</td>
                  <td class="text-center">{{ (plan.receive_weight ? plan.receive_weight : '-') }}</td>
                  <td class="text-center">{{ (plan.rejection_weight ? plan.rejection_weight : '-') }}</td>
                  <td class="text-center">{{ (plan.net_rejection_weight ? plan.net_rejection_weight : '-') }}</td>
                  <td class="text-center">{{ (plan.color ? plan.color : '-') }}</td>
                  <td class="text-center">{{ (plan.cut ? plan.cut : '-') }}</td>
                  <td class="text-center">{{ (plan.purity ? plan.purity : '-') }}</td>
                  <td class="text-center">{{ (plan.polish ? plan.polish : '-') }}</td>
                  <td class="text-center">{{ (plan.shape ? plan.shape : '-') }}</td>
                  <td class="text-center">{{ (plan.symmetry ? plan.symmetry : '-') }}</td>
                  <td :class="{'text-red' : plan.status_color == 'secondary','text-green' : plan.status_color == 'success'}">{{ (plan.status ? plan.status : '-') }}</td>
                  <td :class="{'text-red' : plan.status_color == 'secondary','text-green' : plan.status_color == 'success'}">{{ (plan.message ? plan.message : '-') }}</td>
                </tr>
                <tr class="border">
                  <td class="text-center"></td>
                  <td class="text-center">-</td>
                  <td class="text-center">{{ (screenData.total.issue_weight ? screenData.total.issue_weight : '-') }}</td>
                  <td class="text-center">{{ (screenData.total.receive_weight ? screenData.total.receive_weight : '-') }}</td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td class="text-center"></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>

            </table>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted,inject } from 'vue'
import feather from 'feather-icons'
import VueRouter from 'vue-router'
import { AgGridVue } from "ag-grid-vue3";
import { useRouter, useRoute } from 'vue-router'
import { BASE_URL } from '@/constants/config.js'
import ShapeRenderer from '@/custom-renderer/ShapeRenderer.js'
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
// import "styag-grid-community/les/ag-theme-alpine.css"; // Optional theme CSS

export default defineComponent({
  components: {
   AgGridVue,
  //  ShapeRenderer
 },
  setup() {
    const gridApi = ref(null);
    const router = useRouter()
    const route = useRoute()
    const swal = inject('$swal')
    const filter = reactive({
      field: 'lot_no',
      type: 'like',
      value: ''
    })

    const getRowId = (params) => {
      params.data.sr_no
      console.log(params.data.sr_no);
    }
    const screenData = reactive({
      scanload: false,
      return_progress:false,
      scan_lot_no:'',
      total_scan_pack:0,
      total_scan_pack_wt:0,
      shapes:[],
      colors:[],
      cuts:[],
      purities:[],
      polish:[],
      shape:[],
      symmetry:[],
      fluerocents:[],
      tensions:[],
      stones:[],
      receive_data: [],
      total: {
        'issue_weight': 0,
        'receive_weight': 0
      }

    })

    const onGridReady = (params) => {
     gridApi.value = params.api;
    };

   const rowData = reactive({
    value:[]
   });

   const overlayNoRowsTemplate = 'Scan packets for polish receive!'

   const columnDefs = reactive({
     value: [
          { headerName: "#",field: 'sr_no',maxWidth: 60, editable: false },
          { headerName: "Packet No",field: 'number',minWidth: 100, editable: false },
          { field: 'process', headerName: 'Proc', editable: false },
          { headerName: "Iss Wt",field: 'issue_weight', minWidth: 100, editable: false },
          // { headerName: "PL Weight",field: 'cr_polish_weight', minWidth: 100 },
          { headerName: "Rec Wt",field: 'receive_weight', minWidth: 100,editable: true },
          { headerName: "Shp",field: 'shape',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
              values: screenData.shapes,
            },

          },
          { headerName: "Clr",field: 'color',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
              values: screenData.colors,
            },

          },
          { headerName: "Prt",field: 'purity',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
              values: screenData.purities,
            },

          },
          { headerName: "Cut",field: 'cut',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
              values: screenData.cuts,
            },

          },
          { headerName: "Pl",field: 'polish',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
              values: screenData.polish,
            },

          },
          { headerName: "Symm",field: 'symmetry',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
              values: screenData.symmetry,
            },

          },
          { headerName: "Fluc",field: 'fluerocent',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
              values: screenData.fluerocents,
            },

          },
          { headerName: "Tn",field: 'tension',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
              values: screenData.tensions,
            },

          },
          // { headerName: "Stn",field: 'stone',
          //   cellEditor: 'agSelectCellEditor',
          //   cellEditorParams: {
          //     values: screenData.stones,
          //   },

          // },
     ],
   });


   const defaultColDef = {
     sortable: true,
     filter: true,
     flex: 1
   };


  function getDiamontData() {
        axios({
          url: 'api/master/get_all_parameters',
          method: 'post',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          screenData.colors = res.data.color
          screenData.cuts = res.data.cut
          screenData.purities = res.data.purity
          screenData.polish = res.data.polish
          screenData.shapes = res.data.shape
          screenData.symmetry = res.data.symmetry
          screenData.fluerocents = res.data.fluerocent
          screenData.tensions = res.data.tension
          screenData.stones = res.data.stone
          // pagedata.labs = res.data.lab
        })
      }
    //Edit user
    const scanAndAddPack = () => {
      let codelength = screenData.scan_lot_no.trim().length;
        screenData.scanload = true;
        //Check already in array

      //get lot details
      const data = {
      barcode: screenData.scan_lot_no,
      };
      let promise = axios({
        url: "api/process/get_packet_details_for_recive",
        method: "post",
        data: data,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token"),
 },
        config: { headers: { Accept: "application/json" } },
      });
      return promise
        .then((result) => result.data)
        .then((data) => {
        console.log(data);
        if(data.packet_details == "NO"){
            swal("Warning!", data.error_msg, "warning");
            screenData.scanload = false;
            document.querySelector('[id="scan_lot"]').focus();
        }else {

          let pack_details = data.packet_details;
          var i = rowData.value.length;
              while(i--){
              if(rowData.value[i]['number'] == pack_details.number ){
                screenData.scan_lot_no = "";
                swal("Warning!", "Already in list, Scan other packet!", "warning");
                screenData.scanload = false;
                return;
              }
        }

            screenData.total_scan_pack = screenData.total_scan_pack + 1;

            rowData.value.push(
            {
              "sr_no":screenData.total_scan_pack,
              "number":pack_details.number,
              "barcode": pack_details.barnum,
              "packet_id": pack_details.id,
              "last_history_id": pack_details.transaction_id,
              "process": pack_details.process_short_code,
              "issue_weight": pack_details.issue_weight,
              "cr_wt" : pack_details.cr_weight,
              "shape" : pack_details.shape,
              "purity" : pack_details.purity,
              "color" : pack_details.color,
              "cut" : pack_details.cut,
              "polish" : pack_details.polish,
              "symmetry" : pack_details.symmetry,
              "fluerocent" : pack_details.fluerocent,
              "tension" : pack_details.tension,
              "ex_pw" : pack_details.cr_polish_weight,
              "receive_weight" : '',

            });
            //gridApi.value.setRowData([]);
            gridApi.value.setRowData(rowData.value);

            screenData.scan_lot_no = "";
            screenData.total_scan_pack_wt = parseFloat(screenData.total_scan_pack_wt) + parseFloat(pack_details.cr_polish_weight);
            screenData.scanload = false;

            focusLstRow();
            console.log(rowData.value);
            document.querySelector('[id="scan_lot"]').focus();
        }
        })
        .catch((_error) => {
          screenData.scanload = false;
          return [];
        });
    }

    const focusLstRow = () => {
      document.querySelector('[id="scan_lot_no"]').focus();
    }


    const takePolishReturn = () => {
      console.log(rowData.value);
      if(rowData.value.length == 0){
        swal("Error!", "Kindly select packets for return, Packet count is zero", "error");
        return;
      }
      const data = {
        packet_details: rowData.value,
      };

console.log(data);

      //screenData.return_progress = true;
      swal({
        title: "Are you sure?",
        text: "You are about to take return entry!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Return",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        preConfirm: login => {

          let promise = axios({
            url: "api/process/receive_multiple_packet",
            method: "post",
            data: data,
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),

            },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data)
            .then(data => {
              if(data.status == "success"){
                // swal("Success!", "Item Returned to Manager.", "success");
                //screenData.return_progress = false;
                screenData.receive_data = data.items
                screenData.total.issue_weight = 0
                screenData.total.receive_weight = 0

                screenData.receive_data.forEach(element => {
                    screenData.total.issue_weight += element.issue_weight
                    screenData.total.receive_weight += element.receive_weight
                  });
                  screenData.total.issue_weight = $h.myFloat(screenData.total.issue_weight)
                  screenData.total.receive_weight = $h.myFloat(screenData.total.receive_weight)
                cash('#receive-items-modal').modal('show')
                // router.go();
              }else if(data.status == "error"){
                swal("Error!", data.message , "error");
                screenData.return_progress = false;
                screenData.receive_data = []
                return;
              }
            })
            .catch(_error => {
              screenData.return_progress = false;
              return [];
            });
        },
        allowOutsideClick: () => !$Swal.isLoading()
      }).then(result => {
        if (result.value) {
          screenData.return_progress = false;

        }
      });
    }

    onMounted(() => {
      getDiamontData()
     rowData.value = []
     focusLstRow()
    })


    return {
      filter,
      screenData,
      scanAndAddPack,
      onGridReady,
      takePolishReturn,
      overlayNoRowsTemplate,
      getRowId,
      columnDefs,
      rowData,
      defaultColDef,
      // cellWasClicked: (event) => { // Example of consuming Grid Event
      //   console.log("cell was clicked", event);
      // },
      deselectRows: () =>{
        gridApi.value.deselectAll()
      }
    }

  }
})
</script>

