<template>
  <div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-2">
      <div class="w-full flex justify-between gap-2">
        <div>
          <div class="grid grid-cols-12 w-full gap-5 mt-2">
            <div class="col-span-12">
                <div class="flex flex-wrap">

                    <div class="text-sm font-bold mr-6">Issue Pieces: {{ page_data.issue_pieces ? page_data.issue_pieces : 0 }}</div>
                    <div class="text-sm font-bold mr-6">Issue Weight: {{ page_data.issue_weight ? page_data.issue_weight : 0 }} Ct</div>
                    <div class="text-sm font-bold mr-6">Receive Weight: {{ page_data.receive_weight ? page_data.receive_weight : 0 }} Ct</div>
                    <div class="text-sm font-bold mr-6">Loss Weight: {{ page_data.lose_weight ? page_data.lose_weight : 0 }} Ct</div>
                    <div class="text-sm font-bold mr-6">Total Labour: {{ page_data.total_labour ? page_data.total_labour : 0 }}</div>
                </div>
            </div>
          </div>
        </div>
        <div class="flex gap-2">
          <a
          class="btn btn-primary p-1  rounded-sm text-right"
          href="javascript:;"
          title="Add Comment"
          data-toggle="modal"
          data-target="#filter-modal" :disabled="api_filter.in_progress"
          >Filters</a
        >
        <a class="btn btn-secondary p-1 rounded-sm text-right" @click="exportFilterData"
          >Export</a
        >
        </div>
      </div>
      <div class="progress w-1/2 h-4 rounded">
          <div
            class="progress-bar bg-theme-1 rounded" :style="{width: page_data.progress + '%'}"
            role="progressbar"
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            {{ page_data.progress}} %
          </div>
        </div>
      <div>
        <!-- BEgin: Process Report Start-->
        <div
          id="responsive-table"
          class="p-5"
          :class="{ hidden: page_data.loading == false }"
        >
          <div class="preview">
            <div class="overflow-x-auto">
              <div
                class="col-span-6 sm:col-span-3 flex flex-col justify-end items-center"
              >
                <LoadingIcon icon="bars" class="w-8 h-8" />
                <div class="text-center text-xs mt-2">Getting Latest Price</div>
              </div>
            </div>
          </div>
        </div>

        <div :class="{ hidden: page_data.loading }">
          <div class="mt-5">
            <div class="scrollbar-hidden">
              <div class="intro-y col-span-12 overflow-auto">
                <ag-grid-vue
                  class="ag-theme-alpine"
                  style="height: 600px"
                  :columnDefs="columnDefs.value"
                  :rowData="rowData.value"
                  :defaultColDef="defaultColDef"
                  rowSelection="multiple"
                  animateRows="true"
                  @grid-ready="onGridReady"
                  @filterChanged="calculate_total"
                  :overlayNoRowsTemplate="overlayNoRowsTemplate"
                >
                </ag-grid-vue>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <!-- BEGIN:Modal Filter -->
    <div id="filter-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-xxl">
        <div class="modal-content">
          <a data-dismiss="modal" href="javascript:;">
            <XIcon class="w-8 h-8 text-gray-500" />
          </a>
          <div class="modal-body p-10">
            <!-- <div id="vertical-form"> -->
            <div class="flex flex-col justify-between mt-5">
              <!-- BEGIN: Data List -->
              <div class="intro-y overflow-auto">
                <div class="flex items-center">
                  <h2 class="intro-y text-lg font-medium mr-5">Filters</h2>
                </div>
              </div>
              <div class="grid grid-cols-12 gap-x-2">
                <div class="col-span-6 md:col-span-4 mt-2">
                  <div>
                    <label for="update-profile-form-6" class="form-label py-0"
                      >Packet / Lot</label
                    >
                    <input
                      id="tabulator-html-filter-value"
                      v-model="filter.packet_no"
                      type="text"
                      class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
                      placeholder="Search Packet / Kapan"
                    />
                  </div>
                </div>
                <div class="col-span-6 md:col-span-4 mt-2">
                  <div>
                    <label for="update-profile-form-6" class="form-label py-0"
                      >Select Kapan</label
                    >
                    <TomSelect
                      v-model="filter.kapan_id"
                      :options="{
                        placeholder: 'Select Kapan',
                        loadingClass: 'loading',
                        plugins: {
                          dropdown_header: {
                            title: 'Select Kapan'
                          }
                        }
                      }"
                      class="w-full" multiple
                    >
                      <option
                        v-for="item in page_data.lot_items"
                        :key="item.id"
                        :value="item.id"
                        >{{ item.lot_no }} (
                        {{ item.voucher_no ? item.voucher_no : '-' }} )</option
                      >
                    </TomSelect>
                  </div>
                </div>
                <div class="col-span-6 md:col-span-4 mt-2">
                  <div>
                    <label for="update-profile-form-6" class="form-label py-0"
                      >Select Party</label
                    >
                    <TomSelect
                      v-model="filter.party_id"
                      :options="{
                        placeholder: 'Select Party',
                        loadingClass: 'loading',
                        plugins: {
                          dropdown_header: {
                            title: 'Party'
                          }
                        }
                      }"
                      class="w-full" multiple
                    >
                      <option
                        v-for="party in page_data.parties"
                        :key="party.id"
                        :value="party.id"
                        >{{ party.first_name }} {{ party.last_name }}</option
                      >
                    </TomSelect>
                  </div>
                </div>
                <div class="col-span-6 md:col-span-4 mt-2" v-if="user_details.user_role == 1">
                  <div>
                    <label for="update-profile-form-6" class="form-label py-0"
                      >Select Department</label
                    >
                    <TomSelect
                      v-model="filter.department_id"
                      :options="{
                        placeholder: 'Select Department',
                        loadingClass: 'loading',
                        plugins: {
                          dropdown_header: {
                            title: 'Department'
                          }
                        }
                      }"
                      class="w-full" multiple
                    > <option
                        v-for="department in page_data.departments"
                        :key="department.id"
                        :value="department.id"
                        >{{ department.department_name }} (
                        {{
                          department.department_short_code
                            ? department.department_short_code
                            : '-'
                        }}
                        )</option
                      >
                    </TomSelect>
                  </div>
                </div>
                <div class="col-span-6 md:col-span-4 mt-2">
                  <div>
                    <label for="update-profile-form-6" class="form-label py-0"
                      >Select Process</label
                    >
                    <TomSelect
                      v-model="filter.process_id"
                      :options="{
                        placeholder: 'Select Process',
                        loadingClass: 'loading',
                        plugins: {
                          dropdown_header: {
                            title: 'Process'
                          }
                        }
                      }"
                      class="w-full" multiple @change="get_employee_list"
                    > <option
                        v-for="item in page_data.process_items"
                        :key="item.id"
                        :value="item.id"
                        >{{ item.process_name }} (
                        {{
                          item.process_short_code
                            ? item.process_short_code
                            : '-'
                        }}
                        )</option
                      >
                    </TomSelect>
                  </div>
                </div>
                <div
                  class="col-span-6 md:col-span-4 mt-2"
                  v-if="user_details.user_role == 1"
                >
                  <div>
                    <label for="update-profile-form-6" class="form-label py-0"
                      >Select Manager</label
                    >
                    <TomSelect
                      v-model="filter.manager_id"
                      @change="get_employee_list"
                      :options="{
                        placeholder: 'Select Manager',
                        loadingClass: 'loading',
                        plugins: {
                          dropdown_header: {
                            title: 'Manager'
                          }
                        }
                      }"
                      class="w-full" multiple
                    >
                      <option
                        v-for="item in page_data.manager_items"
                        :key="item.id"
                        :value="item.id"
                        >{{ item.first_name }} {{ item.last_name }} (
                        {{ item.short_code ? item.short_code : '-' }} )</option
                      >
                    </TomSelect>
                  </div>
                </div>
                <div class="col-span-6 md:col-span-4 mt-2">
                  <div>
                    <label for="update-profile-form-6" class="form-label py-0"
                      >Karigar Type</label
                    >
                    <TomSelect
                      v-model="filter.employee_type"
                      @change="get_employee_list"
                      :options="{
                        placeholder: 'Select Karigar Type',
                        loadingClass: 'loading',
                        plugins: {
                          dropdown_header: {
                            title: 'Karigar Type'
                          }
                        }
                      }"
                      class="w-full"
                    >
                      <option value="0">Karigar Type</option>
                      <option value="1">Employee</option>
                      <option value="2">Planner / Checker</option>
                      <option value="3">Job Party</option>
                    </TomSelect>
                  </div>
                </div>
                <div
                  class="col-span-6 md:col-span-4 mt-2"
                  v-if="filter.employee_type != '0'"
                >
                  <div>
                    <label for="update-profile-form-6" class="form-label py-0"
                      >Select Employee</label
                    >
                    <TomSelect
                      v-model="filter.employee_id"
                      :options="{
                        placeholder: 'Select Employee',
                        loadingClass: 'loading',
                        plugins: {
                          dropdown_header: {
                            title: 'Employee'
                          }
                        }
                      }"
                      class="w-full" multiple
                    >
                      <option
                        v-for="item in page_data.employee_items"
                        :key="item.id"
                        :value="item.id"
                        >{{ item.first_name }} {{ item.last_name }} (
                        {{ item.process_short_code ? item.short_code : '-' }}
                        )</option
                      >
                    </TomSelect>
                  </div>
                </div>
                <div class="col-span-6 md:col-span-4 mt-2">
                  <div>
                    <label for="update-profile-form-6" class="form-label py-0"
                      >Select Type</label
                    >
                    <TomSelect
                      v-model="filter.packet_type"
                      :options="{
                        placeholder: 'Select Type',
                        loadingClass: 'loading',
                        plugins: {
                          dropdown_header: {
                            title: 'Type'
                          }
                        }
                      }"
                      class="w-full"
                    >
                      <option value="0">Select Type</option>
                      <option value="makeable">Makeable</option>
                      <option value="rough">Rough</option>
                    </TomSelect>
                  </div>
                </div>
                <div class="col-span-6 md:col-span-4 mt-2">
                  <div>
                    <label for="update-profile-form-6" class="form-label py-0"
                      >Stock Type</label
                    >
                    <TomSelect
                      v-model="filter.stone_type"
                      :options="{
                        placeholder: 'Stock Type',
                        loadingClass: 'loading',
                        plugins: {
                          dropdown_header: {
                            title: 'Stock Type'
                          }
                        }
                      }"
                      class="w-full"
                    >
                      <option value="0">All</option>
                      <option value="1">Home Party</option>
                      <option value="2">Job Work</option>
                    </TomSelect>
                  </div>
                </div>
                <div class="col-span-6 md:col-span-4 mt-2">
                  <div>
                    <label for="update-profile-form-6" class="form-label py-0"
                      >Stock Status</label
                    >
                    <TomSelect
                      v-model="filter.process_status"
                      :options="{
                        placeholder: 'Process Status',
                        loadingClass: 'loading',
                        plugins: {
                          dropdown_header: {
                            title: 'Stock Status'
                          }
                        }
                      }"
                      class="w-full"
                    >
                      <option value="0">All</option>
                      <option value="1">In Process</option>
                      <option value="2">Received</option>
                      <option value="3">Cancelled</option>
                    </TomSelect>
                  </div>
                </div>
                <div class="col-span-6 md:col-span-4 mt-2">
                  <div>
                    <label for="update-profile-form-6" class="form-label py-0"
                      >Date Type</label
                    >
                    <TomSelect
                      v-model="filter.date_type"
                      :options="{
                        placeholder: 'Date Type',
                        loadingClass: 'loading',
                        plugins: {
                          dropdown_header: {
                            title: 'Date Type'
                          }
                        }
                      }"
                      class="w-full"
                    > <option value="1">Issue Date</option>
                      <option value="2">Receive Date</option>
                      <option value="3">Cancelle Date</option>
                    </TomSelect>
                  </div>
                </div>
                <div class="col-span-6 md:col-span-4 mt-2">
                  <div>
                    <div class="flex items-center">
                      <label for="update-profile-form-6" class="form-label py-0"
                        >Select Start Date</label
                      ><input
                        class="show-code form-check-switch mr-0 ml-3 h-5"
                        type="checkbox"
                        v-model="filter.issue_pending"
                      />
                    </div>
                    <input
                      type="date"
                      id="update-profile-form-13"
                      :disabled="filter.issue_pending"
                      class="form-control"
                      placeholder=""
                      v-model="filter.start_date"
                    />
                  </div>
                </div>
                <div class="col-span-6 md:col-span-4 mt-2">
                  <div>
                    <label for="update-profile-form-6" class="form-label py-0"
                      >Select End Date</label
                    >
                    <input
                      type="date"
                      id="update-profile-form-13"
                      class="form-control"
                      placeholder=""
                      :disabled="filter.issue_pending"
                      v-model="filter.end_date"
                    />
                  </div>
                </div>
                <div class="col-span-2 md:col-span-2 xl:col-span-1">
                  <div class="mt-7 flex">
                    <button
                      id="tabulator-html-filter-reset"
                      type="button"
                      class="btn btn-secondary w-full mt-2 sm:mt-0 sm:ml-2"
                      @click="onResetFilter"
                    >
                      Reset
                    </button>
                  </div>
                </div>
                <div class="col-span-2 md:col-span-2 xl:col-span-1">
                  <div class="mt-7 flex">
                    <button
                      id="tabulator-html-filter-go"
                      type="button"
                      class="btn btn-primary w-full ml-2"
                      tabindex="5"
                      @click="getReportDetails(1)"
                    >
                      Generate
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- END:Modal Filter -->
  </div>
</template>

<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config'
import axios from 'axios'
import {
  defineComponent,
  onMounted,
  reactive,
  toRef,ref,
  watch,
  computed
} from 'vue'
import store from '@/store'

import { AgGridVue } from "ag-grid-vue3";
import {
  onGridReady,
  ModuleRegistry
} from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { CsvExportModule } from "@ag-grid-community/csv-export";
import { ClipboardModule } from "@ag-grid-enterprise/clipboard";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { MultiFilterModule } from "@ag-grid-enterprise/multi-filter";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import moment from 'moment';
import { helper as $h} from '@/utils/helper'

import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
// import "styag-grid-community/les/ag-theme-alpine.css"; // Optional theme CSS

export default defineComponent({
  components: {
   AgGridVue,
  //  ShapeRenderer
 },
  setup() {

    const user_details = computed(() => store.state.user.currentUser)
    const page_data = reactive({
      total: [],
      items: [],
      main_tbl: [],
      manager_items: [],
      process_items: [],
      departments: [],
      parties: [],
      lot_items: [],
      employee_items: [],
      loading: false,
      in_progress: false,
      progress: 0,
      current_page: 1,
      total_records: 0,
      sr_no: 1,
      last_page: "",
      per_page: 5000,
      issue_pieces: 0,
      issue_weight: 0,
      lose_weight: 0,
      total_labour: 0,
      receive_weight: 0,
      assortment_weight: 0,
      new_assortment_weight: 0,
    })

    const filter = reactive({
      packet_no: "",
      kapan_id: [],
      party_id: [],
      process_id: [],
      department_id: [],
      manager_id: [],
      employee_type: "0",
      employee_id: [],
      packet_type: "0",
      stone_type: "0",
      issue_pending: false,
      process_status: "0",
      date_type: "1",
      start_date: moment().startOf('month').format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD')
    })

    const api_filter = reactive({
      packet_no: "",
      kapan_id: [],
      party_id: [],
      process_id: [],
      department_id: [],
      manager_id: [],
      employee_type: "0",
      employee_id: [],
      packet_type: "0",
      stone_type: "0",
      issue_pending: false,
      process_status: "0",
      date_type: "1",
      start_date: moment().startOf('month').format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
      enable: false
    })

    const gridApi = ref();

    const rowData = reactive({
      value:[]
    });

    const onGridReady = (params) => {
    gridApi.value = params.api;
    };

    const defaultColDef = {
      sortable: true,
      filter: true,
      flex: 1,
      minWidth: 100,
      floatingFilter: true,
      menuTabs: ["filterMenuTab"],
    };

    ModuleRegistry.registerModules([
      ClientSideRowModelModule,
      CsvExportModule,
      ClipboardModule,
      MenuModule,
      MultiFilterModule,
      SetFilterModule,
    ]);

    const overlayNoRowsTemplate = 'No Records Found!'

    const columnDefs = reactive({
      value: [
      { headerName: "#",field: 'sr_no',maxWidth: 100 },
      { headerName: "Packet No",field: 'number',minWidth: 150},
      { headerName: "Stock No",field: 'vepari_no',minWidth: 150},
      { headerName: "Process",field: 'process_short_code',minWidth: 150},
      { headerName: "Type",field: 'packet_type',minWidth: 150},
      { headerName: "Issue Ct",field: 'issue_weight',minWidth: 150},
      { headerName: "Receive Ct",field: 'receive_weight',minWidth: 150},
      { headerName: "Loss  Ct",field: 'lose_weight',minWidth: 150},
      { headerName: "Dept",field: 'department_short_code',minWidth: 150},
      { headerName: "Manager",field: 'manager_short_name',minWidth: 150},
      { headerName: "Emp",field: 'employee_short_name',minWidth: 150},
      { headerName: "Stone",field: 'stone',minWidth: 100},
      { headerName: "Shape",field: 'shape',minWidth: 100},
      { headerName: "Color",field: 'color',minWidth: 100},
      { headerName: "Purity",field: 'purity',minWidth: 100},
      { headerName: "Cut",field: 'cut',minWidth: 100},
      { headerName: "Polish",field: 'polish',minWidth: 100},
      { headerName: "Symm",field: 'symmetry',minWidth: 100},
      { headerName: "Rate",field: 'emp_rate',minWidth: 100},
      { headerName: "Total Majuri",field: 'emp_majuri',minWidth: 100},
      { headerName: "Issue Date",field: 'issue_date',minWidth: 130},
      { headerName: "Receive Date",field: 'receive_date',minWidth: 130},
      { headerName: "Status",field: 'status',minWidth: 100},
      // { headerName: "Party",field: 'party_name',minWidth: 100},
      // { headerName: "Pieces",field: 'total_pieces',minWidth: 100},
      // { headerName: "Rough(Ct)",field: 'rough_weight',minWidth: 100},
      // { headerName: "Polish(Ct)",field: 'polish_weight',minWidth: 100},
      // { headerName: "Final(Ct)",field: 'total_weight',minWidth: 100},
      // { headerName: "Total Price",field: 'total_amount',minWidth: 100},
      // { headerName: "Date",field: 'outward_date',minWidth: 100},
      ],
    });

    const getReportDetails = (index) => {



      page_data.current_page = index
      if(page_data.current_page == 1) {
        api_filter.packet_no  = filter.packet_no;
        api_filter.kapan_id  = filter.kapan_id;
        api_filter.party_id  = filter.party_id;
        api_filter.process_id  = filter.process_id;
        api_filter.department_id  = filter.department_id;
        api_filter.manager_id  = filter.manager_id;
        api_filter.employee_type  = filter.employee_type;
        api_filter.employee_id  = filter.employee_id;
        api_filter.packet_type  = filter.packet_type;
        api_filter.stone_type  = filter.stone_type;
        api_filter.issue_pending  = filter.issue_pending;
        api_filter.process_status  = filter.process_status;
        api_filter.date_type  = filter.date_type;
        api_filter.start_date  = filter.start_date;
        api_filter.end_date  = filter.end_date;
        api_filter.enable  = true;

        rowData.value = []
        gridApi.value.setRowData(rowData.value);
        page_data.loading = true
        page_data.sr_no = 1;

        cash('#filter-modal').modal('hide')
      }
      page_data.in_progress = true


      const params = {
        packet_no : api_filter.packet_no,
        kapan_id : api_filter.kapan_id,
        party_id : api_filter.party_id,
        process_id : api_filter.process_id,
        department_id : api_filter.department_id,
        manager_id : api_filter.manager_id,
        employee_type : (api_filter.employee_type != "0" ? api_filter.employee_type : ""),
        employee_id : api_filter.employee_id,
        packet_type : (api_filter.packet_type != "0" ? api_filter.packet_type : "" ),
        stone_type : (api_filter.stone_type != "0" ? api_filter.stone_type : "" ),
        issue_pending : api_filter.issue_pending,
        process_status : (api_filter.process_status != "0" ? api_filter.process_status : ""),
        date_type : api_filter.date_type,
        start_date : api_filter.start_date,
        end_date : api_filter.end_date,
        page : page_data.current_page,
        size : page_data.per_page
      }

      let promise = axios({
        url: 'api/report/get_process_report_details',
        method: "post",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),

        },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
      .then(result => result.data)
      .then(data => {

        page_data.loading = false

        let items = data.data

        items.map(item => {
          rowData.value.push({
            'transaction_code': item.transaction_code,
            'id': item.id,
            'packet_type': item.packet_type,
            'number': item.number,
            'vepari_no': item.vepari_no,
            'department_short_code': item.department_short_code,
            'process_short_code': item.process_short_code,
            'process_type': item.process_type,
            'issue_weight': item.issue_weight,
            'receive_weight': item.receive_weight,
            'lose_weight': item.lose_weight,
            'issue_polish_weight': item.issue_polish_weight,
            'issue_comment': item.issue_comment,
            'receive_comment': item.receive_comment,
            'receive_polish_weight': item.receive_polish_weight,
            'assortment_weight': item.assortment_weight,
            'net_assortment_weight': item.net_assortment_weight,
            'issue_date': item.issue_date,
            'receive_date': item.receive_date,
            'employee_short_name': item.employee_short_name,
            'manager_short_name': item.manager_short_name,
            // 'params': ((item.shape ? item.shape : '-') + ', ' + (item.color ? item.color : '-') + ', ' + (item.purity ? item.purity : '-') + ', ' + (item.cut ? item.cut : '-') + ', ' + (item.polish ? item.polish : '-') + ', ' + (item.symmetry ? item.symmetry : '-') + ', ' + (item.fluerocent ? item.fluerocent : '-') + ', ' + (item.tension ? item.tension : '-') + ', ' + (item.stone ? item.stone : '-')),
            'color': item.color,
            'cut': item.cut,
            'purity': item.purity,
            'polish': item.polish,
            'shape': item.shape,
            'tension': item.tension,
            'symmetry': item.symmetry,
            'fluerocent': item.fluerocent,
            'stone': item.stone,
            'emp_rate': item.emp_rate,
            'emp_majuri': item.emp_majuri,
            'status': (item.status == 1 ?  "In  Process" : (item.status == 2 ? "Received" : "Cancelled")),
            'sr_no' : page_data.sr_no,
            });
            page_data.sr_no += 1;
        });

        gridApi.value.setRowData(rowData.value);
        calculate_total()

          if(page_data.current_page == 1) {
            page_data.last_page = data.last_page
            page_data.total_records = data.total
          }

          if(page_data.current_page == data.last_page) {
            page_data.in_progress = false;
            api_filter.enable  = false
          } else {
            page_data.current_page += 1;
            getReportDetails(page_data.current_page);
          }

      })
      .catch(_error => {
        // page_data.items = []
        page_data.loading = false
      });
    }

    const calculate_total = () => {

      let issue_pieces = 0;
      let issue_weight = 0;
      let receive_weight = 0;
      let lose_weight = 0;
      let total_labour = 0;
      let assortment_weight = 0;
      let new_assortment_weight = 0;

      rowData.value.forEach(element => {

        issue_pieces += 1;
        issue_weight = (parseFloat(issue_weight) + parseFloat(element.issue_weight ? element.issue_weight : 0))
        receive_weight = (parseFloat(receive_weight) + parseFloat(element.receive_weight ? element.receive_weight : 0))
        lose_weight = (parseFloat(lose_weight) + parseFloat(element.lose_weight ? element.lose_weight : 0))
        total_labour = (parseFloat(total_labour) + parseFloat(element.emp_majuri ? element.emp_majuri : 0))
        assortment_weight = (parseFloat(assortment_weight) + parseFloat(element.assortment_weight ? element.assortment_weight : 0))
        new_assortment_weight = (parseFloat(new_assortment_weight) + parseFloat(element.new_assortment_weight ? element.new_assortment_weight : 0))

      });

      page_data.issue_pieces = $h.myFloat(issue_pieces);
      page_data.issue_weight = $h.myFloat(issue_weight);
      page_data.receive_weight = $h.myFloat(receive_weight);
      page_data.lose_weight = $h.myFloat(lose_weight);
      page_data.total_labour = $h.myFloat(total_labour);
      page_data.assortment_weight = $h.myFloat(assortment_weight);
      page_data.new_assortment_weight = $h.myFloat(new_assortment_weight);

      page_data.progress = page_data.total_records ? parseFloat(page_data.issue_pieces * 100 / page_data.total_records).toFixed(2) : 0
    }

    function onResetFilter() {
      filter.packet_no = "";
      filter.kapan_id = [];
      filter.party_id = [];
      filter.process_id = [];
      filter.department_id = [];
      filter.manager_id = [];
      filter.employee_type = "0";
      filter.employee_id = [];
      filter.packet_type = "0";
      filter.stone_type = "0";
      filter.issue_pending = false;
      filter.process_status = "0";
      filter.date_type = "1";
      filter.start_date = moment()
        .startOf('month')
        .format('YYYY-MM-DD');
        filter.end_date = moment().format('YYYY-MM-DD')
    }
    const exportFilterData  = () => {
      gridApi.value.exportDataAsCsv();
    }
    const get_manager_list = () => {
      let params = {
      }
      let api_url = 'api/master/dp_managers'
      let promise = axios({
        url: api_url,
        method: 'post',
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            Accept: 'application/json'
          }
        }
      })
      return promise
        .then(result => result.data)
        .then(data => {
          filter.manager_id = []
          page_data.manager_items = data.items
        })
        .catch(_error => {
          filter.manager_id = []
          page_data.manager_items = []
        })
    }
    const get_department_list = () => {
      let params = {
        // department_id : window.btoa(filter.department_id)
      }
      let api_url = 'api/master/dp_department'
      let promise = axios({
        url: api_url,
        method: 'post',
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: { headers: { Accept: 'application/json' } }
      })
      return promise
        .then(result => result.data)
        .then(data => {
          filter.department_id = []
          page_data.departments = data.items
        })
        .catch(_error => {
          filter.department_id = []
          page_data.departments = []
        })
    }
    const get_process_list = () => {

      let params = {
        id: window.btoa(filter.manager_id)
      }
      let api_url = ''

      if (user_details.value.user_role != 1) {
        api_url = 'api/master/get_process_by_manager_encr'
      } else {
        api_url = 'api/master/dp_process'
      }
      let promise = axios({
        url: api_url,
        method: 'post',
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            Accept: 'application/json'
          }
        }
      })
      return promise
        .then(result => result.data)
        .then(data => {
          filter.process_id = []
          page_data.process_items = data.items
        })
        .catch(_error => {
          filter.process_id = []
          page_data.process_items = []
        })
    }
    const get_employee_list = () => {
      let params = {
        manager_id: filter.manager_id,
        process_id: filter.process_id,
        employee_type: filter.employee_type == '0' ? '' : window.btoa(filter.employee_type)
      }
      let api_url = ''

      if (filter.department_id == '0') {
        api_url = 'api/master/get_employees_by_manager_employee_type_multi'
      } else {
        api_url = 'api/master/get_employees_by_manager_employee_type_multi'
      }
      let promise = axios({
        url: api_url,
        method: 'post',
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            Accept: 'application/json'
          }
        }
      })
      return promise
        .then(result => result.data)
        .then(data => {
          filter.employee_id = []
          page_data.employee_items = data.items
        })
        .catch(_error => {
          filter.employee_id = []
          page_data.employee_items = []
        })
    }
    const get_kapan_list = () => {
      let params = {
        id: window.btoa(filter.manager_id)
      }
      let api_url = 'api/master/dp_lot_all_details'

      let promise = axios({
        url: api_url,
        method: 'post',
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            Accept: 'application/json'
          }
        }
      })
      return promise
        .then(result => result.data)
        .then(data => {
          filter.kapan_id = '0'
          page_data.lot_items = data.items
        })
        .catch(_error => {
          filter.kapan_id = '0'
          page_data.lot_items = []
        })

    }
    const get_vendor_list = () => {

      let params = {
      }
      let api_url = 'api/master/dp_parties'
      let promise = axios({
        url: api_url,
        method: 'post',
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: { headers: { Accept: 'application/json' } }
      })
      return promise
        .then(result => result.data)
        .then(data => {
          page_data.parties = data.items
        })
        .catch(_error => {
          page_data.parties = []
        })
    }
    const get_parameters = ()  => {
      get_manager_list();
      get_department_list();
      get_process_list();
      // get_employee_list();
      get_kapan_list();
      get_vendor_list();
    }
    onMounted(() => {
      get_parameters();

      setTimeout(() => {

        getReportDetails(1);
      }, 1000);
    })

    return {
      user_details,
      page_data,
      filter,
      api_filter,
      gridApi,
      rowData,
      onGridReady,
      defaultColDef,
      overlayNoRowsTemplate,
      columnDefs,
      getReportDetails,
      onResetFilter,
      // get_manager_list,
      // get_department_list,
      // get_process_list,
      get_employee_list,
      calculate_total,
      exportFilterData,
      get_vendor_list,
      deselectRows: () =>{
          gridApi.value.deselectAll()
        }
    }
  }
})

</script>
