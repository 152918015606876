<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">{{ pagedata.user_data.id ? pagedata.user_data.first_name + ' ' + pagedata.user_data.last_name + ' (' + pagedata.user_data.short_name + ') ' : 'Vendor Rates' }}</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
          <router-link
          :to="{ name: 'create-vendor-rates' }"
          tag="button"
           class="btn btn-primary shadow-md mr-2">

          New Rate
          </router-link>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div id="tabulator-html-filter-form" class="xl:flex sm:mr-auto">
          <div class="flex w-full">
            <div class="input-group mt-2 sm:mt-0 mr-3">
              <div id="input-group-3" class="input-group-text w-48 text-center">Process Type</div>
              <select
                class="form-select"
                v-model="formData.process_type"
                @change="initTabulator"
                aria-label="Default select example"
              >
                <option value="0">All</option>
                <option value="makeable">Makeable</option>
                <option value="rough">Rough</option>
                <option value="video">Video</option>
                <option value="lab">LAB</option>
              </select>
            </div>
            <div class="input-group mt-2 sm:mt-0">
              <div id="input-group-3" class="input-group-text w-36 text-center">Min Weight</div>
              <input
                type="text"
                v-model="pagedata.min_value"
                class="form-control w-40"
                placeholder="Min Value"
                :disabled="pagedata.update_min_wt == false"
                aria-describedby="input-group-4"
              />
            </div>
            <div>
              <button class="ml-3 btn btn-primary" @click="pagedata.update_min_wt = true" v-if="pagedata.update_min_wt == false">Edit</button>
              <button class="ml-3 btn btn-primary" v-if="pagedata.update_min_wt == true" @click="EditValue">Update</button>
              <button class="ml-1 btn btn-danger" @click="cancelUpdateValue" v-if="pagedata.update_min_wt == true">Cancel</button>
            </div>
          </div>

        </div>
        <div class="flex mt-5 sm:mt-0">

          <div class="dropdown w-1/2 sm:w-auto">
            <button
              class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto"
              aria-expanded="false"
            >
              <FileTextIcon class="w-4 h-4 mr-2" /> Export
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-menu w-40">
              <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                <a
                  id="tabulator-export-csv"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportCsv"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export CSV
                </a>
                <a
                  id="tabulator-export-json"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportJson"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export JSON
                </a>
                <a
                  id="tabulator-export-xlsx"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportXlsx"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX
                </a>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>

</template>

<script>
/* eslint-disable */
import { defineComponent, ref,onMounted, reactive, inject } from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import { useRouter, useRoute } from 'vue-router'
import { BASE_URL } from '@/constants/config.js'

export default defineComponent({
  setup() {
    const router = useRouter()
    const route = useRoute()
    const tableRef = ref()
    const tabulator = ref()
    const swal = inject('$swal')

    const pagedata = reactive({
      user_data: [],
      update_min_wt: false,
      min_value: '',
      party_id: route.params.id
    })
    const formData = reactive({
      process_type : '0',
      process_id : '0',
      processes: []
    })
    const initTabulator = () => {
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: BASE_URL + 'api/master/get_party_rates',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxParams: {
          process_type : formData.process_type == '0' ? '' : formData.process_type,
          party_id: pagedata.party_id
        },
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 20,
        paginationSizeSelector: [10, 20, 30, 40],
        layout: 'fitColumns',
        headerSort: false,
        responsiveLayout: 'collapse',
        placeholder: 'No matching records found',
        columns: [
          // For HTML table
          {
            title: 'Type',
            responsive: 0,
            minWidth: 100,
            field: 'type',
            vertAlign: 'middle',
            print: false,
            download: false,

          },
          {
            title: 'Rates',
            responsive: 0,
            minWidth: 100,
            field: 'rate',
            vertAlign: 'middle',
            print: false,
            download: false,

          },
          {
            title: 'From',
            responsive: 0,
            minWidth: 50,
            field: 'from',
            vertAlign: 'middle',
            print: false,
            download: false,

          },
          {
            title: 'To',
            responsive: 0,
            field: 'to',
            minWidth: 50,
            vertAlign: 'middle',
            print: false,
            download: false,

          },
          // {
          //   title: 'Type',
          //   responsive: 0,
          //   field: 'rate_type',
          //   minWidth: 100,
          //   vertAlign: 'middle',
          //   print: false,
          //   download: false,
          //   formatter(cell) {
          //     if(cell.getData().rate_type == 1) {
          //       return 'Issue Weight'
          //     } else if(cell.getData().rate_type == 2) {
          //       return 'Fixed Piece'
          //     } else if(cell.getData().rate_type == 3) {
          //       return 'Weight + Weight'
          //     } else if(cell.getData().rate_type == 4) {
          //       return 'Piece + Piect'
          //     } else if(cell.getData().rate_type == 5) {
          //       return 'Weight Loss'
          //     }
          //   }
          // },
          {
            title: 'Cl',
            responsive: 0,
            field: 'color',
            // minWidth: 100,
            vertAlign: 'middle',
            print: false,
            download: false,

          },
          {
            title: 'Pt',
            responsive: 0,
            field: 'purity',
            // minWidth: 100,
            vertAlign: 'middle',
            print: false,
            download: false,

          },
          {
            title: 'Sp',
            responsive: 0,
            field: 'shape',
            // minWidth: 100,
            vertAlign: 'middle',
            print: false,
            download: false,

          },
          {
            title: 'Ct',
            responsive: 0,
            field: 'cut',
            // minWidth: 100,
            vertAlign: 'middle',
            print: false,
            download: false,

          },
          {
            title: 'Pl',
            responsive: 0,
            field: 'polish',
            // minWidth: 100,
            vertAlign: 'middle',
            print: false,
            download: false,

          },
          {
            title: 'Sm',
            responsive: 0,
            field: 'symmetry',
            // minWidth: 100,
            vertAlign: 'middle',
            print: false,
            download: false,

          },
          {
            title: 'Fl',
            responsive: 0,
            field: 'fluerocent',
            // minWidth: 100,
            vertAlign: 'middle',
            print: false,
            download: false,

          },
          {
            title: 'Tn',
            responsive: 0,
            field: 'tension',
            // minWidth: 100,
            vertAlign: 'middle',
            print: false,
            download: false,

          },
          {
            title: 'St',
            responsive: 0,
            field: 'stone',
            // minWidth: 100,
            vertAlign: 'middle',
            print: false,
            download: false,

          },
          {
            title: 'Start',
            responsive: 0,
            minWidth:80,
            field: 'start_time',
            // minWidth: 100,
            vertAlign: 'middle',
            print: false,
            download: false,

          },
          {
            title: 'End',
            minWidth:80,
            responsive: 0,
            field: 'end_time',
            // minWidth: 100,
            vertAlign: 'middle',
            print: false,
            download: false,

          },
          {
            title: 'Status',
            minWidth: 100,
            field: 'active_status',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div class="flex lg:justify-center ${
                cell.getData().active_status == 1 ? 'text-theme-9' : 'text-theme-6'
              }">
                <i data-feather="check-square" class="w-4 h-4 mr-2"></i> ${
                  cell.getData().active_status == 1 ? 'Active' : 'Inactive'
                }
              </div>`
            }
          },
          {
            title: '',
            // minWidth: 50,
            field: 'actions',
            responsive: 1,
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              const a = cash(`<div class="flex lg:justify-center items-center">
                <a class="flex items-center mr-3" href="javascript:;">
                  <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Edit
                </a>

              </div>`)
              cash(a).on('click', function() {
                //getDetails(cell.getData().id);
                router.push("/master/rates/vendor/update/" + window.btoa(cell.getData().id));
              })

              return a[0]
            }
          },

          {
            title: 'Process',
            visible: false,
            field: 'process_name',
            vertAlign: 'middle',
            print: false,
            download: true,

          },
          {
            title: 'From',
            visible: false,
            field: 'from',
            vertAlign: 'middle',
            print: false,
            download: true,

          },
          {
            title: 'To',
            visible: false,
            field: 'to',
            vertAlign: 'middle',
            print: false,
            download: true,

          },
          {
            title: 'Type',
            visible: false,
            field: 'rate_type',
            vertAlign: 'middle',
            print: false,
            download: true,
            formatter(cell) {
              if(cell.getData().rate_type == 1) {
                return 'Issue Weight'
              } else if(cell.getData().rate_type == 2) {
                return 'Fixed Piece'
              } else if(cell.getData().rate_type == 3) {
                return 'Weight + Weight'
              } else if(cell.getData().rate_type == 4) {
                return 'Piece + Piect'
              } else if(cell.getData().rate_type == 5) {
                return 'Weight Loss'
              }
            }
          },
          {
            title: 'Color',
            visible: false,
            field: 'color',
            vertAlign: 'middle',
            print: false,
            download: true,

          },
          {
            title: 'Purity',
            visible: false,
            field: 'purity',
            vertAlign: 'middle',
            print: false,
            download: true,

          },
          {
            title: 'Shape',
            visible: false,
            field: 'shape',
            vertAlign: 'middle',
            print: false,
            download: true,

          },
          {
            title: 'Cut',
            visible: false,
            field: 'cut',
            vertAlign: 'middle',
            print: false,
            download: true,

          },
          {
            title: 'Polish',
            visible: false,
            field: 'polish',
            vertAlign: 'middle',
            print: false,
            download: true,

          },
          {
            title: 'Symmetry',
            visible: false,
            field: 'symmetry',
            vertAlign: 'middle',
            print: false,
            download: true,

          },
          {
            title: 'Fluc',
            visible: false,
            field: 'fluerocent',
            vertAlign: 'middle',
            print: false,
            download: true,

          },
          {
            title: 'Tension',
            visible: false,
            field: 'tension',
            vertAlign: 'middle',
            print: false,
            download: true,

          },
          {
            title: 'Stone',
            responsive: 0,
            visible: false,
            field: 'stone',
            vertAlign: 'middle',
            print: false,
            download: true,

          },
          {
            title: 'Status',
            field: 'active_status',
            visible: false,
            print: true,
            download: true,
            formatterPrint(cell) {
              return cell.getValue() ? 'Active' : 'Inactive'
            }
          },

        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }

    const PartyDetails = () => {
      let params = {
        user_id : route.params.id
      }
      let promise = axios({
        url: 'api/master/get_vendor_by_id',
        method: "post",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),

        },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          pagedata.user_data = data.items
          pagedata.min_value = pagedata.user_data.min_value
        })
        .catch(_error => {
        });
    }


    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }


    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'data.csv')
    }

    const onExportJson = () => {
      tabulator.value.download('json', 'data.json')
    }

    const onExportXlsx = () => {
      const win = window
      win.XLSX = xlsx
      tabulator.value.download('xlsx', 'data.xlsx', {
        sheetName: 'Products'
      })
    }

    const onExportHtml = () => {
      tabulator.value.download('html', 'data.html', {
        style: true
      })
    }

    const cancelUpdateValue = () => {
      pagedata.update_min_wt = false
      pagedata.min_value = pagedata.user_data.min_value
    }
    const EditValue = () => {

      let params = {
        value : pagedata.min_value ? window.btoa(pagedata.min_value) : '',
        user_id : route.params.id
      }
      let promise = axios({
        url: 'api/master/edit_vendor_min_value',
        method: "post",
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),

        },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          pagedata.update_min_wt = false
          pagedata.user_data = data.items
          pagedata.min_value = pagedata.user_data.min_value
          swal("Success!", "Minimum Value is Updated !", "success");
        })
        .catch(_error => {
        });
    }

    // Print
    const onPrint = () => {
      tabulator.value.print()
    }

    onMounted(() => {
      PartyDetails()
      initTabulator()
      reInitOnResizeWindow()
    })


    return {
      tableRef,
      formData,
      pagedata,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      initTabulator,
      cancelUpdateValue,
      EditValue
    }
  }
})
</script>
