<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Calculate Rates</h2>
    </div>
    <div class="grid grid-cols-12 gap-2 mt-5 intro-y box p-5">
      <div class="col-span-6 md:col-span-4">
        <div class="mt-1">
          <label for="update-profile-form-6" class="form-label py-1"
            >Select Process</label
          >
          <TomSelect
            v-model="rate_data.process_id"
            class="w-full"
            @change="getEmployeeDetails"
          >
            <option value="0">Select Process</option>
            <option
              v-for="process in page_data.process_list"
              :key="process.id"
              :value="process.id"
              >{{ process.process_name }} ({{ process.process_type }})</option
            >
          </TomSelect>
          <div class="text-primary-3" v-if="page_data.errors.process_id">
            {{ page_data.errors.process_id[0] }}
          </div>
        </div>
      </div>
      <div class="col-span-6 md:col-span-4">
        <div class="mt-1">
          <label for="update-profile-form-6" class="form-label py-1"
            >Select Manager</label
          >
          <TomSelect
            v-model="rate_data.manager_id"
            class="w-full"
            @change="getEmployeeDetails"
          >
            <option value="0">Select Manager</option>
            <option
              v-for="manager in page_data.manager_list"
              :key="manager.id"
              :value="manager.id"
              >{{ manager.first_name }} {{ manager.last_name }}</option
            >
          </TomSelect>
          <div class="text-primary-3" v-if="page_data.errors.manager_id">
            {{ page_data.errors.manager_id[0] }}
          </div>
        </div>
      </div>
      <div class="col-span-6 md:col-span-4">
        <div class="mt-1">
          <label for="update-profile-form-6" class="form-label py-1"
            >Select Worker Type</label
          >
          <TomSelect v-model="rate_data.employee_type" @change="getEmployeeDetails" class="w-full">
            <option value="0">All</option>
            <option value="1">Employee</option>
            <option value="2">Job Party</option>
          </TomSelect>
          <div class="text-primary-3" v-if="page_data.errors.worker_type">
            {{ page_data.errors.worker_type[0] }}
          </div>
        </div>
      </div>
      <div class="col-span-6 md:col-span-4">
        <div class="mt-1">
          <label for="update-profile-form-6" class="form-label py-1"
            >Employees</label
          >
          <TomSelect v-model="rate_data.employee_ids" class="w-full" multiple>
            <option
              v-for="employee in page_data.employees"
              :key="employee.id"
              :value="employee.id"
              >{{ employee.first_name }} {{ employee.last_name }}</option
            >
          </TomSelect>
        </div>
      </div>
      <div class="col-span-6 md:col-span-4">
        <div class="mt-1">
          <label for="update-profile-form-6" class="form-label py-1"
            >Start Date</label
          >
          <input
            type="date"
            id="update-profile-form-13"
            class="form-control"
            placeholder=""
            v-model="rate_data.start_date"
          />
          <div class="text-primary-3" v-if="page_data.errors.start_date">
            {{ page_data.errors.start_date[0] }}
          </div>
        </div>
      </div>
      <div class="col-span-6 md:col-span-4">
        <div class="mt-1">
          <label for="update-profile-form-6" class="form-label py-1"
            >End Date</label
          >
          <input
            type="date"
            id="update-profile-form-13"
            class="form-control"
            placeholder=""
            v-model="rate_data.end_date"
          />
          <div class="text-primary-3" v-if="page_data.errors.end_date">
            {{ page_data.errors.end_date[0] }}
          </div>
        </div>
      </div>
      <div class="col-span-12">
        <!-- BEGIN: Input -->
        <div id="input">
          <div class="preview">
            <div class="text-right mt-3">
              <router-link
                :to="{ name: 'manual-rate-list' }"
                class="btn btn-outline-secondary w-24 mr-1"
              >
                Cancel
              </router-link>
              <button
                type="button"
                @click="SaveEmployeeRate"
                :disabled="page_data.submit"
                class="btn btn-primary"
              >
                Update Rates
                <LoadingIcon
                  v-if="page_data.submit"
                  icon="oval"
                  color="white"
                  class="w-4 h-4 ml-2"
                />
              </button>
            </div>
          </div>
        </div>
        <!-- END: Input -->
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config'
import router from '@/router'
import { useRoute } from 'vue-router'
import axios from 'axios'
import Toastify from 'toastify-js'
import { defineComponent, inject, onMounted, reactive, toRef, watch } from 'vue'
import moment from 'moment'
import cash from 'cash-dom'
import { stringify } from 'json5'
export default defineComponent({
  setup() {
    const route = useRoute()
    const swal = inject('$swal')
    const page_data = reactive({
      worker_list: [],
      submit: false,
      endis: false,
      process_list: [],
      employees: [],
      manager_list: [],
      errors: [],
      success: '',
      s_wt: true,
      e_wt: true
    })

    const rate_data = reactive({
      rate_type: 1,
      manager_id: '0',
      start_date: '',
      end_date: '',
      employee_type: '0',
      employee_ids: [],
      process_id: '0'
    })

    onMounted(() => {
      getManagerDetails()
      getProcessDetails()
    })
    function SaveEmployeeRate() {
      let url = 'api/master/calculate_new_rates'
        page_data.submit = true
        axios({
          url: url,
          method: 'post',
          baseURL: BASE_URL,
          data: {
            rate_type : rate_data.rate_type,
            manager_id : (rate_data.manager_id == '0' ? "" : rate_data.manager_id),
            start_date : rate_data.start_date,
            end_date : rate_data.end_date,
            employee_type : (rate_data.employee_type == '0' ? "" : rate_data.employee_type),
            employee_ids : rate_data.employee_ids,
            process_id : (rate_data.process_id == '0' ? "" : rate_data.process_id),
          },
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { Accept: 'application/json' } }
        })
          .then(res => {
            page_data.submit = false

            if (res.data.status == 'error') {
              swal('Warning', res.data.message, 'warning')
              return
            } else {
              swal('Success', res.data.message, 'success')
            }
            router.push({ name: 'manual-rate-list' })
          })
          .catch(err => {
            page_data.submit = false
            page_data.errors = err.response.data.errors
            setTimeout(() => {
              page_data.errors = []
            }, 5000)
          })
    }

    const getWorkerDetails = () => {
      // Get Worker Details
      let params = {
        process_id:
          rate_data.process_id != '0' ? window.btoa(rate_data.process_id) : '',
        worker_type:
          rate_data.worker_type != '0' ? window.btoa(rate_data.worker_type) : ''
      }

      let promise = axios({
        url: 'api/master/get_worker_by_type',
        method: 'POST',
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            Accept: 'application/json'
          }
        }
      })
      return promise
        .then(result => result.data)
        .then(data => {
          page_data.worker_list = data.items
          rate_data.worker_list = []
        })
        .catch(_error => {
          page_data.worker_list = []
          rate_data.worker_list = []
        })
    }

    const getProcessDetails = () => {
      let params = {}

      let promise = axios({
        url: 'api/master/dp_process',
        method: 'POST',
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            Accept: 'application/json'
          }
        }
      })
      return promise
        .then(result => result.data)
        .then(data => {
          page_data.process_list = data.items
        })
        .catch(_error => {
          page_data.process_list = []
        })
    }
    const getManagerDetails = () => {
      let params = {}

      let promise = axios({
        url: 'api/master/dp_managers',
        method: 'POST',
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            Accept: 'application/json'
          }
        }
      })
      return promise
        .then(result => result.data)
        .then(data => {
          page_data.manager_list = data.items
        })
        .catch(_error => {
          page_data.manager_list = data.items
        })
    }
    const getEmployeeDetails = () => {
      let params = {
        manager_id: rate_data.manager_id == '0' ? '0' : rate_data.manager_id,
        process_id:
        rate_data.process_id == '0' ? '' : window.btoa(rate_data.process_id),
        employee_type:
        rate_data.employee_type == '0' ? '' : window.btoa(rate_data.employee_type)
      }
      let api_url = 'api/master/get_employees_by_manager_employee_type'

      let promise = axios({
        url: api_url,
        method: 'post',
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: { headers: { Accept: 'application/json' } }
      })
      return promise
        .then(result => result.data)
        .then(data => {
          rate_data.employee_ids = []
          page_data.employees = data.items
        })
        .catch(_error => {
          rate_data.employee_ids = []
          page_data.employees = []
        })
    }
    return {
      page_data,
      rate_data,
      SaveEmployeeRate,
      getEmployeeDetails
    }
  }
})
</script>
