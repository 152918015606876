<template>
  <div>
      <!-- Headings -->
      <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
          <h2 class="text-lg font-medium mr-auto">Final Polish Report</h2>
          <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

          </div>
      </div>
      <!-- BEGIN: HTML Table Data -->
      <div class="intro-y box p-5 mt-5">

          <div class="grid grid-cols-12 gap-x-2">
              <div class="col-span-6 md:col-span-3 xl:col-span-2">
                  <div class="mt-2">
                    <div class="flex items-center"><label for="update-profile-form-6" class="form-label"
                      >Report Type</label
                    ><input
                      class="show-code form-check-switch mr-0 ml-3 h-6"
                      type="checkbox" v-model="filter.pending_packet"
                    /></div>
                      <TomSelect v-model="filter.report_type" :options="{
                    placeholder: 'Report Type',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Report'
                      }
                    }
                  }" class="w-full">
                          <option value="1">PolishSummary</option>
                          <option value="2">Polish Lot Wise</option>
                          <option value="3">Pending Stock</option>
                      </TomSelect>
                  </div>
              </div>
              <div class="col-span-6 md:col-span-3 xl:col-span-2">
                  <div class="mt-2">
                      <label for="update-profile-form-6" class="form-label">Packet / Lot</label>
                      <input id="tabulator-html-filter-value" v-model="filter.packet_no" type="text" class="form-control w-full mt-2 sm:mt-0" placeholder="Search Packet / Kapan" />
                  </div>
              </div>
              <div class="col-span-6 md:col-span-3 xl:col-span-2">
                  <div class="mt-2">
                      <label for="update-profile-form-6" class="form-label">Select Kapan</label>
                      <TomSelect v-model="filter.kapan_id" :options="{
                    placeholder: 'Select Kapan',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Process'
                      }
                    }
                  }" class="w-full">
                          <option value="0">Select Kapan</option>
                          <option v-for="item in page_data.lot_items" :key="item.id" :value="item.id">{{item.lot_no}} ( {{ item.voucher_no ? item.voucher_no : '-' }} )</option>
                      </TomSelect>
                  </div>
              </div>
              <div class="col-span-6 md:col-span-3 xl:col-span-2" v-if="[1,2,8].includes(user_details.user_role)">
                  <div class="mt-2">
                      <label for="update-profile-form-6" class="form-label">Select Worker</label>
                      <TomSelect v-model="filter.worker_id" :options="{
                    placeholder: 'Select Worker',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Worker'
                      }
                    }
                  }" class="w-full">
                          <option value="0">Select Worker</option>
                          <option v-for="item in page_data.employee_items" :key="item.id" :value="item.id">{{item.company_name}} ( {{ item.process_short_code ? item.short_code : '-' }} )</option>
                      </TomSelect>
                  </div>
              </div>
              <div class="col-span-6 md:col-span-3 xl:col-span-2">
              <div class="mt-2">
                <div class="flex items-center"><label for="update-profile-form-6" class="form-label"
                  >Select Start Date</label
                ><input
                  class="show-code form-check-switch mr-0 ml-3 h-6"
                  type="checkbox" v-model="filter.issue_pending"
                /></div>
                <input
                  type= "date"
                  id="update-profile-form-13"
                  :disabled="filter.issue_pending"
                  class="form-control"
                  placeholder=""
                  v-model="filter.issue_date"
                />
              </div>

          </div>
          <div class="col-span-6 md:col-span-3 xl:col-span-2">
            <div class="mt-2">
              <label for="update-profile-form-6" class="form-label"
                >Select End Date</label
              >
              <input
                type= "date"
                id="update-profile-form-13"

                class="form-control"
                placeholder=""
                :disabled="filter.issue_pending"
                v-model="filter.receive_date"
              />
            </div>
          </div>
          <div class="col-span-6 md:col-span-3 xl:col-span-4">
                  <div class="mt-2">
                      <label for="update-profile-form-6" class="form-label">Select Jangad</label>
                      <TomSelect v-model="filter.jangad_no" multiple :options="{
                    placeholder: 'Select Jangad',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Process'
                      }
                    }
                  }" class="w-full">
                          <option value="">Select Jangad</option>
                          <option v-for="item in page_data.jangad_items" :key="item.id" :value="item.id">{{item.transaction_code}}</option>
                      </TomSelect>
                  </div>
              </div>
          <div class="col-span-6 md:col-span-3 xl:col-span-4">
                  <div class="mt-2">
                      <label for="update-profile-form-6" class="form-label">Comparisons</label>
                      <TomSelect v-model="filter.compare_items" :options="{
                    placeholder: 'Select Type',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Process'
                      }
                    }
                  }" class="w-full" multiple>
                          <option value="1">Planning</option>
                          <option value="2">Makeable</option>
                          <option value="3">Final Polish</option>
                      </TomSelect>
                  </div>
              </div>
              <div class="col-span-2 md:col-span-2 xl:col-span-1">
                  <div class="mt-7 flex pt-2">

                      <button id="tabulator-html-filter-reset" type="button" class="btn btn-secondary w-full mt-2 sm:mt-0 sm:ml-2" @click="onResetFilter">
                          Reset
                      </button>
                  </div>
              </div>
              <div class="col-span-2 md:col-span-2 xl:col-span-1">
                  <div class="mt-7 flex pt-2">

                      <button id="tabulator-html-filter-go" type="button" class="btn btn-primary w-full ml-2" tabindex="5" @click="getReportDetails()">
                          Generate
                      </button>
                  </div>
              </div>
          </div>

          <div>
              <!-- BEgin: Process Report Start-->
              <div id="responsive-table" class="p-5" v-if="page_data.loading">
                  <div class="preview">
                      <div class="overflow-x-auto">
                          <div class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center">
                              <LoadingIcon icon="bars" class="w-8 h-8" />
                              <div class="text-center text-xs mt-2">Getting Latest Price</div>
                          </div>
                      </div>
                  </div>
              </div>

              <div v-else>
                  <div class="scrollbar-hidden">
                      <div class="intro-y col-span-12 overflow-auto tableFixHead mt-3 border-2">
                          <table class="w-full border-0" v-if="api_filter.report_type == 1">
                              <thead>
                                  <tr class="border-b bg-gray-300 dark:bg-dark-1">
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap border-b">#</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">PACKET NO</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">STOCK NO</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap" v-if="[1,2,8].includes(user_details.user_role)">WORKER</th>
                                      <th class="py-2 text-xs lg:text-md whitespace-nowrap">ISSUE DATE</th>
                                      <th class="py-2 text-xs lg:text-md ">ROUGH <br> WEIGHT</th>
                                      <th class="py-2 text-xs lg:text-md ">PROCESS <br> WEIGHT</th>
                                      <th class="py-2 text-xs lg:text-md ">PLANNING <br> PCS</th>
                                      <th class="py-2 text-xs lg:text-md ">PLANNING <br> WT</th>
                                      <th class="py-2 text-xs lg:text-md ">PLANNING <br> Polish WT</th>
                                      <th class="py-2 text-xs lg:text-md ">POLISH <br> PCS</th>
                                      <th class="py-2 text-xs lg:text-md ">FINAL POLISH <br> PCS</th>
                                      <th class="py-2 text-xs lg:text-md ">FINAL POLISH <br> WEIGHT</th>
                                      <th class="py-2 text-xs lg:text-md ">4P & SEMI <br> POLISH PCS</th>
                                      <th class="py-2 text-xs lg:text-md ">4P & SEMI <br> POLISH WEIGHT</th>
                                      <th class="py-2 text-xs lg:text-md ">FINAL POLISH <br> PENDING PCS</th>
                                      <th class="py-2 text-xs lg:text-md ">FINAL POLISH <br> PENDING WEIGHT</th>
                                    </tr>
                                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.total_packets }}</td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="[1,2,8].includes(user_details.user_role)"></td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r"></td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_rough_weight ? $h.myFloat(page_data.total.total_rough_weight) : 0) }} Ct</td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_issue_weight ? $h.myFloat(page_data.total.total_issue_weight) : 0) }} Ct</td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_ls_packets ? page_data.total.total_ls_packets : 0) }}</td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_ls_weight ? $h.myFloat(page_data.total.total_ls_weight) : 0) }} Ct {{ (page_data.total.total_ls_weight ? '(' + parseFloat(page_data.total.total_ls_weight * 100 / page_data.total.total_issue_weight).toFixed(2) + ' %)' : '') }}</td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_ls_polish_weight ? $h.myFloat(page_data.total.total_ls_polish_weight) : 0) }} Ct {{ (page_data.total.total_ls_polish_weight ? '(' + parseFloat(page_data.total.total_ls_polish_weight * 100 / page_data.total.total_issue_weight).toFixed(2) + ' %)' : '') }}</td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_ls_packets ? page_data.total.total_ls_packets : 0) }}</td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_received_packets ? page_data.total.total_received_packets : 0) }}</td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_received_weight ? $h.myFloat(page_data.total.total_received_weight) : 0) }} Ct {{ (page_data.total.total_received_weight ? '(' + parseFloat(page_data.total.total_received_weight * 100 / page_data.total.total_issue_weight).toFixed(2) + ' %)' : '') }}</td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_semi_packets ? page_data.total.total_semi_packets : 0) }}</td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_semi_weight ? $h.myFloat(page_data.total.total_semi_weight) : 0) }} Ct {{ (page_data.total.total_semi_weight ? '(' + parseFloat(page_data.total.total_semi_weight * 100 / page_data.total.total_issue_weight).toFixed(2) + ' %)' : '') }}</td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_pending_packets ? page_data.total.total_pending_packets : 0) }}</td>
                                        <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_pending_weight ? $h.myFloat(page_data.total.total_pending_weight) : 0) }} {{ (page_data.total.total_pending_weight ? '(' + parseFloat(page_data.total.total_pending_weight * 100 / page_data.total.total_issue_weight).toFixed(2) + ' %)' : '') }}</td>
                                    </tr>
                              </thead>
                                  <tbody v-for="(item,index) in page_data.items" :key="index">

                                    <tr class="border-b hover:bg-gray-200 p-0" :class="{'bg-gray-100': (index % 2) == 0}">
                                        <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ index + 1 }}</td>
                                        <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ item.number }}</td>
                                        <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ item.vepari_no }}</td>
                                        <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r" v-if="[1,2,8].includes(user_details.user_role)">{{ item.company_name ? item.company_name : ((item.first_name ? item.first_name : '') + ' ' + (item.last_name ? item.last_name : '')) }}</td>
                                        <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ item.issue_date }}</td>
                                        <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.rough_weight ? $h.myFloat(item.rough_weight) : 0) }} Ct</td>
                                        <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.issue_weight ? $h.myFloat(item.issue_weight) : 0) }} Ct</td>
                                        <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.total_ls_packets ? item.total_ls_packets : 0) }}</td>
                                        <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.total_ls_weight ? $h.myFloat(item.total_ls_weight) : 0) }} Ct {{ (item.total_ls_weight ? '(' + parseFloat(item.total_ls_weight * 100 / item.issue_weight).toFixed(2) + ' %)' : '') }}</td>
                                        <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.total_ls_polish_weight ? $h.myFloat(item.total_ls_polish_weight) : 0) }} Ct {{ (item.total_ls_polish_weight ? '(' + parseFloat(item.total_ls_polish_weight * 100 / item.issue_weight).toFixed(2) + ' %)' : '') }}</td>
                                        <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.total_ls_packets ? item.total_ls_packets : 0) }}</td>
                                        <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.total_received_packets ? item.total_received_packets : 0) }}</td>
                                        <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.total_received_weight ? $h.myFloat(item.total_received_weight) : 0) }} Ct {{ (item.total_received_weight ? '(' + parseFloat(item.total_received_weight * 100 / item.issue_weight).toFixed(2) + ' %)' : '') }}</td>
                                        <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.total_semi_packets ? item.total_semi_packets : 0) }}</td>
                                        <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.total_semi_weight ? $h.myFloat(item.total_semi_weight) : 0) }} Ct {{ (item.total_semi_weight ? '(' + parseFloat(item.total_semi_weight * 100 / item.issue_weight).toFixed(2) + ' %)' : '') }} </td>
                                        <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.total_pending_packets ? item.total_pending_packets : 0) }}</td>
                                        <td class="py-2 border-b border-t text-xs lg:text-md text-center border-r">{{ (item.total_pending_weight ? $h.myFloat(item.total_pending_weight) : 0) }} Ct {{ (item.total_pending_weight ? '(' + parseFloat(item.total_pending_weight * 100 / item.issue_weight).toFixed(2) + ' %)' : '') }}</td>
                                    </tr>
                                </tbody>

                          </table>
                          <table class="border-0" v-if="api_filter.report_type == 2">
                              <thead>
                                  <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                      <th class="p-2 text-xs lg:text-md">#</th>
                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">Packet No</th>
                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r">Stock No</th>
                                      <!-- <th class="p-2 text-xs lg:text-md border-r">Stone</th> -->
                                      <th class="p-2 text-xs lg:text-md border-r" v-if="[1,2,8].includes(user_details.user_role)">Worker</th>
                                      <th class="p-2 text-xs lg:text-md border-r whitespace-nowrap">Issue Date</th>
                                      <th class="p-2 text-xs lg:text-md border-r">ROUGH <br> WT</th>
                                      <th class="p-2 text-xs lg:text-md border-r">Process <br> WT</th>
                                      <th class="p-2 text-xs lg:text-md border-r">Planning <br> PCS</th>
                                      <th class="p-2 text-xs lg:text-md border-r">Planning <br> WT</th>
                                      <th class="p-2 text-xs lg:text-md border-r">Planning <br> Polish WT</th>
                                      <th class="p-2 text-xs lg:text-md border-r">Polish <br> PCS</th>
                                      <th class="p-2 text-xs lg:text-md border-r">Polish <br> Received PCS</th>
                                      <th class="p-2 text-xs lg:text-md border-r">Polish <br> Received WT</th>
                                      <th class="p-2 text-xs lg:text-md border-r ">4P & SEMI <br> POLISH PCS</th>
                                      <th class="p-2 text-xs lg:text-md border-r ">4P & SEMI <br> POLISH WEIGHT</th>
                                      <th class="p-2 text-xs lg:text-md border-r">Polish <br> Pending PCS</th>
                                      <th class="p-2 text-xs lg:text-md border-r">Polish <br> Pending Weight</th>
                                      <th class="p-2 text-xs lg:text-md border-r">PACKET NO</th>
                                      <th class="p-2 text-xs lg:text-md border-r">STOCK NO</th>
                                      <th class="p-2 text-xs lg:text-md border-r" colspan="3">CR Stocks</th>
                                      <th class="p-2 text-xs lg:text-md border-r" colspan="6" v-if="filter.compare_items.includes('1')">PLANNING</th>
                                      <th class="p-2 text-xs lg:text-md border-r" colspan="12" v-if="filter.compare_items.includes('2')">MAKEABLE</th>
                                      <th class="p-2 text-xs lg:text-md border-r" colspan="10" v-if="filter.compare_items.includes('3')">FINAL POLISH</th>
                                      <!-- <th class="p-2 text-xs lg:text-md whitespace-nowrap border-r" colspan="8">LAB</th> -->
                                    </tr>
                                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border">{{ page_data.total.total_packets }}</th>
                                      <!-- <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th> -->
                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border" v-if="[1,2,8].includes(user_details.user_role)"></th>
                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border">{{ (page_data.total.total_rough_weight ? $h.myFloat(page_data.total.total_rough_weight) : 0) }} Ct</th>
                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border">{{ (page_data.total.total_issue_weight ? $h.myFloat(page_data.total.total_issue_weight) : 0) }} Ct</th>
                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border">{{ (page_data.total.total_ls_packets ? page_data.total.total_ls_packets : 0) }}</th>
                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border">{{ (page_data.total.total_ls_weight ? $h.myFloat(page_data.total.total_ls_weight) : 0) }} Ct {{ (page_data.total.total_ls_weight ? '(' + parseFloat(page_data.total.total_ls_weight * 100 / page_data.total.total_issue_weight).toFixed(2) + ' %)' : '') }}</th>
                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border">{{ (page_data.total.total_ls_polish_weight ? $h.myFloat(page_data.total.total_ls_polish_weight) : 0) }} Ct {{ (page_data.total.total_ls_polish_weight ? '(' + parseFloat(page_data.total.total_ls_polish_weight * 100 / page_data.total.total_issue_weight).toFixed(2) + ' %)' : '') }}</th>
                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border">{{ (page_data.total.total_ls_packets ? page_data.total.total_ls_packets : 0) }}</th>
                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border">{{ (page_data.total.total_received_packets ? page_data.total.total_received_packets : 0) }}</th>
                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border">{{ (page_data.total.total_received_weight ? $h.myFloat(page_data.total.total_received_weight) : 0) }} Ct {{ (page_data.total.total_received_weight ? '(' + parseFloat(page_data.total.total_received_weight * 100 / page_data.total.total_issue_weight).toFixed(2) + ' %)' : '') }}</th>
                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border">{{ (page_data.total.total_semi_packets ? page_data.total.total_semi_packets : 0) }}</th>
                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border">{{ (page_data.total.total_semi_weight ? $h.myFloat(page_data.total.total_semi_weight) : 0) }} Ct {{ (page_data.total.total_semi_weight ? '(' + parseFloat(page_data.total.total_semi_weight * 100 / page_data.total.total_issue_weight).toFixed(2) + ' %)' : '') }}</th>
                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border">{{ (page_data.total.total_pending_packets ? page_data.total.total_pending_packets : 0) }}</th>
                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border">{{ (page_data.total.total_pending_weight ? $h.myFloat(page_data.total.total_pending_weight) : 0) }} {{ (page_data.total.total_pending_weight ? '(' + parseFloat(page_data.total.total_pending_weight * 100 / page_data.total.total_issue_weight).toFixed(2) + ' %)' : '') }}</th>
                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border">Packet No</th>
                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border">Stock No</th>
                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border">Cr Manager</th>
                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border">Cr Process</th>
                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border">Cr Employee</th>
                                      <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('1')">COLOR</th>
                                      <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('1')">CUT</th>
                                      <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('1')">CLARITY</th>
                                      <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('1')">SHAPE</th>
                                      <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('1')">PLAN WT(CT)</th>
                                      <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('1')">TOTAL PLAN WT(CT)</th>
                                      <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('2')">MAK. DATE</th>
                                      <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('2')">MANAGER</th>
                                      <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('2')">WORKER</th>
                                      <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('2')">MAK. Wt(Ct)</th>
                                      <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('2')">MAK. TYPE</th>
                                      <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('2')">COLOR</th>
                                      <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('2')">CUT</th>
                                      <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('2')">CLARITY</th>
                                      <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('2')">SHAPE</th>
                                      <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('2')">EXP WT(CT)</th>
                                      <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('2')">DIFF WT(CT)</th>
                                      <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('2')">TOTAL EXP WT(CT)</th>
                                      <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('3')">REC. DATE</th>
                                      <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('3')">MANAGER</th>
                                      <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('3')">WORKER</th>
                                      <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('3')">COLOR</th>
                                      <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('3')">CUT</th>
                                      <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('3')">CLARITY</th>
                                      <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('3')">SHAPE</th>
                                      <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('3')">POL WT(CT)</th>
                                      <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('3')">DIFF WT(CT)</th>
                                      <th class="p-2 text-xs lg:text-md border" v-if="filter.compare_items.includes('3')">TOTAL POLISH WT(CT)</th>
                                      <!-- <th class="p-2 text-xs lg:text-md whitespace-nowrap border">REC. DATE</th>
                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border">COLOR</th>
                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border">CUT</th>
                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border">CLARITY</th>
                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border">SHAPE</th>
                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border">POL WT(CT)</th>
                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border">DIFF WT(CT)</th>
                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap border">TOTAL POLISH WT(CT)</th> -->
                                    </tr>
                                    <!-- <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                        <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                        <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                        <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                        <td class="p-2 border-b text-xs lg:text-md text-center border-r" v-if="[1,2,8].includes(user_details.user_role)"></td>
                                        <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                        <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                        <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                        <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                        <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                        <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                        <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                        <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                        <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                        <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                        <th class="p-2 text-xs lg:text-md border"></th>
                                        <th class="p-2 text-xs lg:text-md border"></th>
                                        <th class="p-2 text-xs lg:text-md border"></th>
                                        <th class="p-2 text-xs lg:text-md border"></th>
                                        <th class="p-2 text-xs lg:text-md border"></th>
                                        <th class="p-2 text-xs lg:text-md border"></th>
                                        <th class="p-2 text-xs lg:text-md border"></th>
                                        <th class="p-2 text-xs lg:text-md border"></th>
                                        <th class="p-2 text-xs lg:text-md border"></th>
                                        <th class="p-2 text-xs lg:text-md border">{{ (page_data.total.total_planning_polish_weight ? $h.myFloat(page_data.total.total_planning_polish_weight) : '0') }}</th>
                                        <th class="p-2 text-xs lg:text-md border"></th>
                                        <th class="p-2 text-xs lg:text-md border"></th>
                                        <th class="p-2 text-xs lg:text-md border"></th>
                                        <th class="p-2 text-xs lg:text-md border"></th>
                                        <th class="p-2 text-xs lg:text-md border">{{ (page_data.total.total_makeable_weight ? $h.myFloat(page_data.total.total_makeable_weight) : '0') }}</th>
                                        <th class="p-2 text-xs lg:text-md border"></th>
                                        <th class="p-2 text-xs lg:text-md border"></th>
                                        <th class="p-2 text-xs lg:text-md border"></th>
                                        <th class="p-2 text-xs lg:text-md border"></th>
                                        <th class="p-2 text-xs lg:text-md border">{{ (page_data.total.total_makeable_polish_weight ? $h.myFloat(page_data.total.total_makeable_polish_weight) : '0') }}</th>
                                        <th class="p-2 text-xs lg:text-md border">{{ (page_data.total.total_diff_makeable_weight ? $h.myFloat(page_data.total.total_diff_makeable_weight) : '0') }}</th>
                                        <th class="p-2 text-xs lg:text-md border"></th>
                                        <th class="p-2 text-xs lg:text-md border"></th>
                                        <th class="p-2 text-xs lg:text-md border"></th>
                                        <th class="p-2 text-xs lg:text-md border"></th>
                                        <th class="p-2 text-xs lg:text-md border"></th>
                                        <th class="p-2 text-xs lg:text-md border"></th>
                                        <th class="p-2 text-xs lg:text-md border"></th>
                                        <th class="p-2 text-xs lg:text-md border"></th>
                                        <th class="p-2 text-xs lg:text-md border">{{ (page_data.total.total_final_weight ? $h.myFloat(page_data.total.total_final_weight) : '0') }}</th>
                                        <th class="p-2 text-xs lg:text-md border">{{ (page_data.total.total_diff_final_weight ? $h.myFloat(page_data.total.total_diff_final_weight) : '0') }}</th>
                                        <th class="p-2 text-xs lg:text-md border"></th> -->
                                        <!-- <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                        <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                        <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                        <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                        <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                        <th class="p-2 text-xs lg:text-md whitespace-nowrap border">{{ (page_data.total.total_lab_weight ? $h.myFloat(page_data.total.total_lab_weight) : '0') }}</th>
                                        <th class="p-2 text-xs lg:text-md whitespace-nowrap border">{{ (page_data.total.total_diff_lab_weight ? $h.myFloat(page_data.total.total_diff_lab_weight) : '0') }}</th>
                                        <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th> -->
                                      <!-- </tr> -->
                              </thead>
                                  <tbody>

                                    <template v-for="(main_pack,index) in page_data.items" :key="index">
                                      <template v-for="(item,inx) in main_pack.packet_details" :key="inx">

                                        <tr class="border-b hover:bg-gray-200 p-0" :class="{'bg-gray-100': (inx % 2) == 0}">
                                            <template v-if="inx == 0">
                                              <td :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ index + 1 }}</td>
                                              <td :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ main_pack.number }}</td>
                                              <td :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ main_pack.vepari_no }}</td>
                                              <!-- <td :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ main_pack.stone_name }}</td> -->
                                              <td :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs lg:text-md text-center border-r" v-if="[1,2,8].includes(user_details.user_role)">{{ main_pack.company_name ? main_pack.company_name : ((main_pack.first_name ? main_pack.first_name : '') + '<br>' + (main_pack.last_name ? main_pack.last_name : '')) }}</td>
                                              <td :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs lg:text-md text-center border-r"> <span> {{ item.issue_date }}</span></td>
                                              <td :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (main_pack.rough_weight ? main_pack.rough_weight : 0) }} Ct</td>
                                              <td :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (main_pack.issue_weight ? main_pack.issue_weight : 0) }} Ct</td>
                                              <td :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (main_pack.total_ls_packets ? main_pack.total_ls_packets : 0) }}</td>
                                              <td :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (main_pack.total_ls_weight ? $h.myFloat(main_pack.total_ls_weight) : 0) }} Ct {{ (main_pack.total_ls_weight ? '(' + parseFloat(main_pack.total_ls_weight * 100 / main_pack.issue_weight).toFixed(2) + ' %)' : '') }}</td>
                                              <td :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (main_pack.total_ls_polish_weight ? $h.myFloat(main_pack.total_ls_polish_weight) : 0) }} Ct {{ (main_pack.total_ls_polish_weight ? '(' + parseFloat(main_pack.total_ls_polish_weight * 100 / main_pack.issue_weight).toFixed(2) + ' %)' : '') }}</td>
                                              <td :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (main_pack.total_ls_packets ? main_pack.total_ls_packets : 0) }}</td>
                                              <td :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (main_pack.total_received_packets ? main_pack.total_received_packets : 0) }}</td>
                                              <td :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (main_pack.total_received_weight ? $h.myFloat(main_pack.total_received_weight) : 0) }} Ct {{ (main_pack.total_received_weight ? '(' + parseFloat(main_pack.total_received_weight * 100 / main_pack.issue_weight).toFixed(2) + ' %)' : '') }}</td>
                                              <td :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (main_pack.total_semi_packets ? main_pack.total_semi_packets : 0) }}</td>
                                              <td :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (main_pack.total_semi_weight ? $h.myFloat(main_pack.total_semi_weight) : 0) }} Ct {{ (main_pack.total_semi_weight ? '(' + parseFloat(main_pack.total_semi_weight * 100 / main_pack.issue_weight).toFixed(2) + ' %)' : '') }}</td>
                                              <td :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (main_pack.total_pending_packets ? main_pack.total_pending_packets : 0) }}</td>
                                              <td :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (main_pack.total_pending_weight ? $h.myFloat(main_pack.total_pending_weight) : 0) }} Ct {{ (main_pack.total_pending_weight ? '(' + parseFloat(main_pack.total_pending_weight * 100 / main_pack.issue_weight).toFixed(2) + ' %)' : '') }}</td>
                                            </template>
                                            <th class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.number ? item.number : '-') }}</th>
                                            <th class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.vepari_no ? item.vepari_no : '-') }}</th>
                                            <th class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.manager_short_name ? item.manager_short_name : '-') }}</th>
                                            <th class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.process_short_code ? item.process_short_code : '-') }}</th>
                                            <th class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.employee_short_name ? item.employee_short_name : '-') }}</th>
                                            <td v-if="filter.compare_items.includes('1')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.planning_color_name ? item.planning_color_name : '-') }}</td>
                                            <td v-if="filter.compare_items.includes('1')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.planning_cut_name ? item.planning_cut_name : '-') }}</td>
                                            <td v-if="filter.compare_items.includes('1')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.planning_purity_name ? item.planning_purity_name : '-') }}</td>
                                            <td v-if="filter.compare_items.includes('1')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.planning_shape_name ? item.planning_shape_name : '-') }}</td>
                                            <th v-if="filter.compare_items.includes('1')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.pl_ex_wt ? item.pl_ex_wt : '-') }}</th>
                                            <th v-if="filter.compare_items.includes('1') && inx == 0" :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"><span v-if="item.pl_date != null"><span>{{ (main_pack.total_planning_polish_weight ? $h.myFloat(main_pack.total_planning_polish_weight) : '-') }}</span><br><hr class="my-3"><span>{{ (main_pack.total_planning_polish_percent ? main_pack.total_planning_polish_percent + ' %' : '-') }}</span></span></th>
                                            <th v-if="filter.compare_items.includes('2')" class="p-2 border-b border-t text-xs lg:text-md text-center border-r w-40">{{ (item.mk_date ? item.mk_date : '-') }}</th>
                                            <th v-if="filter.compare_items.includes('2')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.makeable_manager_first_name ? item.makeable_manager_first_name : '-' }} <br> {{ (item.makeable_manager_last_name ? item.makeable_manager_last_name : '') }}</th>
                                            <th v-if="filter.compare_items.includes('2')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.makeable_employee_short_name ? item.makeable_employee_short_name : '-' }}</th>
                                            <th v-if="filter.compare_items.includes('2')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.mk_wt ? item.mk_wt : '-') }}</th>
                                            <th v-if="filter.compare_items.includes('2')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.for_p == 1 ? '4P' : (item.semi_polish == 1 ? 'Semi Polish' : '')) }}</th>
                                            <td v-if="filter.compare_items.includes('2')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" :class="{'text-theme-6' : ((item.mk_color > item.pl_color) && item.pl_color > 0 && item.mk_color > 0),'text-theme-9' : ((item.pl_color > item.mk_color) && item.pl_color > 0 && item.mk_color > 0)}">{{ (item.makeable_color_name ? item.makeable_color_name : '-') }}</td>
                                            <td v-if="filter.compare_items.includes('2')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" :class="{'text-theme-6' : ((item.mk_cut > item.pl_cut) && item.pl_cut > 0 && item.mk_cut > 0),'text-theme-9' : ((item.pl_cut > item.mk_cut) && item.pl_cut > 0 && item.mk_cut > 0)}">{{ (item.makeable_cut_name ? item.makeable_cut_name : '-') }}</td>
                                            <td v-if="filter.compare_items.includes('2')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" :class="{'text-theme-6' : ((item.mk_purity > item.pl_purity) && item.pl_purity > 0 && item.mk_purity > 0),'text-theme-9' : ((item.pl_purity > item.mk_purity) && item.pl_purity > 0 && item.mk_purity > 0)}">{{ (item.makeable_purity_name ? item.makeable_purity_name : '-') }}</td>
                                            <td v-if="filter.compare_items.includes('2')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.makeable_shape_name ? item.makeable_shape_name : '-') }}</td>
                                            <th v-if="filter.compare_items.includes('2')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.mk_ex_wt ? item.mk_ex_wt : '-') }}</th>
                                            <th v-if="filter.compare_items.includes('2')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.mk_ex_wt ? $h.myFloat(item.mk_ex_wt - item.pl_ex_wt) : '-') }}</th>
                                            <th v-if="filter.compare_items.includes('2') && inx == 0" :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"><span v-if="item.mk_date != null"><span class="m-5">{{ (main_pack.total_makeable_polish_weight ? $h.myFloat(main_pack.total_makeable_polish_weight) : '-') }}</span><br><hr class="my-3"><span>{{ (main_pack.total_makeable_polish_percent ? main_pack.total_makeable_polish_percent + ' %' : '-') }}</span><br><hr class="my-3"><span class="">{{ (main_pack.total_diff_makeable_percent ? main_pack.total_diff_makeable_percent + ' %' : '-') }}</span></span></th>
                                            <th v-if="filter.compare_items.includes('3')" class="p-2 border-b border-t text-xs lg:text-md text-center border-r w-40">{{ (item.po_date ? item.po_date : '-') }}</th>
                                            <th v-if="filter.compare_items.includes('3')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.final_manager_first_name ? item.final_manager_first_name : '-' }} <br> {{ (item.final_manager_last_name ? item.final_manager_last_name : '') }}</th>
                                            <th v-if="filter.compare_items.includes('3')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.final_employee_short_name ? item.final_employee_short_name : '-' }}</th>
                                            <td v-if="filter.compare_items.includes('3')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" :class="{'text-theme-6' : ((item.po_color > item.mk_color) && item.po_color > 0 && item.mk_color > 0),'text-theme-9' : ((item.po_color < item.mk_color) && item.po_color > 0 && item.mk_color > 0)}">{{ (item.final_color_name ? item.final_color_name : '-') }}</td>
                                            <td v-if="filter.compare_items.includes('3')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" :class="{'text-theme-6' : ((item.po_cut > item.mk_cut) && item.po_cut > 0 && item.mk_cut > 0),'text-theme-9' : ((item.po_cut < item.mk_cut) && item.po_cut > 0 && item.mk_cut > 0)}">{{ (item.final_cut_name ? item.final_cut_name : '-') }}</td>
                                            <td v-if="filter.compare_items.includes('3')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" :class="{'text-theme-6' : ((item.po_purity > item.mk_purity) && item.po_purity > 0 && item.mk_purity > 0),'text-theme-9' : ((item.po_purity < item.mk_purity) && item.po_purity > 0 && item.mk_purity > 0)}">{{ (item.final_purity_name ? item.final_purity_name : '-') }}</td>
                                            <td v-if="filter.compare_items.includes('3')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.final_shape_name ? item.final_shape_name : '-') }}</td>
                                            <th v-if="filter.compare_items.includes('3')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.po_f_pw ? item.po_f_pw : '-') }}</th>
                                            <th v-if="filter.compare_items.includes('3')" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.po_f_pw ? $h.myFloat(item.po_f_pw - item.mk_ex_wt) : '-') }}</th>
                                            <th v-if="filter.compare_items.includes('3') && inx == 0" :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"><span v-if="item.po_date != null"><span class="m-5">{{ (main_pack.total_final_weight ? $h.myFloat(main_pack.total_final_weight) : '-') }}</span><br><hr class="my-3"><span>{{ (main_pack.total_final_percent ? main_pack.total_final_percent + ' %' : '-') }}</span><br><hr class="my-3"><span class="">{{ (main_pack.total_diff_final_percent ? main_pack.total_diff_final_percent + ' %' : '-') }}</span></span></th>
                                            <!-- <th class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.lab_date ? item.lab_date : '-') }}</th>
                                            <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" :class="{'text-theme-6' : ((item.lab_color < item.po_color) && item.po_color > 0 && item.lab_color > 0),'text-theme-9' : ((item.po_color < item.lab_color) && item.po_color > 0 && item.lab_color > 0)}">{{ (item.lab_color_name ? item.lab_color_name : '-') }}</td>
                                            <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" :class="{'text-theme-6' : ((item.lab_cut < item.po_cut) && item.po_cut > 0 && item.lab_cut > 0),'text-theme-9' : ((item.po_cut < item.lab_cut) && item.po_cut > 0 && item.lab_cut > 0)}">{{ (item.lab_cut_name ? item.lab_cut_name : '-') }}</td>
                                            <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" :class="{'text-theme-6' : ((item.lab_purity < item.po_purity) && item.po_purity > 0 && item.lab_purity > 0),'text-theme-9' : ((item.po_purity < item.lab_purity) && item.po_purity > 0 && item.lab_purity > 0)}">{{ (item.lab_purity_name ? item.lab_purity_name : '-') }}</td>
                                            <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.lab_shape_name ? item.lab_shape_name : '-') }}</td>
                                            <th class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.lab_wt ? item.lab_wt : '-') }}</th>
                                            <th class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.lab_wt ? $h.myFloat(item.lab_wt - item.po_f_pw) : '-') }}</th>
                                            <th v-if="inx == 0" :rowspan="main_pack.packet_details.length" class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r"><span class="m-5">{{ (main_pack.total_lab_weight ? $h.myFloat(main_pack.total_lab_weight) : '-') }}</span><br><hr class="my-3"><span>{{ (main_pack.total_lab_percent ? main_pack.total_lab_percent + ' %' : '-') }}</span><br><hr class="my-3"><span class="">{{ (main_pack.total_diff_lab_percent ? main_pack.total_diff_lab_percent + ' %' : '-') }}</span></th> -->
                                        </tr>
                                      </template>
                                    </template>

                                    <tr class="border-b bg-gray-100 dark:bg-dark-1">
                                        <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                        <td class="p-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.total_packets }}</td>
                                        <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                        <!-- <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td> -->
                                        <td class="p-2 border-b text-xs lg:text-md text-center border-r" v-if="[1,2,8].includes(user_details.user_role)"></td>
                                        <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                        <td class="p-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_rough_weight ? $h.myFloat(page_data.total.total_rough_weight) : 0) }} Ct</td>
                                        <td class="p-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_issue_weight ? $h.myFloat(page_data.total.total_issue_weight) : 0) }} Ct</td>
                                        <td class="p-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_ls_packets ? page_data.total.total_ls_packets : 0) }}</td>
                                        <td class="p-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_ls_weight ? $h.myFloat(page_data.total.total_ls_weight) : 0) }} Ct</td>
                                        <td class="p-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_ls_polish_weight ? $h.myFloat(page_data.total.total_ls_polish_weight) : 0) }} Ct</td>
                                        <td class="p-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_ls_packets ? page_data.total.total_ls_packets : 0) }}</td>
                                        <td class="p-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_received_packets ? page_data.total.total_received_packets : 0) }}</td>
                                        <td class="p-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_received_weight ? $h.myFloat(page_data.total.total_received_weight) : 0) }} Ct</td>
                                        <td class="p-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_semi_packets ? page_data.total.total_semi_packets : 0) }}</td>
                                        <td class="p-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_semi_weight ? $h.myFloat(page_data.total.total_semi_weight) : 0) }} Ct</td>
                                        <td class="p-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_pending_packets ? page_data.total.total_pending_packets : 0) }}</td>
                                        <td class="p-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_pending_weight ? $h.myFloat(page_data.total.total_pending_weight) : 0) }} Ct</td>
                                        <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                        <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                        <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                        <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                        <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                        <th v-if="filter.compare_items.includes('1')" class="p-2 text-xs lg:text-md border"></th>
                                        <th v-if="filter.compare_items.includes('1')" class="p-2 text-xs lg:text-md border"></th>
                                        <th v-if="filter.compare_items.includes('1')" class="p-2 text-xs lg:text-md border"></th>
                                        <th v-if="filter.compare_items.includes('1')" class="p-2 text-xs lg:text-md border"></th>
                                        <th v-if="filter.compare_items.includes('1')" class="p-2 text-xs lg:text-md border">{{ (page_data.total.total_planning_polish_weight ? $h.myFloat(page_data.total.total_planning_polish_weight) : '0') }}</th>
                                        <th v-if="filter.compare_items.includes('1')" class="p-2 text-xs lg:text-md border"></th>
                                        <th v-if="filter.compare_items.includes('2')" class="p-2 text-xs lg:text-md border"></th>
                                        <th v-if="filter.compare_items.includes('2')" class="p-2 text-xs lg:text-md border"></th>
                                        <th v-if="filter.compare_items.includes('2')" class="p-2 text-xs lg:text-md border"></th>
                                        <th v-if="filter.compare_items.includes('2')" class="p-2 text-xs lg:text-md border">{{ (page_data.total.total_makeable_weight ? $h.myFloat(page_data.total.total_makeable_weight) : '0') }}</th>
                                        <th v-if="filter.compare_items.includes('2')" class="p-2 text-xs lg:text-md border"></th>
                                        <th v-if="filter.compare_items.includes('2')" class="p-2 text-xs lg:text-md border"></th>
                                        <th v-if="filter.compare_items.includes('2')" class="p-2 text-xs lg:text-md border"></th>
                                        <th v-if="filter.compare_items.includes('2')" class="p-2 text-xs lg:text-md border"></th>
                                        <th v-if="filter.compare_items.includes('2')" class="p-2 text-xs lg:text-md border"></th>
                                        <th v-if="filter.compare_items.includes('2')" class="p-2 text-xs lg:text-md border">{{ (page_data.total.total_makeable_polish_weight ? $h.myFloat(page_data.total.total_makeable_polish_weight) : '0') }}</th>
                                        <th v-if="filter.compare_items.includes('2')" class="p-2 text-xs lg:text-md border">{{ (page_data.total.total_diff_makeable_weight ? $h.myFloat(page_data.total.total_diff_makeable_weight) : '0') }}</th>
                                        <th v-if="filter.compare_items.includes('2')" class="p-2 text-xs lg:text-md border"></th>
                                        <th v-if="filter.compare_items.includes('3')" class="p-2 text-xs lg:text-md border"></th>
                                        <th v-if="filter.compare_items.includes('3')" class="p-2 text-xs lg:text-md border"></th>
                                        <th v-if="filter.compare_items.includes('3')" class="p-2 text-xs lg:text-md border"></th>
                                        <th v-if="filter.compare_items.includes('3')" class="p-2 text-xs lg:text-md border"></th>
                                        <th v-if="filter.compare_items.includes('3')" class="p-2 text-xs lg:text-md border"></th>
                                        <th v-if="filter.compare_items.includes('3')" class="p-2 text-xs lg:text-md border"></th>
                                        <th v-if="filter.compare_items.includes('3')" class="p-2 text-xs lg:text-md border"></th>
                                        <th v-if="filter.compare_items.includes('3')" class="p-2 text-xs lg:text-md border">{{ (page_data.total.total_final_weight ? $h.myFloat(page_data.total.total_final_weight) : '0') }}</th>
                                        <th v-if="filter.compare_items.includes('3')" class="p-2 text-xs lg:text-md border">{{ (page_data.total.total_diff_final_weight ? $h.myFloat(page_data.total.total_diff_final_weight) : '0') }}</th>
                                        <th v-if="filter.compare_items.includes('3')" class="p-2 text-xs lg:text-md border"></th>
                                        <!-- <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                        <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                        <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                        <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                        <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th>
                                        <th class="p-2 text-xs lg:text-md whitespace-nowrap border">{{ (page_data.total.total_lab_weight ? $h.myFloat(page_data.total.total_lab_weight) : '0') }}</th>
                                        <th class="p-2 text-xs lg:text-md whitespace-nowrap border">{{ (page_data.total.total_diff_lab_weight ? $h.myFloat(page_data.total.total_diff_lab_weight) : '0') }}</th>
                                        <th class="p-2 text-xs lg:text-md whitespace-nowrap border"></th> -->
                                      </tr>

                                </tbody>

                          </table>

                          <table class="w-full border-0" v-if="api_filter.report_type == 3">
                              <thead>
                                  <tr class="border-b bg-gray-300 dark:bg-dark-1">
                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap">#</th>
                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap">Packet No</th>
                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap">Stock No</th>
                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap" v-if="[1,2,8].includes(user_details.user_role)">Worker</th>
                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap">Issue Date</th>
                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap">Rough Wight</th>
                                      <th class="p-2 text-xs lg:text-md whitespace-nowrap">Process Wight</th>
                                    </tr>
                                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                        <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                        <td class="p-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.total_packets }}</td>
                                        <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                        <td class="p-2 border-b text-xs lg:text-md text-center border-r" v-if="[1,2,8].includes(user_details.user_role)"></td>
                                        <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                        <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                        <td class="p-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_issue_weight ? $h.myFloat(page_data.total.total_issue_weight) : 0) }} Ct</td>
                                    </tr>
                              </thead>
                                  <tbody>

                                    <tr v-for="(item,index) in page_data.items" :key="index" class="border-b hover:bg-gray-200 p-0" :class="{'bg-gray-100': (index % 2) == 0}">
                                        <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ index + 1 }}</td>
                                        <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.number }}</td>
                                        <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.vepari_no }}</td>
                                        <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r" v-if="[1,2,8].includes(user_details.user_role)">{{ item.company_name ? item.company_name : ((item.first_name ? item.first_name : '') + ' ' + (item.last_name ? item.last_name : '')) }}</td>
                                        <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.issue_date }}</td>
                                        <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.rough_weight ? $h.myFloat(item.rough_weight) : 0) }} Ct</td>
                                        <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.issue_weight ? $h.myFloat(item.issue_weight) : 0) }} Ct</td>
                                        <!-- <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.total_ls_packets ? item.total_ls_packets : 0) }}</td>
                                        <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.total_ls_weight ? $h.myFloat(item.total_ls_weight) : 0) }} Ct</td>
                                        <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.total_ls_polish_weight ? $h.myFloat(item.total_ls_polish_weight) : 0) }} Ct</td>
                                        <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.total_ls_packets ? item.total_ls_packets : 0) }}</td>
                                        <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.total_received_packets ? item.total_received_packets : 0) }}</td>
                                        <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.total_pending_packets ? item.total_pending_packets : 0) }}</td>
                                        <td class="py-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.total_received_weight ? $h.myFloat(item.total_received_weight) : 0) }} Ct</td> -->
                                    </tr>
                                </tbody>

                          </table>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <!-- END: HTML Table Data -->

  </div>
  </template>

  <script>
  /* eslint-disable */
  import {
      BASE_URL
  } from '@/constants/config';
  import axios from 'axios';
  import moment from 'moment';
  import {
      defineComponent,
      onMounted,
      reactive,
      toRef,
      watch,
      computed
  } from 'vue';
  import store from '@/store'
  import { helper as $h } from '@/utils/helper'

  export default defineComponent({
      setup() {
          const user_details = computed(() => store.state.user.currentUser)
          const page_data = reactive({
              total: [],
              items: [],
              manager_items: [],
              process_items: [],
              lot_items: [],
              employee_items: [],
              loading: false,
          })

          const filter = reactive({
              packet_no: '',
              jangad_no: [],
              report_type: '1',
              kapan_id: '0',
              worker_id: '0',
              compare_items: ['1', '2', '3'],
              issue_pending : false,
              pending_packet : false,
              issue_date: moment().startOf('month').format('YYYY-MM-DD'),
              receive_date:moment().format('YYYY-MM-DD'),
          })

          const api_filter = reactive({
              packet_no: '',
              jangad_no: [],
              report_type: '1',
              kapan_id: '0',
              worker_id: '0',
              issue_pending : false,
              pending_packet : false,
              issue_date: moment().startOf('month').format('YYYY-MM-DD'),
              receive_date:moment().format('YYYY-MM-DD'),
          })

          const getReportDetails = async () => {

              api_filter.packet_no = filter.packet_no
              api_filter.jangad_no = filter.jangad_no
              api_filter.report_type = filter.report_type
              api_filter.kapan_id = filter.kapan_id
              api_filter.worker_id = filter.worker_id
              api_filter.issue_pending = filter.issue_pending
              api_filter.pending_packet = filter.pending_packet
              api_filter.issue_date = filter.issue_date
              api_filter.receive_date = filter.receive_date

              const params = {
                  packet_no: window.btoa(api_filter.packet_no),
                  jangad_no: api_filter.jangad_no.length == 0 ? '' : (api_filter.jangad_no),
                  report_type: api_filter.report_type == '0' ? '' : window.btoa(api_filter.report_type),
                  kapan_id: api_filter.kapan_id == '0' ? '' : window.btoa(api_filter.kapan_id),
                  worker_id: api_filter.worker_id == '0' ? '' : window.btoa(api_filter.worker_id),
                  issue_pending : api_filter.issue_pending,
                  pending_packet : api_filter.pending_packet,
                  start_date : window.btoa(api_filter.issue_date),
                  end_date : window.btoa(api_filter.receive_date),
              }
              page_data.loading = true

              let promise = axios({
                  url: 'api/report/rtp_details',
                  method: "post",
                  data: params,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token"),

                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {
                      page_data.items = data.items
                      page_data.total = data.total
                      page_data.loading = false
                  })
                  .catch(_error => {
                      page_data.items = []
                      page_data.total = []
                      page_data.loading = false
                  });
          }

          const getLotDetails = async () => {
              let params = {
              }
              let api_url = 'api/master/dp_lot_all_details'

              let promise = axios({
                  url: api_url,
                  method: "post",
                  data: params,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token"),

                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {
                      filter.kapan_id = '0'
                      page_data.lot_items = data.items
                  })
                  .catch(_error => {
                      filter.kapan_id = '0'
                      page_data.lot_items = []
                  });
          }

          const getJangadDetails = async () => {
              let params = {
              }
              let api_url = 'api/report/rtp_jangad_list'

              let promise = axios({
                  url: api_url,
                  method: "post",
                  data: params,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token"),

                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {
                      filter.jangad_no = []
                      page_data.jangad_items = data.items
                  })
                  .catch(_error => {
                      filter.jangad_no = []
                      page_data.jangad_items = []
                  });
          }

          const getWorkerDetails = async () => {

              let params = {
                  manager_id: user_details.value.user_role == 1 ? '' : user_details.value.account_id,
                  employee_type: window.btoa('3'),
              }
              let api_url = ''

              if (filter.department_id == '0') {

                  api_url = 'api/master/get_employees_by_manager_employee_type'
              } else {
                  api_url = 'api/master/get_employees_by_manager_employee_type'
              }
              let promise = axios({
                  url: api_url,
                  method: "post",
                  data: params,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token"),

                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  }
              });
              return promise
                  .then(result => result.data)
                  .then(data => {
                      filter.worker_id = '0'
                      page_data.employee_items = data.items
                  })
                  .catch(_error => {
                      filter.worker_id = '0'
                      page_data.employee_items = []
                  });
          }

          function onResetFilter() {
              filter.packet_no = ''
              filter.jangad_no = []
              filter.report_type = '1'
              filter.kapan_id = '0'
              filter.worker_id = '0'
              filter.issue_pending = false
              filter.pending_packet = false
              filter.issue_date = moment().startOf('month').format('YYYY-MM-DD')
              filter.receive_date =moment().format('YYYY-MM-DD')
          }
          onMounted(() => {
              getLotDetails()
              getReportDetails()
              getWorkerDetails()
              getJangadDetails()
          })
          return {
              page_data,
              filter,
              api_filter,
              user_details,
              getReportDetails,
              onResetFilter,
              getWorkerDetails,
          }
      }
  })
  </script>
  <style scoped>
  .tableFixHead {
          overflow-y: auto; /* make the table scrollable if height is more than 200 px  */
          max-height: 650px; /* gives an initial height of 200px to the table */
        }
        .tableFixHead thead {
          position: sticky; /* make the table heads sticky */
          top: 0px; /* table head will be placed from the top of the table and sticks to it */
        }
        </style>
