<template>
  <div>
      <div class="intro-y flex items-center mt-8">
          <h2 class="text-lg font-medium mr-auto">Receive Recut</h2>
      </div>
      <div class="grid grid-cols-12 mt-5">
          <div class="intro-y col-span-12 lg:col-span-12">
              <!-- BEGIN: Input -->
              <div class="intro-y box">
                  <div class="p-5">
                      <div class="grid grid-cols-12 gap-x-5">
                          <div class="col-span-12 xl:col-span-4">

                              <div>
                                  <label for="update-profile-form-8" class="form-label">Scan Lot No</label>
                                  <div class="input-group mt-2">

                                      <input id="update-profile-form-6" type="text" tabindex="1" placeholder="Scan Barcode" v-model="scan_lot_no" :class="{'form-control':true,'loading':scanload}" :disabled="scanload" autocomplete="off" @change="scanAndAddPack" />

                                      <button type="button" class="btn btn-primary mr-auto" @click="scanAndAddPack" style="border-radius: 0!important">
                                          <SearchIcon class="w-5 h-5 mr-2" /> Search
                                      </button>
                                      <button type="button" class="btn btn-primary-outline mr-auto" @click="clearSearch('')" style="border-radius: 0!important">
                                          Clear
                                      </button>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <!-- END: Input -->
          </div>
      </div>

      <div class="grid grid-cols-12 mt-5">
          <div class="intro-y col-span-12 lg:col-span-12">
              <!-- BEGIN: Input -->
              <div class="intro-y box">
                  <div class="flex flex-col sm:flex-row items-center justify-between p-5 border-b border-gray-200 dark:border-dark-5">
                      <h2 class="font-medium text-base mr-auto">Packet Details</h2>
                      <div class="text-right">
                          <button v-print="'#print-all-barcods'" class="btn btn-primary btn-sm mr-2">Print All</button>
                          <button class="btn btn-danger btn-sm mr-2"  v-if="user_details.user_role == 1 && packet_details.type == 'makeable'" @click="resetMakeable">Reset Makeable</button>
                          <button class="btn btn-primary btn-sm mr-2"  v-if="user_details.user_role == 1 && packet_details.type == 'makeable'" @click="editMakeable">Edit Makeable</button>
                          <!-- <button class="btn btn-primary btn-sm mr-2" @click="set_part_details" v-if="packet_details.part_ready == 1 && (user_details.account_id == packet_details.manager_id) && (user_details.user_role == packet_details.manager_type)">Add Planning Part</button> -->
                          <!-- <button class="btn btn-primary btn-sm mr-2" @click="reset_ls_data" v-if="packet_details.part_ready == 1 && (user_details.account_id == packet_details.manager_id) && (user_details.user_role == packet_details.manager_type)">Reset(LS)</button> -->
                          <!-- <button class="btn btn-primary btn-sm mr-2" @click="get_ls_edit_details" v-if="packet_details.part_ready == 1 && (user_details.account_id == packet_details.manager_id) && (user_details.user_role == packet_details.manager_type)">Edit(LS)</button> -->
                      </div>
                  </div>

                  <div class="p-1 pb-10">
                      <div class="col-span-12">
                              <div style="background-color:#fff;">
                                  <table class="mt-3 w-full">
                                      <thead>
                                          <tr class="border-b bg-gray-200 border dark:bg-dark-1">
                                              <th class="py-2 text-center border">Pkt No</th>
                                              <th class="py-2 text-center border">Weight</th>
                                              <th class="py-2 text-center border">Current Weight</th>
                                              <th class="py-2 text-center border">MK Weight</th>
                                              <th class="py-2 text-center border">Exp. Wt</th>
                                              <th class="py-2 text-center border">Sp</th>
                                              <th class="py-2 text-center border">Cl</th>
                                              <th class="py-2 text-center border">Prt</th>
                                              <th class="py-2 text-center border">Ct</th>
                                              <th class="py-2 text-center border">Pl</th>
                                              <th class="py-2 text-center border">Sym</th>
                                              <th class="py-2 text-center border">Stn</th>
                                              <th class="py-2 text-center border">Fluc</th>
                                              <th class="py-2 text-center border">Tn</th>
                                              <th class="py-2 text-center border w-28">Print</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          <tr v-for="(packet, index) in stock_items" :key="index" class="border hover:bg-gray-200 p-0 cursor-pointer">
                                              <td class="py-1 font-medium text-center border">{{ packet.number }}</td>
                                              <td class="py-1 font-medium text-center border">{{ packet.weight }}</td>
                                              <td class="py-1 font-medium text-center border">{{ packet.cr_weight }}</td>
                                              <td class="py-1 font-medium text-center border">{{ packet.mk_weight }}</td>
                                              <td class="py-1 font-medium text-center border">{{ packet.polish_weight }}</td>
                                              <td class="py-1 font-medium text-center border">{{ packet.shape }}</td>
                                              <td class="py-1 font-medium text-center border">{{ packet.color }}</td>
                                              <td class="py-1 font-medium text-center border">{{ packet.purity }}</td>
                                              <td class="py-1 font-medium text-center border">{{ packet.cut }}</td>
                                              <td class="py-1 font-medium text-center border">{{ packet.polish }}</td>
                                              <td class="py-1 font-medium text-center border">{{ packet.symmetry }}</td>
                                              <td class="py-1 font-medium text-center border">{{ packet.stone }}</td>
                                              <td class="py-1 font-medium text-center border">{{ packet.fluerocent }}</td>
                                              <td class="py-1 font-medium text-center border">{{ packet.tension }}</td>
                                              <td class="py-1 font-medium text-center border">
                                                  <div class="flex justify-between items-center">
                                                    <input
                                                  class="show-code form-check-switch mr-0 ml-3 p-0"
                                                  type="checkbox" v-model="packet.print_status"
                                                />
                                                      <button class="btn btn-primary btn-sm p-1 mr-3" v-print="'#' + packet.number" v-if="packet.print_status == true" @click="update_print_status(packet)">
                                                          <PrinterIcon class="w-5 h-5" />
                                                      </button>
                                                      <button class="btn btn-primary btn-sm p-1 mr-3" v-print="'#' + packet.number" v-if="packet.print_status == false" disabled @click="update_print_status(packet)">
                                                          <PrinterIcon class="w-5 h-5" />
                                                      </button>
                                                      <div class="p-0 m-0 hidden">
                                                          <div :id="packet.number" class="absolute top-0 left-0 p-0 m-0">
                                                              <table class="p-0 m-0 mt-2 w-full text-black" border="collapse">
                                                                  <tr class="text-center border border-l-0 border-r-0 border-black m-0 p-0">
                                                                      <th colspan="3" class="border m-auto border-black" style="font-size: 11px;line-height: 9px;">{{ packet.number }}</th>
                                                                      <!-- <th colspan="2" class="border m-auto border-black" style="font-size: 11px;line-height: 9px;">Lot : {{ packet.vepari_no }}</th> -->
                                                                      <th colspan="2" class="border m-auto border-black" style="font-size: 11px;line-height: 9px;">RW : {{ packet.mk_weight ? packet.mk_weight : packet.weight }}</th>
                                                                      <th colspan="3" class="border m-auto border-black" style="font-size: 11px;line-height: 9px;">PW : {{ packet.polish_weight }}</th>
                                                                  </tr>
                                                                  <tr class="text-center m-0 p-0">
                                                                      <th colspan="8" class="text-center">
                                                                          <VueBarcode :value="packet.barnum" :options="{ displayValue: false,height:27,width:2 }" tag="svg"></VueBarcode>
                                                                      </th>
                                                                  </tr>
                                                                  <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0" v-if="packet.mk_weight != null">
                                                                      <th class="border m-auto border-black">SHP</th>
                                                                      <th class="border m-auto border-black">CL</th>
                                                                      <th class="border m-auto border-black">PRT</th>
                                                                      <th class="border m-auto border-black">CT</th>
                                                                      <th class="border m-auto border-black">PL</th>
                                                                      <th class="border m-auto border-black">SYM</th>
                                                                      <th class="border m-auto border-black">Fl</th>
                                                                      <th class="border m-auto border-black">TN</th>
                                                                      <!-- <th class="border m-auto border-black">STN</th> -->
                                                                  </tr>
                                                                  <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0" v-if="packet.mk_weight != null">
                                                                      <th class="border m-auto border-black">{{ packet.shape }}</th>
                                                                      <th class="border m-auto border-black">{{ packet.color }}</th>
                                                                      <th class="border m-auto border-black">{{ packet.purity }}</th>
                                                                      <th class="border m-auto border-black">{{ packet.cut }}</th>
                                                                      <th class="border m-auto border-black">{{ packet.polish }}</th>
                                                                      <th class="border m-auto border-black">{{ packet.symmetry }}</th>
                                                                      <th class="border m-auto border-black">{{ packet.fluerocent }}</th>
                                                                      <th class="border m-auto border-black">{{ packet.tension }}</th>
                                                                      <!-- <th class="border m-auto border-black">{{ packet.stone }}</th> -->
                                                                  </tr>
                                                              </table>
                                                              <p class="p-0 mt-1 text-black w-full text-right" style="font-size: 7px;line-height: 1;letter-spacing: 3px;font-weight: bold;"> <span class="text-left">EXCELLENCE</span> / <span class="text-right">KODLLIN TECHONOLOGY</span></p>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </td>
                                          </tr>
                                      </tbody>
                                  </table>
                                  <div class="hidden">
                                      <div id="print-all-barcods" class="absolute left-0 top-0 p-0 m-0">

                                          <div style="page-break-before: always;" :class="{'': index != 0 || (index == 1 && plan_details[0]['ls_done_id'] == null)}" v-for="(packet,index) in stock_items" :key="index">
                                              <table class="p-0 w-full text-black mt-2" border="collapse" v-if="(index != 0) || (index == 0 && packet.ls_done_id != null)">
                                                  <tr class="text-center border border-l-0 border-r-0 border-black m-auto">
                                                      <th colspan="3" class="border m-auto border-black" style="font-size: 11px;line-height: 9px;">{{ packet.number }}</th>
                                                      <!-- <th colspan="2" class="border m-auto border-black" style="font-size: 11px;line-height: 9px;">Lot : {{ packet.vepari_no }}</th> -->
                                                      <th colspan="2" class="border m-auto border-black" style="font-size: 11px;line-height: 9px;">RW : {{ packet.mk_weight ? packet.mk_weight : packet.weight }}</th>
                                                      <th colspan="3" class="border m-auto border-black" style="font-size: 11px;line-height: 9px;">PW : {{ packet.polish_weight }}</th>
                                                  </tr>
                                                  <tr class="text-center">
                                                      <th colspan="8" class="text-center">
                                                          <VueBarcode :value="packet.barnum" :options="{ displayValue: false,height:27,width:2 }" tag="svg"></VueBarcode>
                                                      </th>
                                                  </tr>
                                                  <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0" v-if="packet.mk_weight != null">
                                                      <th class="border m-auto border-black">SHP</th>
                                                      <th class="border m-auto border-black">CL</th>
                                                      <th class="border m-auto border-black">PRT</th>
                                                      <th class="border m-auto border-black">CT</th>
                                                      <th class="border m-auto border-black">PL</th>
                                                      <th class="border m-auto border-black">SYM</th>
                                                      <th class="border m-auto border-black">Fl</th>
                                                      <th class="border m-auto border-black">TN</th>
                                                      <!-- <th class="border m-auto border-black">STN</th> -->
                                                  </tr>
                                                  <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0" v-if="packet.mk_weight != null">
                                                      <th class="border m-auto border-black">{{ packet.shape }}</th>
                                                      <th class="border m-auto border-black">{{ packet.color }}</th>
                                                      <th class="border m-auto border-black">{{ packet.purity }}</th>
                                                      <th class="border m-auto border-black">{{ packet.cut }}</th>
                                                      <th class="border m-auto border-black">{{ packet.polish }}</th>
                                                      <th class="border m-auto border-black">{{ packet.symmetry }}</th>
                                                      <th class="border m-auto border-black">{{ packet.fluerocent }}</th>
                                                      <th class="border m-auto border-black">{{ packet.tension }}</th>
                                                      <!-- <th class="border m-auto border-black">{{ packet.stone }}</th> -->
                                                  </tr>
                                              </table>

                                              <p class="p-0 mt-1 text-black w-full text-right" v-if="(index != 0) || (index == 0 && packet.ls_done_id != null)" style="font-size: 7px;line-height: 1;letter-spacing: 3px;font-weight: bold;"> <span class="text-left">EXCELLENCE</span> / <span class="text-right">KODLLIN TECHONOLOGY</span></p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                      </div>
                  </div>
              </div>
              <!-- END: Input -->
          </div>
      </div>
      <div class="grid grid-cols-12 mt-5" v-if="edit_flag == 1 && (packet_details.status == 1) && (user_details.account_id == packet_details.manager_id) && (user_details.user_role == packet_details.manager_type)">
          <div class="intro-y col-span-12 lg:col-span-12">
              <!-- BEGIN: Input -->
              <div class="intro-y box p-5 px-1">

                <div class="flex flex-col sm:flex-row items-center p-5 py-3 border-b border-gray-200 dark:border-dark-5">
                      <h2 class="font-medium text-base mr-auto">Makeable Transfer Details</h2>
                  </div>

                  <div class="p-5">
                      <div class="grid grid-cols-12 gap-x-5 w-full">
                          <div class="col-span-12 md:col-span-4">

                              <div>
                                  <label for="update-profile-form-6" class="form-label">Packet No :&nbsp; </label>
                                  <label for="update-profile-form-6 " class="form-label font-bold"> {{ packet_details.number}}</label>
                              </div>
                              <div>
                                  <label for="update-profile-form-6" class="form-label">Lot weight :&nbsp; </label>
                                  <label for="update-profile-form-6 " class="form-label font-bold"> {{ packet_details.cr_weight}} Ct</label>
                              </div>

                              <!-- <div>
                                  <label for="update-profile-form-6" class="form-label">Current Manager :&nbsp; </label>
                                  <label for="update-profile-form-6 " class="form-label font-bold"> {{ packet_details.employee_first_name + ' '+ packet_details.employee_last_name}}</label>
                              </div>
                              <div>
                                  <label for="update-profile-form-6" class="form-label">Issue At :&nbsp; </label>
                                  <label for="update-profile-form-6 " class="form-label font-bold"> {{ packet_details.created_at}}</label>
                              </div> -->
                          </div>
                          <div class="col-span-12 md:col-span-2"></div>
                      </div>
                  </div>

                  <div class="p-1 pb-10">
                      <div class="col-span-12">
                          <div class="border rounded mx-5">
                              <div style="background-color:#fff;">
                                  <table class="w-full border">
                                      <thead>
                                          <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">#</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Polish Wt</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Shp</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Clr</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Prt</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Cut</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">PL</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Symm</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Fluc</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">TN</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Stn</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Ratio</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Tabl</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Height</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Repo</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Value</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Back</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          <tr v-for="(item,index) in plan_details" :key="index">
                                              <td class="py-1 border-b text-xs lg:text-md text-center border-r"><span><input type="checkbox" v-model="item.ls_check" @change="validateLSCheck(item)"></span></td>
                                              <td class="py-1 border-b text-xs lg:text-md text-center border-r"><input type="text" class="form-control w-16 py-1" @keypress="$h.preventInvalidInput" @change="loadPlanningValues(item)" v-model="item.polish_weight"></td>
                                              <td class="py-1 border-b text-xs lg:text-md text-center border-r"><select class="form-select py-1" v-model="item.shape" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                <option :value="shape.id" v-for="shape in diamond.shapes" :key="shape.id">{{ shape.shape_name }}</option>
                                                  </select>
                                              </td>
                                              <td class="py-1 border-b text-xs lg:text-md text-center border-r"><select class="form-select py-1" v-model="item.color" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                <option :value="color.id" v-for="color in diamond.colors" :key="color.id">{{ color.color_name }}</option>
                                                  </select>
                                              </td>
                                              <td class="py-1 border-b text-xs lg:text-md text-center border-r"><select class="form-select py-1" v-model="item.purity" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                      <option :value="purity.id" v-for="purity in diamond.purities" :key="purity.id">{{ purity.purity_name }}</option>
                                                  </select>
                                              </td>
                                              <td class="py-1 border-b text-xs lg:text-md text-center border-r"><select class="form-select py-1" v-model="item.cut" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                      <option :value="cut.id" v-for="cut in diamond.cuts" :key="cut.id">{{ cut.cut_name }}</option>
                                                  </select>
                                              </td>
                                              <td class="py-1 border-b text-xs lg:text-md text-center border-r"><select class="form-select py-1" v-model="item.polish" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                      <option :value="polish.id" v-for="polish in diamond.polish" :key="polish.id">{{ polish.polish_name }}</option>
                                                  </select>
                                              </td>
                                              <td class="py-1 border-b text-xs lg:text-md text-center border-r"><select class="form-select py-1" v-model="item.symmetry" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                      <option :value="symmetry.id" v-for="symmetry in diamond.symmetry" :key="symmetry.id">{{ symmetry.symmetry_name }}</option>
                                                  </select>
                                              </td>
                                              <td class="py-1 border-b text-xs lg:text-md text-center border-r"><select class="form-select py-1" v-model="item.fluerocent" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                      <option :value="fluerocent.id" v-for="fluerocent in diamond.fluerocents" :key="fluerocent.id">{{ fluerocent.fluerocent_name }}</option>
                                                  </select>
                                              </td>
                                              <td class="py-1 border-b text-xs lg:text-md text-center border-r"><select class="form-select py-1" v-model="item.tension" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                      <option :value="tension.id" v-for="tension in diamond.tensions" :key="tension.id">{{ tension.tension_name }}</option>
                                                  </select>
                                              </td>
                                              <td class="py-1 border-b text-xs lg:text-md text-center border-r"><select class="form-select py-1" v-model="item.stone" @change="loadPlanningValues(item)" aria-label="Default select example" disabled>
                                                      <option :value="stone.id" v-for="stone in diamond.stones" :key="stone.id">{{ stone.stone_name }}</option>
                                                  </select>
                                              </td>
                                              <td class="py-1 border-b text-xs lg:text-md text-center border-r"><select class="form-select py-1" v-model="item.ratio" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                      <option :value="ratio.id" v-for="ratio in diamond.ratios" :key="ratio.id">{{ ratio.ratio_name }}</option>
                                                  </select>
                                              </td>
                                              <td class="py-1 border-b text-xs lg:text-md text-center border-r"><select class="form-select py-1" v-model="item.tabl" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                      <option :value="tabl.id" v-for="tabl in diamond.tabls" :key="tabl.id">{{ tabl.tabl_name }}</option>
                                                  </select>
                                              </td>
                                              <td class="py-1 border-b text-xs lg:text-md text-center border-r"><select class="form-select py-1" v-model="item.height" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                      <option :value="height.id" v-for="height in diamond.heights" :key="height.id">{{ height.height_name }}</option>
                                                  </select>
                                              </td>
                                              <td class="py-1 border-b text-xs lg:text-md text-center border-r"><input type="text" class="form-control w-16 py-1" @keypress="$h.preventInvalidInput" disabled v-model="item.r_rate"></td>
                                              <td class="py-1 border-b text-xs lg:text-md text-center border-r"><input type="text" class="form-control w-16 py-1" @keypress="$h.preventInvalidInput" disabled v-model="item.value"></td>
                                              <td class="py-1 border-b text-xs lg:text-md text-center border-r"><input type="text" class="form-control w-16 py-1" @keypress="$h.preventInvalidInput" disabled v-model="item.discount"></td>
                                          </tr>
                                      </tbody>
                                  </table>
                              </div>
                              <div class="w-full flex justify-between py-5">
                                <div>

                                  <button class="btn btn-primary btn-sm py-1" title="Add New Rough" @click="addNewPart">
                                    <PlusIcon class="h-4 w-4" /> Add Plan
                                  </button>
                                </div>
                                <div>

                                  <button type="button" class="btn btn-primary btn-sm" @click="submitReturnRough">
                                      Transfer makeable
                                  </button>
                                </div>

                              </div>

                          </div>

                      </div>
                  </div>
              </div>
              <!-- END: Input -->
          </div>
      </div>
      <div class="grid grid-cols-12 mt-5" v-if="edit_flag == 2 && (user_details.user_role == 1)">
          <div class="intro-y col-span-12 lg:col-span-12">
              <!-- BEGIN: Input -->
              <div class="intro-y box p-5 px-1">

                <div class="flex flex-col sm:flex-row items-center p-5 py-3 border-b border-gray-200 dark:border-dark-5">
                      <h2 class="font-medium text-base mr-auto">Makeable Transfer Details</h2>
                  </div>

                  <div class="p-5">
                      <div class="grid grid-cols-12 gap-x-5 w-full">
                          <div class="col-span-12 md:col-span-4">

                              <div>
                                  <label for="update-profile-form-6" class="form-label">Packet No :&nbsp; </label>
                                  <label for="update-profile-form-6 " class="form-label font-bold"> {{ packet_details.number}}</label>
                              </div>
                              <div>
                                  <label for="update-profile-form-6" class="form-label">Lot weight :&nbsp; </label>
                                  <label for="update-profile-form-6 " class="form-label font-bold"> {{ packet_details.cr_weight}} Ct</label>
                              </div>

                              <!-- <div>
                                  <label for="update-profile-form-6" class="form-label">Current Manager :&nbsp; </label>
                                  <label for="update-profile-form-6 " class="form-label font-bold"> {{ packet_details.employee_first_name + ' '+ packet_details.employee_last_name}}</label>
                              </div>
                              <div>
                                  <label for="update-profile-form-6" class="form-label">Issue At :&nbsp; </label>
                                  <label for="update-profile-form-6 " class="form-label font-bold"> {{ packet_details.created_at}}</label>
                              </div> -->
                          </div>
                          <div class="col-span-12 md:col-span-2"></div>
                      </div>
                  </div>

                  <div class="p-1 pb-10">
                      <div class="col-span-12">
                          <div class="border rounded mx-5">
                              <div style="background-color:#fff;">
                                  <table class="w-full border">
                                      <thead>
                                          <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">#</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Polish Wt</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Shp</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Clr</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Prt</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Cut</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">PL</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Symm</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Fluc</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">TN</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Stn</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Ratio</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Tabl</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Height</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Repo</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Value</th>
                                              <th class="py-2 border-b text-xs lg:text-md text-center border-r">Back</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          <tr v-for="(item,index) in edit_plan_details" :key="index">
                                              <td class="py-1 border-b text-xs lg:text-md text-center border-r"><span><input type="checkbox" v-model="item.ls_check" @change="validateLSCheck(item)"></span></td>
                                              <td class="py-1 border-b text-xs lg:text-md text-center border-r"><input type="text" class="form-control w-16 py-1" @keypress="$h.preventInvalidInput" @change="loadPlanningValues(item)" v-model="item.polish_weight"></td>
                                              <td class="py-1 border-b text-xs lg:text-md text-center border-r"><select class="form-select py-1" v-model="item.shape" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                <option :value="shape.id" v-for="shape in diamond.shapes" :key="shape.id">{{ shape.shape_name }}</option>
                                                  </select>
                                              </td>
                                              <td class="py-1 border-b text-xs lg:text-md text-center border-r"><select class="form-select py-1" v-model="item.color" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                <option :value="color.id" v-for="color in diamond.colors" :key="color.id">{{ color.color_name }}</option>
                                                  </select>
                                              </td>
                                              <td class="py-1 border-b text-xs lg:text-md text-center border-r"><select class="form-select py-1" v-model="item.purity" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                      <option :value="purity.id" v-for="purity in diamond.purities" :key="purity.id">{{ purity.purity_name }}</option>
                                                  </select>
                                              </td>
                                              <td class="py-1 border-b text-xs lg:text-md text-center border-r"><select class="form-select py-1" v-model="item.cut" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                      <option :value="cut.id" v-for="cut in diamond.cuts" :key="cut.id">{{ cut.cut_name }}</option>
                                                  </select>
                                              </td>
                                              <td class="py-1 border-b text-xs lg:text-md text-center border-r"><select class="form-select py-1" v-model="item.polish" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                      <option :value="polish.id" v-for="polish in diamond.polish" :key="polish.id">{{ polish.polish_name }}</option>
                                                  </select>
                                              </td>
                                              <td class="py-1 border-b text-xs lg:text-md text-center border-r"><select class="form-select py-1" v-model="item.symmetry" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                      <option :value="symmetry.id" v-for="symmetry in diamond.symmetry" :key="symmetry.id">{{ symmetry.symmetry_name }}</option>
                                                  </select>
                                              </td>
                                              <td class="py-1 border-b text-xs lg:text-md text-center border-r"><select class="form-select py-1" v-model="item.fluerocent" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                      <option :value="fluerocent.id" v-for="fluerocent in diamond.fluerocents" :key="fluerocent.id">{{ fluerocent.fluerocent_name }}</option>
                                                  </select>
                                              </td>
                                              <td class="py-1 border-b text-xs lg:text-md text-center border-r"><select class="form-select py-1" v-model="item.tension" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                      <option :value="tension.id" v-for="tension in diamond.tensions" :key="tension.id">{{ tension.tension_name }}</option>
                                                  </select>
                                              </td>
                                              <td class="py-1 border-b text-xs lg:text-md text-center border-r"><select class="form-select py-1" v-model="item.stone" @change="loadPlanningValues(item)" aria-label="Default select example" disabled>
                                                      <option :value="stone.id" v-for="stone in diamond.stones" :key="stone.id">{{ stone.stone_name }}</option>
                                                  </select>
                                              </td>
                                              <td class="py-1 border-b text-xs lg:text-md text-center border-r"><select class="form-select py-1" v-model="item.ratio" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                      <option :value="ratio.id" v-for="ratio in diamond.ratios" :key="ratio.id">{{ ratio.ratio_name }}</option>
                                                  </select>
                                              </td>
                                              <td class="py-1 border-b text-xs lg:text-md text-center border-r"><select class="form-select py-1" v-model="item.tabl" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                      <option :value="tabl.id" v-for="tabl in diamond.tabls" :key="tabl.id">{{ tabl.tabl_name }}</option>
                                                  </select>
                                              </td>
                                              <td class="py-1 border-b text-xs lg:text-md text-center border-r"><select class="form-select py-1" v-model="item.height" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                      <option :value="height.id" v-for="height in diamond.heights" :key="height.id">{{ height.height_name }}</option>
                                                  </select>
                                              </td>
                                              <td class="py-1 border-b text-xs lg:text-md text-center border-r"><input type="text" class="form-control w-16 py-1" @keypress="$h.preventInvalidInput" disabled v-model="item.r_rate"></td>
                                              <td class="py-1 border-b text-xs lg:text-md text-center border-r"><input type="text" class="form-control w-16 py-1" @keypress="$h.preventInvalidInput" disabled v-model="item.value"></td>
                                              <td class="py-1 border-b text-xs lg:text-md text-center border-r"><input type="text" class="form-control w-16 py-1" @keypress="$h.preventInvalidInput" disabled v-model="item.discount"></td>
                                          </tr>
                                      </tbody>
                                  </table>
                              </div>
                              <div class="w-full flex justify-between py-5">
                                <div>

                                  <button class="btn btn-primary btn-sm py-1" title="Add New Rough" @click="addNewPart">
                                    <PlusIcon class="h-4 w-4" /> Add Plan
                                  </button>
                                </div>
                                <div>

                                  <button type="button" class="btn btn-primary btn-sm" @click="submitReturnRough">
                                      Transfer makeable
                                  </button>
                                </div>

                              </div>

                          </div>

                      </div>
                  </div>
              </div>
              <!-- END: Input -->
          </div>
      </div>
  </div>
  </template>

  <script>
  /* eslint-disable */
  import {
      defineComponent
  } from 'vue'
  import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
  import {
      BASE_URL
  } from '@/constants/config.js'
  import axios from 'axios'
  import datetime from 'vuejs-datetimepicker';
  import Tabulator from 'tabulator-tables'
  // import BarcodeGenerator from "@/components/barcode-generator/Main.vue";
  import VueBarcode from '@chenfengyuan/vue-barcode';

  export default defineComponent({
      components: {
          DarkModeSwitcher,
          datetime,
          VueBarcode
      },
      props: {
          rough_id: {
              type: String,
              default: ""
          }
      },

      data() {
          return {
            scanload: false,
            edit_flag: 1,
            scan_lot_no: "",
            last_pack_id: 1,
            plan_details: [],
            edit_plan_details: [],
            packet_details: [],
            stock_items: [],
            user_details: [],
            diamond: {
                colors: [],
                cuts: [],
                purities: [],
                symmetry: [],
                polish: [],
                fluerocents: [],
                tensions: [],
                stones: [],
                shapes: [],
                heights: [],
                ratios: [],
                tabls: [],
            },
          }
      },
      computed: {

      },
      mounted() {
          this.getDiamontData();

          this.user_details = this.$store.state.user.currentUser
      },

      methods: {
          async scanAndAddPack() {
              let _this = this;
              _this.clearSearch(this.scan_lot_no);
              this.scanload = true;

              //get lot details
              const data = {
                  barcode: this.scan_lot_no,
              };
              let url_post = '';
              if (this.scan_lot_no != '') {
                  url_post = "api/process/get_packet_details_for_recive_ls_makeable"
              } else {
                  url_post = "api/process/get_packet_details_for_recive_ls_makeable"
              }
              let promise = axios({
                  url: url_post,
                  method: "post",
                  data: data,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token"),

                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  },
              });
              return promise
              .then((result) => result.data)
              .then((data) => {

                this.packet_details = data.packet_details
                this.plan_details = data.plan_details
                this.stock_items = data.stock_items

                // Get Last Packet ID
                if(this.packet_details.length > 0) {

                  this.last_pack_id = this.packet_details[this.packet_details.length - 1].id
                }

                this.scanload = false;

              })
              .catch((_error) => {
                  this.scanload = false;
                  return [];
              });
          },
          async editMakeable() {
              let _this = this;

              //get lot details
              const data = {
                  packet_id: this.packet_details.id,
              };
              let url_post = "api/edit/get_packet_plan_detail"
              let promise = axios({
                  url: url_post,
                  method: "post",
                  data: data,
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: "Bearer " + localStorage.getItem("token"),

                  },
                  config: {
                      headers: {
                          Accept: "application/json"
                      }
                  },
              });
              return promise
              .then((result) => result.data)
              .then((data) => {
                this.edit_flag = 2;
                this.edit_plan_details = data.items

              })
              .catch((_error) => {
                  return [];
              });
          },
          clearSearch(lot_no) {
              this.edit_plan_details = [];
              this.plan_details = [];
              this.packet_details = [];
              this.stock_items = [];
              this.edit_flag = 1;
              this.scanload = false
          },
          getDiamontData() {
              axios({
                  url: 'api/master/get_all_parameters',
                  method: 'post',
                  baseURL: BASE_URL,
                  headers: {
                      Authorization: 'Bearer ' + localStorage.getItem('token')
                  },
                  config: {
                      headers: {
                          'Accept': 'application/json'
                      }
                  }
              }).then((res) => {
                  this.diamond.colors = res.data.color
                  this.diamond.cuts = res.data.cut
                  this.diamond.purities = res.data.purity
                  this.diamond.polish = res.data.polish
                  this.diamond.shapes = res.data.shape
                  this.diamond.symmetry = res.data.symmetry
                  this.diamond.fluerocents = res.data.fluerocent
                  this.diamond.tensions = res.data.tension
                  this.diamond.stones = res.data.stone
                  this.diamond.heights = res.data.height
                  this.diamond.tabls = res.data.tabl
                  this.diamond.ratios = res.data.ratio
              })
          },
          addNewPart() {
            if(this.edit_flag == 1) {

              this.last_pack_id = this.last_pack_id + 1
              this.plan_details.push({
                  id: this.last_pack_id,
                  packet_id: "",
                  weight: '',
                  polish_weight: '',
                  color: '',
                  cut: '',
                  purity: '',
                  polish: '',
                  shape: '',
                  symmetry: '',
                  fluerocent: '',
                  tension: '',
                  stone: this.packet_details.stone,
                  height: '',
                  length: '',
                  width: '',
                  ratio: '',
                  tabl: '',
                  r_rate: '',
                  value: '',
                  discount: '',
                  is_makeable: true,
                  ls_check: false,
              })
            } else {
              this.last_pack_id = this.last_pack_id + 1
              this.edit_plan_details.push({
                  id: this.last_pack_id,
                  packet_id: "",
                  weight: '',
                  polish_weight: '',
                  color: '',
                  cut: '',
                  purity: '',
                  polish: '',
                  shape: '',
                  symmetry: '',
                  fluerocent: '',
                  tension: '',
                  stone: this.packet_details.stone,
                  height: '',
                  length: '',
                  width: '',
                  ratio: '',
                  tabl: '',
                  r_rate: '',
                  value: '',
                  discount: '',
                  is_makeable: true,
                  ls_check: false,
              })

            }
          },
          loadPlanningValues(plan) {
              if (plan.polish_weight != '') {
                  if (parseFloat(plan.polish_weight) > parseFloat(this.packet_details.cr_weight)) {
                      plan.polish_weight = ""
                      this.$swal("Warning!", "Polish Must Less then Makeable Weight!", "warning")

                  }
              }
              if (plan.color != '' && plan.cut != '' && plan.purity != '' && plan.polish != '' && plan.shape != '' && plan.symmetry != '' && plan.fluerocent != '' && plan.height != '' && plan.ratio != '' && plan.tabl != '') {

                  let shape_name = ''
                  var i = this.diamond.shapes.length;
                  while (i--) {
                      if (this.diamond.shapes[i]['id'] == plan.shape) {
                          shape_name = this.diamond.shapes[i]['shape_group']
                      }
                  }
                  let params = {
                      color: plan.color,
                      cut: plan.cut,
                      purity: plan.purity,
                      polish: plan.polish,
                      symmetry: plan.symmetry,
                      fluerocent: plan.fluerocent,
                      tension: plan.tension,
                      height: plan.height,
                      ratio: plan.ratio,
                      tabl: plan.tabl,
                      shape: plan.shape,
                      shape_group: shape_name,
                      polish_weight: plan.polish_weight,
                      stone: plan.stone,
                  }
                  axios({
                      url: 'api/plan/get_plan_values',
                      method: 'post',
                      baseURL: BASE_URL,
                      data: params,
                      headers: {
                          Authorization: 'Bearer ' + localStorage.getItem('token')
                      },
                      config: {
                          headers: {
                              'Accept': 'application/json'
                          }
                      }
                  }).then((res) => {
                      let rap_value = res.data.rape_value
                      let back = res.data.back

                      plan.r_rate = parseFloat(rap_value * plan.polish_weight).toFixed(2)
                      plan.discount = back
                      plan.value = plan.r_rate - (plan.r_rate * back / 100)
                  })
              }

          },
          submitReturnRough() {
              let submit = true

              // if(this.menual_total.is_all_chacked == false && !(this.menual_total.rough_weight > 0)) {

              //   this.$swal("Warning!", "Rough weight can not be 0 or Empty !", "warning");
              //   submit = false;
              //   return;
              // }

              let checked = false

              if(this.edit_flag == 1) {

                this.plan_details.forEach(plan => {
                    if (plan.ls_check == true) {
                      checked = true
                      if (!(plan.polish_weight) || !(plan.color) || !(plan.cut) || !(plan.purity) || !(plan.polish) || !(plan.shape) || !(plan.symmetry) || !(plan.fluerocent) || !(plan.height) || !(plan.ratio) || !(plan.tabl)) {
                            this.$swal("Warning!", "Planning Fields are Empty !", "warning")
                            submit = false
                        }
                    }
                });
              } else {

                this.edit_plan_details.forEach(plan => {
                    if (plan.ls_check == true) {
                      checked = true
                      if (!(plan.polish_weight) || !(plan.color) || !(plan.cut) || !(plan.purity) || !(plan.polish) || !(plan.shape) || !(plan.symmetry) || !(plan.fluerocent) || !(plan.height) || !(plan.ratio) || !(plan.tabl)) {
                            this.$swal("Warning!", "Planning Fields are Empty !", "warning")
                            submit = false
                        }
                    }
                });
              }
              if (submit == true && checked == true) {

                  this.$swal({
                      title: "Are you sure?",
                      text: "You are You Want To Update Makeable!",
                      type: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Submit",
                      showLoaderOnConfirm: true,
                      cancelButtonText: "Cancel",

                      allowOutsideClick: () => !$Swal.isLoading()
                  }).then(result => {
                      if (result.isConfirmed) {

                        if(this.edit_flag == 1) {

                          let params = {
                              plan_details: this.plan_details,
                              packet_id: this.packet_details.id,
                              ip: localStorage.getItem('sk_key')
                          }

                          let api_url = 'api/process/receive_makeable';
                          let promise = axios({
                              url: api_url,
                              method: "post",
                              data: params,
                              baseURL: BASE_URL,
                              headers: {
                                  Authorization: "Bearer " + localStorage.getItem("token"),

                              },
                              config: {
                                  headers: {
                                      Accept: "application/json"
                                  }
                              },
                          });
                          return promise
                            .then((result) => result.data)
                            .then((data) => {
                                if (data.status == 'success') {
                                    this.$swal("Success!", "Packet Plan Updated.", "success");
                                    this.scanAndAddPack()
                                } else {

                                    this.$swal("Warning!", "Somthing Wants Wrong!", "warning");
                                }
                            })
                        } else {
                          let params = {
                              plan_details: this.edit_plan_details,
                              packet_id: this.packet_details.id,
                              type: "create",
                              ip: localStorage.getItem('sk_key'),
                              action_type: 1
                          }

                          let api_url = 'api/edit/edit_makeable_transfer';
                          let promise = axios({
                              url: api_url,
                              method: "post",
                              data: params,
                              baseURL: BASE_URL,
                              headers: {
                                  Authorization: "Bearer " + localStorage.getItem("token"),

                              },
                              config: {
                                  headers: {
                                      Accept: "application/json"
                                  }
                              },
                          });
                          return promise
                            .then((result) => result.data)
                            .then((data) => {
                                if (data.status == 'success') {
                                    this.$swal("Success!", "Packet Plan Updated.", "success");
                                    this.scanAndAddPack()
                                } else {

                                  if(data.type == "warning") {
                                    this.submitReturnRoughConfirm(data.message);
                                  } else {
                                    this.$swal("Warning!", "Somthing Wants Wrong!", "warning");
                                  }

                                }
                            })

                        }
                      }
                  });

              }
          },
          submitReturnRoughConfirm(message) {


              this.$swal({
                  title: "Are you sure?",
                  text: "You are You Want To Update Makeable!",
                  type: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Submit",
                  showLoaderOnConfirm: true,
                  cancelButtonText: "Cancel",

                  allowOutsideClick: () => !$Swal.isLoading()
              }).then(result => {
                  if (result.isConfirmed) {


                    let params = {
                        plan_details: this.edit_plan_details,
                        packet_id: this.packet_details.id,
                        type: "confirm",
                        ip: localStorage.getItem('sk_key'),
                        action_type: 1
                    }

                    let api_url = 'api/edit/edit_makeable_transfer';
                    let promise = axios({
                        url: api_url,
                        method: "post",
                        data: params,
                        baseURL: BASE_URL,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token"),

                        },
                        config: {
                            headers: {
                                Accept: "application/json"
                            }
                        },
                    });
                    return promise
                      .then((result) => result.data)
                      .then((data) => {
                          if (data.status == 'success') {
                              this.$swal("Success!", "Packet Plan Updated.", "success");
                              this.scanAndAddPack()
                          } else {

                            if(data.type == "warning") {

                            } else {
                              this.$swal("Warning!", "Somthing Wants Wrong!", "warning");
                            }

                          }
                      })
                  }
              });
          },
          resetMakeable() {
            this.$swal({
                      title: "Are you sure?",
                      text: "You are You Want To Update Makeable!",
                      type: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Submit",
                      showLoaderOnConfirm: true,
                      cancelButtonText: "Cancel",

                      allowOutsideClick: () => !$Swal.isLoading()
                  }).then(result => {
                      if (result.isConfirmed) {

                          let params = {
                            action_type: 2,
                            type: "create",
                            packet_id: this.packet_details.id,
                            ip: localStorage.getItem('sk_key')
                          }

                          let api_url = 'api/edit/edit_makeable_transfer';
                          let promise = axios({
                              url: api_url,
                              method: "post",
                              data: params,
                              baseURL: BASE_URL,
                              headers: {
                                  Authorization: "Bearer " + localStorage.getItem("token"),

                              },
                              config: {
                                  headers: {
                                      Accept: "application/json"
                                  }
                              },
                          });
                          return promise
                              .then((result) => result.data)
                              .then((data) => {
                                  if (data.status == 'success') {
                                      this.$swal("Success!", "Packet Plan Updated.", "success");
                                      this.scanAndAddPack()
                                  } else {

                                    if(data.type == "warning") {
                                      this.resetMakeableConfirm(data.message)
                                    } else {
                                      this.$swal("Warning!", data.message, "warning");
                                    }

                                  }
                              })
                      }
                  });
          },
          resetMakeableConfirm(message) {
            this.$swal({
                      title: "Are you sure?",
                      text: message,
                      type: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Submit",
                      showLoaderOnConfirm: true,
                      cancelButtonText: "Cancel",

                      allowOutsideClick: () => !$Swal.isLoading()
                  }).then(result => {
                      if (result.isConfirmed) {

                          let params = {
                            action_type: 2,
                            type: "confirm",
                            ip: localStorage.getItem('sk_key'),
                            packet_id: this.packet_details.id,
                          }

                          let api_url = 'api/process/edit_makeable_transfer';
                          let promise = axios({
                              url: api_url,
                              method: "post",
                              data: params,
                              baseURL: BASE_URL,
                              headers: {
                                  Authorization: "Bearer " + localStorage.getItem("token"),

                              },
                              config: {
                                  headers: {
                                      Accept: "application/json"
                                  }
                              },
                          });
                          return promise
                              .then((result) => result.data)
                              .then((data) => {
                                  if (data.status == 'success') {
                                      this.$swal("Success!", "Packet Plan Updated.", "success");
                                      this.scanAndAddPack()
                                  } else {

                                    if(data.type == "warning") {

                                    } else {
                                      this.$swal("Warning!", data.message, "warning");
                                    }

                                  }
                              })
                      }
                  });
          },
          reset_ls_data() {
            this.$swal({
                      title: "Are you sure?",
                      text: "You are You Want To Reset LS!",
                      type: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Submit",
                      showLoaderOnConfirm: true,
                      cancelButtonText: "Cancel",

                      allowOutsideClick: () => !$Swal.isLoading()
                  }).then(result => {
                      if (result.isConfirmed) {

                          let params = {
                              packet_id: window.btoa(this.packet_details.id),
                              ip: localStorage.getItem('sk_key')
                          }

                          let api_url = 'api/process/cancel_plan_done'

                          let promise = axios({
                              url: api_url,
                              method: "post",
                              data: params,
                              baseURL: BASE_URL,
                              headers: {
                                  Authorization: "Bearer " + localStorage.getItem("token"),

                              },
                              config: {
                                  headers: {
                                      Accept: "application/json"
                                  }
                              },
                          });
                          return promise
                              .then((result) => result.data)
                              .then((data) => {
                                  if (data.status == 'success') {
                                      this.$swal("Success!", data.message, "success");
                                      this.scanAndAddPack()
                                  } else {

                                      this.$swal("Warning!", data.message, "warning");
                                  }
                              }).catch((error) => {
                                this.$swal("Warning!", "Something went wrong.", "warning");
                              })
                      }
                  });
          },
          clearSearch(lot_no) {
            this.scan_lot_no = lot_no;
            this.plan_details = [];
            this.edit_plan_details = [];
            this.packet_details = [];
            this.stock_items = [];
            this.last_pack_id = 1
            this.edit_flag = 1
          },
          validateLSCheck(item) {

            if(this.edit_flag == 1) {

              this.plan_details.forEach(element => {

                if(element.id != item.id) {
                  element.ls_check = false
                }
              });
            } else {

              this.edit_plan_details.forEach(element => {

                if(element.id != item.id) {
                  element.ls_check = false
                }
              });
            }
          }
      },

  })
  </script>
