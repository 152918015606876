<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Jober Create</h2>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-6">
        <!-- BEGIN: Input -->
        <div class="intro-y box">
          <div id="input" class="p-5">
            <div class="preview">
              <div>
                <label for="regular-form-2" class="form-label">First Name && Last Name</label>
                <div class="sm:grid grid-cols-3 gap-2">
                  <div>
                    <div>
                      <input v-model="party.first_name"
                        id="regular-form-1"
                        type="text"
                        class="form-control"
                        placeholder="First Name"
                      />
                    </div>
                    <span class="text-primary-3" v-if="errors.first_name">{{ errors.first_name[0] }}</span>
                  </div>
                  <div>
                    <div>
                      <input v-model="party.last_name"
                        id="regular-form-1"
                        type="text"
                        class="form-control"
                        placeholder="Last Name"
                      />
                    </div>
                    <span class="text-primary-3" v-if="errors.last_name">{{ errors.last_name[0] }}</span>
                  </div>
                  <div>
                    <div>
                      <input v-model="party.short_name"
                      id="regular-form-1"
                      type="text"
                      class="form-control"
                      placeholder="Short Name"
                      />
                    </div>
                    <span class="text-primary-3" v-if="pagedata.errors.short_name">{{ pagedata.errors.short_name[0] }}</span>
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <label class="form-label">Email Id & Mobile No</label>
                <div class="sm:grid grid-cols-2 gap-2">
                  <div>
                    <div class="input-group">
                      <input v-model="party.email"
                        id="regular-form-1"
                        type="email"
                        class="form-control"
                        placeholder="Email"
                      />
                    </div>
                    <span class="text-primary-3" v-if="errors.email">{{ errors.email[0] }}</span>
                  </div>
                  <div class="mt-2 sm:mt-0">
                    <div class="input-group">
                      <input
                        type="tel"
                        v-model="party.mobile_no"
                        class="form-control"
                        placeholder="Mobile No"
                        aria-describedby="input-group-3"
                      />
                    </div>
                    <span class="text-primary-3" v-if="errors.mobile_no">{{ errors.mobile_no[0] }}</span>
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <label class="form-label">Firm Name & GST No</label>
                <div class="sm:grid grid-cols-2 gap-2">
                  <div>
                    <div class="input-group">
                      <input
                      type="tel"
                      v-model="party.firmname"
                      class="form-control"
                      placeholder="Firm Name"
                      aria-describedby="input-group-3"
                    />
                    </div>
                    <span class="text-primary-3" v-if="errors.firmname">{{ errors.firmname[0] }}</span>
                  </div>
                  <div class="mt-2 sm:mt-0">
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        v-model="party.gst"
                        placeholder="GST No"
                        aria-describedby="input-group-4"
                      />
                    </div>
                    <span class="text-primary-3" v-if="errors.gst">{{ errors.gst[0] }}</span>
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <label class="form-label">PAN CARD & Payment Terms</label>
                <div class="sm:grid grid-cols-2 gap-2">
                  <div>
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        v-model="party.pancard"
                        placeholder="pancard"
                        aria-describedby="input-group-4"
                      />
                    </div>
                    <span class="text-primary-3" v-if="errors.pancard">{{ errors.pancard[0] }}</span>
                  </div>
                  <div class="mt-2 sm:mt-0">
                    <div class="input-group">
                      <input
                        type="text"
                        v-model="party.term"
                        class="form-control"
                        placeholder="Payment Terms"
                        aria-describedby="input-group-3"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <label class="form-label">Select Processes</label>
                <TomSelect
                v-model="party.process"
                    :options="{
                      placeholder: 'Select your favorite actors'
                    }"
                    class="w-full"
                  multiple
                  >
                    <option v-for="process in pagedata.processes" :key="process.id" :value="process.id">{{ process.process_name }}</option>
                  </TomSelect>
                  <span class="text-primary-3" v-if="errors.process">{{ errors.process[0] }}</span>
              </div>
              <div class="text-right mt-5">
              <router-link :to="{ name: 'jober-details' }" class="btn btn-outline-secondary w-24 mr-1">
                Cancel
              </router-link>
              <button type="button" @click="SaveJober" class="btn btn-primary w-24">Save</button>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Input -->
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config'
import router from '@/router'
import { useRoute } from 'vue-router'
import axios from 'axios'
import { defineComponent, onMounted, reactive, ref, toRef } from 'vue'
export default defineComponent({
  setup() {
    const party = reactive({
      id: '',
      first_name: '',
      last_name: '',
      short_name: '',
      email: '',
      firmname: '',
      pancard: '',
      mobile_no: '',
      r_name: '',
      r_mobile: '',
      term: '',
      user_role: 4,
      gst: '',
      process: [],
      user_type: 1,
    })
    const pagedata = reactive({
      errors: [],
      processes: []
    })
    const errors = toRef(pagedata, 'errors')
    const route = useRoute()
    onMounted(() => {
      getProcesses()
      if(route.params.id) {
        party.id = route.params.id
        getJober()
      }
    })
    function getProcesses() {
      axios({
        url: 'api/master/dp_process',
        method: 'post',
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token"),
 },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.processes = res.data.items
      })
    }
    function SaveJober() {
      if(route.params.id) {
        axios({
          url: 'api/master/update_user_details',
          method: 'post',
          baseURL: BASE_URL,
          data: party,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          router.push({ name: 'jober-details' })
        }).catch((err) => {
        errors.value = err.response.data.errors
        setTimeout(() => {
          pagedata.errors = []
        }, 3000)
      })
      } else {
        axios({
        url: 'api/master/add_new_user',
        method: 'post',
        baseURL: BASE_URL,
        data: party,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then(() => {
        router.push({ name: 'jober-details' })
      }).catch((err) => {
        errors.value = err.response.data.errors
        setTimeout(() => {
          pagedata.errors = []
        }, 3000)
      })
      }
    }
    function getJober() {
      const params = {
        id : route.params.id, user_type: party.user_type
      }
      axios({
        url: 'api/master/show_user_details',
          method: 'post',
          baseURL: BASE_URL,
          data: params,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          party.first_name = res.data.item.first_name
          party.last_name = res.data.item.last_name
          party.short_name = res.data.item.short_name
          party.email = res.data.item.email
          party.mobile_no = res.data.item.mobile_no
          party.r_name = res.data.item.r_name
          party.r_mobile = res.data.item.r_mobile
          party.firmname = res.data.item.firm_name
          party.term = res.data.item.term
          party.gst = res.data.item.gst_no
          party.user_role = res.data.item.user_role
          party.process = res.data.item.processes
          party.pancard = res.data.item.pan_card
        })
    }
    return {
      party,
      pagedata,
      errors,
      SaveJober
    }
  }
})
</script>
