<template>

  <div>
    <router-view></router-view>
  </div>

</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({

  setup() {
  }

})
</script>
