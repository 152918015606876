<template>
<div>
    <div class="intro-y flex items-center my-3">
        <h2 class="text-lg font-medium mr-auto">New Purchase</h2>
    </div>
    <!-- <div class="grid grid-cols-12 gap-6 mt-5"> -->
    <div class="grid grid-cols-12 gap-4">
        <div class="intro-y col-span-12 lg:col-span-12">
            <!-- BEGIN: Input -->
            <div class="intro-y box p-5">
                <div id="input" class="">
                    <div class="grid grid-cols-12 gap-4">
                        <div class="col-span-6 sm:col-span-4 md:col-span-3 xl:col-span-2">
                            <div>
                                <div class="flex items-center"><label for="update-profile-form-6" class="form-label py-0">Purchase Date</label></div>
                                <div class="relative">
                                    <div class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 border text-gray-600 dark:bg-dark-1 dark:border-dark-4">
                                        <CalendarIcon class="w-4 h-4" />
                                    </div>
                                    <Litepicker v-model="purchase.date" :options="{
                            autoApply: false,
                            showWeekNumbers: true,
                            dropdowns: {
                              minYear: 1990,
                              maxYear: null,
                              months: true,
                              years: true
                            }
                          }" disabled class="form-control pl-12" />
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-span-6 sm:col-span-4 md:col-span-3 xl:col-span-2">
                            <div>
                                <div class="flex items-center"><label for="update-profile-form-6" class="form-label py-0">Purchase Type</label></div>
                                <select class="form-select" v-model="purchase.type" aria-label="Default select example">
                                        <option value="makeable">Makeable</option>
                                        <option value="rough">Rough</option>
                                        <option value="video">Video</option>
                                        <option value="lab">Lab</option>
                                    </select>
                            </div>
                        </div> -->
                        <div class="col-span-6 sm:col-span-4 md:col-span-3 xl:col-span-2" v-if="user_details.user_role != 3">
                            <div>
                                <div class="flex items-center"><label for="update-profile-form-6" class="form-label py-0">Party</label></div>
                                <TomSelect v-model="purchase.party" @change="openPiece(purchase.party)" :options="{
                          placeholder: 'Select your favorite actors'
                        }" class="w-full">
                                    <!-- <option value="0">Select Party</option> -->
                                    <option v-for="(party,index) in screenData.parties" :key="index" :value="party.id">{{ party.first_name }} {{ party.last_name }}</option>
                                </TomSelect>
                            </div>
                        </div>
                        <div class="col-span-6 sm:col-span-4 md:col-span-3 xl:col-span-2">
                            <div>
                                <div class="flex items-top"><label for="update-profile-form-6" class="form-label py-0">Kapan No</label> <span class="text-primary-3"> &nbsp; *</span></div>
                                <div>

                                    <div class="input-group">
                                        <input type="text" class="form-control" v-model="purchase.voucher" placeholder="Kapan No" @input="updateStockName" aria-describedby="input-group-4" />
                                    </div>
                                    <span class="text-primary-3" v-if="pagedata.errors.voucher">{{ pagedata.errors.voucher[0] }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-span-6 sm:col-span-4 md:col-span-3 xl:col-span-2">
                            <div>
                                <div class="flex items-top"><label for="update-profile-form-6" class="form-label py-0">Select Stone</label></div>
                                <select class="form-select" v-model="purchase.stone_id" @change="loadValueByStone" aria-label="Default select example">
                                    <option v-for="item in screenData.stones" :key="item.id" :value="item.id">{{ item.stone_name }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-span-6 sm:col-span-4 md:col-span-3 xl:col-span-2">
                            <div>
                                <div class="flex items-top"><label for="update-profile-form-6" class="form-label py-0">Duration</label></div>
                                <div>

                                    <div class="input-group mt-2 sm:mt-0">
                                        <input type="text" @keypress="$h.preventInvalidInput" class="form-control" v-model="purchase.due" placeholder="Duration" aria-describedby="input-group-3" />
                                    </div>
                                    <span class="text-primary-3" v-if="pagedata.errors.stone_id">{{ pagedata.errors.stone_id[0] }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-span-6 sm:col-span-4 md:col-span-3 xl:col-span-2">
                            <div>
                                <div class="flex items-top"><label for="update-profile-form-6" class="form-label py-0">Select Type</label></div>
                                <select class="form-select" v-model="purchase.type" disabled aria-label="Default select example">
                                    <option value="makeable">Makeable</option>
                                    <option value="rough">Rough</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-span-6 sm:col-span-4 md:col-span-3 xl:col-span-2">
                            <div>
                                <div class="flex items-top"><label for="update-profile-form-6" class="form-label py-0">Est Value</label></div>
                                <div>
                                    <div class="input-group mt-2 sm:mt-0">
                                        <input type="text" @keypress="$h.preventInvalidInput" v-model="purchase.est_majuri_total" disabled class="form-control" placeholder="Est Value" aria-describedby="input-group-4" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-span-6 sm:col-span-4 md:col-span-3 xl:col-span-2" v-if="pagedata.home_party == true">
                            <div>
                                <div class="flex items-top"><label for="update-profile-form-6" class="form-label py-0">Per Ct Rate</label></div>
                                <div>

                                    <div class="input-group">
                                        <input @input="getWeight" type="text" class="form-control" @keypress="$h.preventInvalidInput" v-model="purchase.per_ct_rate" placeholder="Per Carat Rate ($)" aria-describedby="input-group-4" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-span-6 sm:col-span-4 md:col-span-3 xl:col-span-2" v-if="pagedata.home_party == true">
                            <div>
                                <div class="flex items-top"><label for="update-profile-form-6" class="form-label py-0">Majuri Type</label></div>
                                <div>

                                    <div class="input-group">
                                        <select class="form-select" v-model="purchase.majuri_type" @change="getMarginCosting" aria-label="Default select example">
                                            <option value="1">Percent(%)</option>
                                            <option value="2">Fixed</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-span-6 sm:col-span-4 md:col-span-3 xl:col-span-2" v-if="pagedata.home_party == true">
                            <div>
                                <div class="flex items-top"><label for="update-profile-form-6" class="form-label py-0">Estimate Majuri</label></div>
                                <div>

                                    <div class="input-group mt-2 sm:mt-0">
                                        <input type="text" class="form-control" @keypress="$h.preventInvalidInput" v-model="purchase.majuri_rate" placeholder="Est Majuri" aria-describedby="input-group-4" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-span-6 sm:col-span-4 md:col-span-3 xl:col-span-2" v-if="pagedata.home_party == true">
                            <div>
                                <div class="flex items-top"><label for="update-profile-form-6" class="form-label py-0">Estimate Margin (%)</label></div>
                                <div>

                                    <div class="input-group mt-2 sm:mt-0">
                                        <input type="text" class="form-control" @keypress="$h.preventInvalidInput" v-model="purchase.margin_percent" placeholder="Margin (%)" aria-describedby="input-group-4" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-span-6 sm:col-span-4 md:col-span-3 xl:col-span-2">
                            <div>
                                <div class="flex items-top"><label for="update-profile-form-6" class="form-label py-0">No. of Pieces</label></div>
                                <div>
                                    <div v-if="purchase.party == '0'">

                                        <div class="input-group">
                                            <input type="number" class="form-control" @change="getPaketDetail" v-model="purchase.piece" placeholder="No of Pieces" aria-describedby="input-group-3" disabled />
                                        </div>

                                    </div>
                                    <div v-else>

                                        <div class="input-group">
                                            <input type="number" disabled class="form-control" @input="getPaketDetail" v-model="purchase.piece" placeholder="No of Pieces" aria-describedby="input-group-3" />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-span-6 sm:col-span-4 md:col-span-3 xl:col-span-2">
                            <div>
                                <div class="flex items-top"><label for="update-profile-form-6" class="form-label py-0">Total Weight(Ct)</label></div>
                                <div>
                                    <div class="input-group">
                                        <input type="number" class="form-control" disabled v-model="purchase.weight" placeholder="Total Weight" aria-describedby="input-group-4" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- END: Input -->

            <!-- Begin:Input Table -->
            <div class="intro-y box my-5 p-5 overflow-auto">

              <h2 class="text-lg font-medium mr-auto">Stock Details</h2>

              <table class="table border-2 pt-5">

                <thead>
                    <tr class="border" v-if="purchase.type == 'rough'">
                      <th>Packet No</th>
                      <th>Stock No</th>
                      <th>Stock Weight(Ct) <span class="text-primary-3"> &nbsp; *</span></th>
                      <!-- <th>Length <span class="text-primary-3"> &nbsp; *</span></th>
                      <th>Width <span class="text-primary-3"> &nbsp; *</span></th>
                      <th>Height <span class="text-primary-3"> &nbsp; *</span></th> -->
                      <!-- <th>Comment</th> -->
                      <th>Estimate Value</th>
                      <th>Purchase Rate</th>
                    </tr>
                      <tr class="border" v-if="purchase.type == 'makeable'">
                        <th>Packet No</th>
                        <th>Stock No</th>
                        <th>Stock Weight(Ct) <span class="text-primary-3"> &nbsp; *</span></th>
                        <th>Polish Weight(Ct) <span class="text-primary-3"> &nbsp; *</span></th>
                        <th>Shape <span class="text-primary-3"> &nbsp; *</span></th>
                        <th>Color <span class="text-primary-3"> &nbsp; *</span></th>
                        <th>Purity <span class="text-primary-3"> &nbsp; *</span></th>
                        <th>Cut <span class="text-primary-3"> &nbsp; *</span></th>
                        <th>Polish <span class="text-primary-3"> &nbsp; *</span></th>
                        <th>Symm <span class="text-primary-3"> &nbsp; *</span></th>
                        <th>Fluc <span class="text-primary-3"> &nbsp; *</span></th>
                        <th>TN <span class="text-primary-3"> &nbsp; *</span></th>
                        <th>Discount(%) <span class="text-primary-3"> &nbsp; *</span></th>
                        <th>Rapo Rate <span class="text-primary-3"> &nbsp; *</span></th>
                        <th>Rate <span class="text-primary-3"> &nbsp; *</span></th>
                        <th>Estimate Value</th>
                        <th>Purchase Rate</th>
                      </tr>
                </thead>
                <tbody class="border" v-if="purchase.type == 'rough'">

                  <tr class="" v-for="(item,index) in purchase.packet_details" :key="index">
                    <td class="whitespace-nowrap border-b"><div v-if="this.$route.params.id">
                                <div class="" v-if="item.number">{{ item.number }}</div>
                                <div class="" v-else>
                                    <div v-if="index < 9">{{ purchase.lotno }}-0{{ index + 1 }}</div>
                                    <div v-else>{{ purchase.lotno }}-{{ index + 1 }}</div>
                                </div>
                            </div>
                            <div v-else>
                                <div class=" " v-if="index < 9">XX-XX-0{{ index + 1 }}</div>
                                <div class="" v-else>XX-XX-{{ index + 1 }}</div>
                            </div></td>
                            <!-- <td class="whitespace-nowrap" style="padding: 2px;"><input type="text" v-model="item.paket_no" class="form-control rounded-none w-24" placeholder="Stock No" aria-describedby="input-group-3" disabled /></td> -->
                            <td class="whitespace-nowrap" style="padding: 2px;" v-if="purchase.party != 1"><input type="text" v-model="item.paket_no" class="form-control rounded-none w-24" placeholder="Stock No" aria-describedby="input-group-3" /></td>
                            <td class="whitespace-nowrap border-b" v-else style="padding: 2px;">
                      {{ item.id ? item.paket_no : (item.paket_no + (index + 1)) }}
                    </td>
                    <td class="whitespace-nowrap" style="padding: 2px;"><input type="text" @input="loadRoughMajuriValue(item)" v-model="item.weight" class="form-control rounded-none" @keypress="$h.preventInvalidInput" placeholder="Weight(Ct)" aria-describedby="input-group-3" /></td>
                    <!-- <td class="whitespace-nowrap" style="padding: 2px;"><input type="text" v-model="item.length" class="form-control rounded-none" @keypress="$h.preventInvalidInput" placeholder="Length" aria-describedby="input-group-3" /></td>
                    <td class="whitespace-nowrap" style="padding: 2px;"><input type="text" v-model="item.width" class="form-control rounded-none" @keypress="$h.preventInvalidInput" placeholder="Width" aria-describedby="input-group-3" /></td>
                    <td class="whitespace-nowrap" style="padding: 2px;"><input type="text" v-model="item.height" class="form-control rounded-none" @keypress="$h.preventInvalidInput" placeholder="Height" aria-describedby="input-group-3" /></td> -->
                    <!-- <td class="whitespace-nowrap" style="padding: 2px;"><input type="text" v-model="item.comment" class="form-control rounded-none" placeholder="Comment" aria-describedby="input-group-3" /></td> -->
                    <td class="whitespace-nowrap" style="padding: 2px;"><input type="text" v-model="item.est_majuri" disabled class="form-control rounded-none" placeholder="Purchase Value" aria-describedby="input-group-3" /></td>
                    <td class="whitespace-nowrap" style="padding: 2px;"><input type="text" v-model="item.owner_value" disabled class="form-control rounded-none" placeholder="Est Value" aria-describedby="input-group-3" /></td>
                    <td class="whitespace-nowrap" style="padding: 2px;"><button type="button" class="text-theme-6 flex items-center" tabindex="-1" v-if="!item.id" @click="removeField(item.id, index)">
                            <Trash2Icon class="w-5 h-5 mr-1" tabindex="-1"/>
                          </button></td>
                  </tr>

                </tbody>
                <tbody class="border" v-if="purchase.type == 'makeable'">

                  <tr class="" v-for="(item,index) in purchase.packet_details" :key="index">
                    <td class="whitespace-nowrap border-b"><div v-if="this.$route.params.id">
                                <div class="" v-if="item.number">{{ item.number }}</div>
                                <div class="" v-else>
                                    <div v-if="index < 9">{{ purchase.lotno }}-0{{ index + 1 }}</div>
                                    <div v-else>{{ purchase.lotno }}-{{ index + 1 }}</div>
                                </div>
                            </div>
                            <div v-else>
                                <div class=" " v-if="index < 9">XX-XX-0{{ index + 1 }}</div>
                                <div class="" v-else>XX-XX-{{ index + 1 }}</div>
                            </div></td>
                    <td class="whitespace-nowrap" style="padding: 2px;" v-if="purchase.party != 1"><input type="text" v-model="item.paket_no" class="form-control rounded-none w-24" placeholder="Stock No" aria-describedby="input-group-3" /></td>
                    <td class="whitespace-nowrap border-b" v-else style="padding: 2px;">
                      {{ item.id ? item.paket_no : (item.paket_no + (index + 1)) }}
                    </td>
                    <!-- <td class="whitespace-nowrap border-b" style="padding: 2px;">
                      {{ item.id ? item.paket_no : (item.paket_no + (index + 1)) }}
                    </td> -->
                    <td class="whitespace-nowrap" style="padding: 2px;"><input type="text" v-model="item.weight" class="form-control rounded-none w-28" @keypress="$h.preventInvalidInput" placeholder="Weight(Ct)" aria-describedby="input-group-3" /></td>
                    <td class="whitespace-nowrap" style="padding: 2px;"><input type="text" @input="loadPlanningValues(item)" v-model="item.polish_weight" class="form-control rounded-none w-28" @keypress="$h.preventInvalidInput" placeholder="Polish(Ct)" aria-describedby="input-group-3" /></td>
                    <td class="whitespace-nowrap" style="padding: 2px;">
                      <select
                        class="form-select"
                        v-model="item.shape"
                        @change="loadPlanningValues(item)"
                        aria-label="Default select example"
                      >
                        <option v-for="item in screenData.shapes" :key="item.id" :value="item.id">{{ item.shape_name }}</option>
                      </select>
                    </td>
                    <td class="whitespace-nowrap" style="padding: 2px;">
                      <select
                        class="form-select"
                        v-model="item.color"
                        @change="loadPlanningValues(item)"
                        aria-label="Default select example"
                      >
                      <option v-for="item in screenData.colors" :key="item.id" :value="item.id">{{ item.color_name }}</option>
                      </select>
                    </td>
                    <td class="whitespace-nowrap" style="padding: 2px;">
                      <select
                        class="form-select"
                        v-model="item.purity"
                        @change="loadPlanningValues(item)"
                        aria-label="Default select example"
                      >
                      <option v-for="item in screenData.purities" :key="item.id" :value="item.id">{{ item.purity_name }}</option>
                      </select>
                    </td>
                    <td class="whitespace-nowrap" style="padding: 2px;">
                      <select
                        class="form-select"
                        v-model="item.cut"
                        @change="loadPlanningValues(item)"
                        aria-label="Default select example"
                      >
                      <option v-for="item in screenData.cuts" :key="item.id" :value="item.id">{{ item.cut_name }}</option>
                      </select>
                    </td>
                    <td class="whitespace-nowrap" style="padding: 2px;">
                      <select
                        class="form-select"
                        v-model="item.polish"
                        @change="loadPlanningValues(item)"
                        aria-label="Default select example"
                      >
                      <option v-for="item in screenData.polish" :key="item.id" :value="item.id">{{ item.polish_name }}</option>
                      </select>
                    </td>
                    <td class="whitespace-nowrap" style="padding: 2px;">
                      <select
                        class="form-select"
                        v-model="item.symmetry"
                        @change="loadPlanningValues(item)"
                        aria-label="Default select example"
                      >
                      <option v-for="item in screenData.symmetry" :key="item.id" :value="item.id">{{ item.symmetry_name }}</option>
                      </select>
                    </td>
                    <td class="whitespace-nowrap" style="padding: 2px;">
                      <select
                        class="form-select"
                        v-model="item.fluerocent"
                        @change="loadPlanningValues(item)"
                        aria-label="Default select example"
                      >
                      <option v-for="item in screenData.fluerocents" :key="item.id" :value="item.id">{{ item.fluerocent_name }}</option>
                      </select>
                    </td>
                    <td class="whitespace-nowrap" style="padding: 2px;">
                      <select
                        class="form-select"
                        v-model="item.tension"
                        @change="loadPlanningValues(item)"
                        aria-label="Default select example"
                      >
                      <option v-for="item in screenData.tensions" :key="item.id" :value="item.id">{{ item.tension_name }}</option>
                      </select>
                    </td>
                    <td class="whitespace-nowrap" style="padding: 2px;"><input type="text" v-model="item.discount" class="form-control rounded-none w-24" @keypress="$h.preventInvalidInput" @input="calculateValue(item)" placeholder="Discount" aria-describedby="input-group-3" /></td>
                    <td class="whitespace-nowrap" style="padding: 2px;"><input type="text" disabled v-model="item.rap_value" class="form-control rounded-none w-24" @keypress="$h.preventInvalidInput" placeholder="Rapo rate" aria-describedby="input-group-3" /></td>
                    <td class="whitespace-nowrap" style="padding: 2px;"><input type="text" disabled v-model="item.value" class="form-control rounded-none w-24" placeholder="Value" aria-describedby="input-group-3" /></td>
                    <td class="whitespace-nowrap" style="padding: 2px;"><input type="text" disabled v-model="item.est_majuri" class="form-control rounded-none w-32" placeholder="Est Value" aria-describedby="input-group-3" /></td>
                    <td class="whitespace-nowrap" style="padding: 2px;"><input type="text" disabled v-model="item.owner_value" class="form-control rounded-none w-32" placeholder="Purchase Value" aria-describedby="input-group-3" /></td>
                    <td class="whitespace-nowrap" style="padding: 2px;"><button type="button" class="text-theme-6 flex items-center" tabindex="-1" v-if="!item.id" @click="removeField(item.id, index)">
                            <Trash2Icon class="w-5 h-5 mr-1" tabindex="-1"/>
                          </button></td>
                  </tr>

                </tbody>
              </table>

              <div class="flex justify-between">

                <div>
                  <div class="text-left">
                          <button type="button" @click="AddPiece" class="btn btn-primary w-24">Add Piece</button>
                      </div>
                </div>
                <div>
                  <div class="flex justify-end align-items-center mt-5">
                    <router-link :to="{ name: 'purchase-view' }" class="btn btn-outline-secondary w-24 mr-1">
                          Cancel
                      </router-link>
                      <div class="text-left">
                          <button type="button" @click="SavePurchase" class="btn btn-primary w-24">Save
                              <LoadingIcon v-if="pagedata.submit" icon="oval" color="white" class="w-4 h-4 ml-2" /></button>
                      </div>
                  </div>
                </div>
              </div>


            </div>
            <!-- End:Input Table -->
        </div>
        <div class="intro-y col-span-12 lg:col-span-7 mb-8 hidden" v-if="purchase.piece > 0">
            <!-- BEGIN: Vertical Form -->
            <div class="intro-y box" v-if="purchase.party != '0'">
                <div id="vertical-form" class="p-5" v-if="purchase.type != 'rough'">
                    <div v-if="this.$route.params.id">
                        <h2 class="text-lg font-medium mx-5" v-if="purchase.packet_details[pagedata.paket_num].number">{{ purchase.packet_details[pagedata.paket_num].number }}</h2>
                        <h2 class="text-lg font-medium mx-5" v-else>
                            <div v-if="pagedata.paket_num < 9">{{ purchase.lotno }}-0{{ pagedata.paket_num + 1 }}</div>
                            <div v-else>{{ purchase.lotno }}-{{ pagedata.paket_num + 1 }}</div>
                        </h2>
                    </div>
                    <div v-else>
                        <h2 class="text-lg font-medium mx-5" v-if="pagedata.paket_num < 9">XX-XX-0{{ pagedata.paket_num + 1 }}</h2>
                        <h2 class="text-lg font-medium mx-5" v-else>XX-XX-{{ pagedata.paket_num + 1 }}</h2>
                    </div>
                    <div class="grid grid-cols-12 gap-6 mt-5">
                        <div class="intro-y col-span-12 md:col-span-6">
                            <div class="mt-3">
                                <div class="input-group">
                                    <div id="input-group-3" class="input-group-text w-36 text-center">Number</div>
                                    <input type="text" v-model="purchase.packet_details[pagedata.paket_num].paket_no" class="form-control" disabled placeholder="Paket No" aria-describedby="input-group-3" />
                                </div>
                            </div>
                            <div class="mt-3">
                                <div class="input-group mt-2 sm:mt-0">
                                    <div id="input-group-3" class="input-group-text w-36 text-center">Weight</div>
                                    <input type="text" @keypress="$h.preventInvalidInput" v-model="purchase.packet_details[pagedata.paket_num].weight" @input="getWeight" class="form-control" placeholder="Weight" aria-describedby="input-group-4" />
                                </div>
                            </div>
                            <div class="mt-3" v-if="purchase.type == 'makeable'">
                                <div class="input-group">
                                    <div id="input-group-3" class="input-group-text w-36 text-center">PWT</div>
                                    <input type="text" v-model="purchase.packet_details[pagedata.paket_num].polish_weight" @change="loadPlanningValues(purchase.packet_details[pagedata.paket_num])" class="form-control" placeholder="Polsh Weight" aria-describedby="input-group-3" />
                                </div>
                            </div>
                            <div class="mt-3">
                                <div class="input-group mt-2 sm:mt-0">
                                    <div id="input-group-3" class="input-group-text w-36 text-center">Color</div>
                                    <select class="form-select" v-model="purchase.packet_details[pagedata.paket_num].color" @change="loadPlanningValues(purchase.packet_details[pagedata.paket_num])" aria-label="Default select example">
                                        <option v-for="item in screenData.colors" :key="item.id" :value="item.id">{{ item.color_name }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="mt-3">
                                <div class="input-group">
                                    <div id="input-group-3" class="input-group-text w-36 text-center">Purity</div>
                                    <select class="form-select" v-model="purchase.packet_details[pagedata.paket_num].purity" @change="loadPlanningValues(purchase.packet_details[pagedata.paket_num])" aria-label="Default select example">
                                        <option v-for="item in screenData.purities" :key="item.id" :value="item.id">{{ item.purity_name }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="mt-3">
                                <div class="input-group mt-2 sm:mt-0">
                                    <div id="input-group-3" class="input-group-text w-36 text-center">Shape</div>
                                    <select class="form-select" v-model="purchase.packet_details[pagedata.paket_num].shape" @change="loadPlanningValues(purchase.packet_details[pagedata.paket_num])" aria-label="Default select example">
                                        <option v-for="item in screenData.shapes" :key="item.id" :value="item.id">{{ item.shape_name }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="mt-3">
                                <div class="input-group">
                                    <div id="input-group-3" class="input-group-text w-36 text-center">Cut</div>
                                    <select class="form-select" v-model="purchase.packet_details[pagedata.paket_num].cut" @change="loadPlanningValues(purchase.packet_details[pagedata.paket_num])" aria-label="Default select example">
                                        <option v-for="item in screenData.cuts" :key="item.id" :value="item.id">{{ item.cut_name }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="mt-3">
                                <div class="input-group mt-2 sm:mt-0">
                                    <div id="input-group-3" class="input-group-text w-36 text-center">Polish</div>
                                    <select class="form-select" v-model="purchase.packet_details[pagedata.paket_num].polish" @change="loadPlanningValues(purchase.packet_details[pagedata.paket_num])" aria-label="Default select example">
                                        <option v-for="item in screenData.polish" :key="item.id" :value="item.id">{{ item.polish_name }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="mt-3">
                                <div class="input-group mt-2 sm:mt-0">
                                    <div id="input-group-3" class="input-group-text w-36 text-center">Est Value</div>
                                    <input type="number" v-model="purchase.packet_details[pagedata.paket_num].est_majuri" disabled class="form-control" placeholder="Est Value" aria-describedby="input-group-4" />
                                </div>
                            </div>
                        </div>
                        <div class="intro-y col-span-12 md:col-span-6">
                            <div class="mt-3">
                                <div class="input-group">
                                    <div id="input-group-3" class="input-group-text w-36 text-center">Symmerty</div>
                                    <select class="form-select" v-model="purchase.packet_details[pagedata.paket_num].symmetry" @change="loadPlanningValues(purchase.packet_details[pagedata.paket_num])" aria-label="Default select example">
                                        <option v-for="item in screenData.symmetry" :key="item.id" :value="item.id">{{ item.symmetry_name }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="mt-3">
                                <div class="input-group mt-2 sm:mt-0">
                                    <div id="input-group-3" class="input-group-text w-36 text-center">Flurocent</div>
                                    <select class="form-select" v-model="purchase.packet_details[pagedata.paket_num].fluerocent" @change="loadPlanningValues(purchase.packet_details[pagedata.paket_num])" aria-label="Default select example">
                                        <option v-for="item in screenData.fluerocents" :key="item.id" :value="item.id">{{ item.fluerocent_name }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="mt-3" v-if="purchase.type == 'makeable'">
                                <div class="input-group">
                                    <div id="input-group-3" class="input-group-text w-36 text-center">Tension</div>
                                    <select class="form-select" v-model="purchase.packet_details[pagedata.paket_num].tension" @change="loadPlanningValues(purchase.packet_details[pagedata.paket_num])" aria-label="Default select example">
                                        <option v-for="item in screenData.tensions" :key="item.id" :value="item.id">{{ item.tension_name }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="mt-3" v-if="purchase.type == 'makeable'">
                                <div class="input-group">
                                    <div id="input-group-3" class="input-group-text w-36 text-center">Discount</div>
                                    <input type="text" v-model="purchase.packet_details[pagedata.paket_num].discount" @change="calculateValue(purchase.packet_details[pagedata.paket_num])" class="form-control" placeholder="Discount" aria-describedby="input-group-3" />
                                </div>
                            </div>
                            <div class="mt-3" v-if="purchase.type == 'makeable'">
                                <div class="input-group">
                                    <div id="input-group-3" class="input-group-text w-36 text-center">Rapo Rate</div>
                                    <input type="text" v-model="purchase.packet_details[pagedata.paket_num].rap_value" @change="calculateValue(purchase.packet_details[pagedata.paket_num])" class="form-control" placeholder="Rapo rate" disabled aria-describedby="input-group-3" />
                                </div>
                            </div>
                            <div class="mt-3" v-if="purchase.type == 'makeable'">
                                <div class="input-group">
                                    <div id="input-group-3" class="input-group-text w-36 text-center">Rate</div>
                                    <input type="text" v-model="purchase.packet_details[pagedata.paket_num].value" class="form-control" placeholder="Rate" disabled aria-describedby="input-group-3" />
                                </div>
                            </div>
                            <div class="mt-3" v-if="purchase.type == 'makeable' && pagedata.home_party == true">
                                <div class="input-group">
                                    <div id="input-group-3" class="input-group-text w-36 text-center">Purchase</div>
                                    <input type="text" v-model="purchase.packet_details[pagedata.paket_num].owner_value" class="form-control" placeholder="Purchase Rate" disabled aria-describedby="input-group-3" />
                                </div>
                            </div>
                            <div class="mt-3" v-if="purchase.type == 'makeable'">
                                <div class="input-group sm:mt-0">
                                    <div id="input-group-3" class="input-group-text w-36 text-center">Processing</div>
                                    <select class="form-select" v-model="purchase.packet_details[pagedata.paket_num].processing" aria-label="Default select example">
                                        <option value="normal">Normal</option>
                                        <option value="special">Special</option>
                                        <option value="lab">LAB</option>
                                    </select>
                                </div>
                            </div>
                            <div class="mt-3" v-if="purchase.type == 'video'">
                                <div class="input-group">
                                    <div id="input-group-3" class="input-group-text w-36 text-center">Lab</div>
                                    <select class="form-select" v-model="purchase.packet_details[pagedata.paket_num].lab" aria-label="Default select example">
                                        <option v-for="item in screenData.labs" :key="item.id" :value="item.id">{{ item.lab_name }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="mt-3" v-if="purchase.type == 'video'">
                                <div class="input-group">
                                    <div id="input-group-3" class="input-group-text w-36 text-center">Cert. No</div>
                                    <input type="text" v-model="purchase.packet_details[pagedata.paket_num].certificate_no" class="form-control" placeholder="Certificate No" aria-describedby="input-group-3" />
                                </div>
                            </div>
                            <div class="mt-3" v-if="purchase.type == 'video'">
                                <div class="input-group">
                                    <div id="input-group-3" class="input-group-text w-36 text-center">Depth (%)</div>
                                    <input type="text" v-model="purchase.packet_details[pagedata.paket_num].depth_percent" class="form-control" placeholder="Depth" aria-describedby="input-group-3" />
                                </div>
                            </div>
                            <div class="mt-3" v-if="purchase.type == 'video'">
                                <div class="input-group">
                                    <div id="input-group-3" class="input-group-text w-36 text-center">Min Dia</div>
                                    <div class="flex">
                                        <input type="number" v-model="purchase.packet_details[pagedata.paket_num].min_dia1" class="form-control" placeholder="Min Dia" aria-describedby="input-group-3" />
                                        <input type="number" v-model="purchase.packet_details[pagedata.paket_num].min_dia2" class="form-control" placeholder="Min Dia" aria-describedby="input-group-3" />
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3" v-if="purchase.type == 'video'">
                                <div class="input-group">
                                    <div id="input-group-3" class="input-group-text w-36 text-center">Total Depth</div>
                                    <input type="text" v-model="purchase.packet_details[pagedata.paket_num].total_depth" class="form-control" placeholder="Total Depth" aria-describedby="input-group-3" />
                                </div>
                            </div>
                            <div class="mt-3" v-if="purchase.type == 'video'">
                                <div class="input-group">
                                    <div id="input-group-3" class="input-group-text w-36 text-center">Table (%)</div>
                                    <input type="text" v-model="purchase.packet_details[pagedata.paket_num].table_percent" class="form-control" placeholder="Table" aria-describedby="input-group-3" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex items-center justify-between mt-5">
                        <router-link :to="{ name: 'purchase-view' }" class="btn btn-outline-secondary w-24 mr-1">
                            Cancel
                        </router-link>
                        <div class="text-right">
                            <button class="btn btn-outline-secondary w-24 mr-1" @click="prevnext('prev')" :disabled="pagedata.btnPrev">
                                Previous
                            </button>
                            <button type="button" v-if="!pagedata.btnDis" @click="prevnext('next')" class="btn btn-primary w-24">Next</button>
                            <button type="button" :disabled="purchase.party == '0' || pagedata.submit" v-else @click="SavePurchase" class="btn btn-primary w-24">Save
                                <LoadingIcon v-if="pagedata.submit" icon="oval" color="white" class="w-4 h-4 ml-2" /></button>
                        </div>
                    </div>
                </div>
                <div id="vertical-form" class="p-5" v-else>
                    <div class="grid grid-cols-12 gap-2 mt-5 items-center" v-for="(item,index) in purchase.packet_details" :key="index">
                        <div class="intro-y col-span-12 md:col-span-2">
                            <div v-if="this.$route.params.id">
                                <h2 class="text-lg font-medium" v-if="item.number">{{ item.number }}</h2>
                                <h2 class="text-lg font-medium" v-else>
                                    <div v-if="index < 9">{{ purchase.lotno }}-0{{ index + 1 }}</div>
                                    <div v-else>{{ purchase.lotno }}-{{ index + 1 }}</div>
                                </h2>
                            </div>
                            <div v-else>
                                <h2 class="text-lg font-medium " v-if="index < 9">XX-XX-0{{ index + 1 }}</h2>
                                <h2 class="text-lg font-medium" v-else>XX-XX-{{ index + 1 }}</h2>
                            </div>
                        </div>
                        <div class="intro-y col-span-12 md:col-span-5">
                            <div class="mt-3">
                                <div class="input-group">
                                    <div id="input-group-3" class="input-group-text w-24 text-center">Number</div>
                                    <input type="text" v-model="item.paket_no" disabled class="form-control" placeholder="Paket No" aria-describedby="input-group-3" />
                                </div>
                            </div>
                            <div class="mt-3">
                                <div class="input-group mt-2 sm:mt-0">
                                    <div id="input-group-3" class="input-group-text w-36 text-center">Est Value</div>
                                    <input type="number" v-model="item.est_majuri" disabled class="form-control" placeholder="Est Value" aria-describedby="input-group-4" />
                                </div>
                            </div>
                        </div>
                        <div class="intro-y col-span-12 md:col-span-5">
                            <div class="mt-3">
                                <div class="input-group mt-2 sm:mt-0">
                                    <div id="input-group-3" class="input-group-text w-24 text-center">Weight</div>
                                    <input type="text" v-model="item.weight" @keypress="$h.preventInvalidInput" @input="loadRoughMajuriValue(item)" class="form-control" placeholder="Weight" aria-describedby="input-group-4" />
                                </div>
                            </div>
                            <div class="mt-3">
                                <div class="input-group mt-2 sm:mt-0">
                                    <div id="input-group-3" class="input-group-text w-24 text-center">Purchase</div>
                                    <input type="number" v-model="item.owner_value" disabled class="form-control" placeholder="Purchase Rate" aria-describedby="input-group-4" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex items-center justify-between mt-5" v-if="purchase.type != 'rough'">
                        <router-link :to="{ name: 'purchase-view' }" class="btn btn-outline-secondary w-24 mr-1">
                            Cancel
                        </router-link>
                        <div class="text-right">
                            <button type="button" @click="SavePurchase" class="btn btn-primary w-24">Save
                                <LoadingIcon v-if="pagedata.submit" icon="oval" color="white" class="w-4 h-4 ml-2" /></button>
                        </div>
                    </div>
                    <div class="flex items-center mt-5" v-else>
                        <router-link :to="{ name: 'purchase-view' }" class="btn btn-outline-secondary w-24 mr-1">
                            Cancel
                        </router-link>
                        <div class="text-left">
                            <button type="button" @click="SavePurchase" :disabled="pagedata.submit" class="btn btn-primary w-24">Save
                                <LoadingIcon v-if="pagedata.submit" icon="oval" color="white" class="w-4 h-4 ml-2" /></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- </div> -->
</div>
</template>

<script>
/* eslint-disable */
import {
    BASE_URL
} from '@/constants/config'
import router from '@/router'
import axios from 'axios'
import {
    computed,
    defineComponent,
    inject,
    onMounted,
    reactive,
    ref,
    watch
} from 'vue'
import moment from 'moment'
import {
    useRoute
} from 'vue-router'
import store from '@/store'
import {
    stringify
} from 'json5'
import {
    helper as $h
} from '@/utils/helper'
export default defineComponent({
    setup() {
        const route = useRoute()
        const screenData = reactive({
            parties: [],
            colors: [],
            cuts: [],
            purities: [],
            symmetry: [],
            polish: [],
            fluerocents: [],
            tensions: [],
            stones: [],
            shapes: [],
            labs: [],
        })
        const swal = inject('$swal')
        const user_details = computed(() => store.state.user.currentUser)
        const pagedata = reactive({
            paket_num: 0,
            btnDis: false,
            btnPrev: true,
            submit: false,
            home_party: (user_details.value.home_party == 1) ? true : false,
            errors: [],
        })
        const purchase = reactive({
          ip: localStorage.getItem('sk_key'),
            id: '',
            date: '',
            type: 'rough',
            party: '0',
            stone_id: 2,
            piece: 1,
            weight: 0,
            due: 15,
            voucher: '',
            lotno: '',
            per_ct_rate: '',
            est_majuri_total: '',
            majuri_type: '2',
            majuri_rate: '',
            margin_percent: '',
            packet_details: [{
                paket_no: '',
                id: '',
                weight: '',
                polish_weight: '',
                color: '',
                purity: '',
                shape: '',
                cut: '',
                polish: '',
                symmetry: '',
                fluerocent: '',
                tension: '',
                stone: '',
                lab: '',
                certificate_no: '',
                rap_value: '',
                value: '',
                owner_value: '',
                min_dia1: '',
                min_dia2: '',
                total_depth: '',
                depth_percent: '',
                table_percent: '',
                length: '',
                width: '',
                height: '',
                comment: '',
                discount: 30,
                processing: 'normal',
                number: '',
                est_majuri: ''
            }]
        })
        onMounted(() => {
            getPaketDetail()
            if (user_details.value.user_role == 3) {
                purchase.party = user_details.value.account_id
            }
            if (route.params.id) {
                purchase.id = route.params.id
                getPurchaseData()
            } else {
                getMarginCosting()
            }
            setTimeout(() => {
                get_all_parameters()
                getParties()
            }, 200);
        })

        function getParties() {
            axios({
                url: 'api/master/dp_parties',
                method: 'post',
                baseURL: BASE_URL,
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
                config: {
                    headers: {
                        'Accept': 'application/json'
                    }
                }
            }).then((res) => {
                screenData.parties = res.data.items

                if (route.params.id) {
                    setTimeout(() => {

                        openPiece(purchase.party)
                    }, 200);
                } else {
                    purchase.party = String(screenData.parties[0].id)
                    openPiece(purchase.party)
                }

            })
        }

        function getMarginCosting() {
            const params = {
                type: window.btoa(purchase.majuri_type)
            }
            axios({
                url: 'api/majuri_costing/get_party_majuri_costing',
                method: 'post',
                baseURL: BASE_URL,
                data: params,
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
                config: {
                    headers: {
                        'Accept': 'application/json'
                    }
                }
            }).then((res) => {
                purchase.majuri_rate = res.data.majuri_value.value
                purchase.margin_percent = res.data.margin_value.value
            })
        }

        function get_all_parameters() {
            axios({
                url: 'api/master/get_all_parameters',
                method: 'post',
                baseURL: BASE_URL,
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
                config: {
                    headers: {
                        'Accept': 'application/json'
                    }
                }
            }).then((res) => {
                screenData.colors = res.data.color
                screenData.cuts = res.data.cut
                screenData.purities = res.data.purity
                screenData.polish = res.data.polish
                screenData.shapes = res.data.shape
                screenData.symmetry = res.data.symmetry
                screenData.fluerocents = res.data.fluerocent
                screenData.tensions = res.data.tension
                screenData.stones = res.data.stone
                // purchase.stone_id = screenData.stones[0].id
                screenData.labs = res.data.lab
            })
        }

        function SavePurchase() {
            if (purchase.party != '0') {

                let submit = true
                if (purchase.type == 'makeable') {
                    purchase.packet_details.forEach(plan => {
                        if (!plan.weight || !plan.polish_weight || !plan.color || !plan.cut || !plan.purity || !plan.polish || !plan.shape || !plan.symmetry || !plan.fluerocent) {
                            swal("Warning!", "Fields are Empty !", "warning")
                            submit = false
                        }
                    });

                    if(submit == true) {
                    }
                } else if (purchase.type == 'video') {
                    purchase.packet_details.forEach(plan => {
                        if (!plan.weight || !plan.color || !plan.cut || !plan.purity || !plan.polish || !plan.shape || !plan.symmetry || !plan.fluerocent) {
                            swal("Warning!", "Fields are Empty !", "warning")
                            submit = false
                        }
                    });
                } else {
                    purchase.packet_details.forEach(plan => {
                        if (!plan.weight) {
                        // if (!plan.weight || !plan.length || !plan.width || !plan.height) {
                            swal("Warning!", "Fields are Empty !", "warning")
                            submit = false
                        }
                    });

                }

                getWeight();

                if (submit == true) {


                    // if(validateData()) {
                    pagedata.submit = true
                    purchase.date = moment(purchase.date).format('YYYY-MM-DD');

                    axios({
                        url: '/api/purchase/add_lot_socks_by_purchase',
                        method: 'post',
                        baseURL: BASE_URL,
                        data: purchase,
                        headers: {
                            Authorization: 'Bearer ' + localStorage.getItem('token')
                        },
                        config: {
                            headers: {
                                'Accept': 'application/json'
                            }
                        }
                    }).then((res) => {
                        pagedata.submit = false

                        if(res.data.status == 'error') {

                          swal("Warning!", res.data.message, "warning")
                        } else {

                          router.push({
                              name: 'purchase-view'
                          })
                        }
                    }).catch((err) => {
                        pagedata.submit = false
                        swal("Warning!", err.response.data.error, "warning")
                    })
                }

            }
        }

        function getPurchaseData() {
            purchase.piece = 0
            axios({
                url: 'api/purchase/show_purchase',
                method: 'post',
                baseURL: BASE_URL,
                data: {
                    id: purchase.id
                },
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
                config: {
                    headers: {
                        'Accept': 'application/json'
                    }
                }
            }).then((res) => {
                purchase.piece = res.data.purchase.pieces
                purchase.date = res.data.purchase.date
                purchase.party = stringify(res.data.purchase.party_id)
                purchase.type = res.data.purchase.type
                purchase.stone_id = res.data.purchase.stone_type
                purchase.weight = res.data.purchase.weight
                purchase.est_majuri_total = res.data.purchase.est_value
                purchase.due = res.data.purchase.due
                purchase.voucher = res.data.purchase.voucher_no
                purchase.lotno = res.data.purchase.lot_no
                purchase.per_ct_rate = res.data.purchase.per_ct_rate
                purchase.majuri_rate = res.data.purchase.majuri_rate
                purchase.margin_percent = res.data.purchase.margin_percent

                console.log(purchase.packet_details)
                setTimeout(() => {
                    let ind = 0;
                    if (res.data.packets.length > 0) {

                        res.data.packets.forEach(pkt => {
                              purchase.packet_details[ind].id = pkt.id,
                              purchase.packet_details[ind].number = pkt.number,
                              purchase.packet_details[ind].paket_no = pkt.vepari_no,
                              purchase.packet_details[ind].weight = pkt.weight,
                              purchase.packet_details[ind].polish_weight = pkt.cr_polish_weight,
                              purchase.packet_details[ind].color = pkt.color,
                              purchase.packet_details[ind].purity = pkt.purity,
                              purchase.packet_details[ind].shape = pkt.shape,
                              purchase.packet_details[ind].cut = pkt.cut,
                              purchase.packet_details[ind].polish = pkt.polish,
                              purchase.packet_details[ind].symmetry = pkt.symmetry,
                              purchase.packet_details[ind].fluerocent = pkt.fluerocent,
                              purchase.packet_details[ind].tension = pkt.tension,
                              purchase.packet_details[ind].stone = pkt.stone,
                              purchase.packet_details[ind].discount = pkt.discount,
                              purchase.packet_details[ind].processing = pkt.processing,
                              purchase.packet_details[ind].lab = pkt.lab,
                              purchase.packet_details[ind].certificate_no = pkt.cert_no,
                              purchase.packet_details[ind].rap_value = pkt.rap_value,
                              purchase.packet_details[ind].value = pkt.value,
                              purchase.packet_details[ind].min_dia1 = pkt.min_dia1,
                              purchase.packet_details[ind].min_dia2 = pkt.min_dia2,
                              purchase.packet_details[ind].total_depth = pkt.total_depth,
                              purchase.packet_details[ind].depth_percent = pkt.depth_percent,
                              purchase.packet_details[ind].table_percent = pkt.table_percent,
                              purchase.packet_details[ind].length = pkt.length,
                              purchase.packet_details[ind].width = pkt.width,
                              purchase.packet_details[ind].height = pkt.height,
                              purchase.packet_details[ind].comment = pkt.comment,
                              purchase.packet_details[ind].owner_value = pkt.owner_value,
                              purchase.packet_details[ind].est_majuri = pkt.est_value,
                              ind = ind + 1
                              console.log(purchase.packet_details)

                        });
                      }
                    getWeight()
                }, 20);
                console.log(stringify(purchase.stone_id))

            }).catch(() => {
                router.push({
                    name: 'purchase-view'
                })
            })
        }

        function removeField(id, index) {
            swal({
            title: "Are you sure?",
            text: "You are about to delete a item!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Delete",
            showLoaderOnConfirm: true,
            cancelButtonText: "Cancel",

            allowOutsideClick: () => !$Swal.isLoading()
          }).then(result => {
              if(result.isConfirmed){
                var i = purchase.packet_details.length;
                  while(i--){
                  if(i == index){
                      purchase.packet_details.splice(i,1);
                  }
                }
                calculateEstMajuriDetails()
                getWeight()
              }
          });
        }

        const AddPiece = () => {
          purchase.packet_details.push({
              paket_no: (purchase.voucher && purchase.party == 1 ? (purchase.voucher + '-') : ''),
              number: '',
              weight: '',
              est_majuri: '',
              polish_weight: '',
              color: '',
              purity: '',
              shape: '',
              cut: '',
              polish: '',
              symmetry: '',
              fluerocent: '',
              tension: '',
              stone: '',
              lab: '',
              certificate_no: '',
              rap_value: '',
              min_dia1: '',
              min_dia2: '',
              total_depth: '',
              depth_percent: '',
              table_percent: '',
              length: '',
              width: '',
              height: '',
              comment: '',
              owner_value: '',
              value: '',
              discount: 30,
              processing: 'normal'
          })
        }

        function openPiece(id) {
            let length = screenData.parties.length;

            for (let i = 0; i < length; i++) {
                if (screenData.parties[i].id == id) {
                    if (screenData.parties[i].home_party == 1) {
                        pagedata.home_party = true
                    } else {
                        pagedata.home_party = false
                    }
                }
            }
            getPaketDetail()
        }

        function getPaketDetail() {
            if (pagedata.paket_num == 0 && purchase.piece == 1) {
                pagedata.btnDis = true
                pagedata.btnPrev = true
            } else if (pagedata.paket_num == 0 && purchase.piece > 1) {
                pagedata.btnDis = false
                pagedata.btnPrev = true
            } else if (pagedata.paket_num != 0 && purchase.piece == (pagedata.paket_num + 1)) {
                pagedata.btnPrev = false
                pagedata.btnDis = true
            } else {
                pagedata.btnDis = false
                pagedata.btnPrev = false
            }
            getWeight()
        }

        function prevnext(type) {
            if (type == 'next') {
                // if(validateData()) {
                pagedata.paket_num = pagedata.paket_num + 1;
                // }
            } else if (type == 'prev') {
                pagedata.paket_num = pagedata.paket_num - 1;
            }
            getPaketDetail()
        }

        function getWeight() {
            purchase.weight = 0
            purchase.packet_details.forEach(element => {
                console.log(parseFloat(element.weight))
                console.log(parseFloat(purchase.weight))
                purchase.weight = parseFloat(purchase.weight) + parseFloat((element.weight ? element.weight : 0))
            });
            purchase.weight = $h.myFloat(purchase.weight)

            if (pagedata.home_party == true) {
                let rate = $h.myFloat(purchase.per_ct_rate)

                if (rate) {
                    let length = purchase.packet_details.length;
                    for (let i = 0; i < length; i++) {
                        if (purchase.packet_details[i].weight) {
                            purchase.packet_details[i].owner_value = $h.myFloat($h.myFloat(purchase.packet_details[i].weight) * rate)
                        }
                    }
                }
            }
        }

        function loadPlanningValues(plan) {
            if (parseFloat(plan.polish_weight) > parseFloat(plan.weight)) {
                plan.polish_weight = ''
                swal("Warning!", "Polish Weight Should Be Less then Issue Weight!", "warning");
            }
            if (plan.polish_weight != '' && plan.color != '' && plan.purity != '' && plan.shape != '') {

                var i = screenData.shapes.length;
                let plan_shape = ''
                while (i--) {
                    if (screenData.shapes[i]['id'] == plan.shape) {
                        plan_shape = screenData.shapes[i]['shape_group']
                    }
                }
                let params = {
                    color: plan.color,
                    purity: plan.purity,
                    shape: plan.shape,
                    shape_group: plan_shape,
                    polish_weight: plan.polish_weight
                }
                axios({
                    url: 'api/plan/get_plan_rapo',
                    method: 'post',
                    baseURL: BASE_URL,
                    data: params,
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    },
                    config: {
                        headers: {
                            'Accept': 'application/json'
                        }
                    }
                }).then((res) => {
                    plan.rap_value = $h.myFloat(res.data.rape_value * plan.polish_weight)
                    calculateValue(plan)
                })
            }
            if (plan.polish_weight != '' && plan.color != '' && plan.purity != '' && plan.shape != '' && plan.cut != '' && plan.polish != '' && plan.symmetry != '' && plan.fluerocent != '' && plan.tension != '' && purchase.stone_id != '') {

                let value_params = {
                    polish_weight: plan.polish_weight,
                    color: plan.color,
                    cut: plan.cut,
                    purity: plan.purity,
                    polish: plan.polish,
                    shape: plan.shape,
                    symmetry: plan.symmetry,
                    fluerocent: plan.fluerocent,
                    tension: plan.tension,
                    stone: purchase.stone_id,
                    type: purchase.type,
                    party_id: purchase.party,
                }

                axios({
                    url: 'api/plan/get_makeable_purchase_value',
                    method: 'post',
                    baseURL: BASE_URL,
                    data: value_params,
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    },
                    config: {
                        headers: {
                            'Accept': 'application/json'
                        }
                    }
                }).then((res) => {
                    plan.est_majuri = res.data.majuri_value * plan.weight
                    calculateEstMajuriDetails()
                })
            }

        }

        function calculateEstMajuriDetails() {
            purchase.est_majuri_total = 0
            purchase.packet_details.forEach(element => {
                purchase.est_majuri_total = $h.myFloat(purchase.est_majuri_total) + $h.myFloat(element.est_majuri)
            });
            purchase.est_majuri_total = $h.myFloat(purchase.est_majuri_total)
        }

        function loadRoughMajuriValue(plan) {
            getWeight()

            if (plan.weight != '') {
                let value_params = {
                    weight: plan.weight,
                    stone: purchase.stone_id,
                    type: purchase.type,
                    party_id: purchase.party,
                }

                axios({
                    url: 'api/plan/get_rough_purchase_value',
                    method: 'post',
                    baseURL: BASE_URL,
                    data: value_params,
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    },
                    config: {
                        headers: {
                            'Accept': 'application/json'
                        }
                    }
                }).then((res) => {
                    plan.est_majuri = res.data.majuri_value * plan.weight
                    calculateEstMajuriDetails()
                })
            }
        }

        function loadValueByStone() {
            let value_params = {
                packet_details: purchase.packet_details,
                stone: purchase.stone_id,
                type: purchase.type,
                party_id: purchase.party,
            }

            axios({
                url: 'api/plan/get_stone_change_purchase_value',
                method: 'post',
                baseURL: BASE_URL,
                data: value_params,
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
                config: {
                    headers: {
                        'Accept': 'application/json'
                    }
                }
            }).then((res) => {

                let packet_rates = res.data.items

                let length = purchase.packet_details.length

                for (let i = 0; i < length; i++) {
                    purchase.packet_details[i].est_majuri = packet_rates[i] * purchase.packet_details[i].weight
                }
                calculateEstMajuriDetails()
            })
        }

        function calculateValue(plan) {
            let rap_value = plan.rap_value
            let discount = plan.discount

            if (rap_value && discount) {
                let value = parseFloat(rap_value) * parseFloat(100 - discount) / 100
                plan.value = value
            }
        }
        const updateStockName = () => {

            if (purchase.piece > 0 && purchase.voucher) {

                purchase.packet_details.forEach(function callback(value, index) {
                    value.paket_no = purchase.voucher + '_' + (index + 1)
                });
            } else if (purchase.piece > 0 && !purchase.voucher) {
                purchase.packet_details.forEach(function callback(value, index) {
                    value.paket_no = ''
                });

            }
        }
        watch(() => purchase.piece, (currentValue, oldValue) => {
            let i = oldValue;
            let c = currentValue
            if (currentValue > oldValue) {
                for (i; i < c; i++) {
                    purchase.packet_details.push({
                        paket_no: (purchase.voucher && purchase.party == 1 ? (purchase.voucher + '-') : ''),
                        number: '',
                        weight: '',
                        est_majuri: '',
                        polish_weight: '',
                        color: '',
                        purity: '',
                        shape: '',
                        cut: '',
                        polish: '',
                        symmetry: '',
                        fluerocent: '',
                        tension: '',
                        stone: '',
                        lab: '',
                        certificate_no: '',
                        rap_value: '',
                        min_dia1: '',
                        min_dia2: '',
                        total_depth: '',
                        depth_percent: '',
                        table_percent: '',
                        length: '',
                        width: '',
                        height: '',
                        comment: '',
                        owner_value: '',
                        value: '',
                        discount: 30,
                        processing: 'normal'
                    })
                }
            } else {
                purchase.packet_details.splice(c, purchase.packet_details.length)
            }
        });
        return {
            screenData,
            pagedata,
            purchase,
            user_details,
            loadPlanningValues,
            SavePurchase,
            openPiece,
            getPaketDetail,
            prevnext,
            getWeight,
            loadRoughMajuriValue,
            calculateValue,
            getMarginCosting,
            loadValueByStone,
            updateStockName,
            removeField,
            AddPiece
        }
    }
})
</script>

<!--

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.input-group .input-group-text-custom {
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgba(226, 232, 240, var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    --tw-text-opacity: 1;
    color: rgba(113, 128, 150, var(--tw-text-opacity));
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
</style> -->
