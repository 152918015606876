<template>
  <div class="my-4">
    <div class="grid grid-cols-12 gap-x-2">
      <div class="col-span-12 lg:col-span-4">
        <div>
          <div class="flex items-center">
            <div class="text-lg font-medium mr-2">Please Scan the Packet</div>
            <div>
              <input id="remember-me" type="checkbox" v-model="pagedata.check"
                class="form-check-input border mr-2 bg-white" />
              <label for="remember-me" class="text-md font-medium">Menual</label>
            </div>
          </div>
          <div class="w-full flex sm:w-auto xl:mt-0 mt-3 sm:ml-auto md:ml-0">
            <div class="w-56 relative text-gray-700 dark:text-gray-300 mr-4">
              <input type="text" @change="getPacketDetailsScan" v-model="pagedata.search" autofocus ref="focusMe"
                class="form-control w-56 box pr-10 placeholder-theme-13" placeholder="Search..." />
              <SearchIcon class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0" />
            </div>
            <button class="btn btn-primary shadow-md mr-2" @click="getPaketDetail">Search</button>
          </div>
        </div>
      </div>
      <div class="col-span-12 lg:col-span-8">
        <div class="flex items-end mt-10 xl:mt-0" v-if="pagedata.show_details">
          <div class="mx-5 overflow-auto">
            <div class="flex items-center justify-between">

              <h2 class="intro-y text-lg font-medium">Packet No : {{ pagedata.packet_details.vepari_no }} &nbsp;&nbsp;&nbsp; Kapan No : {{ pagedata.packet_details.voucher_no }}</h2>
              <button v-if="pagedata.packet_details.number != ''" type="button" v-print="'#paketDetails'"
                  class="btn btn-primary w-16 mr-1 py-0">
                  Print
                </button>
            </div>
            <table class="w-full">
              <tbody>
                <tr>
                  <th class="py-2 font-bold border-2 text-md px-1 text-left"> Stock</th>
                  <th class="py-2 font-bold border-2 text-md px-1 text-left" v-if="pagedata.packet_details.shape">Shape </th>
                  <th class="py-2 font-bold border-2 text-md px-1 text-left" v-if="pagedata.packet_details.color">Color </th>
                  <th class="py-2 font-bold border-2 text-md px-1 text-left" v-if="pagedata.packet_details.purity"> Purity</th>
                  <th class="py-2 font-bold border-2 text-md px-1 text-left" v-if="pagedata.packet_details.cut">Cut</th>
                  <th class="py-2 font-bold border-2 text-md px-1 text-left" v-if="pagedata.packet_details.polish"> Polish</th>
                  <th class="py-2 font-bold border-2 text-md px-1 text-left" v-if="pagedata.packet_details.symmetry"> Symmetry</th> <th class="py-2 font-bold border-2 text-md px-1 text-left" v-if="pagedata.packet_details.tension">
                    Tension</th>
                  <th class="py-2 font-bold border-2 text-md px-1 text-left" v-if="pagedata.packet_details.stone">Stone </th>
                  <th class="py-2 font-bold border-2 text-md px-1 text-left whitespace-nowrap" v-if="pagedata.packet_details.cr_weight">Issue Wt</th>
                  <th class="py-2 font-bold border-2 text-md px-1 text-left whitespace-nowrap" v-if="pagedata.packet_details.weight">Oriznal Wt</th>
                  <th class="py-2 font-bold border-2 text-md px-1 text-left whitespace-nowrap" v-if="pagedata.packet_details.cr_polish_weight">PWT</th>
                </tr>
                <tr>
                  <td class="font-bold text-md border-2 text-center whitespace-nowrap">{{ pagedata.packet_details.number }}</td>
                  <td class="font-bold text-md border-2 text-center" v-if="pagedata.packet_details.shape">{{ pagedata.packet_details.shape ? pagedata.packet_details.shape : '' }}</td>
                  <td class="font-bold text-md border-2 text-center" v-if="pagedata.packet_details.color">{{ pagedata.packet_details.color ? pagedata.packet_details.color : '' }}</td>
                  <td class="font-bold text-md border-2 text-center" v-if="pagedata.packet_details.purity">{{ pagedata.packet_details.purity ? pagedata.packet_details.purity : '' }}</td>
                  <td class="font-bold text-md border-2 text-center" v-if="pagedata.packet_details.cut">{{ pagedata.packet_details.cut ? pagedata.packet_details.cut : '' }}</td>
                  <td class="font-bold text-md border-2 text-center" v-if="pagedata.packet_details.polish">{{ pagedata.packet_details.polish ? pagedata.packet_details.polish : '' }}</td>
                  <td class="font-bold text-md border-2 text-center" v-if="pagedata.packet_details.symmetry">{{ pagedata.packet_details.symmetry ? pagedata.packet_details.symmetry : '' }}</td>
                  <td class="font-bold text-md border-2 text-center" v-if="pagedata.packet_details.tension">{{ pagedata.packet_details.tension ? pagedata.packet_details.tension : '' }}</td>
                  <td class="font-bold text-md border-2 text-center" v-if="pagedata.packet_details.stone">{{ pagedata.packet_details.stone ? pagedata.packet_details.stone : '' }}</td>
                  <td class="font-bold text-md border-2 text-center" v-if="pagedata.packet_details.cr_weight">{{ pagedata.packet_details.cr_weight }}</td>
                  <td class="font-bold text-md border-2 text-center" v-if="pagedata.packet_details.weight">{{ pagedata.packet_details.weight }}</td>
                  <td class="font-bold text-md border-2 text-center" v-if="pagedata.packet_details.cr_polish_weight">{{ pagedata.packet_details.cr_polish_weight }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="hidden">
            <div id="paketDetails" class="mx-5">
              <table class="w-48 border-2 border-black">
                <tbody>
                  <tr>
                    <th class="p-2 font-bold text-lg text-left">
                      Packet No:
                    </th>
                    <th class="font-bold text-lg text-center">{{ pagedata.packet_details.vepari_no }}</th>
                    <th class="p-2 font-bold text-lg text-left">
                      Kapan No:
                    </th>
                    <th class="font-bold text-lg text-center">{{ pagedata.packet_details.voucher_no }}</th>
                  </tr>
                  <tr>
                    <th class="p-2 font-bold text-lg text-left">
                      Stock No:
                    </th>
                    <th class="font-bold text-lg text-center">{{ pagedata.packet_details.number }}</th>
                  </tr>
                  <tr v-if="pagedata.packet_details.shape">
                    <td class="p-2 border-2 border-black font-bold text-md text-left">
                      Shape :
                    </td>
                    <td class="font-bold text-md border-2 border-black text-center">{{ pagedata.packet_details.shape ? pagedata.packet_details.shape : '' }}</td>
                  </tr>
                  <tr v-if="pagedata.packet_details.color">
                    <td class="p-2 border-2 border-black font-bold text-md text-left">
                      Color :
                    </td>
                    <td class="font-bold text-md border-2 border-black text-center">{{ pagedata.packet_details.color ? pagedata.packet_details.color : '' }}</td>
                  </tr>
                  <tr v-if="pagedata.packet_details.purity">
                    <td class="p-2 border-2 border-black font-bold text-md text-left">
                      Purity :
                    </td>
                    <td class="font-bold text-md border-2 border-black text-center">{{ pagedata.packet_details.purity ? pagedata.packet_details.purity : '' }}</td>
                  </tr>
                  <tr v-if="pagedata.packet_details.cut">
                    <td class="p-2 border-2 border-black font-bold text-md text-left">
                      Cut :
                    </td>
                    <td class="font-bold text-md border-2 border-black text-center">{{ pagedata.packet_details.cut ? pagedata.packet_details.cut : '' }}</td>
                  </tr>
                  <tr v-if="pagedata.packet_details.polish">
                    <td class="p-2 border-2 border-black font-bold text-md text-left">
                      Polish :
                    </td>
                    <td class="font-bold text-md border-2 border-black text-center">{{ pagedata.packet_details.polish ? pagedata.packet_details.polish : '' }}</td>
                  </tr>
                  <tr v-if="pagedata.packet_details.symmetry">
                    <td class="p-2 border-2 border-black font-bold text-md text-left">
                      Symmetry :
                    </td>
                    <td class="font-bold text-md border-2 border-black text-center">{{ pagedata.packet_details.symmetry ? pagedata.packet_details.symmetry : '' }}</td>
                  </tr>
                  <tr v-if="pagedata.packet_details.tension">
                    <td class="p-2 border-2 border-black font-bold text-md text-left">
                      Tension :
                    </td>
                    <td class="font-bold text-md border-2 border-black text-center">{{ pagedata.packet_details.tension ? pagedata.packet_details.tension : '' }}</td>
                  </tr>
                  <tr v-if="pagedata.packet_details.stone">
                    <td class="p-2 border-2 border-black font-bold text-md text-left">
                      Stone :
                    </td>
                    <td class="font-bold text-md border-2 border-black text-center">{{ pagedata.packet_details.stone ? pagedata.packet_details.stone : '' }}</td>
                  </tr>
                  <tr v-if="pagedata.packet_details.cr_weight">
                    <td class="p-2 border-2 border-black font-bold text-md text-left">
                      Iss. Wt :
                    </td>
                    <td class="font-bold text-md border-2 border-black text-center">{{ pagedata.packet_details.cr_weight }}</td>
                  </tr>
                  <tr v-if="pagedata.packet_details.weight">
                    <td class="p-2 border-2 border-black font-bold text-md text-left">
                      Oriz. Wt :
                    </td>
                    <td class="font-bold text-md border-2 border-black text-center">{{ pagedata.packet_details.weight }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-x-2 mt-8">
      <div v-if="pagedata.packet_details.status == 6"
        class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center text-primary-1">Packet is Deleivred, You can not make change to this Packet ({{ pagedata.packet_details.deliver_date }})</div>
      <div v-else-if="pagedata.packet_details.status == 3"
        class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center text-primary-3">Packet is Cancelled, You can not make change to this Packet</div>
      <div v-else-if="pagedata.packet_details.external == 1"
        class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center text-primary-3">Packet in External, You can not make change to this Packet</div>
      <div v-else-if="pagedata.packet_details.discuss == 1"
        class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center text-primary-3">Packet In Discuss, You can not make change to this Packet</div>
      <div v-else-if="pagedata.packet_details.blocked == 1"
        class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center text-primary-3">Packet is Blocked, You can not make change to this Packet</div>
      <!-- BEGIN: Data List -->
      <div class="intro-y col-span-12 xl:mt-0 mt-2" v-if="pagedata.show_details">
        <div class="intro-y col-span-12 overflow-auto" v-if="pagedata.transaction_details.length > 0">
          <table class="table-report -mt-2 w-full">
            <thead>
              <tr>
                <th class="py-2 whitespace-nowrap">T No</th>
                <th class="py-2 whitespace-nowrap">Proc.</th>
                <th class="py-2 whitespace-nowrap">Mgr.</th>
                <th class="py-2 text-center whitespace-nowrap">Emp.</th>
                <th class="py-2 text-center whitespace-nowrap">Issue Wt.</th>
                <th class="py-2 text-center whitespace-nowrap">Rec. Wt.</th>
                <th class="py-2 text-center whitespace-nowrap"><span>Issue Time</span><br><span>Rec. Time</span></th>
                <th class="py-2 text-center whitespace-nowrap">Shape</th>
                <th class="py-2 text-center whitespace-nowrap">Color</th>
                <th class="py-2 text-center whitespace-nowrap">Purity</th>
                <th class="py-2 text-center whitespace-nowrap">Cut</th>
                <th class="py-2 text-center whitespace-nowrap">Polish</th>
                <th class="py-2 text-center whitespace-nowrap">Symm</th>
                <th class="py-2 text-center whitespace-nowrap">Action</th>
              </tr>
            </thead>
            <tbody>
              <TableList v-for="(packet, index) in pagedata.transaction_details" :key="index" :index="index" :packet="packet" :packetDet="pagedata.packet_details" :diamond="diamond" @getData="getPacketDetailPkt"/>
            </tbody>
          </table>
        </div>
        <div v-else class="intro-y col-span-12 overflow-auto mt-2 text-center">No Packet Found</div>
      </div>
      <div v-else class="intro-y col-span-12 overflow-auto mt-2 text-center">Please Scan Packet</div>
      <!-- END: Data List -->
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BASE_URL
} from '@/constants/config'
import axios from 'axios'
import cash from 'cash-dom'
import {
  defineComponent,
  reactive,
  ref,
  onMounted
} from 'vue'
import print from 'vue3-print-nb'
import TableList from '@/views/PacketTransaction/single-issue/tableList.vue'
export default defineComponent({
  directives: {
    print
  },
  components: {
    TableList
  },
  setup() {
    const focusMe = ref()
    const pagedata = reactive({
      transaction_details: [],
      packet_details: [],
      search: '',
      check: false,
      waiting: false,
      employees: [],
      processes: [],
      parties: [],
      partyproc: [],
      submit: false,
      errors: [],
      managers: [],
      success: {
        success: '',
        manager: '',
      },
      show_details: false,
      error: '',
      changes: '',
    })
    const diamond = reactive({
      colors: [],
      cuts: [],
      purities: [],
      symmetry: [],
      polish: [],
      fluerocents: [],
      tensions: [],
      stones: [],
      shapes: [],
    })
    onMounted(() => {
      focusMe.value.focus()
      getDiamontData()
    })

    function getPaketDetail() {
      if (pagedata.search != '' && pagedata.search != null) {
        const params = {
          number: window.btoa(pagedata.search)
        }
        axios({
          url: 'api/purchase/get_scanned_packet_details',
          method: 'post',
          baseURL: BASE_URL,
          data: params,
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
          config: {
            headers: {
              'Accept': 'application/json'
            }
          }
        }).then((res) => {
          pagedata.transaction_details = []
          pagedata.packet_details = []
          pagedata.transaction_details = res.data.packet_transaction_details
          pagedata.packet_details = res.data.paket_items
          pagedata.show_details = true
          setTimeout(() => {
            pagedata.search = ''
          }, 1000);
        }).catch((err) => {
          pagedata.errors = err.response.data
          cash('#delete-confirmation-modal').modal('show')
          setTimeout(() => {
            pagedata.errors = []
            cash('#delete-confirmation-modal').modal('hide')
          }, 3000);
          setTimeout(() => {
            pagedata.search = ''
          }, 1000);
          pagedata.transaction_details = []
          pagedata.packet_details = []
        })
      }
    }

    function getPacketDetailPkt() {
      pagedata.search = pagedata.packet_details.number
      getPaketDetail()
    }

    function printData() {
      var print = this.$refs.paketDetails
      const newWin = window.open('')
      newWin.document.write(print.outerHTML)
      newWin.print()
    }

    function getDiamontData() {
      axios({
        url: 'api/master/get_all_parameters',
        method: 'post',
        baseURL: BASE_URL,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            'Accept': 'application/json'
          }
        }
      }).then((res) => {
        diamond.colors = res.data.color
        diamond.cuts = res.data.cut
        diamond.purities = res.data.purity
        diamond.polish = res.data.polish
        diamond.shapes = res.data.shape
        diamond.symmetry = res.data.symmetry
        diamond.fluerocents = res.data.fluerocent
        diamond.tensions = res.data.tension
        diamond.stones = res.data.stone
        // pagedata.labs = res.data.lab
      })
    }
    function getPacketDetailsScan() {
      if (!pagedata.check && pagedata.search) {
        getPaketDetail();
      }
    }
    return {
      focusMe,
      pagedata,
      diamond,
      getPaketDetail,
      getPacketDetailPkt,
      printData,
      getPacketDetailsScan
    }
  }
})
</script>
