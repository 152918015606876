<template>
  <div>
      <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
          <h2 class="text-lg font-medium mr-auto">Job Done Transactions</h2>
          <div>
            <router-link to="/packets/job-done/issue-create" class="btn btn-primary">New Job Done / Repairing</router-link>
          </div>
      </div>
      <!-- BEGIN: HTML Table Data -->
      <div class="intro-y box p-5 mt-5">
        <div class="flex flex-wrap items-center justify-between">
            <h2 class="text-lg font-bold">Purchases Details</h2>
            <div class="grid grid-cols-12 gap-x-2 w-full">
                <div class="col-span-6 md:col-span-3 xl:col-span-2">
                  <label for="update-profile-form-6" class="form-label py-1"
                    >Select Party</label
                  >
                  <TomSelect
                    v-model="filters.party_id"
                    :options="{
                      placeholder: 'Select Party',
                      loadingClass: 'loading',
                      plugins: {
                        dropdown_header: {
                          title: 'Party'
                        }
                      }
                    }"
                    class="w-full"
                  >
                  <option value="0">Select Party</option>
                  <option v-for="(stone,index) in screenData.parties" :key="index" :value="stone.id">{{ stone.firm_name }}</option>
                  </TomSelect>
                </div>
                <div class="col-span-6 md:col-span-3 xl:col-span-2">
                  <label for="update-profile-form-6" class="form-label py-1"
                    >Select Type</label
                  >
                  <TomSelect
                    v-model="filters.party_id"
                    :options="{
                      placeholder: 'Select Type',
                      loadingClass: 'loading',
                      plugins: {
                        dropdown_header: {
                          title: 'Party'
                        }
                      }
                    }"
                    class="w-full"
                  >
                  <option value="0">Select Party</option>
                  <option v-for="(stone,index) in screenData.parties" :key="index" :value="stone.id">{{ stone.firm_name }}</option>
                  </TomSelect>
                </div>
                <div class="col-span-6 md:col-span-3 xl:col-span-2">
                  <label for="update-profile-form-6" class="form-label py-1"
                    > Job Type</label
                  >
                  <TomSelect
                    v-model="filters.transaction_type"
                    :options="{
                      placeholder: 'Select Type',
                      loadingClass: 'loading',
                      plugins: {
                        dropdown_header: {
                          title: 'Job Type'
                        }
                      }
                    }"
                    class="w-full"
                  >
                  <option value="0">All</option>
                  <option value="1">Job Done</option>
                  <option value="2">Repairing</option>
                  </TomSelect>
                </div>
                <div class="col-span-6 md:col-span-3 xl:col-span-2">
                  <div class="mt-2">
                    <div class="flex items-center"><label for="update-profile-form-6" class="form-label"
                      >Select Start Date</label
                    ><input
                      class="show-code form-check-switch mr-0 ml-3 h-6"
                      type="checkbox" v-model="filters.issue_pending"
                    /></div>
                    <input
                      type= "date"
                      id="update-profile-form-13"
                      class="form-control"
                      :disabled="filters.issue_pending"
                      placeholder=""
                      v-model="filters.start_date"
                    />
                  </div>

              </div>
              <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div>
                  <label for="update-profile-form-6" class="form-label py-1"
                    >Select End Date</label
                  >
                  <input
                    type= "date"
                    id="update-profile-form-13"

                    class="form-control"
                    placeholder=""
                    :disabled="filters.issue_pending"
                    v-model="filters.end_date"
                  />
                </div>
              </div>
                <div class="col-span-2 md:col-span-2 xl:col-span-1">
                    <div class="flex mt-9">

                        <button id="tabulator-html-filter-reset" type="button" class="btn btn-secondary w-full mt-2 sm:mt-0 sm:ml-2" @click="onResetFilter">
                            Reset
                        </button>
                    </div>
                </div>
                <div class="col-span-2 md:col-span-2 xl:col-span-1">
                    <div class="flex mt-9">

                        <button id="tabulator-html-filter-go" type="button" class="btn btn-primary w-full ml-2" tabindex="5" @click="initTabulator()">
                            Go
                        </button>
                    </div>
                </div>
            </div>
        </div>
          <div class="overflow-x-auto scrollbar-hidden">
              <div id="tabulator" ref="tableRef" class="mt-5"></div>
          </div>
      </div>
      <!-- END: HTML Table Data -->
  </div>
  </template>

  <script>
  import {
      defineComponent,
      computed,
      ref,
      reactive,
      onMounted,
      inject
  } from 'vue'
  import xlsx from 'xlsx'
  import {
      useStore
  } from '@/store'
  import feather from 'feather-icons'
  import Tabulator from 'tabulator-tables'
  import VueRouter from 'vue-router'
  import moment from 'moment';
  import {
      useRouter,
      useRoute
  } from 'vue-router'
  import {
      BASE_URL
  } from '@/constants/config.js'

  export default defineComponent({
      setup() {
          const router = useRouter()
          const store = useStore()
          const route = useRoute()
          const tableRef = ref()
          const tabulator = ref()
          const filter = reactive({
              field: 'lot_no',
              type: 'like',
              value: ''
          })

          const swal = inject('$swal')
          const userDetails = computed(() => store.state.user.currentUser)

          const filters = reactive({
            party_id: '0',
            transaction_type: '0',
            start_date: moment().startOf('month').format('YYYY-MM-DD'),
            end_date: moment().format('YYYY-MM-DD'),
            issue_pending: false,
          })

          const screenData = reactive({
              transaction_no: '',
              issuer: '',
              status: '0',
              employee_type: '3',
              employee_id: '',
              process_id: '0',
              employees: [],
              parties: [],
              process_items: [],
              emp_hit: 1
          })

          const initTabulator = () => {

            let params = {
              party_id: (filters.party_id == "0" ? "" : window.btoa(filters.party_id)),
              transaction_type: (filters.transaction_type == "0" ? "" : window.btoa(filters.transaction_type)),
              start_date: filters.start_date,
              end_date: filters.end_date,
              issue_pending: filters.issue_pending
            }

              let _this = this;
              tabulator.value = new Tabulator(tableRef.value, {
                  ajaxURL: BASE_URL + 'api/job-done/list_items',
                  ajaxConfig: {
                      method: "GET", //set request type to Position
                      headers: {
                          "Content-type": 'application/json; charset=utf-8', //set specific content type
                          "Authorization": "Bearer " + localStorage.getItem("token")
                      },
                  },
                  ajaxParams: params,
                  ajaxFiltering: true,
                  ajaxSorting: true,
                  printAsHtml: true,
                  printStyled: true,
                  pagination: 'remote',
                  paginationSize: 20,
                  paginationSizeSelector: [20, 30, 40],
                  layout: 'fitColumns',
                  responsiveLayout: false,
                  tooltipsHeader: true,
                  headerSort: false,
                  placeholder: 'No matching records found',
                  columns: [

                      // For HTML table
                      {
                          title: 'Transaction No',
                          minWidth: 200,
                          responsive: 0,
                          field: 'transaction_code',
                          vertAlign: 'middle',
                          print: false,
                          download: true,

                          formatter(cell) {

                            if(cell.getData().transaction_type == 1) {

                              const a = cash(`<div><i data-feather="arrow-up-right" class="w-6 h-6 font-bold text-theme-1 mr-1"></i>
                              ${
                                cell.getData().transaction_code
                              }
                              </div>`)
                              cash(a).on('click', function () {
                                view_details(cell.getData().id);
                              })
                              return a[0]
                            } else {

                              const a = cash(`<div><i data-feather="arrow-down-left" class="w-6 h-6 font-bold text-theme-6 mr-1"></i>
                              ${
                                cell.getData().transaction_code
                              }
                              </div>`)
                              cash(a).on('click', function () {
                                view_details(cell.getData().id);
                              })
                              return a[0]

                            }
                          }
                      },
                      {
                          title: 'Party',
                          field: 'party',
                          hozAlign: 'left',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                          formatter(cell) {
                            return cell.getData().party.firm_name
                          }
                      },
                      {
                          title: 'Pieces',
                          field: 'total_pieces',
                          hozAlign: 'left',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                      },
                      {
                          title: 'Rough (Ct)',
                          field: 'rough_weight',
                          hozAlign: 'left',
                          vertAlign: 'middle',
                          print: false,
                          download: true
                      },
                      {
                          title: 'Polish (Ct)',
                          field: 'polish_weight',
                          hozAlign: 'left',
                          vertAlign: 'middle',
                          print: false,
                          download: true
                      },
                      {
                          title: 'Final(Ct)',
                          field: 'total_weight',
                          hozAlign: 'left',
                          vertAlign: 'middle',
                          print: false,
                          download: true
                      },
                      {
                          title: 'Total Amount',
                          field: 'total_amount',
                          hozAlign: 'left',
                          vertAlign: 'middle',
                          print: false,
                          download: true
                      },
                      {
                          title: 'OW Date',
                          field: 'created_time',
                          hozAlign: 'left',
                          vertAlign: 'middle',
                          print: false,
                          download: true
                      },
                      {
                          title: 'Issue IP',
                          field: 'issue_trans_ip',
                          hozAlign: 'left',
                          vertAlign: 'middle',
                          print: false,
                          download: true
                      },
                      {
                        title: 'Status',
                        field: 'active_status',
                        vertAlign: 'middle',
                        print: false,
                        download: false,
                        formatter(cell) {
                          return `<div class="flex lg:justify-center ${
                            cell.getData().statusText == 'Active' ? 'text-theme-9' : 'text-theme-6'
                          }"> ${
                              cell.getData().statusText
                            }
                          </div>`
                        }
                      },
                      {
                        title: 'Action',
                        width: 90,
                        field: '',
                        responsive: 1,
                        hozAlign: 'center',
                        vertAlign: 'middle',
                        headerSort: false,
                        print: false,
                        download: false,
                        formatter(cell) {
                          const a = cash(`<div class="flex lg:justify-center items-center">
                              <button class="btn btn-outline-primary btn-sm flex items-center p-1 hover:text-white">
                                <i data-feather="monitor" class="w-4 h-4 mr-1"></i> View
                              </button>
                            </div>`)
                          cash(a).on('click', function () {
                            view_details(cell.getData().id);
                          })
                          return a[0]
                        }
                    },
                    //   {
                    //     title: '',
                    //     width: 90,
                    //     field: '',
                    //     responsive: 1,
                    //     hozAlign: 'center',
                    //     vertAlign: 'middle',
                    //     headerSort: false,
                    //     print: false,
                    //     download: false,
                    //     formatter(cell) {
                    //       const a = cash(`<div class="flex lg:justify-center items-center">
                    //           <button class="btn btn-outline-primary btn-sm flex items-center p-1 hover:text-white">
                    //             <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Edit
                    //           </button>
                    //         </div>`)
                    //       cash(a).on('click', function () {
                    //         edit_details(cell.getData().id);
                    //       })
                    //       return a[0]
                    //     }
                    // },
                      {
                        title: 'Print',
                        width: 70,
                        field: '',
                        responsive: 1,
                        hozAlign: 'center',
                        vertAlign: 'middle',
                        headerSort: false,
                        print: false,
                        download: false,
                        formatter(cell) {
                          const a = cash(`<div class="flex lg:justify-center items-center">
                              <button class="text-primary-1 p-1">
                                <i data-feather="printer" class="w-4 h-4 mr-1"></i>
                              </button>
                            </div>`)
                          cash(a).on('click', function () {
                            print_details(cell.getData().id);
                          })
                          return a[0]
                        }
                    },

                  ],
                  renderComplete() {
                      feather.replace({
                          'stroke-width': 1.5
                      })
                  }
              })
          }
          function getParties() {
            axios({
                url: 'api/master/dp_parties',
                method: 'post',
                baseURL: BASE_URL,
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
                config: {
                    headers: {
                        'Accept': 'application/json'
                    }
                }
            }).then((res) => {
              screenData.parties = res.data.items
            })
        }


          // Redraw table onresize
          const reInitOnResizeWindow = () => {
              window.addEventListener('resize', () => {
                  tabulator.value.redraw()
                  feather.replace({
                      'stroke-width': 1.5
                  })
              })
          }

          // Filter function
          const onFilter = () => {
              tabulator.value.setFilter(filter.field, filter.type, filter.value)
          }

          //Edit user
          const editUser = (id) => {
              console.log(id);
          }

          // Export
          const onExportCsv = () => {
              tabulator.value.download('csv', 'data.csv')
          }

          const onExportJson = () => {
              tabulator.value.download('json', 'data.json')
          }

          const onExportXlsx = () => {
              const win = window
              win.XLSX = xlsx
              tabulator.value.download('xlsx', 'buyer_list.xlsx', {
                  sheetName: 'Buyers'
              })
          }

          const onExportHtml = () => {
              tabulator.value.download('html', 'data.html', {
                  style: true
              })
          }

          // Print
          const onPrint = () => {
              tabulator.value.print()
          }

          const view_details = (id) => {
            router.push({
              'path': '/packets/job-done/transaction/' + window.btoa(id)
            })
          }
          const edit_details = (id) => {
            router.push({
              'path': '/packets/job-done/transaction/' + window.btoa(id)
            })
          }
          const print_details = (id) => {
            let url = BASE_URL + 'outward_prints/' + '?trans_id=' + window.btoa(id)
            window.open(url, '_blank');
          }
          const onResetFilter = () => {
            filters.party_id = '0'
            filters.transaction_type = '0'
            filters.start_date = moment().startOf('month').format('YYYY-MM-DD')
            filters.end_date = moment().format('YYYY-MM-DD')
            filters.issue_pending = false
            initTabulator()
          }

          onMounted(() => {
            initTabulator()
            getParties()
            reInitOnResizeWindow()
          })

          return {
              tableRef,
              filters,
              screenData,
              userDetails,
              filter,
              onFilter,
              onExportCsv,
              onExportJson,
              onExportXlsx,
              onExportHtml,
              initTabulator,
              onPrint,
              editUser,
              onResetFilter
          }

      }
  })
  </script>
