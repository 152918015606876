<template>
  <div class="intro-y box p-0 mt-5">
    <!-- <div class="pos__tabs nav nav-tabs justify-center" role="tablist">
      <a
        :id="(index) + '-tab'"
        href="javascript:;"
        class="flex-1 py-2 rounded-md text-center w-auto"
        role="tab"
        :aria-controls="(index)"
        aria-selected="true"
        v-for="(tab, index) in page_data.charni_details" :key="index"
        :class="{ active: active_tab_shape === (index) }"
         @click="setActiveTab($event)"
        >{{ tab.shape_name }}</a
      >
    </div> -->
    <div class="grid grid-cols-12 gap-5 mt-5">

      <!-- BEGIN: Ticket -->
      <div class="col-span-12">
        <div class="intro-y pr-1">
          <div class="box p-2">
            <div class="flex items-center justify-start overflow-x-auto" style="scrollbar-width: thin;" role="tablist">
              <button
                class="text-center m-0 rounded-none btn border-1 p-0 py-1 px-5 mr-1 whitespace-nowrap"
                :class="{ 'btn-primary': active_tab_shape === (index) }"
                v-for="(tab, index) in page_data.charni_details" :key="index"
                @click="setActiveTab(index)"
                >{{ tab.shape_name }}</button
              >
            </div>
          </div>
        </div>
        <div class="tab-content pt-3" id="kt_tabs">
          <div class="tab-pane" v-for="(tab, index) in page_data.charni_details" :key="index"
              :class="{ active: active_tab_shape === index }" id="stock_upload">
              <div class="">
                  <!-- For Shape -->
                  <div class="form-check form-switch ml-10 mb-4">

                      <label class="form-check-label whitespace-nowrap" for="flexSwitchCheckDefault">Do you want to duplicate
                          the
                          rate from another shape's rate?</label>
                          <input
                          class="show-code form-check-switch mr-0 ml-3 h-6"
                          type="checkbox" v-model="tab.cpy_shape_rate"
                        />
                  </div>
                  <div class="row items-center ml-10">
                      <ul class="nav nav-stretch nav-line-tabs fw-semobold border-0 col-4 ml-2" role="tablist"
                          id="kt_layout_builder_tabs" ref="kt_layout_builder_tabs">
                          <li class="nav-item" :class="{ 'disabled': tab.rate_type === 1 }" >
                            <button
                              class="text-center m-0 rounded-none btn border-1 p-0 py-1 px-10 mr-1 whitespace-nowrap"
                              :class="{ 'btn-primary': tab.rate_type === 1 }" data-bs-toggle="tab"
                              @click="pushColorFormatToItem(tab, 1)" data-tab-index="0" role="tab"
                              >Price Group By Color</button
                            >
                          </li>
                          <li class="nav-item" :class="{ 'disabled': tab.rate_type === 1 }" >
                            <button
                              class="text-center m-0 rounded-none btn border-1 p-0 py-1 px-10 mr-1 whitespace-nowrap"
                              :class="{ 'btn-primary': tab.rate_type === 2 }" data-bs-toggle="tab"
                              @click="pushColorFormatToItem(tab, 2)" data-tab-index="1" role="tab"
                              >Price Group By Purity</button
                            >
                          </li>
                          <li class="nav-item" :class="{ 'disabled': tab.rate_type === 1 }" >
                            <button
                              class="text-center m-0 rounded-none btn border-1 p-0 py-1 px-10 mr-1 whitespace-nowrap"
                              :class="{ 'btn-primary': tab.rate_type === 3 }" data-bs-toggle="tab"
                              @click="pushColorFormatToItem(tab, 3)" data-tab-index="2" role="tab"
                              >No Group</button
                            >
                          </li>
                      </ul>

                      <div class="col-span-2" v-if="tab.cpy_shape_rate == true">
                        <!-- <TomSelect
                          v-model="tab.alias_shape_id"
                          :options="{
                            placeholder: '',
                            loadingClass: 'loading',
                            plugins: {
                              dropdown_header: {
                                title: ''
                              }
                            }
                          }"
                          >
                          <option v-for="(item, index) in page_data.shape_arr" :value="item.id" :key="index"> {{ item.shape_name }} </option>
                        </TomSelect> -->
                      </div>
                  </div>


                  <div class="w-full pb-4" v-if="tab.cpy_shape_rate == false">
                      <div class="mx-5" v-for="(stoneItem, stoneIndex) in tab.stone_groups" :key="stoneIndex">

                          <div class="card-toolbar w-full mt-5" v-if="tab.rate_type == 1">
                              <div class="w-full">
                                  <div class="grid grid-cols-12 gap-x-2">
                                      <div class="col-span-2">
                                          <label class="fw-bold">Group {{ stoneIndex + 1 }}</label>
                                              <input class="form-control rounded-none" type="text" v-model="stoneItem.group_name" autocomplete="false" />
                                      </div>
                                      <div class="col-span-2">
                                          <div class="fw-bold">
                                              Color From
                                          </div>
                                          <div class="">
                                              <div class="">
                                                  <div class="">
                                                    <TomSelect
                                                      v-model="stoneItem.param_from"
                                                      :options="{
                                                        placeholder: '',
                                                        loadingClass: 'loading',
                                                        plugins: {
                                                          dropdown_header: {
                                                            title: ''
                                                          }
                                                        }
                                                      }"
                                                      >
                                                      <option value="">From</option>
                                                      <option v-for="(item, index) in page_data.color_arr" :label="item.color_name" :value="item.id" :key="index"> {{ item.color_name }}</option>
                                                    </TomSelect>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="col-span-2">
                                          <div class="whitespace-nowrap fw-bold">Color To</div>
                                          <div class="">
                                            <TomSelect
                                                v-model="stoneItem.param_to"
                                                :options="{
                                                  placeholder: '',
                                                  loadingClass: 'loading',
                                                  plugins: {
                                                    dropdown_header: {
                                                      title: ''
                                                    }
                                                  }
                                                }"
                                                >
                                                <option value="">From</option>
                                                <option v-for="(item, index) in page_data.color_arr" :label="item.color_name" :value="item.id" :key="index"> {{ item.color_name }}</option>
                                              </TomSelect>
                                          </div>
                                      </div>
                                      <div class="col-span-6 mt-5">
                                          <div class="flex justify-end">
                                              <button
                                                  :class="{ 'btn btn-gray btn-sm col-span-2 ml-3': stoneItem.rate_details.stone_id !== stoneType.id, 'btn btn-primary btn-sm col-span-2 ml-3': stoneItem.rate_details.stone_id === stoneType.id }"
                                                  @click="stoneItem.rate_details.stone_id = stoneType.id"
                                                  v-for="(stoneType, inx) in stoneItem.rate_details.row_data" :key="inx">
                                                  {{ stoneType.stone_name }}
                                              </button>
                                              <button
                                                class="btn btn-danger btn-sm col-span-2 ml-3"
                                                @click="removeGroup(tab, stoneIndex, stoneIndex)">
                                                  <Trash2Icon class="h-4 w-4" />
                                              </button>
                                          </div>
                                      </div>
                                  </div>
                              </div>

                          </div>
                          <div class="card-toolbar w-full mt-5" v-if="tab.rate_type == 2">
                              <div class="w-full">
                                  <div class="grid grid-cols-12 gap-x-2">
                                      <div class="col-span-2">
                                          <label class="fw-bold">Group {{ stoneIndex + 1 }} </label>
                                          <input type="text" class="form-control rounded-none" v-model="stoneItem.group_name" autocomplete="false" />
                                      </div>
                                      <div class="col-span-2">
                                          <div class="fw-bold">
                                              Purity From
                                          </div>
                                          <div class="">
                                              <div class="">
                                                  <div class="">
                                                    <TomSelect
                                                      v-model="stoneItem.param_from"
                                                      :options="{
                                                        placeholder: '',
                                                        loadingClass: 'loading',
                                                        plugins: {
                                                          dropdown_header: {
                                                            title: ''
                                                          }
                                                        }
                                                      }"
                                                      >
                                                      <option value="">From</option>
                                                      <option v-for="(item, index) in page_data.purity_name" :label="item.purity_name" :value="item.id" :key="index"> {{ item.purity_name }}</option>
                                                    </TomSelect>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="col-span-2">
                                          <div class="whitespace-nowrap fw-bold">Purity To</div>
                                          <div class="">
                                            <TomSelect
                                              v-model="stoneItem.param_to"
                                              :options="{
                                                placeholder: '',
                                                loadingClass: 'loading',
                                                plugins: {
                                                  dropdown_header: {
                                                    title: ''
                                                  }
                                                }
                                              }"
                                              >
                                              <option value="">To</option>
                                              <option v-for="(item, index) in page_data.purity_name" :label="item.purity_name" :value="item.id" :key="index"> {{ item.purity_name }}</option>
                                            </TomSelect>
                                          </div>
                                      </div>
                                      <div class="col-span-6 mt-5">
                                          <div class="flex justify-end">
                                              <button
                                                  :class="{ 'btn btn-gray btn-sm col-span-2 ml-3': stoneItem.rate_details.stone_id !== stoneType.id, 'btn btn-primary btn-sm col-span-2 ml-3': stoneItem.rate_details.stone_id === stoneType.id }"
                                                  @click="stoneItem.rate_details.stone_id = stoneType.id"
                                                  v-for="(stoneType, inx) in stoneItem.rate_details.row_data" :key="inx">
                                                  {{ stoneType.stone_name }}
                                              </button>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="card-toolbar w-full mt-5" v-if="tab.rate_type == 3">
                              <div class="w-full">
                                  <div class="grid grid-cols-12 gap-x-2 w-full">
                                      <div class="col-span-12 mb-5 w-full">
                                          <div class="flex justify-end w-full">
                                              <button
                                                  :class="{ 'btn btn-gray btn-sm col-1 ml-3': stoneItem.rate_details.stone_id !== stoneType.id, 'btn btn-primary btn-sm col-1 ml-3': stoneItem.rate_details.stone_id === stoneType.id }"
                                                  @click="stoneItem.rate_details.stone_id = stoneType.id"
                                                  v-for="(stoneType, inx) in stoneItem.rate_details.row_data" :key="inx">
                                                  {{ stoneType.stone_name }}
                                              </button>
                                              <button
                                                class="btn btn-danger btn-sm col-span-2 ml-3"
                                                @click="removeGroup(tab, stoneIndex, stoneIndex)">
                                                  <Trash2Icon class="h-4 w-4" />
                                              </button>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>


                          <table class="border border-black mt-2">
                              <thead>
                                  <tr class="border border-black">
                                      <template v-for="(header1Item, inx) in stoneItem.rate_details.header_1" :key="inx">
                                          <th v-if="inx == 1" :colspan="stoneItem.rate_details.header_2.length - 1" class="whitespace-nowrap border border-black">
                                              {{ header1Item }}
                                          </th>
                                          <th v-else class="whitespace-nowrap border border-black">
                                              {{ header1Item }}
                                          </th>
                                      </template>

                                  </tr>
                                  <tr>
                                      <th v-for="(header2Item, inx) in stoneItem.rate_details.header_2" :key="inx" class="border border-black">{{
                                          header2Item }}</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <template v-for="(rowItem, inx) in stoneItem.rate_details.row_data" :key="inx">
                                      <template v-if="stoneItem.rate_details.stone_id == rowItem.id">
                                          <tr v-for="(cellItem, cellIndex) in rowItem.rates" :key="cellIndex" class="border border-black">
                                              <td v-for="(cell, cellInx) in cellItem" :key="cellInx" class="border border-black">
                                                  <div v-if="cellInx == 0" class="p-1 whitespace-nowrap">
                                                      {{ cell }}
                                                  </div>
                                                  <div v-else class="whitespace-nowrap">
                                                          <input type="text" class="form-control p-1 rounded-none" v-model="cell.rate"
                                                              autocomplete="false" />
                                                  </div>
                                              </td>
                                          </tr>
                                      </template>
                                  </template>
                              </tbody>
                          </table>

                      </div>
                      <button type="button" class="btn btn-primary btn-sm mt-5 ml-10" @click="addRow(tab)">
                          Add Group
                      </button>
                  </div>
              </div>
          </div>
      </div>
      <div class="flex justify-end pb-5 pe-10 mx-5">
        <button type="button" @click="submit_form()" :disabled="loading" class="btn btn-primary btn-sm w-24">Save
          <LoadingIcon v-if="loading" icon="oval" color="white" class="w-4 h-4 ml-2" /></button>
      </div>
      </div>
      <!-- END: Ticket -->
    </div>
  </div>
</template>

<script>
import {
    BASE_URL
} from '@/constants/config'
import axios from 'axios'
import cash from 'cash-dom'
import {
    computed,
    defineComponent,
    inject,
    onMounted,
    reactive,
    ref,
    toRef,
    watch
} from 'vue'
import store from '@/store'
import router from '@/router'
import user from '@/store/user'
export default defineComponent({
  name: "BreadCrumb",

  setup() {

      const active_tab_shape = ref(0);
      const active_tab_formate = ref(0);
      const loading = ref(false);
      const swal = inject('$swal')
      const page_data = reactive({
          charni_details: [],
          format: [],
          color_arr: [],
          clarity_arr: [],
          color_format: [],
          purity_format: [],
          no_group: [],
          shape_arr: [],
          shapeIndex: 0,
          loading: false
      })


      const get_shape_details = () => {

        axios({
            url: 'api/master/dp_shape',
            method: 'post',
            baseURL: BASE_URL,
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            },
            config: {
                headers: {
                    'Accept': 'application/json'
                }
            }
        }).then((res) => {
          console.log(res.data.items)
          page_data.shape_arr = res.data.items;
          console.log(page_data.shape_arr)
        }).catch((error) => {
          page_data.shape_arr = [];
        })
      }

      const get_color_details = () => {
        axios({
            url: 'api/master/dp_color',
            method: 'post',
            baseURL: BASE_URL,
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            },
            config: {
                headers: {
                    'Accept': 'application/json'
                }
            }
        }).then((res) => {
          page_data.color_arr = res.data.items;
        }).catch((error) => {
          page_data.color_arr = [];
        })
      }
      const get_purity_details = () => {
        axios({
            url: 'api/master/dp_purity',
            method: 'post',
            baseURL: BASE_URL,
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            },
            config: {
                headers: {
                    'Accept': 'application/json'
                }
            }
        }).then((res) => {
          page_data.clarity_arr = res.data.items;
        }).catch((error) => {
          page_data.clarity_arr = [];
        })
      }

      const addRow = (tab) => {
          if (tab.rate_type == 1) {

            color_format(tab);
          }
          if (tab.rate_type == 2) {
              purity_format(tab);
              // tab.stone_groups.push(page_data.purity_format);
          }
          if (tab.rate_type == 3) {
              no_group_format(tab);
              // tab.stone_groups.push(page_data.no_group);
          }

      };

      const submit_form = () => {
          swal.fire({
              text: "Are you sure you want to submit the form?",
              icon: "question",
              showCancelButton: true,
              confirmButtonText: "Yes, submit",
              cancelButtonText: "No, cancel",
              buttonsStyling: false,
              heightAuto: false,
              customClass: {
                  confirmButton: "btn btn-sm btn-primary",
                  cancelButton: "btn btn-sm fw-semibold btn-light-danger",
              },
          }).then((result) => {
              if (result.isConfirmed) {

                const params = {
                      "shape_arr": page_data.charni_details,
                };

                let url_post = "/api/mix-charni-rate-save"

                    let promise = axios({
                        url: url_post,
                        method: "post",
                        data: params,
                        baseURL: BASE_URL,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token"),

                        },
                        config: {
                            headers: {
                                Accept: "application/json"
                            }
                        },
                    });
                    return promise
                        .then((result) => result.data)
                        .then((data) => {
                          swal.fire({
                              text: data.msg,
                              icon: "success",
                              buttonsStyling: false,
                              confirmButtonText: "Ok, got it!",
                              heightAuto: false,
                              customClass: {
                                  confirmButton: "btn btn-sm btn-primary",
                              },
                          }).then(() => {
                          });
                        })
                        .catch((response) => {
                          swal.fire({
                              text: response.data.error,
                              icon: "error",
                              buttonsStyling: false,
                              confirmButtonText: "Try again!",
                              heightAuto: false,
                              customClass: {
                                  confirmButton: "btn btn-sm fw-semibold btn-light-danger",
                              },
                          });
                        });
              }
          });
      };


      const pushColorFormatToItem = (tab, id) => {
          tab.rate_type = id;
          tab.stone_groups = []
          if (tab.rate_type == 1) {
              color_format(tab);
              // tab.stone_groups.push(page_data.color_format);

          }
          if (tab.rate_type == 2) {
              purity_format(tab);
              // tab.stone_groups.push(page_data.purity_format);
          }
          if (tab.rate_type == 3) {
              no_group_format(tab);
              // tab.stone_groups.push(page_data.no_group);
          }
      };


      const get_item_details = () => {

        axios({
            url: 'api/mix-charni-rate-edit',
            method: 'post',
            baseURL: BASE_URL,
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            },
            config: {
                headers: {
                    'Accept': 'application/json'
                }
            }
        }).then((res) => {
          page_data.loading = false
          page_data.charni_details = res.data.items
          page_data.format = res.data.format

        }).catch((error) => {
          page_data.loading = false
        })
      };
      const color_format = (tab) => {

        axios({
            url: 'api/color_format',
            method: 'post',
            baseURL: BASE_URL,
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            },
            config: {
                headers: {
                    'Accept': 'application/json'
                }
            }
        }).then((res) => {
          tab.stone_groups.push(res.data.format);
        }).catch((error) => {
          page_data.loading = false
        })
      };
      const purity_format = (tab) => {
        axios({
            url: 'api/purity_format',
            method: 'post',
            baseURL: BASE_URL,
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            },
            config: {
                headers: {
                    'Accept': 'application/json'
                }
            }
        }).then((res) => {
          tab.stone_groups.push(res.data.format);
        }).catch((error) => {
          page_data.loading = false
        })
      };
      const no_group_format = (tab) => {
        axios({
            url: 'api/no_group',
            method: 'post',
            baseURL: BASE_URL,
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            },
            config: {
                headers: {
                    'Accept': 'application/json'
                }
            }
        }).then((res) => {
          tab.stone_groups.push(res.data.format);
        }).catch((error) => {
          page_data.loading = false
        })
      };

      const setActiveTab = (index) => {
          active_tab_shape.value = index;
          // active_tab_shape.value = parseInt(event.target.getAttribute("data-tab-index"));
      };

      const removeGroup = (details, index, group_index)  => {
        swal.fire({
              text: "Are you sure you want to remove group?",
              icon: "question",
              showCancelButton: true,
              confirmButtonText: "Yes, submit",
              cancelButtonText: "No, cancel",
              buttonsStyling: false,
              heightAuto: false,
              customClass: {
                  confirmButton: "btn btn-sm btn-primary",
                  cancelButton: "btn btn-sm fw-semibold btn-light-danger",
              },
          }).then((result) => {
              if (result.isConfirmed) {

                details.stone_groups.splice(group_index, 1)

                  console.log(details)
                  console.log(index)
              }
          });
      }

      onMounted(() => {
          get_item_details();
          get_color_details();
          get_shape_details();
          get_purity_details();
      });

      return {
          page_data,
          active_tab_formate,
          active_tab_shape,
          setActiveTab,
          pushColorFormatToItem,
          addRow,
          submit_form,
          loading,
          removeGroup
      };
  }
})
</script>

<style scoped>
/* .save-resent-data {
  height: 40%;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  border: 1px solid #ddd;
  padding: 0px;
  text-align: left;
}

th {
  padding: 3px;
}

th {
  background-color: #f2f2f2;
}

.btn-gray {
  background-color: #f2f2f2 !important;
}
.nav-item.disabled .nav-link {
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.5;
  } */
  .active {

  }
</style>




