<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Employee Rate Requests</h2>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <!-- <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <form id="tabulator-html-filter-form" class="xl:flex sm:mr-auto">
          <div class="flex w-full">
            <div class="input-group mt-2 sm:mt-0 mr-3">
              <div id="input-group-3" class="input-group-text w-48 text-center">Process Type</div>
              <select
                class="form-select"
                v-model="formData.process_type"
                aria-label="Default select example"
                @change="getProcesses"
              >
                <option value="0">All</option>
                <option value="makeable">Makeable</option>
                <option value="rough">Rough</option>
                <option value="video">Video</option>
                <option value="lab">LAB</option>
              </select>
            </div>
            <div class="input-group mt-2 sm:mt-0">
              <TomSelect
                v-model="formData.process_id"
                    :options="{
                      placeholder: 'Select your favorite actors'
                    }"
                    class="w-60"
                    @change="initTabulator"
                  >
                  <option value="0">Select Process</option>
                  <option v-for="process in formData.processes" :key="process.id" :value="process.id">{{ process.process_name }}</option>
                </TomSelect>
            </div>
          </div>

        </form>
        <div class="flex mt-5 sm:mt-0">

          <div class="dropdown w-1/2 sm:w-auto">
            <button
              class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto"
              aria-expanded="false"
            >
              <FileTextIcon class="w-4 h-4 mr-2" /> Export
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-menu w-40">
              <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                <a
                  id="tabulator-export-csv"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportCsv"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export CSV
                </a>
                <a
                  id="tabulator-export-json"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportJson"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export JSON
                </a>
                <a
                  id="tabulator-export-xlsx"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportXlsx"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX
                </a>

              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>

</template>

<script>
/* eslint-disable */
import { defineComponent, ref,onMounted, reactive, computed, inject } from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import { useRouter, useRoute } from 'vue-router'
import { BASE_URL } from '@/constants/config.js'
import store from '@/store'

export default defineComponent({
  setup() {
    const router = useRouter()
    const route = useRoute()
    const tableRef = ref()
    const tabulator = ref()
    const user_details = computed(() => store.state.user.currentUser)
    const swal = inject('$swal')

    const initTabulator = () => {
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: BASE_URL + 'api/request/get_employee_rate_request_details',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxParams: {
          type: 1,
        },
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 20,
        paginationSizeSelector: [10, 20, 30, 40],
        layout: 'fitColumns',
        headerSort: false,
        responsiveLayout: 'collapse',
        placeholder: 'No matching records found',
        columns: [
          // For HTML table
          {
            title: 'Packet',
            responsive: 0,
            minWidth: 100,
            field: 'packet_code',
            vertAlign: 'middle',
            print: false,
            download: false,

          },
          {
            title: 'Trans Id',
            responsive: 0,
            minWidth: 100,
            field: 'transaction_code',
            vertAlign: 'middle',
            print: false,
            download: false,

          },
          {
            title: 'Process',
            responsive: 0,
            minWidth: 100,
            field: 'process_code',
            vertAlign: 'middle',
            print: false,
            download: false,

          },
          {
            title: 'Weight',
            responsive: 0,
            minWidth: 100,
            field: 'weight',
            vertAlign: 'middle',
            print: false,
            download: false,

          },
          {
            title: 'Req Type',
            responsive: 0,
            minWidth: 100,
            field: 'req type',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              if(cell.getData().external == 1) {
                return 'JobWork'
              } else {
                return 'Employee'
              }
            }

          },
          {
            title: 'Manager',
            responsive: 0,
            minWidth: 100,
            field: 'manager',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return cell.getData().manager_first_name + ' ' + cell.getData().manager_last_name
            }

          },
          {
            title: 'Employee',
            responsive: 0,
            minWidth: 100,
            field: 'employee',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              if(cell.getData().external == 1) {
                return cell.getData().jober_first_name + ' ' + cell.getData().jober_last_name
              } else {
                return cell.getData().employee_first_name + ' ' + cell.getData().employee_last_name
              }
            }

          },
          // {
          //   title: 'Cl',
          //   responsive: 0,
          //   field: 'color',
          //   // minWidth: 100,
          //   vertAlign: 'middle',
          //   print: false,
          //   download: false,

          // },
          // {
          //   title: 'Pt',
          //   responsive: 0,
          //   field: 'purity',
          //   // minWidth: 100,
          //   vertAlign: 'middle',
          //   print: false,
          //   download: false,

          // },
          // {
          //   title: 'Sp',
          //   responsive: 0,
          //   field: 'shape',
          //   // minWidth: 100,
          //   vertAlign: 'middle',
          //   print: false,
          //   download: false,

          // },
          // {
          //   title: 'Ct',
          //   responsive: 0,
          //   field: 'cut',
          //   // minWidth: 100,
          //   vertAlign: 'middle',
          //   print: false,
          //   download: false,

          // },
          // {
          //   title: 'Pl',
          //   responsive: 0,
          //   field: 'polish',
          //   // minWidth: 100,
          //   vertAlign: 'middle',
          //   print: false,
          //   download: false,

          // },
          // {
          //   title: 'Sm',
          //   responsive: 0,
          //   field: 'symmetry',
          //   // minWidth: 100,
          //   vertAlign: 'middle',
          //   print: false,
          //   download: false,

          // },
          // {
          //   title: 'Fl',
          //   responsive: 0,
          //   field: 'fluerocent',
          //   // minWidth: 100,
          //   vertAlign: 'middle',
          //   print: false,
          //   download: false,

          // },
          // {
          //   title: 'Tn',
          //   responsive: 0,
          //   field: 'tension',
          //   // minWidth: 100,
          //   vertAlign: 'middle',
          //   print: false,
          //   download: false,

          // },
          // {
          //   title: 'St',
          //   responsive: 0,
          //   field: 'stone',
          //   // minWidth: 100,
          //   vertAlign: 'middle',
          //   print: false,
          //   download: false,

          // },
          {
            title: 'Action',
            // minWidth: 50,
            field: 'actions',
            responsive: 1,
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              // const a = cash(`<div class="flex lg:justify-center items-center">
              //   <a class="flex items-center mr-3" href="javascript:;">
              //     <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Edit
              //   </a>

              // </div>`)
              const a = cash(`<div class="flex lg:justify-center items-center">
                <a class="flex items-center mr-3" href="javascript:;">
                  <i data-feather="check-square" class="w-4 h-4 mr-1"></i>`+ (user_details.value.user_role == 1 ? 'Edit' : 'Accept') + `
                </a>

               </div>`)
              cash(a).on('click', function() {
                //getDetails(cell.getData().id);

                if(user_details.value.user_role == 1) {

                  if(cell.getData().external == 1) {

                    router.push("/notification/create-jober-rate/" + window.btoa(cell.getData().id));
                  } else {

                    router.push("/notification/create-employee-rate/" + window.btoa(cell.getData().id));
                  }
                } else {
                  acceptPacketUpdate(cell.getData().id)

                }
              })

              return a[0]
            }
          },

        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    function acceptPacketUpdate(id) {
       swal({
        title: "Are you sure?",
        text: "You Want To Accept Packet!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm & Accept",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        preConfirm: login => {

          let promise = axios({
            url: 'api/request/add_accept_packet_changes',
            method: "post",
            data: {id : id},
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),

            },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data)
            .then(data => {
              initTabulator()
            })
        },
      }).then(result => {
        if (result.value) {
          //swal("Success!", "User Password Updated.", "success");
        }
      });
    }


    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'data.csv')
    }

    const onExportJson = () => {
      tabulator.value.download('json', 'data.json')
    }

    const onExportXlsx = () => {
      const win = window
      win.XLSX = xlsx
      tabulator.value.download('xlsx', 'data.xlsx', {
        sheetName: 'Products'
      })
    }

    const onExportHtml = () => {
      tabulator.value.download('html', 'data.html', {
        style: true
      })
    }

    // Print
    const onPrint = () => {
      tabulator.value.print()
    }

    onMounted(() => {
      initTabulator()
      reInitOnResizeWindow()
    })


    return {
      tableRef,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      initTabulator
    }
  }
})
</script>
