<template>
<div>
    <div class="intro-y flex items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Receive Recut</h2>
    </div>
    <div class="grid grid-cols-12 mt-5">
        <div class="intro-y col-span-12 lg:col-span-12">
            <!-- BEGIN: Input -->
            <div class="intro-y box">
                <div class="p-5">
                    <div class="grid grid-cols-12 gap-x-5">
                        <div class="col-span-12 xl:col-span-4">

                            <div>
                                <label for="update-profile-form-8" class="form-label">Scan Lot No</label>
                                <div class="input-group mt-2">

                                    <input id="update-profile-form-6" type="text" tabindex="1" placeholder="Scan Barcode" v-model="scan_lot_no" :class="{'form-control':true,'loading':scanload}" :disabled="scanload" autocomplete="off" @change="scanAndAddPack" />

                                    <button type="button" class="btn btn-primary mr-auto" @click="scanAndAddPack" style="border-radius: 0!important">
                                        <SearchIcon class="w-5 h-5 mr-2" /> Search
                                    </button>
                                    <button type="button" class="btn btn-primary-outline mr-auto" @click="clearSearch('')" style="border-radius: 0!important">
                                        Clear
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- END: Input -->
        </div>
    </div>

    <div class="grid grid-cols-12 mt-5">
        <div class="intro-y col-span-12 lg:col-span-12">
            <!-- BEGIN: Input -->
            <div class="intro-y box">
                <div class="flex flex-col sm:flex-row items-center justify-between p-5 border-b border-gray-200 dark:border-dark-5">
                    <h2 class="font-medium text-base mr-auto">Packet Details</h2>
                    <div class="text-right">
                        <button v-print="'#print-all-barcods'" class="btn btn-primary btn-sm mr-2">Print All</button>
                        <!-- <button class="btn btn-primary btn-sm mr-2" @click="set_part_details" v-if="packet_details.part_ready == 1 && (user_details.account_id == packet_details.manager_id) && (user_details.user_role == packet_details.manager_type)">Add Planning Part</button> -->
                        <!-- <button class="btn btn-primary btn-sm mr-2" @click="reset_ls_data" v-if="packet_details.part_ready == 1 && (user_details.account_id == packet_details.manager_id) && (user_details.user_role == packet_details.manager_type)">Reset(LS)</button> -->
                        <!-- <button class="btn btn-primary btn-sm mr-2" @click="get_ls_edit_details" v-if="packet_details.part_ready == 1 && (user_details.account_id == packet_details.manager_id) && (user_details.user_role == packet_details.manager_type)">Edit(LS)</button> -->
                    </div>
                </div>

                <div class="p-1 pb-10">
                    <div class="col-span-12">
                            <div style="background-color:#fff;">
                                <table class="mt-3 w-full">
                                    <thead>
                                        <tr class="border-b bg-gray-200 border dark:bg-dark-1">
                                            <th class="py-2 text-center border">Pkt No</th>
                                            <th class="py-2 text-center border">Weight</th>
                                            <th class="py-2 text-center border">MK Weight</th>
                                            <th class="py-2 text-center border">Exp. Wt</th>
                                            <th class="py-2 text-center border">Sp</th>
                                            <th class="py-2 text-center border">Cl</th>
                                            <th class="py-2 text-center border">Prt</th>
                                            <th class="py-2 text-center border">Ct</th>
                                            <th class="py-2 text-center border">Pl</th>
                                            <th class="py-2 text-center border">Sym</th>
                                            <th class="py-2 text-center border">Stn</th>
                                            <th class="py-2 text-center border">Fluc</th>
                                            <th class="py-2 text-center border">Tn</th>
                                            <th class="py-2 text-center border w-28">Print</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(packet, index) in stock_items" :key="index" class="border hover:bg-gray-200 p-0 cursor-pointer">
                                            <td class="py-1 font-medium text-center border">{{ packet.number }}</td>
                                            <td class="py-1 font-medium text-center border">{{ packet.weight }}</td>
                                            <td class="py-1 font-medium text-center border">{{ packet.mk_weight }}</td>
                                            <td class="py-1 font-medium text-center border">{{ packet.polish_weight }}</td>
                                            <td class="py-1 font-medium text-center border">{{ packet.shape }}</td>
                                            <td class="py-1 font-medium text-center border">{{ packet.color }}</td>
                                            <td class="py-1 font-medium text-center border">{{ packet.purity }}</td>
                                            <td class="py-1 font-medium text-center border">{{ packet.cut }}</td>
                                            <td class="py-1 font-medium text-center border">{{ packet.polish }}</td>
                                            <td class="py-1 font-medium text-center border">{{ packet.symmetry }}</td>
                                            <td class="py-1 font-medium text-center border">{{ packet.stone }}</td>
                                            <td class="py-1 font-medium text-center border">{{ packet.fluerocent }}</td>
                                            <td class="py-1 font-medium text-center border">{{ packet.tension }}</td>
                                            <td class="py-1 font-medium text-center border">
                                                <div class="flex justify-between items-center">
                                                  <input
                                                class="show-code form-check-switch mr-0 ml-3 p-0"
                                                type="checkbox" v-model="packet.print_status"
                                              />
                                                    <button class="btn btn-primary btn-sm p-1 mr-3" v-print="'#' + packet.number" v-if="packet.print_status == true" @click="update_print_status(packet)">
                                                        <PrinterIcon class="w-5 h-5" />
                                                    </button>
                                                    <button class="btn btn-primary btn-sm p-1 mr-3" v-print="'#' + packet.number" v-if="packet.print_status == false" disabled @click="update_print_status(packet)">
                                                        <PrinterIcon class="w-5 h-5" />
                                                    </button>
                                                    <div class="p-0 m-0 hidden">
                                                        <div :id="packet.number" class="absolute top-0 left-0 p-0 m-0">
                                                            <table class="p-0 m-0 mt-2 w-full text-black" border="collapse">
                                                                <tr class="text-center border border-l-0 border-r-0 border-black m-0 p-0">
                                                                    <th colspan="3" class="border m-auto border-black" style="font-size: 11px;line-height: 9px;">{{ packet.number }}</th>
                                                                    <!-- <th colspan="2" class="border m-auto border-black" style="font-size: 11px;line-height: 9px;">Lot : {{ packet.vepari_no }}</th> -->
                                                                    <th colspan="2" class="border m-auto border-black" style="font-size: 11px;line-height: 9px;">RW : {{ packet.mk_weight ? packet.mk_weight : packet.weight }}</th>
                                                                    <th colspan="3" class="border m-auto border-black" style="font-size: 11px;line-height: 9px;">PW : {{ packet.polish_weight }}</th>
                                                                </tr>
                                                                <tr class="text-center m-0 p-0">
                                                                    <th colspan="8" class="text-center">
                                                                        <VueBarcode :value="packet.barnum" :options="{ displayValue: false,height:27,width:2 }" tag="svg"></VueBarcode>
                                                                    </th>
                                                                </tr>
                                                                <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0" v-if="packet.mk_weight != null">
                                                                    <th class="border m-auto border-black">SHP</th>
                                                                    <th class="border m-auto border-black">CL</th>
                                                                    <th class="border m-auto border-black">PRT</th>
                                                                    <th class="border m-auto border-black">CT</th>
                                                                    <th class="border m-auto border-black">PL</th>
                                                                    <th class="border m-auto border-black">SYM</th>
                                                                    <th class="border m-auto border-black">Fl</th>
                                                                    <th class="border m-auto border-black">TN</th>
                                                                    <!-- <th class="border m-auto border-black">STN</th> -->
                                                                </tr>
                                                                <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0 p-0" v-if="packet.mk_weight != null">
                                                                    <th class="border m-auto border-black">{{ packet.shape }}</th>
                                                                    <th class="border m-auto border-black">{{ packet.color }}</th>
                                                                    <th class="border m-auto border-black">{{ packet.purity }}</th>
                                                                    <th class="border m-auto border-black">{{ packet.cut }}</th>
                                                                    <th class="border m-auto border-black">{{ packet.polish }}</th>
                                                                    <th class="border m-auto border-black">{{ packet.symmetry }}</th>
                                                                    <th class="border m-auto border-black">{{ packet.fluerocent }}</th>
                                                                    <th class="border m-auto border-black">{{ packet.tension }}</th>
                                                                    <!-- <th class="border m-auto border-black">{{ packet.stone }}</th> -->
                                                                </tr>
                                                            </table>
                                                            <p class="p-0 mt-1 text-black w-full text-right" style="font-size: 7px;line-height: 1;letter-spacing: 3px;font-weight: bold;"> <span class="text-left">EXCELLENCE</span> / <span class="text-right">KODLLIN TECHONOLOGY</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="hidden">
                                    <div id="print-all-barcods" class="absolute left-0 top-0 p-0 m-0">

                                        <div style="page-break-before: always;" :class="{'': index != 0 || (index == 1 && plan_details[0]['ls_done_id'] == null)}" v-for="(packet,index) in stock_items" :key="index">
                                            <table class="p-0 w-full text-black mt-2" border="collapse" v-if="(index != 0) || (index == 0 && packet.ls_done_id != null)">
                                                <tr class="text-center border border-l-0 border-r-0 border-black m-auto">
                                                    <th colspan="3" class="border m-auto border-black" style="font-size: 11px;line-height: 9px;">{{ packet.number }}</th>
                                                    <!-- <th colspan="2" class="border m-auto border-black" style="font-size: 11px;line-height: 9px;">Lot : {{ packet.vepari_no }}</th> -->
                                                    <th colspan="2" class="border m-auto border-black" style="font-size: 11px;line-height: 9px;">RW : {{ packet.mk_weight ? packet.mk_weight : packet.weight }}</th>
                                                    <th colspan="3" class="border m-auto border-black" style="font-size: 11px;line-height: 9px;">PW : {{ packet.polish_weight }}</th>
                                                </tr>
                                                <tr class="text-center">
                                                    <th colspan="8" class="text-center">
                                                        <VueBarcode :value="packet.barnum" :options="{ displayValue: false,height:27,width:2 }" tag="svg"></VueBarcode>
                                                    </th>
                                                </tr>
                                                <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0" v-if="packet.mk_weight != null">
                                                    <th class="border m-auto border-black">SHP</th>
                                                    <th class="border m-auto border-black">CL</th>
                                                    <th class="border m-auto border-black">PRT</th>
                                                    <th class="border m-auto border-black">CT</th>
                                                    <th class="border m-auto border-black">PL</th>
                                                    <th class="border m-auto border-black">SYM</th>
                                                    <th class="border m-auto border-black">Fl</th>
                                                    <th class="border m-auto border-black">TN</th>
                                                    <!-- <th class="border m-auto border-black">STN</th> -->
                                                </tr>
                                                <tr style="font-size: 9px;line-height: 8px;" class="text-center border border-l-0 border-r-0 border-black m-0" v-if="packet.mk_weight != null">
                                                    <th class="border m-auto border-black">{{ packet.shape }}</th>
                                                    <th class="border m-auto border-black">{{ packet.color }}</th>
                                                    <th class="border m-auto border-black">{{ packet.purity }}</th>
                                                    <th class="border m-auto border-black">{{ packet.cut }}</th>
                                                    <th class="border m-auto border-black">{{ packet.polish }}</th>
                                                    <th class="border m-auto border-black">{{ packet.symmetry }}</th>
                                                    <th class="border m-auto border-black">{{ packet.fluerocent }}</th>
                                                    <th class="border m-auto border-black">{{ packet.tension }}</th>
                                                    <!-- <th class="border m-auto border-black">{{ packet.stone }}</th> -->
                                                </tr>
                                            </table>

                                            <p class="p-0 mt-1 text-black w-full text-right" v-if="(index != 0) || (index == 0 && packet.ls_done_id != null)" style="font-size: 7px;line-height: 1;letter-spacing: 3px;font-weight: bold;"> <span class="text-left">EXCELLENCE</span> / <span class="text-right">KODLLIN TECHONOLOGY</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
            <!-- END: Input -->
        </div>
    </div>

    <div class="grid grid-cols-12 mt-5" v-if="((packet_details.main_transaction_status == 1 && plan_details.length > 0) || edit_mode == true) && (user_details.account_id == packet_details.manager_id) && (user_details.user_role == packet_details.manager_type) && (packet_details.process_type == 2 || packet_details.process_type == 5)">
        <div class="intro-y col-span-12 lg:col-span-12">
            <!-- BEGIN: Input -->
            <div class="intro-y box">
                <div class="flex flex-col sm:flex-row items-center p-5 py-3 border-b border-gray-200 dark:border-dark-5">
                    <h2 class="font-medium text-base mr-auto">Last Process Details</h2>
                </div>

                <div class="p-5">
                    <div class="grid grid-cols-12 gap-x-5 w-full">
                        <div class="col-span-12 md:col-span-4">

                            <div>
                                <label for="update-profile-form-6" class="form-label">Packet No :&nbsp; </label>
                                <label for="update-profile-form-6 " class="form-label font-bold"> {{ packet_details.number}}</label>
                            </div>
                            <div>
                                <label for="update-profile-form-6" class="form-label">Issue Lot weight :&nbsp; </label>
                                <label for="update-profile-form-6 " class="form-label font-bold"> {{ packet_details.issue_weight}} Ct</label>
                            </div>

                            <div>
                                <label for="update-profile-form-6" class="form-label">Issue To :&nbsp; </label>
                                <label for="update-profile-form-6 " class="form-label font-bold"> {{ packet_details.employee_first_name + ' '+ packet_details.employee_last_name}}</label>
                            </div>
                            <div>
                                <label for="update-profile-form-6" class="form-label">Issue At :&nbsp; </label>
                                <label for="update-profile-form-6 " class="form-label font-bold"> {{ packet_details.created_at}}</label>
                            </div>
                        </div>
                        <div class="col-span-12 md:col-span-2"></div>
                          <!-- <div class="col-span-12 md:col-span-4">
                            <div>
                                <label for="update-profile-form-6" class="form-label">Majuri Details </label>
                            </div>
                            <table class="w-full border">
                                <thead>
                                    <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                        <th class="py-2 border-b text-xs lg:text-md text-center border-r">Weight (Ct)</th>
                                        <th class="py-2 border-b text-xs lg:text-md text-center border-r">Majuri</th>
                                        <th class="py-2 border-b text-xs lg:text-md text-center border-r">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item,index) in majuri_details" :key="index">
                                        <th class="py-2 border-b text-xs lg:text-md text-center border-r"><input type="text" class="form-control text-center" :disabled="index == 0" @change="getMajuri_details(item)" v-model="item.weight"></th>
                                        <th class="py-2 border-b text-xs lg:text-md text-center border-r"><input type="text" disabled class="form-control text-center" v-model="item.majuri"></th>
                                        <th class="py-2 border-b text-xs lg:text-md text-center border-r">
                                            <MinusSquareIcon class="w-8 h-8 text-primary-3" @click="removenewMajuriWeight(item)" />
                                        </th>
                                    </tr>
                                    <tr class="border-b bg-gray-100 dark:bg-dark-1">
                                        <th class="py-2 border-b text-xs lg:text-md text-center border-r">{{ $h.myFloat(majuri_total.weight) }}</th>
                                        <th class="py-2 border-b text-xs lg:text-md text-center border-r">{{ $h.myFloat(majuri_total.majuri) }}</th>
                                        <th class="py-2 border-b text-xs lg:text-md text-center border-r"></th>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="mt-3">
                                <button @click="addnewMajuriWeight()" class="btn btn-primary">Add Weight</button>
                            </div>
                        </div> -->
                        <!-- <div class="col-span-12 md:col-span-4">
                          <div class="">
                                <label for="update-profile-form-6" class="form-label whitespace-nowrap">Return Weight :&nbsp;</label>
                                <input id="update-profile-form-6" type="text" placeholder="Weight in Ct" v-model="menual_total.return_weight" :class="{'form-control':true,'loading':scanload}" @keypress="$h.preventInvalidInput" onpaste="return false;" @change="calculateGhat" readonly />

                            </div>

                            <div class="mt-2" v-if="(user_details.account_id == packet_details.manager_id) && (user_details.user_role == packet_details.manager_type) && packet_details.process_type == 2">

                                <label for="update-profile-form-6"  class="form-label whitespace-nowrap">Process Ghat :&nbsp;</label>
                                <input id="update-profile-form-6" type="text" placeholder="Weight in Ct" v-model="menual_total.ghat_weight" :class="{'form-control':true}" @change="calculateGhat" readonly />
                            </div>
                            <div class="mt-2" v-if="(user_details.account_id == packet_details.manager_id) && (user_details.user_role == packet_details.manager_type) && packet_details.process_type == 2">

                                <label for="update-profile-form-6"  class="form-label whitespace-nowrap">Process Assortment :&nbsp;</label>
                                <input id="update-profile-form-6" type="text" placeholder="Weight in Ct" v-model="menual_total.assortment_weight" :class="{'form-control':true}" @change="calculateGhat" readonly />
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
            <!-- END: Input -->

        </div>

    </div>
    <div class="grid grid-cols-12 mt-5" v-if="(packet_details.main_transaction_status == 1 && plan_details.length > 0) && (user_details.account_id == packet_details.manager_id) && (user_details.user_role == packet_details.manager_type) && (packet_details.process_type == 2 || packet_details.process_type == 5)">
        <div class="intro-y col-span-12 lg:col-span-12">
            <!-- BEGIN: Input -->
            <div class="intro-y box">
                <div class="flex flex-col sm:flex-row items-center p-5 py-3 border-b border-gray-200 dark:border-dark-5">
                    <h2 class="font-medium text-base mr-auto">Packet Plan Details <span v-if="costing_value"> ( {{ costing_value }} $ )</span>
                        <span>
                            <span v-if="costing_value">
                                [ <span v-if="menual_total.value - costing_value > 0" class="text-theme-9">
                                    <ArrowUpIcon class="h-6 w-6 p-1" /> ( {{ parseFloat(100 * (menual_total.value - costing_value) /costing_value).toFixed(2) }} % )
                                </span>
                                <span v-else class="text-theme-6">
                                    <ArrowDownIcon class="h-6 w-6 p-1" /> ( {{ parseFloat(100 * (costing_value - menual_total.value) /costing_value).toFixed(2) }} % )
                                </span> ]
                            </span>
                            <span>
                                [ <span v-if="menual_total.value - costing_value_manager > 0" class="text-theme-9">
                                    <ArrowUpIcon class="h-6 w-6 p-1" /> ( {{ parseFloat(100 * (menual_total.value - costing_value_manager) /costing_value_manager).toFixed(2) }} % )
                                </span>
                                <span v-else class="text-theme-6">
                                    <ArrowDownIcon class="h-6 w-6 p-1" /> ( {{ parseFloat(100 * (costing_value_manager - menual_total.value) /costing_value_manager).toFixed(2) }} % )
                                </span> ]
                            </span>
                        </span></h2>
                        <button type="button" class="btn btn-primary btn-sm" v-if="enable_return" @click="submitReturnRough">
                            Take Return
                        </button>
                </div>

                <div class="p-1 pb-10">
                    <div class="col-span-12">
                        <div class="border">
                            <div style="background-color:#fff;">
                                <table class="w-full border">
                                    <thead>
                                        <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">#</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Shp</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Clr</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Prt</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Cut</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">PL</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Symm</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Fluc</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">TN</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Stn</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Ratio</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Tabl</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Height</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Repo</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Value</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Back</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">MK Wt</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Polish Wt</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r" v-if="(user_details.account_id == packet_details.manager_id) && (user_details.user_role == packet_details.manager_type) && packet_details.process_type == 2">Makeable</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item,index) in plan_details" :key="index">
                                            <td class="py-1 border-b text-xs lg:text-md text-center border-r"><span v-if="menual_total.is_ls_weight == false">{{ index + 1 }}</span> <span v-if="menual_total.is_ls_weight"><input type="checkbox" v-model="item.ls_check" @change="getPlanningTotal"></span></td>
                                            <td class="py-1 border-b text-xs lg:text-md text-center border-r"><select class="form-select py-1" v-model="item.shape" @change="loadPlanningValues(item)" aria-label="Default select example">
                                              <option :value="shape.id" v-for="shape in diamond.shapes" :key="shape.id">{{ shape.shape_name }}</option>
                                                </select>
                                            </td>
                                            <td class="py-1 border-b text-xs lg:text-md text-center border-r"><select class="form-select py-1" v-model="item.color" @change="loadPlanningValues(item)" aria-label="Default select example">
                                              <option :value="color.id" v-for="color in diamond.colors" :key="color.id">{{ color.color_name }}</option>
                                                </select>
                                            </td>
                                            <td class="py-1 border-b text-xs lg:text-md text-center border-r"><select class="form-select py-1" v-model="item.purity" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                    <option :value="purity.id" v-for="purity in diamond.purities" :key="purity.id">{{ purity.purity_name }}</option>
                                                </select>
                                            </td>
                                            <td class="py-1 border-b text-xs lg:text-md text-center border-r"><select class="form-select py-1" v-model="item.cut" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                    <option :value="cut.id" v-for="cut in diamond.cuts" :key="cut.id">{{ cut.cut_name }}</option>
                                                </select>
                                            </td>
                                            <td class="py-1 border-b text-xs lg:text-md text-center border-r"><select class="form-select py-1" v-model="item.polish" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                    <option :value="polish.id" v-for="polish in diamond.polish" :key="polish.id">{{ polish.polish_name }}</option>
                                                </select>
                                            </td>
                                            <td class="py-1 border-b text-xs lg:text-md text-center border-r"><select class="form-select py-1" v-model="item.symmetry" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                    <option :value="symmetry.id" v-for="symmetry in diamond.symmetry" :key="symmetry.id">{{ symmetry.symmetry_name }}</option>
                                                </select>
                                            </td>
                                            <td class="py-1 border-b text-xs lg:text-md text-center border-r"><select class="form-select py-1" v-model="item.fluerocent" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                    <option :value="fluerocent.id" v-for="fluerocent in diamond.fluerocents" :key="fluerocent.id">{{ fluerocent.fluerocent_name }}</option>
                                                </select>
                                            </td>
                                            <td class="py-1 border-b text-xs lg:text-md text-center border-r"><select class="form-select py-1" v-model="item.tension" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                    <option :value="tension.id" v-for="tension in diamond.tensions" :key="tension.id">{{ tension.tension_name }}</option>
                                                </select>
                                            </td>
                                            <td class="py-1 border-b text-xs lg:text-md text-center border-r"><select class="form-select py-1" v-model="item.stone" @change="loadPlanningValues(item)" aria-label="Default select example" disabled>
                                                    <option :value="stone.id" v-for="stone in diamond.stones" :key="stone.id">{{ stone.stone_name }}</option>
                                                </select>
                                            </td>
                                            <td class="py-1 border-b text-xs lg:text-md text-center border-r"><select class="form-select py-1" v-model="item.ratio" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                    <option :value="ratio.id" v-for="ratio in diamond.ratios" :key="ratio.id">{{ ratio.ratio_name }}</option>
                                                </select>
                                            </td>
                                            <td class="py-1 border-b text-xs lg:text-md text-center border-r"><select class="form-select py-1" v-model="item.tabl" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                    <option :value="tabl.id" v-for="tabl in diamond.tabls" :key="tabl.id">{{ tabl.tabl_name }}</option>
                                                </select>
                                            </td>
                                            <td class="py-1 border-b text-xs lg:text-md text-center border-r"><select class="form-select py-1" v-model="item.height" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                    <option :value="height.id" v-for="height in diamond.heights" :key="height.id">{{ height.height_name }}</option>
                                                </select>
                                            </td>
                                            <td class="py-1 border-b text-xs lg:text-md text-center border-r"><input type="text" class="form-control w-16 py-1" @keypress="$h.preventInvalidInput" disabled v-model="item.r_rate"></td>
                                            <td class="py-1 border-b text-xs lg:text-md text-center border-r"><input type="text" class="form-control w-16 py-1" @keypress="$h.preventInvalidInput" disabled v-model="item.value"></td>
                                            <td class="py-1 border-b text-xs lg:text-md text-center border-r"><input type="text" class="form-control w-16 py-1" @keypress="$h.preventInvalidInput" disabled v-model="item.discount"></td>
                                            <td class="py-1 border-b text-xs lg:text-md text-center border-r"><input type="text" class="form-control w-16 py-1" @keypress="$h.preventInvalidInput" @change="getPlanningTotal" v-model="item.weight"></td>
                                            <td class="py-1 border-b text-xs lg:text-md text-center border-r"><input type="text" class="form-control w-16 py-1" @keypress="$h.preventInvalidInput" @change="loadPlanningValues(item)" v-model="item.polish_weight"></td>
                                            <td class="py-1 border-b text-xs lg:text-md text-center border-r" v-if="(user_details.account_id == packet_details.manager_id) && (user_details.user_role == packet_details.manager_type) && packet_details.process_type == 2"><span><input type="checkbox" v-model="item.is_makeable"></span></td>
                                            <!-- <td>
                                                <button type="button" class="text-theme-6 flex items-center" v-if="index != 0" tabindex="-1" @click="removeField(item.id)">
                                                    <Trash2Icon class="w-5 h-5 mr-1" tabindex="-1" />
                                                </button>
                                            </td> -->
                                        </tr>
                                        <tr class="border-b bg-gray-100 dark:bg-dark-1">
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td class="py-1 border-b text-xs lg:text-md text-center border-r">{{ menual_total.value }}</td>
                                            <td></td>
                                            <td class="py-1 border-b text-xs lg:text-md text-center border-r">{{ menual_total.weight }}</td>
                                            <td class="py-1 border-b text-xs lg:text-md text-center border-r">{{ menual_total.polish_weight }}</td>
                                            <td v-if="(user_details.account_id == packet_details.manager_id) && (user_details.user_role == packet_details.manager_type) && packet_details.process_type == 2"></td>
                                            <!-- <td></td> -->
                                        </tr>
                                        <tr class="border-b bg-gray-100 dark:bg-dark-1" v-if="(user_details.account_id == packet_details.manager_id) && (user_details.user_role == packet_details.manager_type) && packet_details.process_type == 2">
                                            <td colspan="16" class="text-right">Rough Available Weight</td>
                                            <td class="py-1 border-b text-xs lg:text-md text-center border-r"><input type="text" class="form-control w-16 py-1 text-center" @keypress="$h.preventInvalidInput" @change="getPlanningTotal" :disabled="this.menual_total.is_all_chacked == true" v-model="menual_total.rough_weight"></td>
                                            <td>
                                              <button class="btn btn-primary btn-sm py-1" title="Add New Rough" @click="createNewRough">
                                                <PlusIcon class="h-4 w-4" /> Rough
                                              </button>
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr class="border-b bg-gray-100 dark:bg-dark-1" v-for="(item, index) in rough_items" v-if="(user_details.account_id == packet_details.manager_id) && (user_details.user_role == packet_details.manager_type) && packet_details.process_type == 2">
                                            <td colspan="16" class="text-right">New Part [{{ index + 1 }}]</td>
                                            <td class="py-1 border-b text-xs lg:text-md text-center border-r"><input type="text" class="form-control w-16 py-1 text-center" @keypress="$h.preventInvalidInput" @change="getPlanningTotal" v-model="item.rough_weight"></td>
                                            <td>
                                              <button class="btn btn-danger btn-sm py-1 text-white" title="Remove New Rough" @click="removeRough(item.id)">
                                                <TrashIcon class="h-4 w-4 text-white" />
                                              </button>
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr class="border-b bg-gray-100 dark:bg-dark-1" v-if="(user_details.account_id == packet_details.manager_id) && (user_details.user_role == packet_details.manager_type) && packet_details.process_type == 2">
                                            <td colspan="16" class="text-right">Assortment Weight</td>
                                            <td class="py-1 border-b text-xs lg:text-md text-center border-r"><input type="text" class="form-control w-16 py-1 text-center" @keypress="$h.preventInvalidInput" @change="getPlanningTotal" v-model="menual_total.assortment_weight"></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <!-- <tr class="border-b bg-gray-100 dark:bg-dark-1">
                                            <td colspan="16" class="text-right">Ghat Weight</td>
                                            <td class="py-1 border-b text-xs lg:text-md text-center border-r"><input type="text" class="form-control w-16 py-1 text-center" @keypress="$h.preventInvalidInput" v-model="menual_total.ghat_weight" disabled></td>
                                            <td></td>
                                            <td></td>
                                        </tr> -->
                                    </tbody>
                                </table>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <!-- END: Input -->
        </div>
    </div>
    <div class="grid grid-cols-12 mt-5" v-else-if="edit_mode == true && (user_details.account_id == packet_details.manager_id) && (user_details.user_role == packet_details.manager_type) && (packet_details.process_type == 2 || packet_details.process_type == 5)">
        <div class="intro-y col-span-12 lg:col-span-12">
            <!-- BEGIN: Input -->
            <div class="intro-y box">
                <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
                    <h2 class="font-medium text-base mr-auto">Packet Plan Details <span v-if="costing_value"> ( {{ costing_value }} $ )</span>
                        <span>
                            <span v-if="costing_value">
                                [ <span v-if="menual_total.value - costing_value > 0" class="text-theme-9">
                                    <ArrowUpIcon class="h-6 w-6 p-1" /> ( {{ parseFloat(100 * (menual_total.value - costing_value) /costing_value).toFixed(2) }} % )
                                </span>
                                <span v-else class="text-theme-6">
                                    <ArrowDownIcon class="h-6 w-6 p-1" /> ( {{ parseFloat(100 * (costing_value - menual_total.value) /costing_value).toFixed(2) }} % )
                                </span> ]
                            </span>
                            <span>
                                [ <span v-if="menual_total.value - costing_value_manager > 0" class="text-theme-9">
                                    <ArrowUpIcon class="h-6 w-6 p-1" /> ( {{ parseFloat(100 * (menual_total.value - costing_value_manager) /costing_value_manager).toFixed(2) }} % )
                                </span>
                                <span v-else class="text-theme-6">
                                    <ArrowDownIcon class="h-6 w-6 p-1" /> ( {{ parseFloat(100 * (costing_value_manager - menual_total.value) /costing_value_manager).toFixed(2) }} % )
                                </span> ]
                            </span>
                        </span></h2>
                </div>

                <div class="p-1 pb-10">
                    <div class="col-span-12">
                        <div class="border">
                            <div style="background-color:#fff;">
                                <table class="w-full border">
                                    <thead>
                                        <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">#</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Packet No</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Stock No</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Shp</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Clr</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Prt</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Cut</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">PL</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Symm</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Fluc</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">TN</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Stn</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Ratio</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Tabl</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Height</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Repo</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Value</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Back</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">MK Wt</th>
                                            <th class="py-2 border-b text-xs lg:text-md text-center border-r">Polish Wt</th>
                                            <!-- <th class="py-2 border-b text-xs lg:text-md text-center border-r">Action</th> -->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item,index) in plan_details" :key="index">
                                            <td class="py-2 border-b text-xs lg:text-md text-center border-r"><span v-if="menual_total.is_ls_weight == false">{{ index + 1 }}</span> <span v-if="menual_total.is_ls_weight"><input type="checkbox" v-model="item.ls_check" @change="getPlanningTotal"></span></td>
                                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ item.number ? item.number : '-' }}</td>
                                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ item.vepari_no ? item.vepari_no : '-' }}</td>
                                            <td class="py-2 border-b text-xs lg:text-md text-center border-r"><select class="form-select" v-model="item.shape" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                    <option :value="shape.id" v-for="shape in diamond.shapes" :key="shape.id">{{ shape.shape_name }}</option>
                                                </select>
                                            </td>
                                            <td class="py-2 border-b text-xs lg:text-md text-center border-r"><select class="form-select" v-model="item.color" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                    <option :value="color.id" v-for="color in diamond.colors" :key="color.id">{{ color.color_name }}</option>
                                                </select>
                                            </td>
                                            <td class="py-2 border-b text-xs lg:text-md text-center border-r"><select class="form-select" v-model="item.purity" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                    <option :value="purity.id" v-for="purity in diamond.purities" :key="purity.id">{{ purity.purity_name }}</option>
                                                </select>
                                            </td>
                                            <td class="py-2 border-b text-xs lg:text-md text-center border-r"><select class="form-select" v-model="item.cut" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                    <option :value="cut.id" v-for="cut in diamond.cuts" :key="cut.id">{{ cut.cut_name }}</option>
                                                </select>
                                            </td>
                                            <td class="py-2 border-b text-xs lg:text-md text-center border-r"><select class="form-select" v-model="item.polish" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                    <option :value="polish.id" v-for="polish in diamond.polish" :key="polish.id">{{ polish.polish_name }}</option>
                                                </select>
                                            </td>
                                            <td class="py-2 border-b text-xs lg:text-md text-center border-r"><select class="form-select" v-model="item.symmetry" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                    <option :value="symmetry.id" v-for="symmetry in diamond.symmetry" :key="symmetry.id">{{ symmetry.symmetry_name }}</option>
                                                </select>
                                            </td>
                                            <td class="py-2 border-b text-xs lg:text-md text-center border-r"><select class="form-select" v-model="item.fluerocent" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                    <option :value="fluerocent.id" v-for="fluerocent in diamond.fluerocents" :key="fluerocent.id">{{ fluerocent.fluerocent_name }}</option>
                                                </select>
                                            </td>
                                            <td class="py-2 border-b text-xs lg:text-md text-center border-r"><select class="form-select" v-model="item.tension" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                    <option :value="tension.id" v-for="tension in diamond.tensions" :key="tension.id">{{ tension.tension_name }}</option>
                                                </select>
                                            </td>
                                            <td class="py-2 border-b text-xs lg:text-md text-center border-r"><select class="form-select" v-model="item.stone" @change="loadPlanningValues(item)" aria-label="Default select example" disabled>
                                                    <option :value="stone.id" v-for="stone in diamond.stones" :key="stone.id">{{ stone.stone_name }}</option>
                                                </select>
                                            </td>
                                            <td class="py-2 border-b text-xs lg:text-md text-center border-r"><select class="form-select" v-model="item.ratio" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                    <option :value="ratio.id" v-for="ratio in diamond.ratios" :key="ratio.id">{{ ratio.ratio_name }}</option>
                                                </select>
                                            </td>
                                            <td class="py-2 border-b text-xs lg:text-md text-center border-r"><select class="form-select" v-model="item.tabl" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                    <option :value="tabl.id" v-for="tabl in diamond.tabls" :key="tabl.id">{{ tabl.tabl_name }}</option>
                                                </select>
                                            </td>
                                            <td class="py-2 border-b text-xs lg:text-md text-center border-r"><select class="form-select" v-model="item.height" @change="loadPlanningValues(item)" aria-label="Default select example">
                                                    <option :value="height.id" v-for="height in diamond.heights" :key="height.id">{{ height.height_name }}</option>
                                                </select>
                                            </td>
                                            <td class="py-2 border-b text-xs lg:text-md text-center border-r"><input type="text" class="form-control w-16" disabled v-model="item.r_rate"></td>
                                            <td class="py-2 border-b text-xs lg:text-md text-center border-r"><input type="text" class="form-control w-16" disabled v-model="item.value"></td>
                                            <td class="py-2 border-b text-xs lg:text-md text-center border-r"><input type="text" class="form-control w-16" disabled v-model="item.discount"></td>
                                            <td class="py-2 border-b text-xs lg:text-md text-center border-r"><input type="text" class="form-control w-16" @change="getPlanningTotal" v-model="item.weight"></td>
                                            <td class="py-2 border-b text-xs lg:text-md text-center border-r"><input type="text" class="form-control w-16" @change="loadPlanningValues(item)" v-model="item.polish_weight"></td>
                                            <!-- <td>
                                                <button type="button" class="text-theme-6 flex items-center" v-if="index != 0" tabindex="-1" @click="removeField(item.id)">
                                                    <Trash2Icon class="w-5 h-5 mr-1" tabindex="-1" />
                                                </button>
                                            </td> -->
                                        </tr>
                                        <tr class="border-b bg-gray-100 dark:bg-dark-1">
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ menual_total.value }}</td>
                                            <td></td>
                                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ menual_total.weight }}</td>
                                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ menual_total.polish_weight }}</td>
                                            <!-- <td></td> -->
                                        </tr>
                                        <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                            <td colspan="3">Assortment Weight</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td class="py-2 border-b text-xs lg:text-md text-center border-r"><input type="text" class="form-control w-16 text-center" @change="getPlanningTotal" v-model="menual_total.assortment_weight"></td>
                                            <td></td>
                                            <!-- <td></td> -->
                                        </tr>
                                        <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                            <td colspan="3">Ghat Weight</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td class="py-2 border-b text-xs lg:text-md text-center border-r">{{ menual_total.ghat_weight }}</td>
                                            <td></td>
                                            <!-- <td></td> -->
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>

                    </div>
                    <div class="flex justify-between mt-4">
                        <div>

                        </div>
                        <button type="button" class="btn btn-primary mt-3" v-if="enable_return" @click="submitReturnEditRough">
                            Update
                        </button>
                    </div>
                </div>
            </div>
            <!-- END: Input -->
        </div>
    </div>
    <div id="new-manual-planning" ref="new-manual-planning" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="font-medium text-base mr-auto">Add Planning Details</h2>
                </div>
                <div class="modal-body">

                    <table class="w-full border">
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>Mk Wt</th>
                                <th>PL Wt</th>
                                <th>Shp</th>
                                <th>Clr</th>
                                <th>Prt</th>
                                <th>Cut</th>
                                <th>PL</th>
                                <th>Symm</th>
                                <th>Flue</th>
                                <th>TN</th>
                                <!-- <th>Stn</th> -->
                                <th>Ratio</th>
                                <th>tabl</th>
                                <th>Height</th>
                                <th>R Rate ($)</th>
                                <th>Back (%)</th>
                                <th>Value ($)</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="border" v-for="(plan,index) in manualPlanning" :key="index">
                                <td>
                                    <p>{{ index + 1 }}</p>
                                </td>
                                <td>
                                    <input v-model="plan.weight" type="text" @input="loadPlanningValuesNew(plan)" class="form-control w-20" placeholder="Weight" @keypress="$h.preventInvalidInput" onpaste="return false;">
                                </td>
                                <td>
                                    <input v-model="plan.polish_weight" type="text" @input="loadPlanningValuesNew(plan)" class="form-control w-20" placeholder="Polish Weight" @keypress="$h.preventInvalidInput" onpaste="return false;">
                                </td>
                                <td>
                                    <select class="form-select" v-model="plan.shape" @change="loadPlanningValuesNew(plan)" aria-label="Default select example">
                                        <option :value="shape.id" v-for="shape in diamond.shapes" :key="shape.id">{{ shape.shape_name }}</option>
                                    </select>
                                </td>
                                <td>
                                    <select class="form-select" v-model="plan.color" @change="loadPlanningValuesNew(plan)" aria-label="Default select example">
                                        <option :value="shape.id" v-for="shape in diamond.colors" :key="shape.id">{{ shape.color_name }}</option>
                                    </select>
                                </td>
                                <td>
                                    <select class="form-select" v-model="plan.purity" @change="loadPlanningValuesNew(plan)" aria-label="Default select example">
                                        <option :value="shape.id" v-for="shape in diamond.purities" :key="shape.id">{{ shape.purity_name }}</option>
                                    </select>
                                </td>
                                <td>
                                    <select class="form-select" v-model="plan.cut" @change="loadPlanningValuesNew(plan)" aria-label="Default select example">
                                        <option :value="shape.id" v-for="shape in diamond.cuts" :key="shape.id">{{ shape.cut_name }}</option>
                                    </select>
                                </td>
                                <td>
                                    <select class="form-select" v-model="plan.polish" @change="loadPlanningValuesNew(plan)" aria-label="Default select example">
                                        <option :value="shape.id" v-for="shape in diamond.polish" :key="shape.id">{{ shape.polish_name }}</option>
                                    </select>
                                </td>
                                <td>
                                    <select class="form-select" v-model="plan.symmetry" @change="loadPlanningValuesNew(plan)" aria-label="Default select example">
                                        <option :value="shape.id" v-for="shape in diamond.symmetry" :key="shape.id">{{ shape.symmetry_name }}</option>
                                    </select>
                                </td>
                                <td>
                                    <select class="form-select" v-model="plan.fluerocent" @change="loadPlanningValuesNew(plan)" aria-label="Default select example">
                                        <option :value="shape.id" v-for="shape in diamond.fluerocents" :key="shape.id">{{ shape.fluerocent_name }}</option>
                                    </select>
                                </td>
                                <td>
                                    <select class="form-select" v-model="plan.tension" @change="loadPlanningValuesNew(plan)" aria-label="Default select example">
                                        <option :value="shape.id" v-for="shape in diamond.tensions" :key="shape.id">{{ shape.tension_name }}</option>
                                    </select>
                                </td>
                                <td>
                                    <select class="form-select" v-model="plan.ratio" @change="loadPlanningValuesNew(plan)" aria-label="Default select example">
                                        <option :value="ratio.id" v-for="ratio in diamond.ratios" :key="ratio.id">{{ ratio.ratio_name }}</option>
                                    </select>
                                </td>
                                <td>
                                    <select class="form-select" v-model="plan.tabl" @change="loadPlanningValuesNew(plan)" aria-label="Default select example">
                                        <option :value="tabl.id" v-for="tabl in diamond.tabls" :key="tabl.id">{{ tabl.tabl_name }}</option>
                                    </select>
                                </td>
                                <td>
                                    <select class="form-select" v-model="plan.height" @change="loadPlanningValuesNew(plan)" aria-label="Default select example">
                                        <option :value="height.id" v-for="height in diamond.heights" :key="height.id">{{ height.height_name }}</option>
                                    </select>
                                </td>
                                <td>
                                    <input v-model="plan.r_rate" type="text" disabled class="form-control w-20" placeholder="R rate" @keypress="$h.preventInvalidInputInt" onpaste="return false;">
                                </td>
                                <td>
                                    <input v-model="plan.discount" type="text" disabled class="form-control w-20" placeholder="Back" @keypress="$h.preventInvalidInputInt" onpaste="return false;">
                                </td>
                                <td>
                                    <input v-model="plan.value" type="text" disabled @input="getPlanningTotalNew" class="form-control w-20" placeholder="Value" @keypress="$h.preventInvalidInputInt" onpaste="return false;">
                                </td>
                                <td>
                                    <button type="button" class="text-theme-6 flex items-center" tabindex="-1" @click="removeFieldNew(plan.id)">
                                        <Trash2Icon class="w-5 h-5 mr-1" tabindex="-1" />
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr class="border">
                                <td>
                                </td>
                                <td :class="{'text-theme-6' : $h.myFloat(menual_total_planning.weight ? menual_total_planning.weight : 0) > $h.myFloat(sub_part.weight)}">
                                    <p>{{ menual_total_planning.weight }} / {{ sub_part.weight }}</p>
                                </td>
                                <td :class="{'text-theme-6' : $h.myFloat(menual_total_planning.polish_weight ? menual_total_planning.polish_weight : 0) > $h.myFloat(sub_part.weight)}">
                                    <p>{{ menual_total_planning.polish_weight }}</p>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                    <p>{{ $h.myFloat(this.menual_total_planning.value ? this.menual_total_planning.value : 0) }}</p>
                                </td>
                            </tr>
                        </tbody>

                    </table>

                    <div>
                        <button type="button" @click="AddFieldPlan" class="btn btn-primary ml-5 mt-5 mb-5 mr-auto">
                            New Part Planning
                        </button>
                    </div>

                </div>

                <div class="modal-footer text-right">
                    <button type="button" data-dismiss="modal" class="btn btn-outline-secondary w-32 mr-1">
                        Cancel
                    </button>
                    <button type="button" @click="submitManualPlanning" :disabled="this.loading" class="btn btn-primary">Save Details
                        <LoadingIcon v-if="this.loading" icon="oval" color="white" class="w-4 h-4 ml-2" />
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
/* eslint-disable */
import {
    defineComponent
} from 'vue'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import {
    BASE_URL
} from '@/constants/config.js'
import axios from 'axios'
import datetime from 'vuejs-datetimepicker';
import Tabulator from 'tabulator-tables'
// import BarcodeGenerator from "@/components/barcode-generator/Main.vue";
import VueBarcode from '@chenfengyuan/vue-barcode';

export default defineComponent({
    components: {
        DarkModeSwitcher,
        datetime,
        VueBarcode
    },
    props: {
        rough_id: {
            type: String,
            default: ""
        }
    },

    data() {
        return {
            plan_details: [],
            packet_details: [],
            stock_items: [],
            rough_items: [],
            rough_cnt: 0,
            edit_mode: false,
            diamond: {
                colors: [],
                cuts: [],
                purities: [],
                symmetry: [],
                polish: [],
                fluerocents: [],
                tensions: [],
                stones: [],
                shapes: [],
                heights: [],
                ratios: [],
                tabls: [],
            },
            user_details: [],
            costing_value: 0,
            costing_value_manager: 0,
            manualPlanning: [{
                id: 1,
                part_no: '',
                weight: '',
                polish_weight: '',
                color: '',
                cut: '',
                purity: '',
                polish: '',
                shape: '',
                symmetry: '',
                fluerocent: '',
                tension: '',
                stone: '',
                ratio: '',
                tabl: '',
                r_rate: '',
                value: '',
                height: '',
                discount: '',
            }],
            menual_total: {
                is_ls_weight: false,
                rough_weight: 0,
                total_return_weight: 0,
                weight: 0,
                polish_weight: 0,
                value: 0,
                return_weight: 0,
                ghat_weight: 0,
                assortment_weight: 0,
                part_weight: 0,
                is_all_chacked: true,
            },
            menual_total_planning: {
                is_ls_weight: false,
                rough_weight: 0,
                weight: 0,
                polish_weight: 0,
                value: 0,
                return_weight: 0,
                ghat_weight: 0,
                assortment_weight: 0,
            },
            total_items: 1,
            ls_receive: false,
            last_pack_id: '',
            scanload: false,
            showreturndetails: false,
            enable_return: false,
            loading: false,
            scan_lot_no: '',
            sub_part: {
                packet_no: '',
                vepari_no: '',
                packet_id: '',
                weight: '',
                new_weight: '',
                loading: false
            },
            majuri_total_id: 1,
            majuri_details: [{
              id: 1,
              weight: '',
              majuri: '',
            }],
            majuri_total: {
              weight: 0,
              majuri: 0,
            },
        }
    },
    computed: {

    },
    mounted() {
        this.getDiamontData();

        this.user_details = this.$store.state.user.currentUser
    },

    methods: {
        async scanAndAddPack() {
            let _this = this;
            _this.clearSearch(this.scan_lot_no);
            this.scanload = true;

            //get lot details
            const data = {
                barcode: this.scan_lot_no,
            };
            let url_post = '';
            if (this.scan_lot_no != '') {
                url_post = "api/process/get_packet_details_for_recive_ls"
            } else {
                url_post = "api/process/get_packet_details_for_recive_ls"
            }
            let promise = axios({
                url: url_post,
                method: "post",
                data: data,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),

                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                },
            });
            return promise
                .then((result) => result.data)
                .then((data) => {

                  this.edit_mode = false
                    this.packet_details = data.packet_details
                    this.plan_details = data.plan_details
                    this.stock_items = data.stock_items
                    this.menual_total.is_ls_weight = true

                    if(this.packet_details.main_transaction_status == 1 && this.plan_details.length > 0) {
                      this.plan_details.forEach(element => {

                        if(this.packet_details.process_type == 2) {

                          element.ls_check = false
                        } else {
                          element.ls_check = true
                        }
                          })

                          this.getPlanningTotal()

                          this.enable_return = true;
                    } else {
                      // _this.clearSearch('');
                      //       this.$swal("Warning!", "Packet Details Not Found!", "warning");
                    }

                    if(this.packet_details != null) {

                      if(this.packet_details.return_record != null) {
                        this.majuri_details = data.packet_details.return_record
                      } else {

                        this.majuri_details[0]['weight'] = this.packet_details.issue_weight

                        this.getMajuri_details(this.majuri_details[0])
                      }
                    } else {

                      this.majuri_details[0]['weight'] = this.packet_details.issue_weight

                      this.getMajuri_details(this.majuri_details[0])
                    }

                    this.scanload = false;

                })
                .catch((_error) => {
                    this.scanload = false;
                    return [];
                });
        },
        async get_ls_edit_details() {
            let _this = this;
            _this.clearSearch(this.scan_lot_no);
            this.scanload = true;

            //get lot details
            const data = {
                barcode: this.scan_lot_no,
            };
            let url_post = '';
            if (this.scan_lot_no != '') {
                url_post = "api/process/get_edit_ls_details"
            } else {
                url_post = "api/process/get_edit_ls_details"
            }
            let promise = axios({
                url: url_post,
                method: "post",
                data: data,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),

                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                },
            });
            return promise
                .then((result) => result.data)
                .then((data) => {

                  this.edit_mode = true
                    this.plan_details = data.plan_details
                    this.packet_details = data.plan_details
                    this.sub_packets = data.sub_packets

                    if (this.edit_mode == true) {

                        if (data.plan_details.length > 0) {


                            this.plan_details.forEach(element => {
                                element.ls_check = false
                            })

                            this.getPlanningTotal()

                            this.enable_return = true;

                        } else {

                            _this.clearSearch('');
                            this.$swal("Warning!", "Packet Details Not Found!", "warning");

                        }

                    } else {

                        if (this.packet_details) {
                            this.packet_details.cr_weight = this.packet_details.weight_loss
                        }
                    }

                    this.scanload = false;

                })
                .catch((_error) => {
                    this.scanload = false;
                    return [];
                });
        },
        clearSearch(lot_no) {
            this.scan_lot_no = lot_no;
            this.plan_details = [];
            this.packet_details = [];
            this.stock_items = [];
            this.rough_items = [];
            this.menual_total.weight = 0;
            this.menual_total.return_weight = 0;
            this.menual_total.polish_weight = 0;
            this.menual_total.part_weight = 0;
            this.menual_total.rough_weight = 0;
            this.menual_total.value = 0;
            this.menual_total.ghat_weight = 0;
            this.menual_total.assortment_weight = 0;
            this.scanload = false
            this.showreturndetails = false
            this.enable_return = false
        },
        getDiamontData() {
            axios({
                url: 'api/master/get_all_parameters',
                method: 'post',
                baseURL: BASE_URL,
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
                config: {
                    headers: {
                        'Accept': 'application/json'
                    }
                }
            }).then((res) => {
                this.diamond.colors = res.data.color
                this.diamond.cuts = res.data.cut
                this.diamond.purities = res.data.purity
                this.diamond.polish = res.data.polish
                this.diamond.shapes = res.data.shape
                this.diamond.symmetry = res.data.symmetry
                this.diamond.fluerocents = res.data.fluerocent
                this.diamond.tensions = res.data.tension
                this.diamond.stones = res.data.stone
                this.diamond.heights = res.data.height
                this.diamond.tabls = res.data.tabl
                this.diamond.ratios = res.data.ratio
            })
        },
        removeField(id) {
            this.$swal({
                title: "Are you sure?",
                text: "You are about to delete a item!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Delete",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {
                    var i = this.plan_details.length;
                    while (i--) {
                        if (this.plan_details[i]['id'] == id) {
                            this.plan_details.splice(i, 1);
                        }
                    }
                    this.getPlanningTotal()
                }
            });
        },
        loadPlanningValues(plan) {
            if (plan.polish_weight != '' && plan.weight != '') {
                if (parseFloat(plan.polish_weight) > parseFloat(plan.weight)) {

                    this.$swal("Warning!", "Polish Must Less then Makeable Weight!", "warning")
                    var i = this.plan_details.length;
                    while (i--) {
                        if (this.plan_details[i]['id'] == plan.id) {
                            this.plan_details[i]['polish_weight'] = ''
                            return
                        }
                    }
                }
            }
            this.getPlanningTotal()
            if (plan.polish_weight != '' && plan.color != '' && plan.cut != '' && plan.purity != '' && plan.polish != '' && plan.shape != '' && plan.symmetry != '' && plan.fluerocent != '' && plan.height != '' && plan.ratio != '' && plan.tabl != '') {

                let shape_name = ''
                var i = this.diamond.shapes.length;
                while (i--) {
                    if (this.diamond.shapes[i]['id'] == plan.shape) {
                        shape_name = this.diamond.shapes[i]['shape_group']
                    }
                }
                let params = {
                    color: plan.color,
                    cut: plan.cut,
                    purity: plan.purity,
                    polish: plan.polish,
                    symmetry: plan.symmetry,
                    fluerocent: plan.fluerocent,
                    tension: plan.tension,
                    height: plan.height,
                    ratio: plan.ratio,
                    tabl: plan.tabl,
                    shape: plan.shape,
                    shape_group: shape_name,
                    polish_weight: plan.polish_weight,
                    stone: plan.stone,
                }
                axios({
                    url: 'api/plan/get_plan_values',
                    method: 'post',
                    baseURL: BASE_URL,
                    data: params,
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    },
                    config: {
                        headers: {
                            'Accept': 'application/json'
                        }
                    }
                }).then((res) => {
                    let rap_value = res.data.rape_value
                    let back = res.data.back

                    var i = this.plan_details.length;
                    while (i--) {
                        if (this.plan_details[i]['id'] == plan.id) {
                            this.plan_details[i]['r_rate'] = parseFloat(rap_value * plan.polish_weight).toFixed(2)
                            this.plan_details[i]['discount'] = back
                            this.plan_details[i]['value'] = this.plan_details[i]['r_rate'] - (this.plan_details[i]['r_rate'] * back / 100)
                        }
                    }
                    this.getPlanningTotal()
                })
            }

        },
        getPlanningTotal() {

          this.menual_total.weight = 0
          this.menual_total.return_weight = 0
          this.menual_total.polish_weight = 0
          this.menual_total.part_weight = 0
          this.menual_total.value = 0

          this.menual_total.is_all_chacked = true
          let length = this.plan_details.length

          this.plan_details.forEach(element => {

            if(element.ls_check == false) {
              this.menual_total.is_all_chacked = false
            }
          });

          if(this.packet_details.process_type == 2) {


            if(this.menual_total.is_all_chacked == true) {
              this.menual_total.rough_weight = 0;
            } else {

            }

            if (this.menual_total.is_ls_weight) {

                for (let i = 0; i < length; i++) {
                    if (i == (length - 1)) {
                        this.last_pack_id = this.plan_details[i]['id']
                    }

                    if (this.plan_details[i]['ls_check']) {

                        this.menual_total.weight = parseFloat(parseFloat(this.menual_total.weight) + parseFloat(this.plan_details[i]['weight'] ? this.plan_details[i]['weight'] : 0)).toFixed(4)
                        this.menual_total.return_weight = parseFloat(parseFloat(this.menual_total.return_weight) + parseFloat(this.plan_details[i]['weight'] ? this.plan_details[i]['weight'] : 0)).toFixed(4)
                        this.menual_total.polish_weight = parseFloat(parseFloat(this.menual_total.polish_weight) + parseFloat(this.plan_details[i]['polish_weight'] ? this.plan_details[i]['polish_weight'] : 0)).toFixed(4)
                        this.menual_total.value = parseFloat(parseFloat(this.menual_total.value) + parseFloat(this.plan_details[i]['value'] ? this.plan_details[i]['value'] : 0)).toFixed(2)

                        if (parseFloat(this.menual_total.weight) > parseFloat(this.packet_details.issue_weight)) {
                            this.menual_total.weight = parseFloat(parseFloat(this.menual_total.weight) - parseFloat(this.plan_details[i]['weight'] ? this.plan_details[i]['weight'] : 0)).toFixed(4)
                            this.plan_details[i]['weight'] = '';
                            this.$swal("Warning!", "Return Weight Grater then issue Weight!", "warning");
                            this.getPlanningTotal()
                            return;
                        }
                      }

                      if (parseFloat(parseFloat(this.menual_total.weight) + parseFloat(this.menual_total.rough_weight) + parseFloat(this.menual_total.assortment_weight)) > parseFloat(this.packet_details.issue_weight)) {
                        this.menual_total.assortment_weight = 0
                        this.menual_total.ghat_weight = 0
                        this.menual_total.rough_weight = 0
                        this.$swal("Warning!", "Return Weight Grater then issue Weight!", "warning");
                        this.getPlanningTotal()
                        return;

                    }
                    this.menual_total.ghat_weight = parseFloat((this.packet_details.issue_weight ? this.packet_details.issue_weight : 0) - (parseFloat((this.menual_total.weight ? this.menual_total.weight : 0)) + parseFloat((this.menual_total.assortment_weight ? this.menual_total.assortment_weight : 0)) + parseFloat((this.menual_total.rough_weight ? this.menual_total.rough_weight : 0)))).toFixed(4)

                }

            } else {
                for (let i = 0; i < length; i++) {
                    if (i == (length - 1)) {
                        this.last_pack_id = this.plan_details[i]['id']
                    }
                    this.menual_total.weight = parseFloat(parseFloat(this.menual_total.weight) + parseFloat(this.plan_details[i]['weight'] ? this.plan_details[i]['weight'] : 0)).toFixed(4)
                    this.menual_total.return_weight = parseFloat(parseFloat(this.menual_total.return_weight) + parseFloat(this.plan_details[i]['weight'] ? this.plan_details[i]['weight'] : 0)).toFixed(4)
                    this.menual_total.polish_weight = parseFloat(parseFloat(this.menual_total.polish_weight) + parseFloat(this.plan_details[i]['polish_weight'] ? this.plan_details[i]['polish_weight'] : 0)).toFixed(4)
                    this.menual_total.value = parseFloat(parseFloat(this.menual_total.value) + parseFloat(this.plan_details[i]['value'] ? this.plan_details[i]['value'] : 0)).toFixed(2)

                    if (parseFloat(this.menual_total.weight) > parseFloat(this.packet_details.issue_weight)) {
                      this.menual_total.weight = parseFloat(parseFloat(this.menual_total.weight) - parseFloat(this.plan_details[i]['weight'] ? this.plan_details[i]['weight'] : 0)).toFixed(4)
                        this.plan_details[i]['weight'] = '';
                        this.$swal("Warning!", "Return Weight Grater then issue Weight!", "warning");
                        this.getPlanningTotal()
                        return;
                    }

                    if (parseFloat(parseFloat(this.menual_total.weight) + parseFloat(this.menual_total.assortment_weight)) > parseFloat(this.packet_details.issue_weight)) {
                        this.menual_total.assortment_weight = ''
                        this.menual_total.ghat_weight = ''
                        this.$swal("Warning!", "Return Weight Grater then issue Weight!", "warning");
                        this.getPlanningTotal()
                        return;
                    }
                    this.menual_total.ghat_weight = parseFloat((this.packet_details.issue_weight ? this.packet_details.issue_weight : 0) - (parseFloat((this.menual_total.weight ? this.menual_total.weight : 0)) + parseFloat((this.menual_total.part_weight ? this.menual_total.part_weight : 0)) + parseFloat((this.menual_total.assortment_weight ? this.menual_total.assortment_weight : 0)))).toFixed(4)

                }

              }

              this.menual_total.return_weight = parseFloat(parseFloat(this.menual_total.return_weight) + parseFloat(this.menual_total.rough_weight ? this.menual_total.rough_weight : 0)).toFixed(4)
              let rough_weight_len = this.rough_items.length

              for (let i = 0; i < rough_weight_len; i++) {
                    if (i == (length - 1)) {
                        this.last_pack_id = this.rough_items[i]['id']
                    }

                    this.menual_total.part_weight = parseFloat(parseFloat(this.menual_total.part_weight) + parseFloat(this.rough_items[i]['rough_weight'] ? this.rough_items[i]['rough_weight'] : 0)).toFixed(4)

                    if (parseFloat(this.menual_total.part_weight) > parseFloat(this.packet_details.issue_weight)) {
                        this.menual_total.part_weight = parseFloat(parseFloat(this.menual_total.part_weight) - parseFloat(this.rough_items[i]['rough_weight'] ? this.rough_items[i]['rough_weight'] : 0)).toFixed(4)
                        this.rough_items[i]['rough_weight'] = 0;
                        this.$swal("Warning!", "Return Weight Grater then issue Weight!", "warning");
                        this.getPlanningTotal()
                        return;
                      }

                      if (parseFloat(parseFloat(this.menual_total.weight) + parseFloat(this.menual_total.rough_weight) + parseFloat(this.menual_total.part_weight) + parseFloat(this.menual_total.assortment_weight)) > parseFloat(this.packet_details.issue_weight)) {
                        this.menual_total.assortment_weight = 0
                        this.menual_total.ghat_weight = 0
                        this.menual_total.rough_weight = 0
                        this.$swal("Warning!", "Return Weight Grater then issue Weight!", "warning");
                        this.getPlanningTotal()
                        return;


                      }
                    }
              this.menual_total.ghat_weight = parseFloat((this.packet_details.issue_weight ? this.packet_details.issue_weight : 0) - (parseFloat((this.menual_total.weight ? this.menual_total.weight : 0)) + parseFloat((this.menual_total.part_weight ? this.menual_total.part_weight : 0)) + parseFloat((this.menual_total.assortment_weight ? this.menual_total.assortment_weight : 0)) + parseFloat((this.menual_total.rough_weight ? this.menual_total.rough_weight : 0)))).toFixed(4)
              this.menual_total.return_weight = parseFloat(parseFloat(this.menual_total.return_weight) + parseFloat(this.menual_total.part_weight ? this.menual_total.part_weight : 0)).toFixed(4)

            } else {

              if(this.menual_total.is_all_chacked == true) {
              this.menual_total.rough_weight = 0;

                if (this.menual_total.is_ls_weight) {

                  for (let i = 0; i < length; i++) {
                      if (i == (length - 1)) {
                          this.last_pack_id = this.plan_details[i]['id']
                      }

                      if (this.plan_details[i]['ls_check']) {

                          this.menual_total.weight = parseFloat(parseFloat(this.menual_total.weight) + parseFloat(this.plan_details[i]['weight'] ? this.plan_details[i]['weight'] : 0)).toFixed(4)
                          this.menual_total.return_weight = parseFloat(parseFloat(this.menual_total.return_weight) + parseFloat(this.plan_details[i]['weight'] ? this.plan_details[i]['weight'] : 0)).toFixed(4)
                          this.menual_total.polish_weight = parseFloat(parseFloat(this.menual_total.polish_weight) + parseFloat(this.plan_details[i]['polish_weight'] ? this.plan_details[i]['polish_weight'] : 0)).toFixed(4)
                          this.menual_total.value = parseFloat(parseFloat(this.menual_total.value) + parseFloat(this.plan_details[i]['value'] ? this.plan_details[i]['value'] : 0)).toFixed(2)


                          if(this.packet_details.part_ready != null) {

                            if (parseFloat(this.menual_total.weight) > parseFloat(this.packet_details.weight_loss)) {
                                this.menual_total.weight = parseFloat(parseFloat(this.menual_total.weight) - parseFloat(this.plan_details[i]['weight'] ? this.plan_details[i]['weight'] : 0)).toFixed(4)
                                this.plan_details[i]['weight'] = '';
                                this.$swal("Warning!", "Return Weight Grater then issue Weight!", "warning");
                                this.getPlanningTotal()
                                return;
                            }
                          } else {

                            if (parseFloat(this.menual_total.weight) > parseFloat(this.packet_details.issue_weight)) {
                                this.menual_total.weight = parseFloat(parseFloat(this.menual_total.weight) - parseFloat(this.plan_details[i]['weight'] ? this.plan_details[i]['weight'] : 0)).toFixed(4)
                                this.plan_details[i]['weight'] = '';
                                this.$swal("Warning!", "Return Weight Grater then issue Weight!", "warning");
                                this.getPlanningTotal()
                                return;
                            }
                          }
                        }
                        if(this.packet_details.part_ready != null) {

                          this.menual_total.ghat_weight = parseFloat((this.packet_details.issue_weight ? this.packet_details.weight_loss : 0) - parseFloat(this.menual_total.weight ? this.menual_total.weight : 0)).toFixed(4)
                        } else {

                          this.menual_total.ghat_weight = parseFloat((this.packet_details.issue_weight ? this.packet_details.issue_weight : 0) - parseFloat(this.menual_total.weight ? this.menual_total.weight : 0)).toFixed(4)
                        }

                  }

                }
              } else {
                if (this.menual_total.is_ls_weight) {

                  for (let i = 0; i < length; i++) {
                      if (i == (length - 1)) {
                          this.last_pack_id = this.plan_details[i]['id']
                      }

                      if (this.plan_details[i]['ls_check']) {

                          this.menual_total.weight = parseFloat(parseFloat(this.menual_total.weight) + parseFloat(this.plan_details[i]['weight'] ? this.plan_details[i]['weight'] : 0)).toFixed(4)
                          this.menual_total.return_weight = parseFloat(parseFloat(this.menual_total.return_weight) + parseFloat(this.plan_details[i]['weight'] ? this.plan_details[i]['weight'] : 0)).toFixed(4)
                          this.menual_total.polish_weight = parseFloat(parseFloat(this.menual_total.polish_weight) + parseFloat(this.plan_details[i]['polish_weight'] ? this.plan_details[i]['polish_weight'] : 0)).toFixed(4)
                          this.menual_total.value = parseFloat(parseFloat(this.menual_total.value) + parseFloat(this.plan_details[i]['value'] ? this.plan_details[i]['value'] : 0)).toFixed(2)


                          if(this.packet_details.part_ready != null) {

                            if (parseFloat(this.menual_total.weight) > parseFloat(this.packet_details.weight_loss)) {
                                this.menual_total.weight = parseFloat(parseFloat(this.menual_total.weight) - parseFloat(this.plan_details[i]['weight'] ? this.plan_details[i]['weight'] : 0)).toFixed(4)
                                this.plan_details[i]['weight'] = '';
                                this.$swal("Warning!", "Return Weight Grater then issue Weight!", "warning");
                                this.getPlanningTotal()
                                return;
                            }
                          } else {

                            if (parseFloat(this.menual_total.weight) > parseFloat(this.packet_details.issue_weight)) {
                                this.menual_total.weight = parseFloat(parseFloat(this.menual_total.weight) - parseFloat(this.plan_details[i]['weight'] ? this.plan_details[i]['weight'] : 0)).toFixed(4)
                                this.plan_details[i]['weight'] = '';
                                this.$swal("Warning!", "Return Weight Grater then issue Weight!", "warning");
                                this.getPlanningTotal()
                                return;
                            }
                          }
                        }
                        if(this.packet_details.part_ready != null) {

                          this.menual_total.ghat_weight = parseFloat((this.packet_details.issue_weight ? this.packet_details.weight_loss : 0) - parseFloat(this.menual_total.weight ? this.menual_total.weight : 0)).toFixed(4)
                        } else {

                          this.menual_total.ghat_weight = parseFloat((this.packet_details.issue_weight ? this.packet_details.issue_weight : 0) - parseFloat(this.menual_total.weight ? this.menual_total.weight : 0)).toFixed(4)
                        }
                  }

                }
              }

              this.menual_total.return_weight = parseFloat(parseFloat(this.menual_total.return_weight) + parseFloat(this.menual_total.rough_weight ? this.menual_total.rough_weight : 0)).toFixed(4)
            }

        },
        addNewPart() {
            this.last_pack_id = this.last_pack_id + 1
            this.plan_details.push({
                id: this.last_pack_id,
                weight: '',
                polish_weight: '',
                ls_check: false,
                color: '',
                cut: '',
                purity: '',
                polish: '',
                shape: '',
                symmetry: '',
                fluerocent: '',
                tension: '',
                stone: this.packet_details.stone,
                height: '',
                ratio: '',
                tabl: '',
                r_rate: '',
                value: '',
                discount: '',
            })
        },
        submitReturnRough() {
            let submit = true

            // if(this.menual_total.is_all_chacked == false && !(this.menual_total.rough_weight > 0)) {

            //   this.$swal("Warning!", "Rough weight can not be 0 or Empty !", "warning");
            //   submit = false;
            //   return;
            // }

            if (this.menual_total.is_ls_weight) {

                this.plan_details.forEach(plan => {
                    if (plan.ls_check == true) {
                      if (!(plan.weight) || !(plan.polish_weight) || !(plan.color) || !(plan.cut) || !(plan.purity) || !(plan.polish) || !(plan.shape) || !(plan.symmetry) || !(plan.fluerocent) || !(plan.height) || !(plan.ratio) || !(plan.tabl)) {
                            this.$swal("Warning!", "Planning Fields are Empty !", "warning")
                            submit = false
                        }
                    }
                });
            } else {

                this.plan_details.forEach(plan => {
                  if (!(plan.weight) || !(plan.polish_weight) || !(plan.color) || !(plan.cut) || !(plan.purity) || !(plan.polish) || !(plan.shape) || !(plan.symmetry) || !(plan.fluerocent) || !(plan.height) || !(plan.ratio) || !(plan.tabl)) {
                        this.$swal("Warning!", "Planning Fields are Empty !", "warning")
                        submit = false
                    }
                });
              }

          this.rough_items.forEach(plan => {
          if (!(plan.rough_weight)) {
                  this.$swal("Warning!", "New Rough Weight is Required !", "warning")
                  submit = false
              }
          });
            if (submit == true) {

                this.$swal({
                    title: "Are you sure?",
                    text: "You are You Want To Return!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Submit",
                    showLoaderOnConfirm: true,
                    cancelButtonText: "Cancel",

                    allowOutsideClick: () => !$Swal.isLoading()
                }).then(result => {
                    if (result.isConfirmed) {

                        let params = {
                            plan_details: this.plan_details,
                            menual_total: this.menual_total,
                            rough_items: this.rough_items,
                            packet_id: this.packet_details.id,
                            majuri_details: this.majuri_details,
                            trans_id: this.packet_details.transaction_id,
                        }

                        let api_url = '';

                        if(this.packet_details.process_type == 2) {
                          api_url = 'api/process/return_ls_receive'
                        } else if(this.packet_details.process_type == 5) {
                          api_url = 'api/process/return_ls_receive_party'

                        }
                        let promise = axios({
                            url: api_url,
                            method: "post",
                            data: params,
                            baseURL: BASE_URL,
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("token"),

                            },
                            config: {
                                headers: {
                                    Accept: "application/json"
                                }
                            },
                        });
                        return promise
                            .then((result) => result.data)
                            .then((data) => {
                                if (data.status == 'success') {
                                    this.$swal("Success!", "Item Returned to Manager.", "success");
                                    this.scanAndAddPack()
                                } else {

                                    this.$swal("Warning!", "Somthing Wants Wrong!", "warning");
                                }
                            })
                    }
                });

            }
        },
        submitReturnEditRough() {
            let submit = true

            if (this.menual_total.is_ls_weight) {

                this.plan_details.forEach(plan => {
                    if (plan.ls_check == true) {
                        if (!(plan.weight) || !(plan.polish_weight) || !(plan.color) || !(plan.cut) || !(plan.purity) || !(plan.polish) || !(plan.shape) || !(plan.symmetry) || !(plan.fluerocent) || !(plan.height) || !(plan.ratio) || !(plan.tabl)) {
                            this.$swal("Warning!", "Planning Fileds are Empty !", "warning")
                            submit = false
                        }
                    }
                });
            } else {

                this.plan_details.forEach(plan => {
                    if (!(plan.weight) || !(plan.polish_weight) || !(plan.color) || !(plan.cut) || !(plan.purity) || !(plan.polish) || !(plan.shape) || !(plan.symmetry) || !(plan.fluerocent) || !(plan.height) || !(plan.ratio) || !(plan.tabl)) {
                        this.$swal("Warning!", "Planning Fileds are Empty !", "warning")
                        submit = false
                    }
                });
            }
            if (submit == true) {

              this.$swal({
                  title: "Are you sure?",
                  text: "You are You Want To Update Packets!",
                  type: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Submit",
                  showLoaderOnConfirm: true,
                  cancelButtonText: "Cancel",

                  allowOutsideClick: () => !$Swal.isLoading()
              }).then(result => {
                  if (result.isConfirmed) {

                      let params = {
                          plan_details: this.plan_details,
                          menual_total: this.menual_total,
                          packet_id: this.packet_details.id,
                          trans_id: this.packet_details.transaction_id,
                        }

                      let api_url = ''
                      if(this.packet_details.process_type == 2) {
                          api_url = 'api/process/update_ls_receive_internal'
                        } else if(this.packet_details.process_type == 5) {
                          api_url = 'api/process/update_ls_receive'

                        }

                      let promise = axios({
                          url: api_url,
                          method: "post",
                          data: params,
                          baseURL: BASE_URL,
                          headers: {
                              Authorization: "Bearer " + localStorage.getItem("token"),

                          },
                          config: {
                              headers: {
                                  Accept: "application/json"
                              }
                          },
                      });
                      return promise
                          .then((result) => result.data)
                          .then((data) => {
                              if (data.status == 'success') {
                                  this.$swal("Success!", "Item Returned to Manager.", "success");
                                  this.scanAndAddPack()
                              } else {

                                  this.$swal("Warning!", "Soothing Wants Wrong!", "warning");
                              }
                          })
                  }
              });

          }
        },
        set_part_details() {
            cash('#new-manual-planning').modal('show')
            this.sub_part.packet_no = this.packet_details.number
            this.sub_part.vepari_no = this.packet_details.vepari_no
            this.sub_part.packet_id = this.packet_details.id
            this.sub_part.weight = this.packet_details.weight_loss

            this.addNewPlanning(this.sub_part.packet_id)
        },
        reset_ls_data() {
          this.$swal({
                    title: "Are you sure?",
                    text: "You are You Want To Reset LS!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Submit",
                    showLoaderOnConfirm: true,
                    cancelButtonText: "Cancel",

                    allowOutsideClick: () => !$Swal.isLoading()
                }).then(result => {
                    if (result.isConfirmed) {

                        let params = {
                            packet_id: window.btoa(this.packet_details.id),
                        }

                        let api_url = 'api/process/cancel_plan_done'

                        let promise = axios({
                            url: api_url,
                            method: "post",
                            data: params,
                            baseURL: BASE_URL,
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("token"),

                            },
                            config: {
                                headers: {
                                    Accept: "application/json"
                                }
                            },
                        });
                        return promise
                            .then((result) => result.data)
                            .then((data) => {
                                if (data.status == 'success') {
                                    this.$swal("Success!", data.message, "success");
                                    this.scanAndAddPack()
                                } else {

                                    this.$swal("Warning!", data.message, "warning");
                                }
                            }).catch((error) => {
                              this.$swal("Warning!", "Something went wrong.", "warning");
                            })
                    }
                });
        },
        AddFieldPlan() {
            this.total_items++
            this.manualPlanning.push({
                id: this.total_items,
                part_no: '',
                weight: '',
                polish_weight: '',
                color: '',
                cut: '',
                purity: '',
                polish: '',
                shape: '',
                symmetry: '',
                fluerocent: '',
                tension: '',
                stone: this.packet_details.stone,
                ratio: '',
                tabl: '',
                r_rate: '',
                value: '',
                height: '',
                discount: '',
            });
            console.log(this.total_items);

        },
        removeFieldNew(id) {
            this.$swal({
                title: "Are you sure?",
                text: "You are about to delete a item!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Delete",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                allowOutsideClick: () => !this.$swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {
                    var i = this.manualPlanning.length;
                    while (i--) {
                        if (this.manualPlanning[i]['id'] == id) {
                            this.manualPlanning.splice(i, 1);
                        }
                    }
                }
            });
        },
        getPlanningTotalNew() {
            let length = this.manualPlanning.length

            this.menual_total_planning.weight = 0
            this.menual_total_planning.polish_weight = 0
            this.menual_total_planning.value = 0

            for (let i = 0; i < length; i++) {
                this.menual_total_planning.weight = parseFloat(parseFloat(this.menual_total_planning.weight) + parseFloat(this.manualPlanning[i]['weight'] ? this.manualPlanning[i]['weight'] : 0)).toFixed(4)
                this.menual_total_planning.polish_weight = parseFloat(parseFloat(this.menual_total_planning.polish_weight) + parseFloat(this.manualPlanning[i]['polish_weight'] ? this.manualPlanning[i]['polish_weight'] : 0)).toFixed(4)
                this.menual_total_planning.value = parseFloat(parseFloat(this.menual_total_planning.value) + parseFloat(this.manualPlanning[i]['value'] ? this.manualPlanning[i]['value'] : 0))
            }

            console.log(this.sub_part.weight)
            if (this.menual_total_planning.weight > this.sub_part.weight) {
                swal("Warning!", "Total Planning Weight Must Be less then Issue Weight!", "warning")
            }
        },
        loadPlanningValuesNew(plan) {
            if (plan.polish_weight != '' && plan.weight != '') {
                if (plan.polish_weight > plan.weight) {

                    swal("Warning!", "Polish Must Less then Makeable Weight!", "warning")
                    var i = this.manualPlanning.length;
                    while (i--) {
                        if (this.manualPlanning[i]['id'] == plan.id) {
                            this.manualPlanning[i]['polish_weight'] = ''
                            return
                        }
                    }
                }
            }
            this.getPlanningTotalNew()

            if (plan.polish_weight != '' && plan.color != '' && plan.cut != '' && plan.purity != '' && plan.polish != '' && plan.shape != '' && plan.symmetry != '' && plan.fluerocent != '' && plan.height != '' && plan.ratio != '' && plan.tabl != '') {

                let shape_name = ''
                var i = this.diamond.shapes.length;
                while (i--) {
                    if (this.diamond.shapes[i]['id'] == plan.shape) {
                        shape_name = this.diamond.shapes[i]['shape_group']
                    }
                }
                let params = {
                    color: plan.color,
                    cut: plan.cut,
                    purity: plan.purity,
                    polish: plan.polish,
                    symmetry: plan.symmetry,
                    fluerocent: plan.fluerocent,
                    tension: plan.tension,
                    height: plan.height,
                    ratio: plan.ratio,
                    tabl: plan.tabl,
                    shape: plan.shape,
                    shape_group: shape_name,
                    polish_weight: plan.polish_weight,
                    stone: this.stone,
                }
                axios({
                    url: 'api/plan/get_plan_values',
                    method: 'post',
                    baseURL: BASE_URL,
                    data: params,
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    },
                    config: {
                        headers: {
                            'Accept': 'application/json'
                        }
                    }
                }).then((res) => {
                    let rap_value = res.data.rape_value
                    let back = res.data.back

                    var i = this.manualPlanning.length;
                    while (i--) {
                        if (this.manualPlanning[i]['id'] == plan.id) {
                            this.manualPlanning[i]['r_rate'] = parseFloat(rap_value * plan.polish_weight).toFixed(2)
                            this.manualPlanning[i]['discount'] = back
                            this.manualPlanning[i]['value'] = parseFloat(this.manualPlanning[i]['r_rate'] - (this.manualPlanning[i]['r_rate'] * back / 100)).toFixed(2)
                        }
                    }
                    this.getPlanningTotalNew()
                })
            }

        },
        addNewPlanning(id) {

            this.manualPlanning = [{
                id: 1,
                part_no: '',
                weight: '',
                polish_weight: '',
                color: '',
                cut: '',
                purity: '',
                polish: '',
                shape: '',
                symmetry: '',
                fluerocent: '',
                tension: '',
                stone: this.packet_details.stone,
                ratio: '',
                tabl: '',
                r_rate: '',
                value: '',
                height: '',
                discount: '',
            }]

            this.getPlanningTotalNew()

            this.total_items = 1
            cash('#new-manual-planning').modal('show')
        },
        submitManualPlanning() {

            let submit = true
            this.manualPlanning.forEach(plan => {
                if (plan.weight == '' || plan.polish_weight == '' || plan.color == '' || plan.cut == '' || plan.purity == '' || plan.polish == '' || plan.shape == '' || plan.symmetry == '' || plan.fluerocent == '' || plan.height == '' || plan.ratio == '' || plan.tabl == '') {
                    swal("Warning!", "Fileds are Empty !", "warning")
                    submit = false
                }
            });

            if (submit == true) {

                this.loading = true
                let params = {
                    plan_details: this.manualPlanning,
                    packet_id: this.packet_details.id,
                    menual_total: this.menual_total_planning,
                }

                axios({
                    url: 'api/plan/add_ls_sub_pieces',
                    method: 'post',
                    baseURL: BASE_URL,
                    data: params,
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    },
                    config: {
                        headers: {
                            'Accept': 'application/json'
                        }
                    }
                }).then((res) => {

                  this.loading = false

                  if(res.data.status == 'error') {
                    this.$swal("Warning!", res.data.message, "warning")
                  } else {

                    cash('#new-manual-planning').modal('hide')
                    this.manualPlanning = [{
                        id: 1,
                        part_no: '',
                        weight: '',
                        polish_weight: '',
                        color: '',
                        cut: '',
                        purity: '',
                        polish: '',
                        shape: '',
                        symmetry: '',
                        fluerocent: '',
                        tension: '',
                        stone: '',
                        ratio: '',
                        tabl: '',
                        r_rate: '',
                        value: '',
                        height: '',
                        discount: '',
                    }]
                    this.total_items = 1


                    this.menual_total_planning.polish_weight = 0
                    this.menual_total_planning.weight = 0
                    this.menual_total_planning.value = 0
                    this.scanAndAddPack()
                    this.$swal("Success!", res.data.message, "success")
                  }

                }).catch((err) => {
                  this.loading = false
                  this.$swal("Warning!", "Something went wrong.", "warning")
                })
            }

        },
        update_print_status(item) {

          let params = {
            packet_id: window.btoa(item.id)
          }

          axios({
              url: 'api/process/update_print_status',
              method: 'post',
              baseURL: BASE_URL,
              data: params,
              headers: {
                  Authorization: 'Bearer ' + localStorage.getItem('token')
              },
              config: {
                  headers: {
                      'Accept': 'application/json'
                  }
              }
          }).then((res) => {

            if(res.data.status == 'error') {

              this.$swal("Warning!", res.data.message, "warning")
            } else if(res.data.status == 'success') {

              item.print_status = false
            }
          }).catch((err) => {
            this.loading = false
            this.$swal("Warning!", "Something went wrong.", "warning")
          })

        },
        createNewRough() {
          this.rough_cnt += 1;
          let new_arr = {
            "id": this.rough_cnt,
            "rough_weight": 0
          }

          this.rough_items.push(new_arr)
        },
        removeRough(id) {
          this.$swal({
                title: "Are you sure?",
                text: "You are about to delete a item!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Delete",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                allowOutsideClick: () => !this.$swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {
                    var i = this.rough_items.length;
                    while (i--) {
                        if (this.rough_items[i]['id'] == id) {
                            this.rough_items.splice(i, 1);
                        }
                    }
                      this.getPlanningTotal()
                }
            });
        },
        clearSearch(lot_no) {
              this.scan_lot_no = lot_no;
              this.packet_item = [];
              this.majuri_total_id = 1
              this.majuri_details = [{
                  id: 1,
                  weight: '',
                  majuri: '',
              }]
              this.majuri_total = {
                  weight: 0,
                  majuri: 0,
              }
          },
        addnewMajuriWeight() {
              this.majuri_total_id = this.majuri_total_id + 1
              this.majuri_details.push({
                  id: this.majuri_total_id,
                  weight: '',
                  majuri: '',
              })
          },
          calculateMajuri() {
              let length = this.majuri_details.length

              this.majuri_total.weight = 0
              this.majuri_total.majuri = 0

              for (let i = 0; i < length; i++) {

                  this.majuri_total.weight = parseFloat(parseFloat(this.majuri_total.weight) + parseFloat(this.majuri_details[i]['weight'] ? this.majuri_details[i]['weight'] : 0)).toFixed(4)
                  this.majuri_total.majuri = parseFloat(parseFloat(this.majuri_total.majuri) + parseFloat(this.majuri_details[i]['majuri'] ? this.majuri_details[i]['majuri'] : 0)).toFixed(2)
              }

          },
          removenewMajuriWeight(item) {

            this.$swal({
                title: "Are you sure?",
                text: "You are about cancel Weight!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Delete",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {
                    var i = this.majuri_details.length;
                    while (i--) {
                        if (this.majuri_details[i]['id'] == item.id) {
                            this.majuri_details.splice(i, 1);
                        }
                    }
                }
                this.getsubmitdetails()
                this.calculateMajuri()
            });
          },
          getMajuri_details(item) {

            console.log(item)
              this.getsubmitdetails()


              this.calculateMajuri()
              let params = {
                  weight: item.weight,
                  packet_id: this.packet_details.id
              }
              axios({
                  url: 'api/process/get_ls_majuri_details',
                  method: 'post',
                  baseURL: BASE_URL,
                  data: params,
                  headers: {
                      Authorization: 'Bearer ' + localStorage.getItem('token')
                  },
                  config: {
                      headers: {
                          'Accept': 'application/json'
                      }
                  }
              }).then((res) => {

                  let rate = res.data.rate

                  if (rate) {

                      var i = this.majuri_details.length;
                      while (i--) {
                          if (this.majuri_details[i]['id'] == item.id) {
                              this.majuri_details[i]['majuri'] = parseFloat(rate * this.majuri_details[i]['weight']).toFixed(2)
                          }
                      }
                      this.calculateMajuri()
                  }
              })
            },
            getsubmitdetails() {

              var length = this.majuri_details.length;

              for (let i = 0; i < length; i++) {
                  if (i != 0) {
                      if (parseFloat(this.majuri_details[i]['weight']) > parseFloat(this.majuri_details[i - 1]['weight'])) {
                          this.$swal("Warning!", "Majuri Weight is Not Correct!", "warning");
                          this.majuri_details[i]['weight'] = ''
                          this.majuri_details[i]['majuri'] = ''
                      }
                  }
              }
            },
    },

})
</script>
