<template>
  <div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <h2 class="text-lg font-medium mr-auto">Secret Key Access</h2>
        <a
          href="javascript:;"
          class="btn btn-primary shadow-md mr-2" @click="generateKey"
          >Generate Key</a
        >
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5 table-report table-report--tabulator"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->

    <!-- Add Color modal-->

    <div id="new-order-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">New Color</h2>
          </div>
          <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
            <div class="col-span-12">
              <label for="pos-form-1" class="form-label">Color Name</label>
              <input
                id="pos-form-1"
                v-model="colorForm.color_name"
                name="color_name"
                type="text"
                class="form-control flex-1"
                :class="{ 'border-theme-6': validate.color_name.$error }"
                placeholder="Color name"
              />
              <template v-if="validate.color_name.$error">
                    <div
                      v-for="(error, index) in validate.color_name.$errors"
                      :key="index"
                      class="text-theme-6 mt-2"
                    >
                      {{ error.$message }}
                    </div>
                  </template>
            </div>
            <div class="col-span-12">
              <label for="pos-form-2" class="form-label">Code</label>
              <input
                id="pos-form-2"
                v-model="colorForm.color_code"
                name="color_code"
                type="text"
                class="form-control flex-1"
                :class="{ 'border-theme-6': validate.color_code.$error }"
                placeholder="Code"
              />
              <template v-if="validate.color_code.$error">
                    <div
                      v-for="(error, index) in validate.color_code.$errors"
                      :key="index"
                      class="text-theme-6 mt-2"
                    >
                      {{ error.$message }}
                    </div>
                  </template>
            </div>

            <div class="col-span-12">
              <label for="pos-form-3" class="form-label"
                >Order</label
              >
              <input
                id="pos-form-3"
                type="text"
                name="order"
                v-model="colorForm.order"

                class="form-control flex-1"
                :class="{ 'border-theme-6': validate.order.$error }"
                placeholder=""
              />
              <template v-if="validate.order.$error">
                    <div
                      v-for="(error, index) in validate.order.$errors"
                      :key="index"
                      class="text-theme-6 mt-2"
                    >
                      {{ error.$message }}
                    </div>
                  </template>
            </div>
          </div>
          <div class="modal-footer text-right">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-32 mr-1"
            >
              Cancel
            </button>
            <button type="button" class="btn btn-primary w-32" @click="submitForm">
              Save Details
            </button>
            <!-- <button type="button" v-if="colorForm.id != ''" class="btn btn-danger w-32 ml-1" @click="deleteItem">
              Delete
            </button> -->
          </div>
        </div>
      </div>
    </div>

    <div
                id="success-notification-content"
                class="toastify-content hidden flex"
              >
                <CheckCircleIcon class="text-theme-9" />
                <div class="ml-4 mr-4">
                  <div class="font-medium">Details saved successfully!</div>
                  <div class="text-gray-600 mt-1">

                  </div>
                </div>
              </div>
              <!-- END: Success Notification Content -->
              <!-- BEGIN: Failed Notification Content -->
              <div
                id="failed-notification-content"
                class="toastify-content hidden flex"
              >
                <XCircleIcon class="text-theme-6" />
                <div class="ml-4 mr-4">
                  <div class="font-medium">Something went wrong!</div>
                  <div class="text-gray-600 mt-1">
                    Please check the fileld form.
                  </div>
                </div>
              </div>
              <!-- END: Failed Notification Content -->
            </div>

</template>

<script>
/* eslint-disable */
import { defineComponent, ref, reactive, toRefs,onMounted, inject } from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import Toastify from 'toastify-js'
import {
  required,
  minLength,
  maxLength,
  email,
  url,
  integer
} from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import axios from 'axios'
import VueRouter from 'vue-router'
import { useRouter, useRoute } from 'vue-router'
import { BASE_URL } from '@/constants/config.js'

export default defineComponent({
  setup() {
    const tableRef = ref()
    const tabulator = ref()
    const filter = reactive({
      field: 'color_name',
      type: 'like',
      value: ''
    })
    const swal = inject('$swal')

    const colorForm = reactive({
      color_name: '',
      color_code: '',
      color_group: '',
      order:'',
      id:''
    })

    const rules = {
      color_name: {
        required,
      },
      color_code: {
        required,

      },

      order: {
        integer
      }
    }
    const validate = useVuelidate(rules, toRefs(colorForm))

    const initTabulator = () => {
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: BASE_URL + 'api/secret/akg_glk',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxFiltering: true,
        ajaxSorting: false,
        printAsHtml: true,
        printStyled: true,
        headerSort: false,
        pagination: 'remote',
        paginationSize: 20,
        paginationSizeSelector: [10, 20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: 'collapse',
        placeholder: 'No matching records found',
        columns: [
          {
            formatter: 'responsiveCollapse',
            width: 40,
            minWidth: 30,
            hozAlign: 'center',
            resizable: false,
            headerSort: false
          },

          // For HTML table
          {
            title: 'System No',
            width: 200,
            responsive: 0,
            field: 'system_no',
            vertAlign: 'middle',
            print: false,
            download: false,
            // formatter: "textarea",

          },
          {
            title: 'Secret Key',
            width: 200,
            responsive: 0,
            field: 'key',
            vertAlign: 'middle',
            print: false,
            download: false,
            // formatter: "textarea",

          },

          {

            title: 'Createt At',
            minWidth: 100,
            field: 'created_at',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Last User',
            minWidth: 100,
            field: 'last_user',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return cell.getData().first_name + ' ' + cell.getData().last_name
            }
          },
          {

            title: 'Last Login',
            minWidth: 100,
            field: 'last_login_date',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          // {

          //   title: 'IP Address',
          //   minWidth: 100,
          //   field: 'ip',
          //   hozAlign: 'center',
          //   vertAlign: 'middle',
          //   print: false,
          //   download: false
          // },
          {
            title: 'Status',
            minWidth: 100,
            field: 'active_status',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div class="flex items-center lg:justify-center ${
                cell.getData().status == 1 ? 'text-theme-9' : 'text-theme-6'
              }">
                ${
                  cell.getData().status == 1 ? 'Active' : 'Inactive'
                }
              </div>`
            }
          },
          {
            title: 'Current Status',
            minWidth: 100,
            field: 'active_status',
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div class="flex items-center lg:justify-center ${
                cell.getData().cr_status == 1 ? 'text-theme-9' : 'text-theme-6'
              }">
                ${
                  cell.getData().cr_status == 1 ? 'Created' : 'In Use'
                }
              </div>`
            }
          },
          {
            title: 'Actions',
            minWidth: 100,
            field: 'actions',
            responsive: 1,
            hozAlign: 'left',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {

              if(cell.getData().status == 1) {

                const a = cash(`<div class="flex lg:justify-center items-center">
                  <a class="flex items-center btn btn-danger btn-sm py-1 mr-3" href="javascript:;">
                    <i data-feather="trash" class="w-4 h-4 mr-1"></i> Expire
                  </a>

                </div>`)
                cash(a).on('click', function() {
                  expireKey(cell.getData().id);
                })

                return a[0]
              } else {
                const a = cash(`<div class="flex lg:justify-center items-center">
                  <a class="flex items-center btn btn-danger disabled btn-sm py-1 mr-3" href="javascript:;">
                    <i data-feather="trash" class="w-4 h-4 mr-1"></i> Expire
                  </a>

                </div>`)
                cash(a).on('click', function() {
                  // expireKey(cell.getData().id);
                })

                return a[0]

              }
            }
          },

        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    // Filter function
    const onFilter = () => {
      tabulator.value.setFilter(filter.field, filter.type, filter.value)
    }

    const getDetails = (id) => {
      console.log(id);
      const params = {
      "id":  id,
      };

      let promise = axios({
        url: "api/master/get_color_details_by_id",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token"),
 },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

        colorForm.color_name = data.item_details.color_name;
        colorForm.color_code = data.item_details.color_code;
        colorForm.order = data.item_details.color_order;
        colorForm.id = data.item_details.id;
        cash('#new-order-modal').modal('show')

        })
        .catch(_error => {

        });
    }

    const generateKey = () => {

      swal.fire({
        title: "System No",
        input: "text",
        inputAttributes: {
          autocapitalize: "off"
        },
        showCancelButton: true,
        confirmButtonText: "Generate",
        showLoaderOnConfirm: true,
        preConfirm: async (login) => {
          let promise = axios({
              url: "api/secret/akg_gk",
              method: "post",
              baseURL: BASE_URL,
              params: {
                system_no: login
              },
              headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),

              },
              config: {
                  headers: {
                      Accept: "application/json"
                  }
              }
          });
          return promise
              .then(result => result.data)
              .then(data => {
                  if (data.status == "error") {
                      swal("Error!", data.message, "error");
                    } else {
                      initTabulator();
                    }
              })
              .catch(_error => {
                swal("Error!", "Something went wrong.", "error");
                  return [];
              });
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: `${result.value.login}'s avatar`,
            imageUrl: result.value.avatar_url
          });
        }
      });

    }

    const expireKey = (id) => {

      swal({
                title: "Are you sure?",
                text: "You are about to Expire Secret Key!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Confirm & Accept",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                preConfirm: login => {

                    let promise = axios({
                        url: "api/secret/akg_ek",
                        method: "post",
                        baseURL: BASE_URL,
                        data: {
                          key_id: window.btoa(id)
                        },
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token"),

                        },
                        config: {
                            headers: {
                                Accept: "application/json"
                            }
                        }
                    });
                    return promise
                        .then(result => result.data)
                        .then(data => {
                            console.log(data.message);
                            if (data.status == "error") {
                                swal("Error!", data.message, "error");
                              } else {
                                initTabulator();
                              }
                        })
                        .catch(_error => {
                          swal("Error!", "Something went wrong.", "error");
                            return [];
                        });
                },
                allowOutsideClick: () => !swal.isLoading()
            }).then(result => {
                if (result.value) {
                    //swal("Success!", "User Password Updated.", "success");
                }
            });

    }

    const submitForm = () => {
      validate.value.$touch()
      if (validate.value.$invalid) {
        Toastify({
          node: cash('#failed-notification-content')
            .clone()
            .removeClass('hidden')[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
      } else {


      const params = {
      "color_name":  colorForm.color_name,
      "color_code":  colorForm.color_code,
      "color_group":  colorForm.color_group,
      "color_order":  colorForm.order,
      "id" : colorForm.id
      };
      let promise_url = '';
      if(colorForm.id != '' && colorForm.id != null){
        promise_url = "api/master/update_color_details";
      }else{
        promise_url = "api/master/add_color_details"
      }
      let promise = axios({
        url: promise_url,
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token"),
 },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          Toastify({
          node: cash('#success-notification-content')
            .clone()
            .removeClass('hidden')[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()

        cash('#new-order-modal').modal('hide')
        tabulator.value.setFilter(filter.field, filter.type, filter.value)
        colorForm.color_name = '';
        colorForm.color_code = '';
        colorForm.color_group = '';
        colorForm.order = '';
        colorForm.id = '';

        validate.value.$reset()
        })
        .catch(_error => {

        });


      }

    }
    const resetID = () => {
      colorForm.color_name = '';
        colorForm.color_code = '';
        colorForm.color_group = '';
        colorForm.order = '';
        colorForm.id = '';

    }


    const deleteItem = () => {
      console.log(colorForm.id);
    }

    // On reset filter
    const onResetFilter = () => {
      filter.field = 'color_name'
      filter.type = 'like'
      filter.value = ''
      onFilter()
    }

    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'data.csv')
    }

    const onExportJson = () => {
      tabulator.value.download('json', 'data.json')
    }

    const onExportXlsx = () => {
      const win = window
      win.XLSX = xlsx
      tabulator.value.download('xlsx', 'data.xlsx', {
        sheetName: 'Products'
      })
    }

    const onExportHtml = () => {
      tabulator.value.download('html', 'data.html', {
        style: true
      })
    }

    // Print
    const onPrint = () => {
      tabulator.value.print()
    }

    onMounted(() => {
      initTabulator()
      reInitOnResizeWindow()
    })


    return {
      tableRef,
      filter,
      colorForm,
      validate,
      submitForm,
      onFilter,
      deleteItem,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      resetID,
      generateKey,
      expireKey
    }
  }
})
</script>
