<template>
  <div>
    <div class="intro-y box p-5 mt-5">
      <div class="flex justify-between">
        <div>

          <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2 ml-2 mt-2"
                 >Select Shape</label
               >
               <select
                 id="tabulator-html-filter-type"
                 v-model="page_data.price_cut"
                 class="form-select w-full mt-2 sm:mt-0 sm:w-auto"
                 @change="getOldRates()"
               >
                 <option value="All">All</option>
                 <option value="Round">ROUND</option>
                 <option value="Pear">FANCY</option>
               </select>
        </div>
        <div>
          <button class="btn btn-dark btn-sm py-2" :disabled="page_data.new_price" @click="syncRepoRates">
            <LoadingIcon v-if="page_data.new_price" icon="oval" color="white" class="w-4 h-4 ml-2" /> Sync Rates</button>
        </div>
      </div>


      <div id="responsive-table" class="p-5" v-if="page_data.load_price">
            <div class="preview">
              <div class="overflow-x-auto">
                <div
        class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
      >
        <LoadingIcon icon="bars" class="w-8 h-8" />
        <div class="text-center text-xs mt-2">Getting Latest Price</div>
      </div>
              </div>
            </div>
      </div>
      <div id="responsive-table" class="p-5" v-else>
            <div class="preview">
              <div class="overflow-x-auto">
                <table class="table">
                  <thead>
                    <tr class="bg-gray-700 dark:bg-dark-1 text-white">
                      <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">#</th>
                      <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Shape</th>
                      <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Purity</th>
                      <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Color</th>
                      <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">From</th>
                      <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">To</th>
                      <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, key) in page_data.round_rate" :key="item.id" >
                      <td class="border-b whitespace-nowrap">{{ key + 1 }}</td>
                      <td class="border-b whitespace-nowrap">{{ item.shape }}</td>
                      <td class="border-b whitespace-nowrap">{{ item.purity }}</td>
                      <td class="border-b whitespace-nowrap">{{ item.color }}</td>
                      <td class="border-b whitespace-nowrap">{{ item.weight_from }}</td>
                      <td class="border-b whitespace-nowrap">{{ item.weight_to }}</td>
                      <td class="border-b whitespace-nowrap">{{ item.price }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
      </div>
    <div>
    </div>

    </div>

  </div>
</template>

<script>
/* eslint-disable */
import { defineComponent, ref, provide, reactive, onMounted } from 'vue'
import { BASE_URL } from "@/constants/config";
import axios from 'axios';

export default defineComponent({
  setup() {

    const page_data = reactive({
      last_sync_date : '',
      round_rate : [],
      load_price:false,
      new_price:false,
      price_cut:'All',
      token: localStorage.getItem("token"),
    })

    const getOldRates = () => {
      page_data.load_price = true;
      const params = {
        "rate_id":  'TY736488',
        "cut": page_data.price_cut
      };

      let promise = axios({
        url: "api/master/get-repo-rate",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token"),
 },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

        page_data.last_sync_date = data.last_sync_date;
        page_data.round_rate = data.round_rate;
        page_data.load_price = false;
        console.log(page_data.round_rate);

        })
        .catch(_error => {

        });
    }
    const syncRepoRates = () => {
      page_data.new_price = true;
      const params = {
      };

      let promise = axios({
        url: "api/master/sync-repo",
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token"),
 },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
      .then(result => result.data)
      .then(data => {

        page_data.new_price = false;
        getOldRates();

      })
      .catch(_error => {
          page_data.new_price = false;

        });
    }

    onMounted(() => {
      getOldRates();
    })
   return {
      page_data,
      getOldRates,
      syncRepoRates
    }
  }
})
</script>

