<template>
<div>
    <!-- Headings -->
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">LAB Report</h2>
        <div class="w-full sm:w-auto flex mt-4 sm:mt-0">

        </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

        <div class="grid grid-cols-12 gap-x-2">
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div>
                  <label for="update-profile-form-6" class="form-label py-1">Report Type</label>
                    <TomSelect v-model="filter.report_type" :options="{
                  placeholder: 'Report Type',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Report'
                    }
                  }
                }" class="w-full">
                        <option value="1">LAB Result Received</option>
                        <option value="4">LAB Receive Pending</option>
                        <option value="3">LAB Received</option>
                        <option value="2">Lab Result Pending</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div class="mt-3">
                    <label for="update-profile-form-6" class="form-label">Packet / Lot</label>
                    <input id="tabulator-html-filter-value" v-model="filter.packet_no" type="text" class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0" placeholder="Search Packet / Kapan" />
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div>
                    <label for="update-profile-form-6" class="form-label py-1">Select Kapan</label>
                    <TomSelect v-model="filter.kapan_id" :options="{
                  placeholder: 'Select Kapan',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Process'
                    }
                  }
                }" class="w-full">
                        <option value="0">Select Kapan</option>
                        <option v-for="item in page_data.lot_items" :key="item.id" :value="item.id">{{item.lot_no}} ( {{ item.voucher_no ? item.voucher_no : '-' }} )</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
                <div>
                    <label for="update-profile-form-6" class="form-label py-1">Select Worker</label>
                    <TomSelect v-model="filter.worker_id" :options="{
                  placeholder: 'Select Worker',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Worker'
                    }
                  }
                }" class="w-full">
                        <option value="0">Select Worker</option>
                        <option v-for="item in page_data.employee_items" :key="item.id" :value="item.id">{{item.company_name}} ( {{ item.process_short_code ? item.short_code : '-' }} )</option>
                    </TomSelect>
                </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2">
            <div>
              <div class="flex items-center"><label for="update-profile-form-6" class="form-label pt-3"
                >Select Start Date</label
              ><input
                class="show-code form-check-switch mr-0 ml-3"
                type="checkbox" v-model="filter.issue_pending"
              /></div>
              <input
                type= "date"
                id="update-profile-form-13"
                :disabled="filter.issue_pending"
                class="form-control"
                placeholder=""
                v-model="filter.issue_date"
              />
            </div>

        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
            <label for="update-profile-form-6" class="form-label pt-3"
              >Select End Date</label
            >
            <input
              type= "date"
              id="update-profile-form-13"

              class="form-control"
              placeholder=""
              :disabled="filter.issue_pending"
              v-model="filter.receive_date"
            />
          </div>
        </div>
            <div class="col-span-2 md:col-span-2 xl:col-span-1">
                <div class="mt-7 flex pt-3">

                    <button id="tabulator-html-filter-reset" type="button" class="btn btn-secondary w-full mt-2 sm:mt-0 sm:ml-2" @click="onResetFilter">
                        Reset
                    </button>
                </div>
            </div>
            <div class="col-span-2 md:col-span-2 xl:col-span-1">
                <div class="mt-7 flex pt-3">

                    <button id="tabulator-html-filter-go" type="button" class="btn btn-primary w-full ml-2" tabindex="5" @click="getReportDetails()">
                        Generate
                    </button>
                </div>
            </div>
        </div>

        <div>
            <!-- BEgin: Process Report Start-->
            <div id="responsive-table" class="p-5" v-if="page_data.loading">
                <div class="preview">
                    <div class="overflow-x-auto">
                        <div class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center">
                            <LoadingIcon icon="bars" class="w-8 h-8" />
                            <div class="text-center text-xs mt-2">Getting Latest Price</div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-else>
                <div class="scrollbar-hidden">
                    <div class="intro-y col-span-12 overflow-auto">
                        <table class="w-full mt-3 border-2" v-if="api_filter.report_type == 1 || api_filter.report_type == 3 || api_filter.report_type == 4">
                            <thead>
                                <tr class="border-b bg-gray-300 dark:bg-dark-1">
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">#</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Packet No</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Stock No</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Worker</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Issue Date</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Receive Date</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Issue Wight</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Final Wight</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Document No</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Report Date</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Report Number</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Prefix</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Other Report Number</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Final Shape</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Shape</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Number Stones</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Description</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Measurement1</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Measurement2</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Measurement3</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Table</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Crown Height</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Pavilion Depth</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Total Depth</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Crown Angle</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Pavilion Angle</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Culet Size</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Girdle Percent</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Girdle Name</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">POL or Pol/Sym</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">SYM</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">CUT-PROP</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Clarity</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Color (Long)</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Report Type I</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Report Format I</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Color (Short)</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Report Type II</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Report Format II</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Fluorescence</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Comments Consultation</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Special Comments</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Report Comment</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Customer Ref No.</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Memo No</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Fax Comments</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Laserscribe</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Recheck</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Remark</th>
                                  </tr>
                                  <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.total_packets }}</td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_issue_weight ? $h.myFloat(page_data.total.total_issue_weight) : 0) }} Ct</td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_receive_weight ? $h.myFloat(page_data.total.total_receive_weight) : 0) }} Ct</td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                  </tr>
                            </thead>
                                <tbody>

                                  <tr v-for="(item,index) in page_data.items" :key="index" class="border-b hover:bg-gray-200 p-0" :class="{'bg-gray-100': (index % 2) == 0}">
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ index + 1 }}</td>
                                      <th class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.number }}</th>
                                      <th class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.vepari_no }}</th>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.company_name ? item.company_name : ((item.first_name ? item.first_name : '') + ' ' + (item.last_name ? item.last_name : '')) }}</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.issue_date }}</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.receive_date }}</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.issue_weight ? $h.myFloat(item.issue_weight) : 0) }} Ct</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.final_weight ? $h.myFloat(item.final_weight) : 0) }} Ct</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.document_no ? item.document_no : '-') }}</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.report_date ? item.report_date : '-') }}</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.report_number ? item.report_number : '-') }}</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.prefix ? item.prefix : '-') }}</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.other_report_number ? item.other_report_number : '-') }}</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.final_shape_name ? item.final_shape_name : '-') }}</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.shape_name ? item.shape_name : '-') }}</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.number_stones ? item.number_stones : '-') }}</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.description ? item.description : '-') }}</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.measurement_1 ? item.measurement_1 : '-') }}</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.measurement_2 ? item.measurement_2 : '-') }}</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.measurement_3 ? item.measurement_3 : '-') }}</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.table ? item.table : '-') }}</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.crown_height ? item.crown_height : '-') }}</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.pavilion_depth ? item.pavilion_depth : '-') }}</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.total_depth ? item.total_depth : '-') }}</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.crown_angle ? item.crown_angle : '-') }}</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.pavilion_angle ? item.pavilion_angle : '-') }}</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.culet_size ? item.culet_size : '-') }}</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.girdle_percent ? item.girdle_percent : '-') }}</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.girdle_name ? item.girdle_name : '-') }}</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.polish ? item.polish : '-') }}</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.sym ? item.sym : '-') }}</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.cut_prop ? item.cut_prop : '-') }}</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.clarity ? item.clarity : '-') }}</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.color_long ? item.color_long : '-') }}</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.report_type_1 ? item.report_type_1 : '-') }}</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.report_format_1 ? item.report_format_1 : '-') }}</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.color_short ? item.color_short : '-') }}</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.report_type_2 ? item.report_type_2 : '-') }}</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.report_format_2 ? item.report_format_2 : '-') }}</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.fluorescence ? item.fluorescence : '-') }}</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.comments_consultation ? item.comments_consultation : '-') }}</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.special_comments ? item.special_comments : '-') }}</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.report_comment ? item.report_comment : '-') }}</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.customer_ref_no ? item.customer_ref_no : '-') }}</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.memo_no ? item.memo_no : '-') }}</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.fax_comments ? item.fax_comments : '-') }}</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.laser_scribe ? item.laser_scribe : '-') }}</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.recheck ? item.recheck : '-') }}</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.remark ? item.remark : '-') }}</td>
                                  </tr>
                              </tbody>

                        </table>
                        <table class="w-full mt-3 border-2" v-if="api_filter.report_type == 2">
                            <thead>
                                <tr class="border-b bg-gray-300 dark:bg-dark-1">
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">#</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Packet No</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Stock No</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Worker</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Shape</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Issue Date</th>
                                    <th class="p-2 text-xs lg:text-md whitespace-nowrap">Process Wight</th>
                                  </tr>
                                  <tr class="border-b bg-gray-200 dark:bg-dark-1">
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r">{{ page_data.total.total_packets }}</td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r"></td>
                                      <td class="p-2 border-b text-xs lg:text-md text-center border-r">{{ (page_data.total.total_issue_weight ? $h.myFloat(page_data.total.total_issue_weight) : 0) }} Ct</td>

                                    </tr>
                            </thead>
                                <tbody>

                                  <tr v-for="(item,index) in page_data.items" :key="index" class="border-b hover:bg-gray-200 p-0" :class="{'bg-gray-100': (index % 2) == 0}">
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ index + 1 }}</td>
                                      <th class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.number }}</th>
                                      <th class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.vepari_no }}</th>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.company_name ? item.company_name : ((item.first_name ? item.first_name : '') + ' ' + (item.last_name ? item.last_name : '')) }}</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.final_shape_name }}</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ item.issue_date }}</td>
                                      <td class="p-2 border-b border-t text-xs whitespace-nowrap lg:text-md text-center border-r">{{ (item.issue_weight ? $h.myFloat(item.issue_weight) : 0) }} Ct</td>
                                  </tr>
                              </tbody>

                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END: HTML Table Data -->

</div>
</template>

<script>
/* eslint-disable */
import {
    BASE_URL
} from '@/constants/config';
import axios from 'axios';
import moment from 'moment';
import {
    defineComponent,
    onMounted,
    reactive,
    toRef,
    watch,
    computed
} from 'vue';
import store from '@/store'
import { helper as $h } from '@/utils/helper'

export default defineComponent({
    setup() {
        const user_details = computed(() => store.state.user.currentUser)
        const page_data = reactive({
            total: [],
            items: [],
            manager_items: [],
            process_items: [],
            lot_items: [],
            employee_items: [],
            loading: false,
        })

        const filter = reactive({
            packet_no: '',
            report_type: '1',
            kapan_id: '0',
            worker_id: '0',
            issue_pending : false,
            issue_date: moment().startOf('month').format('YYYY-MM-DD'),
            receive_date:moment().format('YYYY-MM-DD'),
        })

        const api_filter = reactive({
            packet_no: '',
            report_type: '1',
            kapan_id: '0',
            worker_id: '0',
            issue_pending : false,
            issue_date: moment().startOf('month').format('YYYY-MM-DD'),
            receive_date:moment().format('YYYY-MM-DD'),
        })

        const getReportDetails = async () => {

            api_filter.packet_no = filter.packet_no
            api_filter.report_type = filter.report_type
            api_filter.kapan_id = filter.kapan_id
            api_filter.worker_id = filter.worker_id
            api_filter.issue_pending = filter.issue_pending
            api_filter.issue_date = filter.issue_date
            api_filter.receive_date = filter.receive_date

            const params = {
                packet_no: window.btoa(api_filter.packet_no),
                report_type: api_filter.report_type == '0' ? '' : window.btoa(api_filter.report_type),
                kapan_id: api_filter.kapan_id == '0' ? '' : window.btoa(api_filter.kapan_id),
                worker_id: api_filter.worker_id == '0' ? '' : window.btoa(api_filter.worker_id),
                issue_pending : api_filter.issue_pending,
                issue_date : window.btoa(api_filter.issue_date),
                receive_date : window.btoa(api_filter.receive_date),
            }
            page_data.loading = true

            let promise = axios({
                url: 'api/report/lab_report',
                method: "post",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),

                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    page_data.items = data.items
                    page_data.total = data.total
                    page_data.loading = false
                })
                .catch(_error => {
                    page_data.items = []
                    page_data.total = []
                    page_data.loading = false
                });
        }

        const getLotDetails = async () => {
            let params = {
            }
            let api_url = 'api/master/dp_lot_all_details'

            let promise = axios({
                url: api_url,
                method: "post",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),

                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    filter.kapan_id = '0'
                    page_data.lot_items = data.items
                })
                .catch(_error => {
                    filter.kapan_id = '0'
                    page_data.lot_items = []
                });
        }

        const getWorkerDetails = async () => {

            let params = {
                manager_id: user_details.value.user_role == 1 ? '' : user_details.value.account_id,
                employee_type: window.btoa('3'),
            }
            let api_url = ''

            if (filter.department_id == '0') {

                api_url = 'api/master/get_employees_by_manager_employee_type'
            } else {
                api_url = 'api/master/get_employees_by_manager_employee_type'
            }
            let promise = axios({
                url: api_url,
                method: "post",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),

                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    filter.worker_id = '0'
                    page_data.employee_items = data.items
                })
                .catch(_error => {
                    filter.worker_id = '0'
                    page_data.employee_items = []
                });
        }

        function onResetFilter() {
            filter.packet_no = ''
            filter.report_type = '1'
            filter.kapan_id = '0'
            filter.worker_id = '0'
            filter.issue_pending = false
            filter.issue_date = moment().startOf('month').format('YYYY-MM-DD')
            filter.receive_date =moment().format('YYYY-MM-DD')
        }
        onMounted(() => {
            getLotDetails()
            getReportDetails()
            getWorkerDetails()
        })
        return {
            page_data,
            filter,
            api_filter,
            user_details,
            getReportDetails,
            onResetFilter,
            getWorkerDetails,
        }
    }
})
</script>
