<template>
  <div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">

      <div class="grid grid-cols-12 gap-x-2">
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <TomSelect
                v-model="filter.detail_type"
                :options="{
                  placeholder: 'Report Type',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Report Type'
                    }
                  }
                }"
                class="w-full"
              >
              <option value="1">Summary</option>
              <option value="2">Lot Wise</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
            <div>
              <input
                type= "date"
                id="update-profile-form-13"
                class="form-control"
                :disabled="filter.issue_pending"
                placeholder="Start Date"
                v-model="filter.start_date"
              />
            </div>

        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
            <input
              type= "date"
              id="update-profile-form-13"

              class="form-control"
              placeholder=""
              :disabled="filter.issue_pending"
              v-model="filter.end_date"
            />
          </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
              <TomSelect
                v-model="filter.party_id"
                :options="{
                  placeholder: 'Select Party',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Party'
                    }
                  }
                }"
                class="w-full" multiple
              >
              <option value="0">Select Party</option>
              <option v-for="party in page_data.parties" :key="party.id" :value="party.id">{{party.first_name}} {{ party.last_name }}</option>
              </TomSelect>
            </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div class="flex itens-center">

            <button
              id="tabulator-html-filter-reset"
              type="button"
              class="btn btn-secondary"
              @click="onResetFilter"
            >
              Reset
            </button>
            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary ml-2"
              tabindex="5"
              @click="getReportDetail(1)"
              :disabled="page_data.in_progress == true || page_data.loading == true"
            >
            Generate
            </button>
            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary ml-2"
              tabindex="5"
              @click="exportFilterData"
              :disabled="page_data.in_progress == true || page_data.loading == true"
            >
            Export
            </button>
          </div>
        </div>
      </div>

      <div>
        <!-- BEgin: Process Report Start-->
        <div id="responsive-table" class="p-5" v-if="page_data.loading">
          <div class="preview">
            <div class="overflow-x-auto">
              <div
                class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
              >
                <LoadingIcon icon="bars" class="w-8 h-8" />
                <div class="text-center text-xs mt-2">Getting Latest Price</div>
            </div>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-12 w-full gap-5 mt-2">
                <div class="col-span-12">
                    <div class="flex flex-wrap">

                        <div class="text-sm font-bold mr-6">Total Pieces: {{ page_data.total_pieces ? page_data.total_pieces : 0 }}</div>
                        <div class="text-sm font-bold mr-6">Rough Weight: {{ page_data.rough_weight ? page_data.rough_weight : 0 }} Ct</div>
                        <div class="text-sm font-bold mr-6">Polish Weight: {{ page_data.polish_weight ? page_data.polish_weight : 0 }} Ct</div>
                        <div class="text-sm font-bold mr-6">Final Weight: {{ page_data.final_weight ? page_data.final_weight : 0 }} Ct</div>
                        <div class="text-sm font-bold mr-6">Total Amount: {{ page_data.total_amount ? page_data.total_amount : 0 }}</div>
                    </div>
                </div>
            </div>

        <div class="mt-5">
          <div class="scrollbar-hidden">
            <div class="intro-y col-span-12 overflow-auto" v-if="api_filter.detail_type == 1">
              <ag-grid-vue
                class="ag-theme-alpine"
                style="height: 600px"
                :columnDefs="(columnDefs.value)"
                :rowData="rowData.value"
                :defaultColDef="defaultColDef"
                rowSelection="multiple"
                animateRows="true"
                @grid-ready="onGridReady"
                @filterChanged="calculate_total"
                :overlayNoRowsTemplate="overlayNoRowsTemplate"
                >
              </ag-grid-vue>
            </div>
            <div class="intro-y col-span-12 overflow-auto" v-if="api_filter.detail_type == 2">
              <ag-grid-vue
              class="ag-theme-alpine"
              style="height: 600px"
              :columnDefs="(lotColumnDefs.value)"
              :rowData="rowData.value"
              :defaultColDef="defaultColDef"
              rowSelection="multiple"
              animateRows="true"
              @filterChanged="calculate_total"
                @grid-ready="onGridReady"
                :overlayNoRowsTemplate="overlayNoRowsTemplate"
              >
              </ag-grid-vue>
            </div>
          </div>
        </div>
      </div>
      <!-- END: HTML Table Data -->
    </div>

  </div>
</template>
<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config';
import axios from 'axios';
import moment from 'moment';
import { defineComponent, onMounted, reactive, ref, toRef, watch, computed } from 'vue';
import store from '@/store'
import { AgGridVue } from "ag-grid-vue3";
import ShapeRenderer from '@/custom-renderer/ShapeRenderer.js'
import {
  ColDef,
  ColGroupDef,
  GridApi,
  onGridReady,
  GridOptions,
  GridReadyEvent,
  IDateFilterParams,
  IMultiFilterParams,
  ISetFilterParams,
  ITextFilterParams,
  ModuleRegistry,
  SideBarDef,
  createGrid,
} from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { CsvExportModule } from "@ag-grid-community/csv-export";
import { ClipboardModule } from "@ag-grid-enterprise/clipboard";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { MultiFilterModule } from "@ag-grid-enterprise/multi-filter";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { helper as $h} from '@/utils/helper'

import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
// import "styag-grid-community/les/ag-theme-alpine.css"; // Optional theme CSS


export default defineComponent({
  components: {
   AgGridVue,
  //  ShapeRenderer
 },
  setup() {
    const page_data = reactive({
      parties: [],
      current_page: 1,
      sr_no: 1,
      last_page: "",
      per_page: 200,
      total_records: 0,
      total_pieces: 0,
      rough_weight: 0,
      polish_weight: 0,
      final_weight: 0,
      total_amount: 0,
      total_jangad: 0,
      loading: false,
      generated: false,
      in_progress: false,
    })

    const filter = reactive({
      start_date : moment().startOf('month').format('YYYY-MM-DD'),
      end_date : moment().endOf('month').format('YYYY-MM-DD'),
      party_id : [],
      detail_type : '1',
      report_type : '1',
      issue_pending : false,
    })
    const api_filter = reactive({
      start_date : moment().startOf('month').format('YYYY-MM-DD'),
      end_date : moment().endOf('month').format('YYYY-MM-DD'),
      party_id : [],
      detail_type : '1',
      report_type : '1',
      issue_pending : false,
    })

    const gridApi = ref();

    const rowData = reactive({
      value:[]
    });

    const onGridReady = (params) => {
    gridApi.value = params.api;
    };


    const defaultColDef = {
      sortable: true,
      filter: true,
      // floatingFilter: true,
      // menuTabs: ["filterMenuTab"],
      // flex: 1
      flex: 1,
      minWidth: 200,
      floatingFilter: true,
      menuTabs: ["filterMenuTab"],
    };

    ModuleRegistry.registerModules([
      ClientSideRowModelModule,
      CsvExportModule,
      ClipboardModule,
      MenuModule,
      MultiFilterModule,
      SetFilterModule,
    ]);

    const overlayNoRowsTemplate = 'Scan packets for polish receive!'

   const columnDefs = reactive({
     value: [
     { headerName: "#",field: 'sr_no',maxWidth: 60 },
      { headerName: "Outward No",field: 'outward_no',minWidth: 100},
      { headerName: "Party",field: 'party_name',minWidth: 100},
      { headerName: "Pieces",field: 'total_pieces',minWidth: 100},
      { headerName: "Rough(Ct)",field: 'rough_weight',minWidth: 100},
      { headerName: "Polish(Ct)",field: 'polish_weight',minWidth: 100},
      { headerName: "Final(Ct)",field: 'total_weight',minWidth: 100},
      { headerName: "Total Price",field: 'total_amount',minWidth: 100},
      { headerName: "Date",field: 'outward_date',minWidth: 100},
     ],
   });
    const lotColumnDefs = reactive({
     value: [
      { headerName: "Sr. No", field: "sr_no", minWidth: 100 },
      { headerName: "Type", field: "type", minWidth: 100 },
      { headerName: "Packet No", field: "packet_no", minWidth: 150 },
      { headerName: "Stock No", field: "stock_no", minWidth: 150 },
      { headerName: "Rough WT", field: "rough_weight", minWidth: 150 },
      { headerName: "Polish WT", field: "polish_weight", minWidth: 150 },
      { headerName: "Final Polish", field: "final_weight", minWidth: 150 },
      { headerName: "Shape", field: "shape", minWidth: 100 },
      { headerName: "Color", field: "color", minWidth: 100 },
      { headerName: "Purity", field: "purity", minWidth: 100 },
      { headerName: "Cut", field: "cut", minWidth: 100 },
      { headerName: "Polish", field: "polish", minWidth: 100 },
      { headerName: "Symm", field: "symmetry", minWidth: 100 },
      { headerName: "Job Bhav", field: "karigar_bhav", minWidth: 150 },
      { headerName: "Job Majuri", field: "job_done_value", minWidth: 150 },
      { headerName: "Date", field: "outward_date", minWidth: 100 },
      { headerName: "Status", field: "status", minWidth: 100 }
     ],
   });

    const getReportDetail = (index) => {

      api_filter.start_date = filter.start_date
      api_filter.end_date = filter.end_date
      api_filter.party_id = filter.party_id
      api_filter.detail_type = filter.detail_type
      api_filter.report_type = filter.report_type
      api_filter.issue_pending = filter.issue_pending


      page_data.current_page = index
      if(page_data.current_page == 1) {

        rowData.value = []
        gridApi.value.setRowData(rowData.value);
        page_data.loading = true
        page_data.sr_no = 1;
      }
      page_data.in_progress = true


        let params = {
          start_date : api_filter.start_date,
          end_date : api_filter.end_date,
          issue_pending : api_filter.issue_pending,
          detail_type : api_filter.detail_type,
          report_type : api_filter.report_type,
          party_id : api_filter.party_id.length == 0 ? '' : api_filter.party_id ,
          page : page_data.current_page,
          size : page_data.per_page,
        }

        let promise = axios({
          url: 'api/report/job-done-report',
          method: "post",
          data: params,
          baseURL: BASE_URL,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),

          },
          config: { headers: { Accept: "application/json" } }
        });
        return promise
        .then(result => result.data)
        .then(data => {

          page_data.loading = false

          let items = data.data


            if(api_filter.detail_type == 1) {

              items.map(item => {
                rowData.value.push({
                    'id' : item.id,
                    'sr_no' : page_data.sr_no,
                    'outward_no' : item.transaction_code,
                    'rough_weight' : item.rough_weight,
                    'polish_weight' : item.polish_weight,
                    'total_weight' : item.total_weight,
                    'total_pieces' : item.total_pieces,
                    'transaction_type' : item.transaction_type,
                    'total_amount' : item.total_amount,
                    'issue_by' : item.issue_by,
                    'created_by' : item.created_by,
                    'issue_daye' : item.issue_daye,
                    'party_name' : item.party.firm_name,
                    'manager_name' : (item.manager.first_name + ' ' + (item.manager.last_name ? item.manager.last_name : '')),
                    'outward_date' : item.issue_date
                  });
                  page_data.sr_no += 1;
              });

              gridApi.value.setRowData(rowData.value);

            } else if(api_filter.detail_type == 2) {

              items.map(item => {
                rowData.value.push({
                  'id' : item.id,
                  'sr_no' : page_data.sr_no,
                  'type' : (item.job_type == 1 ? "Job Done" : (item.job_type == 2 ? "Repairing" : "Cancelled")),
                  'packet_no' : item.packet.number,
                  'stock_no' : item.packet.vepari_no,
                  'rough_weight' : item.weight,
                  'polish_weight' : item.polish_weight,
                  'final_weight' : item.final_weight,
                  'color' : item.color_item.color_name,
                  'cut' : item.cut_item.cut_name,
                  'polish' : item.polish_item.polish_name,
                  'symmetry' : item.symmetry_item.symmetry_name,
                  'purity' : item.purity_item.purity_name,
                  'shape' : item.shape_item.shape_name,
                  'fluerocent' : item.fluerocent_item.fluerocent_name,
                  'stone' : item.stone_item.stone_name,
                  'karigar_bhav' : item.makeable_bhav?.rates,
                  'job_done_value' : item.job_done_value,
                  'outward_date' : item.issue_date,
                  'status' : (item.status == 1 ? "Delivered" : (item.status == 2 ? "Returned" : ((item.status == 3 ? "Cancelled" : '-'))))
                });
                page_data.sr_no += 1;
              });

              gridApi.value.setRowData(rowData.value);
            }
            calculate_total()

            if(page_data.current_page == 1) {
              page_data.last_page = data.last_page
            }

            if(page_data.current_page == data.last_page) {
              page_data.in_progress = false;
            } else {
              page_data.current_page += 1;
              getReportDetail(page_data.current_page);
            }

        })
        .catch(_error => {
          // page_data.items = []
          page_data.loading = false
        });
    }
    const exportFilterData = () => {
      gridApi.value.exportDataAsCsv();
    }

    const calculate_total = () => {

      let total_pieces = 0;
      let rough_weight = 0;
      let polish_weight = 0;
      let final_weight = 0;
      let total_amount = 0;
      let total_jangad = 0;
      let items = gridApi.value.getModel().rowsToDisplay

      if(api_filter.detail_type == 1) {

        items.forEach(element => {
          console.log(element.data)

          total_pieces += element.data.total_pieces
          rough_weight += parseFloat(element.data.rough_weight)
          polish_weight += parseFloat(element.data.polish_weight)
          final_weight += parseFloat(element.data.total_weight)
          total_amount += parseFloat(element.data.total_amount)
          total_jangad += 1
        });
      } else {
        items.forEach(element => {

          total_pieces += 1
          rough_weight += parseFloat(element.data.rough_weight)
          polish_weight += parseFloat(element.data.polish_weight)
          final_weight += parseFloat(element.data.final_weight)
          total_amount += parseFloat(element.data.job_done_value)
        });

      }

      page_data.total_pieces = total_pieces;
      page_data.rough_weight = $h.myFloat(rough_weight);
      page_data.polish_weight = $h.myFloat(polish_weight);
      page_data.final_weight = $h.myFloat(final_weight);
      page_data.total_amount = $h.myFloat(total_amount);
      page_data.total_jangad = total_jangad;
    }

    window.getValue = function getValue(inputSelector) {
      var text = document.querySelector(inputSelector).value;
      switch (text) {
        case "none":
          return;
        case "tab":
          return "\t";
        default:
          return text;
      }
    };

    window.getParams = function getParams() {
        return {
          columnSeparator: getValue("#columnSeparator"),
        };
      };

    const getPartyDetails = () => {

        let params = {
          // department_id : window.btoa(filter.department_id)
        }
        let api_url = 'api/master/dp_parties'
        let promise = axios({
          url: api_url,
          method: "post",
          data: params,
          baseURL: BASE_URL,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),

          },
          config: { headers: { Accept: "application/json" } }
        });
        return promise
          .then(result => result.data)
          .then(data => {
            page_data.parties = data.items
          })
          .catch(_error => {
            page_data.parties = []
          });
    }
    function onResetFilter() {
      filter.start_date = moment().startOf('month').format('YYYY-MM-DD')
      filter.end_date = moment().endOf('month').format('YYYY-MM-DD')
      filter.party_id = []
      filter.detail_type = '1'
      filter.report_type = '0'
      filter.issue_pending = false
    }
    onMounted(() => {
      getPartyDetails()
      setTimeout(() => {

        getReportDetail(1)
      }, 200);

    })
    return {
      getReportDetail, exportFilterData, page_data, filter, onResetFilter, columnDefs, rowData, overlayNoRowsTemplate, onGridReady, defaultColDef, ModuleRegistry, lotColumnDefs, api_filter,
      calculate_total, deselectRows: () =>{
        gridApi.value.deselectAll()
      }
    }
  }
})
</script>
