<template>
  <div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <form id="tabulator-html-filter-form" class="xl:flex sm:mr-auto">
          <div class="grid grid-cols-12 gap-x-2">
            <div class="col-span-6 md:col-span-4 xl:col-span-3 my-1">
              <div class="flex items-center sm:mr-2">

                <label class="mr-2"
                  >Field</label
                >
                <select
                  id="tabulator-html-filter-field"
                  v-model="filter.field"
                  class="form-select"
                >
                <option value="charni_name">Charni Name</option>
                <option value="charni_code">Charni Code</option>
                <option value="charni_from">Charni From</option>
                <option value="charni_to">Charni To</option>
                </select>
              </div>
            </div>
            <div class="col-span-6 md:col-span-3 xl:col-span-2 my-1">
              <div class="flex items-center sm:mr-2">

                <label class="mr-2"
                >Type</label
              >
              <select
                id="tabulator-html-filter-type"
                v-model="filter.type"
                class="form-select w-fullo"
              >
                <option value="like" selected>like</option>
                <option value="=">=</option>
                <option value="<">&lt;</option>
                <option value="<=">&lt;=</option>
                <option value=">">></option>
                <option value=">=">>=</option>
                <option value="!=">!=</option>
              </select>
              </div>
            </div>
            <div class="col-span-6 md:col-span-4 xl:col-span-3 my-1">
              <div class="flex items-center sm:mr-2">

                <label class="mr-2">Value</label>
                <input
                  id="tabulator-html-filter-value"
                  v-model="filter.value"
                  type="text"
                  class="form-control sm:w-40"
                  placeholder="Search..."
                />
              </div>
            </div>
            <div class="col-span-3 md:col-span-2 xl:col-span-1 my-1">
              <button
                  id="tabulator-html-filter-go"
                  type="button"
                  class="btn btn-primary w-full"
                  @click="onFilter"
                >
                  Go
                </button>
            </div>
            <div class="col-span-3 md:col-span-2 xl:col-span-1 my-1">
              <button
                  id="tabulator-html-filter-reset"
                  type="button"
                  class="btn btn-secondary w-full"
                  @click="onResetFilter"
                >
                  Reset
                </button>
            </div>
          </div>
        </form>
        <div class="flex mt-5 sm:mt-0">
          <a
          href="javascript:;"
          data-toggle="modal"
          data-target="#new-order-modal"
          class="btn btn-primary shadow-md mr-2" @click="resetID"
          >New Charni</a
        >
          <router-link
          to="/master/mix-charni-rates"
          class="btn btn-primary shadow-md mr-2"
          >Rates</router-link
        >
          <div class="dropdown w-1/2 sm:w-auto">
            <button
              class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto"
              aria-expanded="false"
            >
              <FileTextIcon class="w-4 h-4 mr-2" /> Export
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-menu w-40">
              <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                <a
                  id="tabulator-export-csv"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportCsv"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export CSV
                </a>
                <a
                  id="tabulator-export-json"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportJson"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export JSON
                </a>
                <a
                  id="tabulator-export-xlsx"
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="onExportXlsx"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX
                </a>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5 table-report table-report--tabulator"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->

    <!-- Add Charni modal-->

    <div id="new-order-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="font-medium text-base mr-auto">New Charni</h2>
          </div>
          <div class="modal-body grid grid-cols-12 gap-4 gap-y-1">
            <div class="col-span-12">
              <label for="pos-form-1" class="form-label">Charni Name</label>
              <input
                id="pos-form-1"
                v-model="charniForm.charni_name"
                name="charni_name"
                type="text"
                class="form-control flex-1"
                :class="{ 'border-theme-6': validate.charni_name.$error }"
                placeholder="Charni name"
              />
              <template v-if="validate.charni_name.$error">
                    <div
                      v-for="(error, index) in validate.charni_name.$errors"
                      :key="index"
                      class="text-theme-6 mt-1"
                    >
                      {{ error.$message }}
                    </div>
                  </template>
            </div>
            <div class="col-span-12">
              <label for="pos-form-2" class="form-label">Code</label>
              <input
                id="pos-form-2"
                v-model="charniForm.charni_code"
                name="charni_code"
                type="text"
                class="form-control flex-1"
                :class="{ 'border-theme-6': validate.charni_code.$error }"
                placeholder="Code"
              />
              <template v-if="validate.charni_code.$error">
                    <div
                      v-for="(error, index) in validate.charni_code.$errors"
                      :key="index"
                      class="text-theme-6 mt-1"
                    >
                      {{ error.$message }}
                    </div>
                  </template>
            </div>
            <div class="col-span-12">
              <label for="pos-form-2" class="form-label">Code</label>
              <input
                id="pos-form-2"
                v-model="charniForm.charni_code"
                name="charni_code"
                type="text"
                class="form-control flex-1"
                :class="{ 'border-theme-6': validate.charni_code.$error }"
                placeholder="Code"
              />
              <template v-if="validate.charni_code.$error">
                    <div
                      v-for="(error, index) in validate.charni_code.$errors"
                      :key="index"
                      class="text-theme-6 mt-1"
                    >
                      {{ error.$message }}
                    </div>
                  </template>
            </div>
            <div class="col-span-12">
              <label for="pos-form-2" class="form-label">Charni From</label>
              <input
                id="pos-form-2"
                v-model="charniForm.charni_from"
                name="charni_from"
                type="text"
                class="form-control flex-1"
                :class="{ 'border-theme-6': validate.charni_from.$error }"
                placeholder="Code"
              />
              <template v-if="validate.charni_from.$error">
                    <div
                      v-for="(error, index) in validate.charni_from.$errors"
                      :key="index"
                      class="text-theme-6 mt-1"
                    >
                      {{ error.$message }}
                    </div>
                  </template>
            </div>
            <div class="col-span-12">
              <label for="pos-form-2" class="form-label">Charni To</label>
              <input
                id="pos-form-2"
                v-model="charniForm.charni_to"
                name="charni_to"
                type="text"
                class="form-control flex-1"
                :class="{ 'border-theme-6': validate.charni_to.$error }"
                placeholder="Code"
              />
              <template v-if="validate.charni_to.$error">
                    <div
                      v-for="(error, index) in validate.charni_to.$errors"
                      :key="index"
                      class="text-theme-6 mt-1"
                    >
                      {{ error.$message }}
                    </div>
                  </template>
            </div>

            <div class="col-span-12">
              <label for="pos-form-3" class="form-label"
                >Order</label
              >
              <input
                id="pos-form-3"
                type="text"
                name="order"
                v-model="charniForm.order"

                class="form-control flex-1"
                :class="{ 'border-theme-6': validate.order.$error }"
                placeholder=""
              />
              <template v-if="validate.order.$error">
                    <div
                      v-for="(error, index) in validate.order.$errors"
                      :key="index"
                      class="text-theme-6 mt-1"
                    >
                      {{ error.$message }}
                    </div>
                  </template>
            </div>
          </div>
          <div class="modal-footer text-right">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-32 mr-1"
            >
              Cancel
            </button>
            <button type="button" class="btn btn-primary w-32" @click="submitForm">
              Save Details
            </button>
            <!-- <button type="button" v-if="charniForm.id != ''" class="btn btn-danger w-32 ml-1" @click="deleteItem">
              Delete
            </button> -->
          </div>
        </div>
      </div>
    </div>

    <div
                id="success-notification-content"
                class="toastify-content hidden flex"
              >
                <CheckCircleIcon class="text-theme-9" />
                <div class="ml-4 mr-4">
                  <div class="font-medium">Details saved successfully!</div>
                  <div class="text-gray-600 mt-1">

                  </div>
                </div>
              </div>
              <!-- END: Success Notification Content -->
              <!-- BEGIN: Failed Notification Content -->
              <div
                id="failed-notification-content"
                class="toastify-content hidden flex"
              >
                <XCircleIcon class="text-theme-6" />
                <div class="ml-4 mr-4">
                  <div class="font-medium">Something went wrong!</div>
                  <div class="text-gray-600 mt-1">
                    Please check the fileld form.
                  </div>
                </div>
              </div>
              <!-- END: Failed Notification Content -->
            </div>

</template>

<script>
/* eslint-disable */
import { defineComponent, ref, reactive, toRefs,onMounted } from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import Toastify from 'toastify-js'
import {
  required,
  minLength,
  maxLength,
  email,
  url,
  integer
} from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import axios from 'axios'
import VueRouter from 'vue-router'
import { useRouter, useRoute } from 'vue-router'
import { BASE_URL } from '@/constants/config.js'

export default defineComponent({
  setup() {
    const tableRef = ref()
    const tabulator = ref()
    const filter = reactive({
      field: 'charni_name',
      type: 'like',
      value: ''
    })

    const charniForm = reactive({
      charni_name: '',
      charni_code: '',
      charni_group: '',
      charni_from: '',
      charni_to: '',
      order:'',
      id:''
    })

    const rules = {
      charni_name: {
        required,
      },
      charni_code: {
        required,

      },
      charni_from: {
        required,

      },
      charni_to: {
        required,

      },

      order: {
        integer
      }
    }
    const validate = useVuelidate(rules, toRefs(charniForm))

    const initTabulator = () => {
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: BASE_URL + 'api/master/get_mix_charni_details',
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 20,
        paginationSizeSelector: [10, 20, 30, 40],
        layout: 'fitColumns',
        headerSort: true,
        responsiveLayout: 'collapse',
        placeholder: 'No matching records found',
        columns: [
          {
            formatter: 'responsiveCollapse',
            width: 40,
            minWidth: 30,
            hozAlign: 'center',
            resizable: false,
            headerSort: false
          },

          // For HTML table
          {
            title: 'Charni Name',
            minWidth: 100,
            responsive: 0,
            field: 'charni_name',
            vertAlign: 'middle',
            print: false,
            download: false,

          },

          {

            title: 'Charni Code',
            minWidth: 100,
            field: 'charni_code',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {

            title: 'From (Ct)',
            minWidth: 100,
            field: 'charni_from',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {

            title: 'To (Ct)',
            minWidth: 100,
            field: 'charni_to',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Order',
            minWidth: 100,
            field: 'charni_order',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Status',
            minWidth: 100,
            field: 'active_status',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div class="flex items-center lg:justify-center ${
                cell.getData().active_status == 'Active' ? 'text-theme-9' : 'text-theme-6'
              }">
                <i data-feather="check-square" class="w-4 h-4 mr-2"></i> ${
                  cell.getData().active_status == 'Active' ? 'Active' : 'Inactive'
                }
              </div>`
            }
          },
          {
            title: 'Actions',
            minWidth: 100,
            field: 'actions',
            responsive: 1,
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              const a = cash(`<div class="flex lg:justify-center items-center">
                <a class="flex items-center mr-3" href="javascript:;">
                  <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Edit
                </a>

              </div>`)
              cash(a).on('click', function() {
                getDetails(cell.getData().id);
              })

              return a[0]
            }
          },

          // For print format
          {
            title: 'Charni Name',
            field: 'charni_name',
            visible: false,
            print: true,
            download: true
          },
          {
            title: 'Charni Code',
            field: 'charni_code',
            visible: false,
            print: true,
            download: true
          },
          {
            title: 'From (Ct)',
            field: 'charni_from',
            visible: false,
            print: true,
            download: true
          },
          {
            title: 'To (Ct)',
            field: 'charni_to',
            visible: false,
            print: true,
            download: true
          },
          {
            title: 'Status',
            field: 'status',
            visible: false,
            print: true,
            download: true,
            formatterPrint(cell) {
              return cell.getValue() ? 'Active' : 'Inactive'
            }
          },

        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    // Filter function
    const onFilter = () => {
      tabulator.value.setFilter(filter.field, filter.type, filter.value)
    }

    const getDetails = (id) => {
      console.log(id);
      const params = {
      "id":  id,
      };

      let promise = axios({
        url: "api/master/get_mix_charni_details_by_id",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token"),
 },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {

        charniForm.charni_name = data.item_details.charni_name;
        charniForm.charni_code = data.item_details.charni_code;
        charniForm.charni_from = data.item_details.charni_from;
        charniForm.charni_to = data.item_details.charni_to;
        charniForm.order = data.item_details.charni_order;
        charniForm.id = data.item_details.id;
        cash('#new-order-modal').modal('show')

        })
        .catch(_error => {

        });
    }

    const submitForm = () => {
      validate.value.$touch()
      if (validate.value.$invalid) {
        Toastify({
          node: cash('#failed-notification-content')
            .clone()
            .removeClass('hidden')[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
      } else {


      const params = {
      "charni_name":  charniForm.charni_name,
      "charni_code":  charniForm.charni_code,
      "charni_group":  charniForm.charni_group,
      "charni_from":  charniForm.charni_from,
      "charni_to":  charniForm.charni_to,
      "charni_order":  charniForm.order,
      "id" : charniForm.id
      };
      let promise_url = '';
      if(charniForm.id != '' && charniForm.id != null){
        promise_url = "api/master/update_mix_charni_details";
      }else{
        promise_url = "api/master/add_mix_charni_details"
      }
      let promise = axios({
        url: promise_url,
        method: "POST",
        data: params,
        baseURL: BASE_URL,
        headers: { Authorization: "Bearer " + localStorage.getItem("token"),
 },
        config: { headers: { Accept: "application/json" } }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          Toastify({
          node: cash('#success-notification-content')
            .clone()
            .removeClass('hidden')[0],
          duration: 3000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()

        cash('#new-order-modal').modal('hide')
        tabulator.value.setFilter(filter.field, filter.type, filter.value)
        charniForm.charni_name = '';
        charniForm.charni_code = '';
        charniForm.charni_group = '';
        charniForm.charni_from = '';
        charniForm.charni_to = '';
        charniForm.order = '';
        charniForm.id = '';
        validate.value.$reset()
        })
        .catch(_error => {

        });


      }

    }
    const resetID = () => {
      charniForm.charni_name = '';
        charniForm.charni_code = '';
        charniForm.charni_group = '';
        charniForm.charni_from = '';
        charniForm.charni_to = '';
        charniForm.order = '';
        charniForm.id = '';
    }


    const deleteItem = () => {
      console.log(charniForm.id);
    }

    // On reset filter
    const onResetFilter = () => {
      filter.field = 'charni_name'
      filter.type = 'like'
      filter.value = ''
      onFilter()
    }

    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'data.csv')
    }

    const onExportJson = () => {
      tabulator.value.download('json', 'data.json')
    }

    const onExportXlsx = () => {
      const win = window
      win.XLSX = xlsx
      tabulator.value.download('xlsx', 'data.xlsx', {
        sheetName: 'Products'
      })
    }

    const onExportHtml = () => {
      tabulator.value.download('html', 'data.html', {
        style: true
      })
    }

    // Print
    const onPrint = () => {
      tabulator.value.print()
    }

    onMounted(() => {
      initTabulator()
      reInitOnResizeWindow()
    })


    return {
      tableRef,
      filter,
      charniForm,
      validate,
      submitForm,
      onFilter,
      deleteItem,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      resetID
    }
  }
})
</script>
